(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("main.blogs", {
            abstract: true,
            templateUrl: "layout/main/blog/blog.html"
        });
    });
})();
