(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmYouTubePlayer")
        .directive("ssmYouTubePlayer", ssmYouTubePlayer);

    function ssmYouTubePlayer() {
        return {
            templateUrl: "components/ssmYouTubePlayer/ssmYouTubePlayer.html",
            restrict: "E",
            scope: {
                videoId: "@"
            },
            replace: true,
            controller: ssmYouTubePlayerController,
            controllerAs: "vm",
            bindToController: true,
            link: link
        };

        function link(scope, element, attrs, controller) {
            var player = new YT.Player(element.children()[0], {
                // jshint ignore:line
                playerVars: {
                    autoplay: 0,
                    html5: 1,
                    fs: 1,
                    playsinline: 0,
                    rel: 0,
                    modesbranding: 1,
                    iv_load_policy: 3,
                    showinfo: 1,
                    controls: 1,
                    enablejsapi: 1
                },
                videoId: attrs.videoId || "",
                events: {
                    onReady: controller.onPlayerReady,
                    onStateChange: controller.onPlayerStateChange
                }
            });

            controller.player = player;
        }
    }

    function ssmYouTubePlayerController(Analytics, CurrentUser) {
        /*jshint validthis: true */
        var vm = this;

        var PLAYER_STATE = {
            NOT_STARTED: -1,
            STOP: 0,
            PLAY: 1,
            PAUSE: 2,
            BUFFERING: 3,
            VIDEO_CUED: 5
        };

        vm.onPlayerReady = onPlayerReady;
        vm.onPlayerStateChange = onPlayerStateChange;

        activate();

        function activate() {
            vm.userProfile = CurrentUser.profile;
        }

        function onPlayerReady(event) {}

        function onPlayerStateChange(event) {
            var videoId =
                event.target && event.target.getVideoData()
                    ? event.target.getVideoData().video_id
                    : "UNKNOWN_VIDEO_ID";
            var userId = vm.userProfile ? vm.userProfile.id : "UNKNOWN_USER_ID";
            var duration = event.target.getDuration()
                ? event.target.getDuration()
                : 1;
            var currentTime = event.target.getCurrentTime()
                ? event.target.getCurrentTime()
                : 0.5;
            var percentViewed = currentTime / duration;

            switch (event.data) {
                case PLAYER_STATE.STOP:
                    Analytics.eventTrack("stopVideo", {
                        category: "ssmYouTubePlayerController",
                        label: videoId,
                        dimension2: percentViewed,
                        dimension4: userId
                    });
                    Analytics.eventTrack2('stop_video', {
                        category: "ssmYouTubePlayerController",
                        label: videoId,
                        dimension2: percentViewed,
                        dimension4: userId
                    });
                    break;

                case PLAYER_STATE.PLAY:
                    Analytics.eventTrack("playVideo", {
                        category: "ssmYouTubePlayerController",
                        label: videoId,
                        dimension2: percentViewed,
                        dimension4: userId
                    });
                    Analytics.eventTrack2('play_video', {
                        category: "ssmYouTubePlayerController",
                        label: videoId,
                        dimension2: percentViewed,
                        dimension4: userId
                    });
                    break;

                case PLAYER_STATE.PAUSE:
                    Analytics.eventTrack("pauseVideo", {
                        category: "ssmYouTubePlayerController",
                        label: videoId,
                        dimension2: percentViewed,
                        dimension4: userId
                    });
                    Analytics.eventTrack2('pause_video', {
                        category: "ssmYouTubePlayerController",
                        label: videoId,
                        dimension2: percentViewed,
                        dimension4: userId
                    });
                    break;

                default:
                    break;
            }
        }
    }
})();
