(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsFindCtrl", OrgsFindCtrl);

    function OrgsFindCtrl(FindOrg, $state, $translate, SSMTitle) {
        /*jshint validthis: true */
        var vm = this;
        vm.submit = submit;
        vm.reset = reset;
        vm.data = FindOrg.data;
        $translate("TITLE.FIND_ORGANIZATIONS").then(function(title) {
            SSMTitle.setTitle(title);
        });

        function submit() {
            $state.go("main.orgs.find_results");
        }

        function reset() {
            FindOrg.initFields();
        }
    }
})();
