(function() {
    "use strict";

    angular.module("ssmAngularApp.ssmAuth.service", [
        "ui.router",
        "ssmAngularApp.analytics",
        "ssmAngularApp.config",
        "ssmAngularApp.cookie",
        "ssmAngularApp.logger",
        "ssmAngularApp.ssmLocalStorage",
        "ssmAngularApp.tenant.service",
        "ssmAngularApp.user.service",
    ]);
})();
