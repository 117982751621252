(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("main.externalInvites", {
            url: "/externalInvites",
            templateUrl: "layout/main/externalInvites/externalInvites.html",
            controller: "ExternalInvitesCtrl",
            controllerAs: "vm"
        });
    });
})();
