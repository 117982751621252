(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmContentFeed")
        .directive("ssmContentFeed", ssmContentFeed)
        .controller("ssmContentFeedController", ssmContentFeedController);

    function ssmContentFeed() {
        return {
            templateUrl: "components/ssmContentFeed/ssmContentFeed.html",
            restrict: "E",
            replace: true,
            scope: {
                data: "=",
                owner: "="
            },
            controller: ssmContentFeedController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmContentFeedController(
        $scope,
        $translate,
        $uibModal,
        Content,
        ssmMarketplace
    ) {
        /*jshint validthis:true */
        var vm = this;
        vm.removeContent = removeContent;
        vm.removeResponse = removeResponse;
        vm.removeEmbed = removeEmbed;
        vm.showChildren = showChildren;
        vm.filterChanged = filterChanged;
        vm.nextPage = nextPage;
        vm.disableInfiniteScrollEvents = false;
        vm.checkMixedContent = checkMixedContent;

        var CONTENT_LIMIT = 8;

        activate();

        function activate() {
            vm.data = vm.data || [];
            vm.filter = "both";
            vm.shown = vm.data;

            $scope.$watch(
                function() {
                    return vm.data && vm.data.length;
                },
                function(newValue, oldValue) {
                    if (oldValue === newValue) {
                        return;
                    }

                    filterChanged();
                }
            );
        }

        function nextPage() {
            vm.disableInfiniteScrollEvents = true;
            var params = {
                sort: { update_date: -1 },
                content_type: ["question", "article"],
                limit: CONTENT_LIMIT
            };

            var lastPost = _.last(vm.data);
            if (lastPost) {
                params.before_date = lastPost.update_date;
            }

            ssmMarketplace
                .getAllContentForListing(vm.owner && vm.owner.id, params)
                .then(function(results) {
                    if (!results || results.length === 0) {
                        // leave infinite scroll disabled
                        return;
                    }
                    vm.data.push.apply(vm.data, results);
                    vm.disableInfiniteScrollEvents = false;

                    vm.checkMixedContent();
                })
                .catch(function(error) {
                    // Error getting content, so leave infinite scroll disabled
                    console.log(error);
                });
        }

        function removeContent(content) {
            var uibModalInstance = confirmDeleteModal(
                "MARKETPLACE_LISTING.CONFIRM_DELETE_QUESTION"
            );
            uibModalInstance.result.then(function(response) {
                if (response.confirmed) {
                    Content.remove(content.id).then(function(result) {
                        _.remove(vm.data, function(n) {
                            return n.id === content.id;
                        });
                    });
                }
            });
        }

        function removeEmbed(content) {
            var uibModalInstance = confirmDeleteModal(
                "CONTENT.CONFIRM_DELETE_EMBEDLY"
            );
            uibModalInstance.result.then(function(response) {
                if (response.confirmed) {
                    if (!content) {
                        return;
                    }

                    content.embed = null;
                    var updatedPost = {
                        embed: null
                    };

                    Content.update(content.id, updatedPost);
                }
            });
        }

        function checkMixedContent() {
            var questions = _.filter(vm.data, function(item) {
                return item.content_type === "question";
            });

            var articles = _.filter(vm.data, function(item) {
                return item.content_type === "question";
            });

            if (questions.length && articles.length) {
                vm.mixedContent = true;
            } else {
                vm.mixedContent = false;
            }
        }

        function confirmDeleteModal(confirmText) {
            var promise = $uibModal.open({
                templateUrl: "components/modal/areYouSure/areYouSure.html",
                controller: "AreYouSureCtrl as vm",
                resolve: {
                    textResolve: function() {
                        return $translate(confirmText);
                    }
                }
            });
            return promise;
        }

        function removeResponse(response) {
            Content.remove(response.id).then(function(result) {
                var parent = _.find(vm.data, function(parent) {
                    return parent.id === response.parent_id;
                });
                if (!parent) {
                    return;
                }

                _.remove(parent.comments, function(n) {
                    return n.id === response.id;
                });
                parent.commentCount = parent.comments && parent.comments.length;

                _.remove(parent.answers, function(n) {
                    return n.id === response.id;
                });
                parent.answerCount = parent.answers && parent.answers.length;
            });
        }

        function showChildren(parent) {
            parent.showDetails = true;
        }

        function filterChanged() {
            if (vm.onlyQuestions) {
                vm.shown = _.filter(vm.data, function(item) {
                    return item.content_type === "question";
                });
            } else {
                vm.shown = vm.data;
            }
        }
    }
})();
