(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.textBoxModal")
        .controller("TextBoxCtrl", TextBoxCtrl);

    function TextBoxCtrl(textResolve, $uibModalInstance, $scope, $translate) {
        var vm = this;
        vm.name = textResolve;
        vm.confirm = confirm;
        vm.reject = reject;
        vm.renameText = "Rename:";
        vm.textBoxText = "";
        activate();

        function activate() {
            $translate("Edit").then(function(translation) {
                vm.titleText = "Edit " + vm.name + ":";
            });
        }

        function reject() {
            $uibModalInstance.close({ confirmed: false });
        }

        function confirm() {
            $uibModalInstance.close({
                confirmed: true,
                name: vm.textBoxText
            });
        }

        $scope.$on("$locationChangeStart", function() {
            $uibModalInstance.close({ confirmed: false });
        });
    }
})();
