(function() {
    "use strict";

    angular
        .module("ssmAngularApp.alert.service")
        .service("AlertService", AlertService);

    function AlertService() {
        var allAlerts = [];
        var DEFAULT_ANIMATION = "fadeInRight";
        var DEFAULT_DURATION = 5000;

        var TYPES = {
            DANGER: "danger",
            INFO: "info",
            MESSAGE: "message",
            SUCCESS: "success",
            WARNING: "warning"
        };

        function add(alert) {
            if (!alert.msg) {
                return false;
            }

            if (!alert.animation) {
                alert.animation = DEFAULT_ANIMATION;
            }

            if (!alert.duration) {
                alert.duration = DEFAULT_DURATION;
            }

            allAlerts.push(alert);
            return true;
        }

        function remove(index) {
            allAlerts.splice(index, 1);
            return true;
        }

        function getAlerts() {
            return allAlerts;
        }

        return {
            TYPES: TYPES,
            getAlerts: getAlerts,
            add: add,
            remove: remove
        };
    }
})();
