(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.focusMe", []).directive("focusMe", focusMe);

    function focusMe($timeout, $parse) {
        return {
            link: function(scope, element, attrs) {
                var watchFocusMe = $parse(attrs.focusMe);
                scope.$watch(attrs.focusMe, function(value) {
                    if (value === true) {
                        $timeout(function() {
                            if (element.children().length === 0) {
                                element[0].focus();
                            } else {
                                element.children()[0].focus();
                            }
                        });
                        if (watchFocusMe.assign) {
                            watchFocusMe.assign(scope, false);
                        }
                    }
                });
            }
        };
    }
})();
