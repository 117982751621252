(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("UsersShowCtrl", UsersShowCtrl);

    function UsersShowCtrl(
        $translate,
        User,
        $stateParams,
        CurrentUser,
        Audience,
        Tenant,
        userProfileResolve,
        Logger,
        WallPost,
        Connection,
        SSMTitle
    ) {
        /*jshint validthis: true */
        var vm = this;

        vm.posts = [];
        vm.disableInfiniteScrollEvents = false;
        vm.currentUserData = CurrentUser.profile;
        vm.profileData = userProfileResolve;
        vm.isCurrentUser = vm.currentUserData.id === vm.profileData.id;
        vm.translationData = { user_name: vm.profileData.first_name };
        vm.location = {
            id: vm.profileData.id,
            type: WallPost.LOCATION_TYPE.USER,
            permissions: { canPost: false }
        };

        var POST_LIMIT = 20;

        vm.nextPage = nextPage;
        vm.processPosts = processPosts;

        vm.showVendorManager = false;

        activate();

        function activate() {
            SSMTitle.setTitle(vm.profileData.full_name);

            vm.showVendorManager = vm.currentUserData.is_admin;
            vm.location.permissions.canPost =
                vm.currentUserData.id === vm.location.id ||
                (vm.profileData.connection &&
                    vm.profileData.connection.status ===
                        Connection.CONNECTION_STATUS.APPROVED);
            vm.location.permissions.canComment =
                vm.currentUserData.id === vm.location.id ||
                (vm.profileData.connection &&
                    vm.profileData.connection.status ===
                        Connection.CONNECTION_STATUS.APPROVED);

            if (!vm.isCurrentUser) {
                trackProfileHashTags();
            }

            vm.nextPage();
        }

        function trackProfileHashTags() {
            var emailAddress = _.head(vm.currentUserData.emails).email;
            Tenant.getTenant().then(function(tenant) {
                Audience.updateHashTags(
                    tenant.organization_id,
                    emailAddress,
                    vm.profileData.hash_tags
                );
            });
        }

        function handleError(error) {
            switch (error.status) {
                case 403: //forbidden
                    $translate("WALL.GET_POSTS_FORBIDDEN_USER").then(function(
                        translation
                    ) {
                        Logger.warning("Private User: ", translation, error);
                    });
                    break;
                default:
                    $translate("WALL.GET_POSTS_ERROR").then(function(
                        translation
                    ) {
                        Logger.error("ERROR: ", translation, error);
                    });
                    break;
            }
        }

        function nextPage() {
            var lastPost = _.last(vm.posts);
            var query = {
                limit: POST_LIMIT,
                sort_by_activity: true,
                format: "enhanced"
            };
            if (lastPost) {
                query.before_date = lastPost.activity_date;
            }

            vm.disableInfiniteScrollEvents = true;
            User.getPosts($stateParams.id, query)
                .then(function(posts) {
                    vm.processPosts(posts);
                })
                .catch(handleError); // Error getting posts, don't add any more and leave infinite scroll disabled
        }

        function processPosts(posts) {
            if (!posts || posts.length === 0) {
                // no more posts, don't add any more and leave infinite scroll disabled
                return;
            }
            _.each(posts, function(post) {
                // User will have permission to comment on a user's post on their wall if they are connected
                // This is far less than calculating on the server and we're only using this for UI.  The server will
                // still validate the connection when the user submits the comment.
                if (post.location.id === vm.location.id) {
                    post.location.permissions.canComment =
                        vm.location.permissions.canComment;
                }
            });
            vm.posts.push.apply(vm.posts, posts);
            vm.disableInfiniteScrollEvents = false;
        }
    }
})();
