(function() {
    "use strict";

    angular
        .module("ssmAngularApp.audience", [
            "ssmAngularApp.api",
            "ssmAngularApp.currentUser"
        ])
        .factory("Audience", Audience);

    function Audience(Api, ipService, CurrentUser) {
        const audienceApi = Api.all("audiencemember");
        return {
            upsert(audienceMember) {
                return audienceApi.post(audienceMember);
            },
            upsertWithIpAddress(audienceMember) {
                if (CurrentUser.profile) {
                    audienceMember.user_id = CurrentUser.profile.id;
                }
                return ipService.getIpAddress().then(ipinfo => {
                    audienceMember.ip_address = ipinfo.ip;
                    audienceMember.guest_token = ipinfo.guest_token;
                    audienceApi.post(audienceMember);
                });
            },
            all(options) {
                return audienceApi.getList(options);
            },
            getMembers(org_id, options) {
                return audienceApi
                    .all(org_id)
                    .all("members")
                    .getList(options);
            },
            report(options) {
                return Api.one("audiencemember").get(options, {
                    Accept: "text/csv"
                });
            },
            importAudience(file_id, organization_id, message) {
                return audienceApi.all("import").post({
                    file_id,
                    organization_id,
                    message
                });
            },
            updateHashTags(
                organization_id,
                email_address,
                hash_tags,
                inline_hash_tags
            ) {
                return audienceApi.all("updateHashTags").patch({
                    organization_id,
                    email_address,
                    hash_tags,
                    inline_hash_tags
                });
            }
        };
    }
})();
