(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.branding", []).service("Branding", Branding);

    function Branding(Tenant) {
        var DEFAULT_COLORS = [
            {
                name: "primary",
                val: "#0198b5",
                text: "#fff"
            },
            {
                name: "secondary",
                val: "#0198b5",
                text: "#fff"
            },
            {
                name: "tertiary",
                val: "#27c3a0",
                text: "#fff"
            },
            {
                name: "success",
                val: "#27c3a0",
                text: "#fff"
            },
            {
                name: "info",
                val: "#f0f0f0",
                text: "#004e6b"
            },
            {
                name: "warning",
                val: "#fee54b",
                text: "#30404d"
            },
            {
                name: "danger",
                val: "#ec4d4d",
                text: "#fff"
            }
        ];

        function init() {
            Tenant.getTenant().then(function(tenant) {
                var css = "";

                var colors = DEFAULT_COLORS;

                if (tenant.colors) {
                    colors = tenant.colors.concat(colors);
                    colors = _.uniqBy(colors, "name");
                }

                css += _.map(colors, processColor).join("\n\n");
                css += processSpecialStyles(colors);

                var style = document.createElement("style");
                style.innerHTML = css;

                document.getElementsByTagName("head")[0].appendChild(style);
            });
        }

        function getColor(colors, name) {
            var color = _.find(colors, { name: name });
            return color ? color : {};
        }

        function processSpecialStyles(colors) {
            var template = [
                ".has-error .help-inline {",
                "   color: ${danger_color};",
                "}",
                "",
                ".angular-wizard .steps-indicator li .done a:before {",
                "   background-color: ${secondary_color};",
                "}",
                "",
                ".angular-wizard .steps-indicator li .editing a:before",
                "   background-color: ${tertiary_color};",
                "}",
                "",
                ".angular-wizard .steps-indicator li .current a:before",
                "   background-color: ${primary_color};",
                "}"
            ];

            var values = {
                danger_color: getColor(colors, "danger").val,
                primary_color: getColor(colors, "primary").val,
                secondary_color: getColor(colors, "secondary").val,
                tertiary_color: getColor(colors, "tertiary").val
            };

            var compiled = _.template(template.join("\n"));
            return compiled(values) + "\n";
        }

        function processColor(color) {
            var template = [
                ".bg-${category} {",
                "   background: ${value};",
                "}",
                ".bg-${category}-ha:hover, .bg-${category}-ha:active {",
                "   background: ${value};",
                "}",
                "",
                ".bg-${category}-light {",
                "   background: ${lightenValue};",
                "}",
                "",
                ".text-${category} {",
                "   color: ${value};",
                "}",
                "",
                ".text-${category}:hover {",
                "   color: ${value} !important;",
                "}",
                "",
                ".text-${category}-over:hover, .text-${category}:active {",
                "   color: ${value} !important;",
                "}",
                "",
                ".border-${category} {",
                "   border-color: ${value};",
                "}",
                "",
                ".border-${category}-light {",
                "   border-color: ${lightenValue};",
                "}",
                "",
                ".icon-${category}:before {",
                "   color: ${darkenValue};",
                "}",
                "",
                ".btn-${category} {",
                "   background-color: ${value};",
                "   border-color: ${value};",
                "   color: ${text};",
                "}",
                "",
                ".btn-${category}:hover, .btn-${category}:focus, .btn-${category}:active, .btn-${category}.active {",
                "   background-color: ${darkenValue};",
                "   border-color: ${darkenValue};",
                "}",
                "",
                ".bg-${category}-text-color {",
                "   color: ${text};",
                "}",
                "",
                ".stroke-${category} {",
                "   stroke: ${value};",
                "}"
            ];

            var values = {
                category: color.name,
                value: color.val,
                lightenValue: lightenDarkenColor(color.val, 0.4),
                darkenValue: lightenDarkenColor(color.val, -0.1),
                text: color.text
            };

            var compiled = _.template(template.join("\n"));
            return compiled(values) + "\n";
        }

        function lightenDarkenColor(color, percent) {
            /*jshint bitwise: false*/
            var num = parseInt(color.slice(1), 16);
            var R = num >> 16;
            var G = (num >> 8) & 0x00ff;
            var B = num & 0x0000ff;
            var hsl = rgbToHsl(R, G, B);
            hsl[2] += percent;
            var rgb = hslToRgb(hsl[0], hsl[1], hsl[2]);
            R = rgb[0];
            G = rgb[1];
            B = rgb[2];
            return (
                "#" +
                (
                    0x1000000 +
                    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
                    (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
                    (B < 255 ? (B < 1 ? 0 : B) : 255)
                )
                    .toString(16)
                    .slice(1)
            );
        }

        function hslToRgb(h, s, l) {
            var r, g, b;

            if (s === 0) {
                r = g = b = l; // achromatic
            } else {
                var hue2rgb = function hue2rgb(p, q, t) {
                    if (t < 0) {
                        t += 1;
                    }
                    if (t > 1) {
                        t -= 1;
                    }
                    if (t < 1 / 6) {
                        return p + (q - p) * 6 * t;
                    }
                    if (t < 1 / 2) {
                        return q;
                    }
                    if (t < 2 / 3) {
                        return p + (q - p) * (2 / 3 - t) * 6;
                    }
                    return p;
                };

                var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
                var p = 2 * l - q;
                r = hue2rgb(p, q, h + 1 / 3);
                g = hue2rgb(p, q, h);
                b = hue2rgb(p, q, h - 1 / 3);
            }

            return [
                Math.round(r * 255),
                Math.round(g * 255),
                Math.round(b * 255)
            ];
        }

        function rgbToHsl(r, g, b) {
            r /= 255;
            g /= 255;
            b /= 255;
            var max = Math.max(r, g, b),
                min = Math.min(r, g, b);
            var h,
                s,
                l = (max + min) / 2;

            if (max === min) {
                h = s = 0; // achromatic
            } else {
                var d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                switch (max) {
                    case r:
                        h = (g - b) / d + (g < b ? 6 : 0);
                        break;
                    case g:
                        h = (b - r) / d + 2;
                        break;
                    case b:
                        h = (r - g) / d + 4;
                        break;
                }
                h /= 6;
            }

            return [h, s, l];
        }

        return {
            init: init
        };
    }
})();
