(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("ProfileSettingsCtrl", ProfileSettingsCtrl);

    function ProfileSettingsCtrl(
        $scope,
        $stateParams,
        $state,
        CurrentUser,
        tenantResolve,
        $timeout,
        SSMTitle,
        SSMUtilities,
        Tutorial,
        User,
        ssmAuth,
        ssmAuthHelper,
        Analytics
    ) {
        var vm = this;
        vm.employerInvalid = false;
        vm.showSavedFlag = false;
        vm.updateProfile = updateProfile;
        vm.shouldShowProviderSignOnButton = false;
        vm.ssoSettings = {};

        vm.resetForm = resetForm;
        vm.chimeProfileUpdateMessageText = "Update your address, phone number, name or email on your ";
        vm.chimeProfileUpdateMessage = "";

        vm.BROADCAST_IDENTIFIER = "profileSettingsForm";

        // TODO: define this in a constant store
        var DELAY_TIME_HIDING_FLAG = 3000;

        SSMTitle.setTitle(CurrentUser.profile.full_name);

        activate();

        function activate() {
            setUpUserModel(
                CurrentUser.profile,
                null,
                $stateParams.organization_id
            );
            
            vm.showEmail = CurrentUser.profile.show_email;
            vm.forgotPasswordUrl = tenantResolve.forgot_password_url;
            vm.disableEmployerEdit = !tenantResolve.flags.edit_profile_questions 
                || tenantResolve.requiresOrganizationSupportLink;
            vm.requiresEmailSupportLink = tenantResolve.requiresEmailSupportLink;
            vm.requiresOrganizationSupportLink = tenantResolve.requiresOrganizationSupportLink;
            vm.validateEmail = validateEmail;
            vm.emailVerified = vm.userModel.emails[0].verified;
            vm.ssoSettings = tenantResolve.sso_button;

            determineIfShouldShowProviderSignOnButton();

            vm.chimeProfileUpdateMessage = vm.chimeProfileUpdateMessageText + chimeProfileURL();

            if (tenantResolve.flags.enable_tutorial_mode) {
                Tutorial.openStep3to7();
            }
        }

        function updateProfile(form) {
            var edits = {
                first_name: vm.userModel.first_name,
                last_name: vm.userModel.last_name,
                title: vm.userModel.title,
                state: vm.userModel.state,
                institution: vm.userModel.institution,
                show_email: vm.showEmail
            };

            if (vm.userModel.photo) {
                edits.photo_id = vm.userModel.photo.id;
            }
            edits.hash_tags = vm.userModel.hash_tags;

            var params = {
                add_affiliate_list: form.add_affiliate_list || [],
                remove_affiliate_list: form.remove_affiliate_list || []
            };

            User.updateAffiliations(CurrentUser.profile.id, params)
            .then(function() {
                CurrentUser.update(edits)
                .then(function(result) {
                    setUpUserModel(result, form);

                    vm.showSavedFlag = true;
                    $timeout(function() {
                        vm.showSavedFlag = false;
                    }, DELAY_TIME_HIDING_FLAG);
                });
            });
        }

        function resetForm(form) {
            setUpUserModel(CurrentUser.profile, form);
        }

        function validateEmail() {
            ssmAuth.resendValidation(ssmAuthHelper.token, "/profile/settings");
            Analytics.eventTrack("profileCompleted", {
                category: "RegistrationCtrl",
                label: "user_id:" + CurrentUser.profile.id
            });
            Analytics.eventTrack2("profile_completed", {
                category: "RegistrationCtrl",
                label: "user_id:" + CurrentUser.profile.id
            });
            $state.go("validate");
        }

        function setUpUserModel(modelData, form, createdOrg) {
            if (!vm.userModel) {
                vm.userModel = {};
            }
            vm.userModel.id = modelData.id;
            vm.userModel.first_name = modelData.first_name;
            vm.userModel.last_name = modelData.last_name;
            vm.userModel.full_name = modelData.full_name;
            vm.userModel.title = modelData.title;
            vm.userModel.photo = modelData.photo;
            vm.userModel.photo_id = modelData.photo_id;
            vm.userModel.hash_tags = modelData.hash_tags || [];
            vm.userModel.imageUpload = [];
            vm.userModel.emails = modelData.emails;
            vm.userModel.affiliations = modelData.affiliations || [];
            vm.userModel.createdOrg = createdOrg;
            vm.userModel.state = modelData.state;
            vm.userModel.institution = modelData.institution;
            if (form) {
                form.add_affiliate_list = [];
                form.remove_affiliate_list = [];
            }

            $scope.$broadcast(
                SSMUtilities.SSM_TAG_SELECT_PROGRAMMATIC_CHANGE_EVENT,
                vm.BROADCAST_IDENTIFIER
            );
            $scope.$broadcast(
                SSMUtilities.SSM_AFFILIATION_PROGRAMMATIC_CHANGE_EVENT,
                vm.BROADCAST_IDENTIFIER
            );

            if (form && form.$setPristine) {
                form.$setPristine();
            }
        }

        function determineIfShouldShowProviderSignOnButton() {
            if (CurrentUser.hasNeverUsedSso()) return;
            if (ssoProviderButtonDoesNotExist()) return;
            setDefaultButtonBlurbIfNoneExists();

            vm.shouldShowProviderSignOnButton = true;
        }

        function setDefaultButtonBlurbIfNoneExists() {
            if (!vm.ssoSettings.sso_provider_button_blurb) {
                vm.ssoSettings.sso_provider_button_blurb = "Need to change some info?";
            }
        }

        function ssoProviderButtonDoesNotExist() {
            if (
                !vm.ssoSettings ||
                !vm.ssoSettings.sso_provider_button_url ||
                !vm.ssoSettings.sso_provider_button_img
                ) return true
            else return false
        }

        function chimeProfileURL() {
            return "<a target='_blank' href='" + SSM.CHIME_PROFILE_URL + "'>CHIME Profile</a>.";
        }
    }
})();
