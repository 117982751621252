(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmDelegationBlog")
        .directive("ssmDelegationBlog", ssmDelegationBlog);

    function ssmDelegationBlog() {
        return {
            templateUrl: "components/ssmDelegationBlog/ssmDelegationBlog.html",
            restrict: "E",
            scope: {
                onSelectionChange: "=",
                onCancel: "="
            },
            controllerAs: "vm",
            controller: ssmDelegationBlogController,
            bindToController: true
        };
    }

    function ssmDelegationBlogController(
        $timeout,
        $translate,
        CurrentUser,
        Delegation
    ) {
        /*jshint validthis:true */
        var vm = this;
        var MAX_NUMBER_SEARCH_RESULTS = 25;

        vm.enterNamePlaceholder = "";
        vm.principalsAutocomplete = {
            deactivate: false,
            suggestions: [],
            searchCallback: searchCallback,
            onSelect: onSelect
        };
        vm.selectedPrincipalName = null;
        vm.principalToPostAs = null;
        vm.removeSelectedMember = removeSelectedMember;
        vm.showDelegateSelection = showDelegateSelection;
        vm.showMemberNotFound = showMemberNotFound;
        vm.cancel = cancelDelegation;

        activate();

        function activate() {
            $translate("WALL.PRINCIPAL_SEARCH_PLACEHOLDER").then(function(
                translation
            ) {
                vm.enterNamePlaceholder = translation;
            });
        }

        function showDelegateSelection(shouldShow) {
            vm.postingAsDelegate = shouldShow;
            if (!shouldShow) {
                removeSelectedMember();
            }
        }

        function onSelect(search_result) {
            if (search_result && search_result.user) {
                vm.principalToPostAs = search_result.user;
                if (vm.onSelectionChange) {
                    vm.onSelectionChange(vm.principalToPostAs);
                }
            }
        }

        function showMemberNotFound() {
            return (
                vm.selectedPrincipalName &&
                !vm.principalToPostAs &&
                !vm.principalsAutocomplete.suggestions.length
            );
        }

        function removeSelectedMember() {
            // Clear out previous principals and search results.
            vm.selectedPrincipalName = null;
            vm.principalToPostAs = null;

            // Force autocomplete directive to clear its inputs based on programmatic change.
            $timeout(function() {
                vm.principalsAutocomplete.onChangeInput();
            });

            if (vm.onSelectionChange) {
                vm.onSelectionChange(vm.principalToPostAs);
            }
        }

        // search principals who are blog authors.
        function searchCallback(searchText) {
            return Delegation.getPrincipalsForBlog(CurrentUser.profile.id, {
                size: MAX_NUMBER_SEARCH_RESULTS,
                q: searchText
            });
        }

        function cancelDelegation() {
            if (vm.onCancel) {
                vm.onCancel();
            }
        }
    }
})();
