(function() {
    "use strict";

    angular.module("ssmAngularApp.ssmTitle").factory("SSMTitle", SSMTitle);

    function SSMTitle($rootScope, $q, Tenant) {
        $rootScope.ssm = {};
        return {
            setTitle: function(title) {
                Tenant.getTenant().then(function(tenant) {
                    $q.when(title).then(function(titleResolved) {
                        $rootScope.ssm.title = tenant.getTitle(titleResolved);
                    });
                });
            }
        };
    }
})();
