(function() {
    "use strict";

    angular
        .module("ssmAngularApp.fileGallery")
        .directive("fileGallery", fileGallery)
        .controller("fileGalleryController", fileGalleryController);

    function fileGallery() {
        return {
            templateUrl: "components/fileGallery/fileGallery.html",
            restrict: "E",
            replace: true,
            scope: {
                files: "=",
                createView: "@",
                homeLocationId: "@",
                handleRemove: "&"
            },
            controller: fileGalleryController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    /* @ngInject */
    function fileGalleryController(SSM_FILES, ssmFileUpload) {
        /*jshint validthis: true */
        var vm = this;

        vm.filesToShow = filesToShow;
        vm.remove = remove;
        vm.isFileType = isFileType;

        function filesToShow() {
            return _.filter(vm.files, function(file) {
                return isFileType(file);
            });
        }

        function isFileType(file) {
            return -1 === SSM_FILES.TYPES.IMAGE.indexOf(file.file_type);
        }

        function remove(event, index) {
            event.preventDefault();
            var nonImageFiles = vm.filesToShow();
            if (index < 0 || index >= nonImageFiles.length) {
                return;
            }
            
            var fileIndex = getFilesIndex(nonImageFiles, index);

            if (vm.handleRemove) {
                vm.handleRemove({ fileIndex: fileIndex });
            } else {
                vm.files.splice(fileIndex, 1);
            }
        }

        function getFilesIndex(nonImageFiles, index) {
            var targetId = nonImageFiles[index].id;
            var fileIndex = _.findIndex(vm.files, function(file) {
                return file.id === targetId;
            });
            return fileIndex;
        }
    }
})();
