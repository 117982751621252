(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmSavedFlag")
        .directive("ssmSavedFlag", ssmSavedFlag);

    function ssmSavedFlag() {
        return {
            templateUrl: "components/ssmSavedFlag/ssmSavedFlag.html",
            restrict: "E",
            scope: {
                flashFlagCall: "="
            },
            replace: true,
            controller: ssmSavedFlagController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmSavedFlagController($timeout) {
        /*jshint validthis: true */
        var vm = this;
        vm.showSavedFlag = false;
        vm.flashFlagCall = flashFlagCall;

        var DELAY_TIME_HIDING_FLAG = 3000;

        function flashFlagCall() {
            vm.showSavedFlag = true;
            $timeout(function() {
                vm.showSavedFlag = false;
            }, DELAY_TIME_HIDING_FLAG);
        }
    }
})();
