(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmDelegationPost")
        .directive("ssmDelegationPost", ssmDelegationPost);

    function ssmDelegationPost() {
        return {
            templateUrl: "components/ssmDelegationPost/ssmDelegationPost.html",
            restrict: "E",
            scope: {
                // Action to call when the post button is clicked
                submitPost: "&",
                // Boolean on whether or not to show posting as delegate UI.  Lets parent know if cancel button is clicked.
                postingAsDelegate: "=",
                // The search function to call, used by the author type-ahead search to filter out user's who don't have access.
                searchCallback: "=",
                // The id of the desired author for the post.
                delegationPostAuthorId: "=",
                // String ID of Error message to show if member is not found.
                memberNotFoundError: "@"
            },
            controllerAs: "vm",
            controller: ssmDelegationPostController,
            bindToController: true
        };
    }

    function ssmDelegationPostController(
        $timeout,
        $translate,
        CurrentUser,
        WallPost
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.currentUserProfile = null;
        vm.enterNamePlaceholder = "";
        vm.principalsAutocomplete = {
            deactivate: false,
            suggestions: [],
            searchCallback: vm.searchCallback,
            onSelect: onSelect
        };
        vm.principalToPostAs = null;
        vm.selectedPrincipalName = null;
        vm.createPost = createPost;
        vm.removeSelectedMember = removeSelectedMember;
        vm.showDelegateSelection = showDelegateSelection;
        vm.showMemberNotFound = showMemberNotFound;

        activate();

        function activate() {
            vm.currentUserProfile = CurrentUser.profile;
            $translate("WALL.PRINCIPAL_SEARCH_PLACEHOLDER").then(function(
                translation
            ) {
                vm.enterNamePlaceholder = translation;
            });
            if (!vm.memberNotFoundError) {
                vm.memberNotFoundError = "WALL.MEMBER_NOT_FOUND";
            }
        }

        function createPost() {
            vm.submitPost({ postAsType: WallPost.POST_AS_TYPE.USER });
            showDelegateSelection(false);
        }

        function showDelegateSelection(shouldShow) {
            vm.postingAsDelegate = shouldShow;
            if (!shouldShow) {
                removeSelectedMember();
            }
        }

        function onSelect(search_result) {
            if (search_result && search_result.user) {
                vm.principalToPostAs = search_result.user;
                vm.delegationPostAuthorId = vm.principalToPostAs.id;
            }
        }

        function showMemberNotFound() {
            return (
                vm.selectedPrincipalName &&
                !vm.principalToPostAs &&
                !vm.principalsAutocomplete.suggestions.length
            );
        }

        function removeSelectedMember() {
            // Clear out previous principals and search results.
            vm.selectedPrincipalName = null;
            vm.principalToPostAs = null;

            // Force autocomplete directive to clear its inputs based on programmatic change.
            $timeout(function() {
                vm.principalsAutocomplete.onChangeInput();
            });
        }
    }
})();
