(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("UsersConnectionsController", UsersConnectionsController);

    function UsersConnectionsController(
        Connection,
        Logger,
        $translate,
        SSMTitle
    ) {
        var vm = this;
        vm.messageConnEmpty = true;

        activate();
        function activate() {
            $translate("TITLE.CONNECTIONS").then(function(title) {
                SSMTitle.setTitle(title);
            });

            Connection.getAll()
                .then(function(data) {
                    vm.list = data;

                    if(vm.list && vm.list.length > 0)
                    {
                        vm.messageConnEmpty = false;
                    }
                })
                .catch(function(error) {
                    Logger.errorWithoutAlert("Error all connections", error);
                });
        }
    }
})();
