/*jshint maxparams: 11 */
(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("BlogIndexCtrl", BlogIndexCtrl);

    function BlogIndexCtrl(
        authorProfileResolve,
        CurrentUser,
        Analytics,
        Blog,
        Logger,
        User,
        tenantResolve,
        $state,
        $stateParams,
        $translate,
        $q,
        SSMTitle
    ) {
        var vm = this;
        vm.authorProfileResolve = authorProfileResolve;
        vm.userData = CurrentUser.profile;
        vm.blogs = [];
        vm.authorNameToIdMap = [];
        vm.disableInfiniteScrollEvents = false;
        vm.selectChange = selectChange;
        vm.navigateToMyBlogs = navigateToMyBlogs;
        vm.canAuthorBlogs = false;
        vm.blogAuthors = [];
        vm.nextPage = nextPage;
        var defaultBlogTitle;

        // TODO: SSM-688 Standardize limits
        var POST_LIMIT = 20;

        activate();

        function activate() {
            vm.canAuthorBlogs = vm.userData.is_blog_author;

            $translate("TITLE.BLOGS").then(function(title) {
                SSMTitle.setTitle(title);
            });

            Analytics.eventTrack("blogIndex", {
                category: "blogIndex"
            });
            Analytics.eventTrack2("blog_index", {
                category: "blogIndex"
            });

            $translate("BLOG.AUTHOR_LIST").then(function(translation) {
                vm.authorMenuText = translation;
            });

            $translate("BLOG.DEFAULT_BLOG_TITLE").then(function(translation) {
                defaultBlogTitle = translation;
            });

            $q.all([Blog.authors(), $translate("BLOG.ALL_AUTHORS")])
                .then(function(content) {
                    createBlogAuthorList(content);
                    vm.authorNameToIdMap = [];
                    _.forEach(vm.authors, function(author) {
                        if (author.id === vm.authorProfileResolve.id) {
                            author.selected = true;
                        }
                        vm.authorNameToIdMap[author.full_name] = author.id;
                    });
                })
                .catch(function(error) {
                    Logger.errorWithoutAlert(
                        "Error fetching blog authors.",
                        error
                    );
                });
        }

        function createBlogAuthorList(content) {
            vm.disableInfiniteScrollEvents = true;

            var query = setupGetBlogQuery();

            User.getBlogs($stateParams.id, query)
                .then(function(data) {
                    filterAuthorListing(data, content);
                })
                .catch(function(error) {
                    // Error getting blogs, don't add any more and leave infinite scroll disabled
                    $translate("BLOG.GET_BLOGS_ERROR").then(function(
                        translation
                    ) {
                        Logger.error("Error: ", translation);
                    });
                });
        }

        function filterAuthorListing(blogs, content) {
            _.forEach(blogs, function(blog) {
                vm.blogAuthors.push(blog.author.id);
            });

            var authorListings = content[0];
            vm.authors = [];
            _.forEach(authorListings, function(authorListing) {
                var doesAuthorExist = _.includes(
                    vm.blogAuthors,
                    authorListing.id
                );
                if (doesAuthorExist) {
                    vm.authors.push(authorListing);
                }
            });

            vm.authors.unshift({
                full_name: content[1],
                id: "all"
            });

            vm.nextPage();
        }

        function nextPage() {
            vm.disableInfiniteScrollEvents = true;

            var query = setupGetBlogQuery();

            User.getBlogs($stateParams.id, query)
                .then(function(data) {
                    processBlogs(data);
                })
                .catch(function(error) {
                    // Error getting blogs, don't add any more and leave infinite scroll disabled
                    $translate("BLOG.GET_BLOGS_ERROR").then(function(
                        translation
                    ) {
                        Logger.error("Error: ", translation);
                    });
                });
        }

        function setupGetBlogQuery() {
            var query = {
                limit: POST_LIMIT,
                site_organization_id: tenantResolve.organization_id
            };
            var lastPost = _.last(vm.blogs);
            if (lastPost) {
                query.before_date = lastPost.create_date;
            }

            return query;
        }

        function processBlogs(blogs) {
            if (!blogs || blogs.length === 0) {
                // no more blogs, don't add any more and leave infinite scroll disabled
                return;
            }
            vm.blogs.push.apply(vm.blogs, blogs);
            vm.disableInfiniteScrollEvents = false;
        }

        function selectChange() {
            $state.go("main.blogs.index", {
                id: vm.selectedAuthor
            });
        }

        function navigateToMyBlogs() {
            $state.go("main.blogs.mine", {
                id: vm.userData.id
            });
        }
    }
})();
