(function() {
    "use strict";

    angular
        .module("ssmAngularApp.userCardBrief")
        .directive("userCardBrief", userCardBrief);

    function userCardBrief() {
        return {
            templateUrl: "components/userCardBrief/userCardBrief.html",
            restrict: "E",
            replace: true,
            scope: {
                subject: "=",
                border: "@",
                oneBtn: "@",
                noLink: "@",
                showConnectionButtons: "@",
                auxiliaryButton: "=?",
                communityData: "=",
                showPending: "@"
            },
            controller: userCardBriefCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function userCardBriefCtrl(Tenant, CommunityMembership) {
        /*jshint validthis:true */
        var vm = this;
        vm.isPendingNotClicked = true;
        vm.modifyPendingCommunityInvite = modifyPendingCommunityInvite;

        Tenant.getTenant().then(function(tenant) {
            vm.tenant = {
                flags: tenant.flags
            };
        });

        activate();

        function activate() {
            vm.is_email_alias = () => vm.subject && !vm.subject.full_name;
        }

        function modifyPendingCommunityInvite(isAccepted) {
            // alert pop-up to show action is completed
            // error messaging
            CommunityMembership.setPendingFollow(
                isAccepted,
                vm.subject.id,
                vm.communityData.id
            );
            vm.isPendingNotClicked = false;
        }
    }
})();
