(function() {
    "use strict";

    angular
        .module("ssmAngularApp.closeButton", [])
        .directive("closeButton", closeButton);

    function closeButton() {
        return {
            templateUrl: "components/closeButton/closeButton.html",
            restrict: "E",
            replace: true
        };
    }
})();
