(function() {
    "use strict";

    angular
        .module("ssmAngularApp.inviteEmailsForm", [])
        .directive("inviteEmailsForm", inviteEmailsForm);

    function inviteEmailsForm() {
        return {
            templateUrl: "components/inviteEmailsForm/inviteEmailsForm.html",
            restrict: "E",
            scope: {
                emails: "="
            },
            controller: inviteEmailsFormCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function inviteEmailsFormCtrl(
        AlertService,
        Logger,
        Organization,
        Tenant,
        $translate
    ) {
        /*jshint validthis:true */
        var vm = this;
        vm.addEmailsToList = addEmailsToList;
        vm.removeEmail = removeEmail;
        vm.emails = [];
        vm.emailTextStart = "Invite your friends and colleagues to join ";
        vm.emailTextEnd = ` by sending a personal invitation.  To start,
                            add their email addresses below separated by a
                            comma, space, or on individual lines.`;
        vm.emailText = "";
        vm.org;

        activate();

        function activate() {
            Tenant.getTenant().then(function(tenant) {
                getOrganizationById(tenant.organization_id);
            });
        }

        function getOrganizationById(orgName) {
            if (!orgName) {
                Logger.error("ERROR: ", "Please specify an organization ID");
                return;
            }
            return Organization.getOrganization(orgName)
                .then(function(results) {
                    vm.org = results;
                    vm.emailText =
                        vm.emailTextStart + vm.org.name + vm.emailTextEnd;
                })
                .catch(function(error) {
                    Logger.error(
                        "ERROR: ",
                        "HTTP Server Error: " + error.status
                    );
                });
        }

        function addEmailsToList(emailEntry) {
            var emailList = convertToEmailArray(emailEntry);

            var duplicateFree = _.uniqBy(
                _.union(vm.emails, emailList),
                function(email) {
                    return email.toLowerCase();
                }
            );
            vm.emails = _.sortBy(duplicateFree);
            vm.emailEntry = "";
        }

        function convertToEmailArray(text) {
            var emailRegex = /([a-zA-Z0-9\+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
            var matches = text.match(emailRegex);

            var replaceRegex = /([a-zA-Z0-9\+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)|[,\n]/gi;
            // Find the things that failed
            var failureString = text.replace(replaceRegex, "").trim();

            if (failureString) {
                $translate(
                    "EXTERNAL_INVITES_WIZARD.INVALID_EMAIL_INPUT_ERROR"
                ).then(function(translation) {
                    AlertService.add({
                        msg: translation + failureString.replace(/[,]/g, ""),
                        type: "warning",
                        duration: 1,
                        animation: "fadeInRight"
                    });
                });
            }
            return matches;
        }

        function removeEmail(email) {
            var index = vm.emails.indexOf(email);
            if (index > -1) {
                vm.emails.splice(index, 1);
            }
        }
    }
})();
