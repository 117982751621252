(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.layout").controller("BlogCtrl", BlogCtrl);

    function BlogCtrl() {}
})();
