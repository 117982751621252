(function() {
    "use strict";
    /**
     * @ngInject
     */


    angular.module("ssmAngularApp.layout")
        .controller("SsoLoadingScreenCtrl", SsoLoadingScreenCtrl);

    function SsoLoadingScreenCtrl(
        $location,
        ssmAuth,
        CurrentUser,
        ssmAuthHelper,
    ) {
        var CONFIG = {
            SIGN_IN_POLICY: "B2C_1A_signup_signin_migratejitv2",
            FORGOT_PASSWORD_POLICY: "B2C_1A_PasswordReset_migratejitv2",
            client_id: "",
            azure_allowed_url: ""
        };

        var vm = this;

        vm.userInfoFromAzureB2C;

        var msalAuthObj;
        var msalAuthResetObj;
        var idToken;
        var accessToken;
        var mainAccount;

        activate();

        function activate() {
            clearDeviceStorage();
            determineIfThisPageLoadWasFromARedirectFromB2C();
            initConfigObject();
        }

        function clearDeviceStorage() {
            localStorage.clear();
            sessionStorage.clear();
        }

        function determineIfThisPageLoadWasFromARedirectFromB2C() {
            if (argumentContainsNothingUseful($location.hash())) {
                return;
            }
            else {
                vm.userInfoToken = $location.hash();
            }
        }

        function initConfigObject() {
            var currentHost = $location.host();
            if (currentHost == "ahima.ssm-devbox.local") {
                window.sessionStorage.setItem("tenant", "ahimaTest");
                CONFIG.client_id = "fffaee6c-051a-4aad-b4f4-b4268a65be80";
                CONFIG.azure_allowed_url = "https://ahima.ssm-devbox.local:8443/ahima-sso/loading";
            }
    
            else if (currentHost == "ahima.staging.nextwaveconnect.com") {
                sessionStorage.setItem("tenant", "ahimaTest");
                CONFIG.client_id = "fffaee6c-051a-4aad-b4f4-b4268a65be80";
                CONFIG.azure_allowed_url = "https://ahima.staging.nextwaveconnect.com/ahima-sso/loading";
            }
        
            else if (currentHost == "ahima.nextwaveconnect.com"
                || currentHost == "access.ahima.org"
            ) {
                sessionStorage.setItem("tenant", "ahimaProdB2C");
                CONFIG.client_id = "263a9d40-fa9a-418e-ab7c-c1d7f2003ba9";
                CONFIG.azure_allowed_url = "https://access.ahima.org/ahima-sso/loading";
            }
        }

        window.addEventListener("load", function () {
            
            msalAuthObj = new msal.PublicClientApplication(msalAuthCodeConfig);
            attemptLogin()
            .catch(error => {
                if (userForgotTheirPasswordAsIndicatedByThe(error)) {
                    forgotPasswordB2C();
                }
                else if (userWasNotFoundAsIndicatedByThe(error)){
                    msalAuthObj.loginRedirect(tokenRequest);
                }
            });
        });

        async function attemptLogin() {
            await msalAuthObj.handleRedirectPromise();
            
            const accounts = msalAuthObj.getAllAccounts();
            if (accounts.length === 0) {
                msalAuthObj.loginRedirect();
            }
            else {
                console.log(accounts);
                mainAccount = accounts[0];
                getTokenRedirect(mainAccount.homeAccountId)
                .then((response) => {
                    idToken = response.idToken;
                    accessToken = response;
                    finalLoginStep();
                });
            }
        }

        function getTokenRedirect(currentAccountId) {
            tokenRequest.account = currentAccountId;
            return msalAuthObj.acquireTokenSilent(tokenRequest)
                .catch(error => {
                    if (error instanceof msal.InteractionRequiredAuthError) {
                        msalAuthObj.acquireTokenRedirect(tokenRequest);
                    } else {
                        console.warn(error);   
                    }
                });
        }

        function finalLoginStep() {

            /*
                If the two lines of code AFTER this comment do not look exactly like the two lines of code WITHIN this comment
                be weary. 

                These two lines were added because it was noticed that new users who attempted to SSO in for the first time were
                not being sent the extension_AhimaID field within the idTokenClaims field within the idToken sent over from B2C.

                It was noticed that the problem could be solved by simply refreshing the page. 
                It's important to note that control will only ever get here if the user was successfully able to login to B2C. Therefor, 
                no null checking is necessary because the user will have a populated idToken. 

                /////////////////////////////////////////////
                var decoded_id_token = jwt_decode(idToken);
                if (argumentContainsNothingUseful(decoded_id_token["extension_AhimaID"])) {
                    window.location.reload();
                }
                /////////////////////////////////////////////
            */
            var decoded_id_token = jwt_decode(idToken);
            if (argumentContainsNothingUseful(decoded_id_token["extension_AhimaID"])) {
                window.location.reload();
            }
            var ssoToken = {
                sso_data: idToken,
                jwt_token: accessToken
            };  

            ssmAuth.ahimaSsoLogin(ssoToken)
            .catch(function(error) {
                console.log(error);
                vm.isNotAhimaMember = error.data === "AHIMA user is not a member";
                setInterval(() => {
                    CurrentUser.clear();
                    ssmAuthHelper.logout(false);
                }, 20000)
            });
        }

        function argumentContainsNothingUseful(data) {
            if (!data 
                || data === null
                || data === undefined
                || data === ""
            ) {
                return true;
            }
            return false;
        }

        function userForgotTheirPasswordAsIndicatedByThe(error) { 
            return error.errorMessage.indexOf("AADB2C90118") >= 0;
        }

        function userWasNotFoundAsIndicatedByThe(error) {
            return error.errorMessage.indexOf("AADB2C") >= 0;
        }

        function forgotPasswordB2C() {
            msalAuthResetObj = new msal.PublicClientApplication(msalForgotConfig);
            msalAuthResetObj.loginRedirect(tokenRequest);
        }

        var appConfig = {
            b2cScopes: [
                "https://" + sessionStorage.getItem("tenant") + ".onmicrosoft.com/api/read", 
                "https://" + sessionStorage.getItem("tenant") + ".onmicrosoft.com/api/write"
            ],
            webApi: "https://" + sessionStorage.getItem("tenant") + ".onmicrosoft.com/api/"
        };

        const _tenant = "https://" + sessionStorage.getItem("tenant") + ".b2clogin.com/" + sessionStorage.getItem("tenant") + ".onmicrosoft.com/";

        const msalAuthCodeConfig = {
            auth: {
                clientId: CONFIG.client_id, 
                authority: _tenant + CONFIG.SIGN_IN_POLICY,
                validateAuthority: false,
                redirectUri: CONFIG.azure_allowed_url,
                navigateToLoginRequestUrl: false,
                knownAuthorities: [_tenant + CONFIG.SIGN_IN_POLICY]
            },
            cache: {
                storeAuthStateInCookie: true,
                cacheLocation: "sessionStorage",
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPii) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case msal.LogLevel.Error:
                                console.error(message);
                                return;
                            case msal.LogLevel.Info:
                                console.info(message);
                                return;
                            case msal.LogLevel.Verbose:
                                console.debug(message);
                                return;
                            case msal.LogLevel.Warning:
                                console.warn(message);
                                return;
                        }
                   },
                   piiLoggingEnabled: true
                }
             }
        };

        const msalForgotConfig = {
            auth: {
                clientId: CONFIG.client_id,
                authority: _tenant + CONFIG.FORGOT_PASSWORD_POLICY,
                validateAuthority: false,
                redirectUri: CONFIG.azure_allowed_url,
                storeAuthStateInCookie: true
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true,
                secureCookies: false
            }
        };

        const tokenRequest = {
            scopes: appConfig.b2cScopes
        };   
    }
})();