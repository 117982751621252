(function() {
    "use strict";

    angular
        .module("ssmAngularApp.searchResults", [])
        .directive("searchResults", searchResults);

    function searchResults() {
        return {
            templateUrl: "components/searchResults/searchResults.html",
            restrict: "E",
            scope: {
                searchResultsData: "="
            },
            controller: searchResultsCtrl,
            controllerAs: "searchResults",
            bindToController: true
        };
    }

    function searchResultsCtrl($location, $state, $translate) {
        /*jshint validthis:true */
        var vm = this;

        vm.showNext = showNext;

        vm.searchResults = undefined;
        vm.continue_with = 0;
        vm.showscore = false;
        vm.estimated_start = 0;
        vm.estimated_end = 0;
        vm.no_results_found = false;
        vm.canShowNext = false;
        vm.estimated_total = 0;
        vm.showing_results_msg = "";

        vm.searchResultsData.then(function(data) {
            var size = $location.search().size || 10;

            vm.searchResults = data.results;
            vm.continue_with = data.continue_with;
            vm.showscore = $location.search().showscore;
            vm.estimated_start = Number($location.search().start || 1);
            vm.estimated_end = vm.estimated_start + data.results.length - 1;
            vm.no_results_found = vm.estimated_end === 0;

            if (data.continue_with === data.estimated_total) {
                //end of list
                vm.canShowNext = false;
                vm.estimated_total = vm.estimated_end;
            } else {
                vm.canShowNext = true;
                vm.estimated_total = data.estimated_total;
            }

            var last = $state.start + data.results.length;
            if (data.estimated_total > 0) {
                $translate("SEARCH.SHOWING_RESULTS", {
                    start: vm.estimated_start,
                    end: vm.estimated_end,
                    estimated_total: vm.estimated_total
                }).then(function(translation) {
                    vm.showing_results_msg = translation;
                });
            }
        });

        function showNext() {
            $state.go($state.$current, {
                start: vm.estimated_end + 1,
                continuationtoken: vm.continue_with
            });
        }
    }
})();
