(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmContentRespond")
        .directive("ssmContentRespond", ssmContentRespond)
        .controller("ssmContentRespondController", ssmContentRespondController);

    function ssmContentRespond() {
        return {
            templateUrl: "components/ssmContentRespond/ssmContentRespond.html",
            restrict: "E",
            replace: true,
            scope: {
                content: "=",
                submitCallback: "="
            },
            controller: ssmContentRespondController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmContentRespondController(
        $translate,
        Content,
        SSMInlineContentHelper
    ) {
        /*jshint validthis:true */
        var vm = this;
        vm.response = {};
        vm.createResponse = createResponse;
        vm.resetResponseForm = resetResponseForm;
        vm.showUpdate = false;
        vm.getResponseType = getResponseType;
        vm.addResponseToCollection = addResponseToCollection;
        vm.getResponseCollection = getResponseCollection;

        activate();

        function activate() {
            $translate([
                "CONTENT.QUESTION_RESPONSE_TITLE",
                "CONTENT.ARTICLE_RESPONSE_TITLE",
                "CONTENT.DEFAULT_RESPONSE_TITLE",
                "CONTENT.QUESTION_RESPONSE_PLACEHOLDER",
                "CONTENT.ARTICLE_RESPONSE_PLACEHOLDER",
                "CONTENT.DEFAULT_RESPONSE_PLACEHOLDER"
            ]).then(function(data) {
                if (vm.content.content_type === "article") {
                    vm.responseFormTitle =
                        data["CONTENT.ARTICLE_RESPONSE_TITLE"];
                    vm.placeholder =
                        data["CONTENT.ARTICLE_RESPONSE_PLACEHOLDER"];
                } else if (vm.content.content_type === "question") {
                    vm.responseFormTitle =
                        data["CONTENT.QUESTION_RESPONSE_TITLE"];
                    vm.placeholder =
                        data["CONTENT.QUESTION_RESPONSE_PLACEHOLDER"];
                } else {
                    vm.responseFormTitle =
                        data["CONTENT.DEFAULT_RESPONSE_TITLE"];
                    vm.placeholder =
                        data["CONTENT.DEFAULT_RESPONSE_PLACEHOLDER"];
                }
            });
        }

        function getResponseType(parentContentType) {
            if (vm.responseType) {
                return vm.responseType;
            }

            switch (parentContentType) {
                case "question":
                    vm.responseType = "answer";
                    break;
                case "article":
                    vm.responseType = "comment";
                    break;
            }
            return vm.responseType;
        }

        function getResponseCollection(contentChild) {
            var collection = "comments";
            switch (contentChild.content_type) {
                case "answer":
                    collection = "answers";
                    break;
                case "comment":
                    collection = "comments";
                    break;
            }
            return collection;
        }

        function addResponseToCollection(contentChild) {
            vm.content[vm.getResponseCollection(contentChild)].push(
                contentChild
            );
            switch (contentChild.content_type) {
                case "answer":
                    vm.content.answerCount++;
                    break;
                case "comment":
                    vm.content.commentCount++;
                    break;
            }
        }

        function createResponse() {
            var responseBody = {
                text: SSMInlineContentHelper.sanitizeUserInput(
                    vm.response.text
                ),
                content_type: vm.getResponseType(vm.content.content_type)
            };

            Content.createChildContent(vm.content.id, responseBody).then(
                function(response) {
                    vm.addResponseToCollection(response);
                    vm.content.children_ids.push(response.id);
                    vm.resetResponseForm();
                    if (vm.submitCallback) {
                        vm.submitCallback();
                    }
                }
            );
        }

        function resetResponseForm() {
            vm.response = {};
        }
    }
})();
