(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmMarketplaceFilterList")
        .directive("ssmMarketplaceFilterList", ssmMarketplaceFilterList)
        .controller(
            "ssmMarketplaceFilterListController",
            ssmMarketplaceFilterListController
        );

    function ssmMarketplaceFilterList() {
        return {
            templateUrl:
                "components/ssmMarketplaceFilterList/ssmMarketplaceFilterList.html",
            restrict: "E",
            replace: true,
            scope: {
                listing: "=",
                marketplaceId: "="
            },
            controller: ssmMarketplaceFilterListController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmMarketplaceFilterListController(
        $scope,
        $state,
        $location,
        ssmMarketplaceConstants
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.paramList = [];
        vm.filterList = ssmMarketplaceConstants.FILTERS;

        vm.getParamList = getParamList;
        vm.removeParamFromList = removeParamFromList;

        activate();

        function activate() {
            vm.getParamList();

            $scope.$on("$stateChangeSuccess", function() {
                vm.getParamList();
            });
        }

        function removeParamFromList(index) {
            var paramItem = vm.paramList[index];
            vm.paramList.splice(index, 1);
            $location.search(paramItem.param, null);
        }

        function getParamList() {
            vm.paramList = [];
            _.forEach(vm.filterList, function(filter) {
                var paramValue = $state.params[filter.param];
                if (paramValue) {
                    vm.paramList.push({
                        filterName: filter.name + ": ",
                        name: paramValue,
                        param: filter.param
                    });
                }
            });
        }
    }
})();
