(function() {
    "use strict";

    angular
        .module("ssmAngularApp.marketplace.services")
        .service("ssmCategories", ssmCategories)
        .service("ssmCategoriesModel", ssmCategoriesModel)
        .service("ssmCategoriesHelper", ssmCategoriesHelper);

    function ssmCategoriesModel(ActiveAngular, ssmCategoriesHelper) {
        var options = {
            formatResponse: ssmCategoriesHelper.formatResponse
        };

        return new ActiveAngular("categories/:id", options);
    }

    function ssmCategories(ssmCategoriesModel) {
        //This is a workaround to be able to inject the same model inside of itself.  You usually don't have to do this.
        ssmCategoriesModel.$hydrate = {
            parent: {
                model: ssmCategoriesModel,
                key: "parent_id"
            }
        };
        return ssmCategoriesModel;
    }

    function ssmCategoriesHelper() {
        var service = {
            formatResponse: formatResponse
        };

        return service;

        function formatResponse(data) {
            if (angular.isArray(data)) {
                data.categoryMap = _.groupBy(data, "parent_id");
                data.categoryIdMap = _.keyBy(data, "id");
            }

            return data;
        }
    }
})();
