(function() {
    "use strict";

    angular.module("ssmAngularApp").config(function($stateProvider) {
        $stateProvider.state("feedback", {
            url: "/feedback",
            templateUrl: "layout/feedback/feedback.html",
            controller: "FeedbackCtrl",
            controllerAs: "vm"
        });
    });
})();
