(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").controller("PostsCtrl", PostsCtrl);

    function PostsCtrl(
        $state,
        singlePostResolve,
        locationResolve,
        SSMTitle,
        $translate,
        AlertService,
        Tenant
    ) {
        var vm = this;

        vm.allowEvents;

        vm.onApprove = onApprove;
        vm.onDelete = onDelete;

        vm.singlePost = singlePostResolve;
        vm.location = locationResolve;

        activate();

        function activate() {
            setTitle();
            determineIfShouldAllowEvents();
        }

        function setTitle() {
            if (vm.singlePost) {
                SSMTitle.setTitle(vm.singlePost.author.full_name);
            } else {
                SSMTitle.setTitle($translate("WALL.POST_NOTFOUND"));
            } 
        }

        function determineIfShouldAllowEvents() {
            Tenant.getTenant().then(function(tenant) {
                vm.allowEvents = tenant.flags.calendar;
            });
        }

        function onDelete() {
            AlertService.add({
                type: "success",
                msg: "The post was deleted."
            });
            goToCommunity();
        }

        function goToCommunity() {
            var params = {
                id: vm.singlePost.community.community_id,
                pending: vm.singlePost.location.permissions.canEditAsCommunityPosts,
                url_name: vm.singlePost.community.url_name
            };

            $state.go("main.communities_show", params);
        }

        function onApprove() {
            AlertService.add({
                type: "success",
                msg: "The post was approved."
            });
            goToCommunity();
        }
    }
})();
