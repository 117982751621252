(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.appa.service", ["ssmAngularApp.api"])
        .factory("Appa", Appa);

    function Appa(Api) {
        var AppaApi = Api.all("appa");
        const CC_HEADLINES = "5f404dc261cc9013b701fde3";
        const PERSPECTIVES = "5f43d6c34ddac91396e02003";
        const PREMIUM_WEBINAR_RECORDINGS = "5f3c3903f2feba301a8ff625";
        const APPA_ORGANIZATION_ID = "5f283a7f1e74102c0e54252c";

        function userIsIndividualMember(appaSettings) {
            var customerType = appaSettings.customer_type;
            return (customerType == "Individual"
                    || customerType == "Lifetime"
                    || customerType == "Student");
        }

        function userReceivesBenefits(appaSettings) {
            if (appaSettings.member_benefits_flag == "1") {
                return true;
            } else return false;
        }

        function userIsConstituent(currentUserProfile) {
            var externalIds = currentUserProfile.external_ids;
            var userHasAppaSSO = false;
            externalIds = _.compact(externalIds);
            _.forEach(externalIds, function(externalId) {
                var externalIdType = externalId.split("|")[0];
                if (externalIdType == "avectra-appa") {
                    userHasAppaSSO = true;
                }
            })

            return userHasAppaSSO;
        }

        return {
            getAppaJobsList: function() {
                return AppaApi.one("appa-jobs").get();
            },

            isApprovedUser: function(currentUserProfile, marketplaceId) {
                var appaSettings = currentUserProfile.appa_settings;

                switch (marketplaceId) {
                    case CC_HEADLINES:
                        return userReceivesBenefits(appaSettings);
                    case PERSPECTIVES:
                        return userIsConstituent(currentUserProfile);
                    case PREMIUM_WEBINAR_RECORDINGS:
                        return userIsIndividualMember(appaSettings);
                    default:
                        return true;
                }
            }
        };
    }
})();
