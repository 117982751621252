(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmAuth.service")
        .factory("ssmAuthInterceptor", ssmAuthInterceptor);

    function ssmAuthInterceptor(
        ssmAuthHelper,
        $q,
        $translate,
        $injector,
        SSM_API_URL,
        AlertService
    ) {
        let uibModal;

        return {
            request: request,
            responseError: responseError,
            setUibModal: setUibModal
        };

        function setUibModal(modal) {
            uibModal = modal;
        }

        function isApiUrl(url) {
            return url.indexOf(SSM_API_URL) === 0;
        }

        function request(config) {
            var isApi = isApiUrl(config.url);

            if (isApi && ssmAuthHelper.isSignedIn) {
                config.headers.Authorization = "Bearer " + ssmAuthHelper.token;
            }

            return config;
        }

        function responseError(rejection) {
            if (rejection.config && isApiUrl(rejection.config.url)) {
                if (rejection.config.url.indexOf("/auth/login") < 0) {
                    switch (rejection.status) {
                        case 401:
                            logSessionExpired(rejection);
                            ssmAuthHelper.logout(true);
                            break;
                        case 403:
                            // for some reason we make a POST request to get
                            // the HOME flair. Avoid showing an auth error
                            // to users as it won't affect them anyway.
                            if (
                                rejection.data.request !==
                                    "POST /v1/flair/HOME" &&
                                rejection.data.message === "guest_user"
                            ) {
                                guestRegistration();
                            }
                            break;
                    }
                }
            }

            return $q.reject(rejection);
        }

        function guestRegistration() {
            var postInfo = {};

            uibModal.open({
                templateUrl:
                    "components/modal/guestRegistration/guestRegistration.html",
                controller: "guestRegistrationController as guestRegistration",
                controllerAs: "vm",
                resolve: {
                    postInfo: function() {
                        return postInfo;
                    }
                }
            });
        }

        function logSessionExpired(rejection) {
            $translate("LOGIN.ERROR.SESSION_EXPIRED").then(function(
                translation
            ) {
                var Logger = $injector.get("Logger");
                Logger.infoWithoutAlert(null, translation, rejection);
            });
        }
    }
})();
