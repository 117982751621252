(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("main.blogs.mine", {
            url: "/user/{id}/blogs/edit",
            templateUrl: "layout/main/blog/blogMine/blogMine.html",
            controller: "BlogMineCtrl",
            controllerAs: "blogs",
            resolve: {
                authorProfileResolve: function(
                    $q,
                    $state,
                    $stateParams,
                    CurrentUser,
                    User
                ) {
                    // Blog editing index is intended for only the current user.
                    // to edit someone elses blogs the current user must use the delegation search on their own {id}/blogs/edit page.
                    // Not specify a different ID in the URL.
                    return User.getUser($stateParams.id)
                        .then(function(user) {
                            if (user.id !== CurrentUser.profile.id) {
                                $state.go("404", {}, { location: "replace" });
                            } else {
                                return $q.when(user);
                            }
                        })
                        .catch(function(error) {
                            $state.go("404", {}, { location: "replace" });
                        });
                }
            }
        });
    });
})();
