(function() {
    "use strict";

    angular
        .module("ssmAngularApp.image.service", [])
        .service("ImageService", ImageService);

    function ImageService() {
        var assetRoot = "";

        function getPlaceholder(imageType) {
            var path;
            switch (imageType) {
                case "communities":
                    path =
                        assetRoot + "/assets/images/placeholder-community.png";
                    break;

                case "users":
                    path = assetRoot + "/assets/images/placeholder-user.png";
                    break;

                case "blogs":
                    path =
                        assetRoot + "/assets/images/placeholder-community.png";
                    break;

                case "organizations":
                    path =
                        assetRoot + "/assets/images/placeholder-community.png";
                    break;

                default:
                    path = assetRoot + "/assets/images/placeholder-user.png";
                    break;
            }
            return path;
        }

        function getSize(fileObject, size, imageType) {
            var path;
            var desiredUrl = fileObject.urls[size];
            var originalUrl = fileObject.urls.download;

            if (desiredUrl) {
                path = assetRoot + desiredUrl;
            } else if (originalUrl) {
                path = assetRoot + originalUrl;
            } else {
                return getPlaceholder(imageType);
            }
            return path;
        }

        function getPath(fileObject, size, imageType) {
            if (fileObject && fileObject.urls) {
                return getSize(fileObject, size, imageType);
            } else {
                return getPlaceholder(imageType);
            }
        }

        return {
            getPath: getPath,
            getSize: getSize
        };
    }
})();
