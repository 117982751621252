(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("InvitePendingController", InvitePendingController);

    function InvitePendingController(pendingPagerResolve) {
        var vm = this;
        vm.invitePager = pendingPagerResolve;

        activate();

        function activate() {}
    }
})();
