(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("CalendarCtrl", CalendarCtrl);

    function CalendarCtrl(eventsResolve, SSMTitle, $translate) {
        var vm = this;

        eventsResolve.forEach(function(event) {
            event.className = [];
            event.className.push("calendar-event");
            if (event.attending) {
                event.className.push("bg-primary");
                event.className.push("border-primary");
                event.className.push("bg-primary-text-color");
            } else {
                event.className.push("bg-info");
                event.className.push("border-primary");
                event.className.push("bg-info-text-color");
                event.className.push("text-primary-over");
            }

            // We have to rename the url parameter because the calendar appears
            // to ignore the "false" return value from eventClick below.
            event._url = event.url;
            event.url = undefined;
        });

        vm.events = [eventsResolve];

        vm.config = {
            height: "auto",
            editable: false,
            header: {
                left: "prev,next today",
                center: "title",
                right: "month,basicWeek,basicDay"
            },
            defaultView: "basicWeek",
            eventClick: eventClick
        };

        $translate("TITLE.EVENTS").then(function(title) {
            SSMTitle.setTitle(title);
        });

        function eventClick(event) {
            window.open(event._url);
            return false;
        }
    }
})();
