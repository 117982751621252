(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("AEHIXAuthCtrl", AEHIXAuthCtrl);

    function AEHIXAuthCtrl(
        $translate,
        $state,
        ssmAuth,
        CurrentUser,
        Logger,
        $location
    ) {
        var query = $location.search();
        var path = $location.path();

        var siteOrgSSO;
        if (path === "/auth/login/aehia/callback") {
            siteOrgSSO = "AehiaSingleSignOn";
        }
        if (path === "/auth/login/aehis/callback") {
            siteOrgSSO = "AehisSingleSignOn";
        }
        if (path === "/auth/login/aehit/callback") {
            siteOrgSSO = "AehitSingleSignOn";
        }

        var user = {
            email: siteOrgSSO + "|" + new Date(),
            password:
                query["ssoToken"] ||
                query["&ssoToken"] ||
                query["&amp;ssoToken"] ||
                query["amp;ssoToken"]
        };

        ssmAuth
            .login(user)
            .then(onLoginSuccess)
            .catch(onLoginFailed);

        function onLoginSuccess() {
            //Try hitting home in case they are already signed in.  Home will redirect them to login if they are not authenticated.
            $state.go("main.home", {}, { location: "replace" });
        }

        function onLoginFailed(error) {
            $translate("LOGIN.ERROR.AVECTRA_LOGIN").then(function(translation) {
                Logger.error("ERROR: ", translation, error);
                //Try hitting home in case they are already signed in.  Home will redirect them to login if they are not authenticated.
                $state.go("main.home", {}, { location: "replace" });
            });
        }
    }
})();
