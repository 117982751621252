(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("main.styleGuide", {
            url: "/style_guide",
            templateUrl: "layout/main/styleGuide/styleGuide.html",
            controller: "StyleGuideCtrl"
        });
    });
})();
