(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmVote", [])
        .directive("ssmVote", ssmVote)
        .controller("ssmVoteController", ssmVoteController);

    function ssmVote() {
        return {
            templateUrl: "components/ssmVote/ssmVote.html",
            restrict: "E",
            replace: true,
            scope: {
                likeSummary: "=",
                content: "="
            },
            controller: ssmVoteController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmVoteController(Content) {
        /*jshint validthis:true */
        var vm = this;

        vm.onUpVote = onUpVote;
        vm.onDownVote = onDownVote;

        activate();

        function activate() {}

        function onUpVote(content) {
            return castVote(content, { like_value: 1 });
        }

        function onDownVote(content) {
            return castVote(content, { like_value: -1 });
        }

        function castVote(content, vote) {
            if (!content) {
                return;
            }

            Content.voteForContent(content.id, vote).then(function(results) {
                content.likeSummary = results.likeSummary;
            });
        }
    }
})();
