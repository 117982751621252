(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.appaSideNav")
        .directive("appaSideNav", appaSideNav)
        .controller("appaSideNavController", appaSideNavController);

    function appaSideNav() {
        return {
            templateUrl: "components/appaSideNav/appaSideNav.html",
            restrict: "E",
            replace: true,
            scope: {},
            controller: appaSideNavController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function appaSideNavController(Appa) {
        /*jshint validthis: true */
        var vm = this;
        vm.jobPostings = [];

        activate();
        function activate() {
            Appa.getAppaJobsList()
                .then(function(jobPostings) {
                    vm.jobPostings = jobPostings;
                })
                .catch(handleError);
        }

        function handleError() {
            Logger.errorWithoutAlert(
                "Error trying to get APPA job positions.",
                error
            );
            vm.jobPostings = [];
        }
    }
})();
