(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").controller("AppaCtrl", AppaCtrl);

    function AppaCtrl(
        $translate, 
        $state, 
        ssmAuth, 
        Logger, 
        $location,
        Cookie
        ) {
        var query = $location.search();

        var user = {
            email: "AppaSingleSignOn|" + new Date(),
            password:
                query["ssoToken"] ||
                query["&ssoToken"] ||
                query["&amp;ssoToken"] ||
                query["amp;ssoToken"]
        };

        var shouldReturnToLastPage = query["samePage"] == "true" ? true : false;
        var urlOfLastPage = Cookie.read("lastPageVisited");

        ssmAuth
            .login(user)
            .then(onLoginSuccess)
            .catch(onLoginFailed);

        function onLoginSuccess() {
            if (shouldReturnToLastPage) {
                window.location.href = urlOfLastPage;
            }
            
        }

        function onLoginFailed(error) {
            $translate("LOGIN.ERROR.AVECTRA_LOGIN").then(function(translation) {
                Logger.error("ERROR: ", translation, error);
                $state.go("main.home", {}, { location: "replace" });
            });
        }
    }
})();
