(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.ssmFiles.service")
        .factory("ssmFiles", ssmFiles);

    function ssmFiles(Api) {
        var filesApi = Api.all("files");

        var service = {
            create: create,
            createNoAuth: createNoAuth,
            update: update,
            updateNoAuth: updateNoAuth,
            ie9awsCreate: ie9awsCreate,
            clearSignedUrlCache: clearSignedUrlCache
        };

        return service;

        function create(fileToUpload) {
            return filesApi.post(fileToUpload).then(function(response) {
                return response.plain();
            });
        }

        function createNoAuth(fileToUpload) {
            return filesApi
                .all("noauth")
                .post(fileToUpload)
                .then(function(response) {
                    return response.plain();
                });
        }

        function update(fileId, updatedFile) {
            return filesApi
                .one(fileId)
                .patch(updatedFile)
                .then(function(response) {
                    return response.plain();
                });
        }

        function updateNoAuth(fileId, updatedFile) {
            return filesApi
                .all("noauth")
                .one(fileId)
                .patch(updatedFile)
                .then(function(response) {
                    return response.plain();
                });
        }

        function ie9awsCreate(fileToUpload) {
            return filesApi
                .customPOST(fileToUpload, "uploadFileIE9")
                .then(function(response) {
                    return response.plain();
                });
        }

        function clearSignedUrlCache() {
            return filesApi
                .customPOST({}, "clearSignedUrlCache")
                .then(function(response) {
                    return response.plain();
                });
        }
    }
})();
