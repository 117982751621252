(function() {
    angular
        .module("ssmAngularApp.ellipsis", [])
        .directive("ssmEllipsis", ssmEllipsis);

    function ssmEllipsis() {
        return {
            restrict: "A",
            scope: {
                ngBind: "=",
                ngBindHtml: "=",
                ssmBindCompile: "=",
                ssmEllipsisAppend: "@",
                bufferSize: "@"
            },
            link: ssmEllipsisLink
        };

        function ssmEllipsisLink(scope, element) {
            var EMBEDDED_PLAYERS = ["embeddedMp3", "embeddedYouTube"];

            //Set buffer Size in pixels.
            var bufferHeight = scope.bufferSize || 100,
                unbindWatcher,
                readMoreTemplate =
                    "<span class='ssm-ellipsis-read-more text-primary'>" +
                    scope.ssmEllipsisAppend +
                    "</span>";

            element.addClass("ssm-ellipsis ssm-ellipsis-animate");

            //Wait for html to inject before doing calculations.
            if (scope.ngBindHtml) {
                unbindWatcher = scope.$watch("ngBindHtml", function() {
                    buildEllipsis();
                    //remove watch
                    unbindWatcher();
                });
            } else if (scope.ssmBindCompile) {
                unbindWatcher = scope.$watch("ssmBindCompile", function() {
                    buildEllipsis();
                    //remove watch
                    unbindWatcher();
                });
            } else {
                unbindWatcher = scope.$watch("ngBind", function() {
                    buildEllipsis();
                    //remove watch
                    unbindWatcher();
                });
            }

            function buildEllipsis() {
                if (!isOverflowed(element)) {
                    element.removeClass("ssm-ellipsis ssm-ellipsis-animate");
                } else {
                    if (scope.ssmEllipsisAppend) {
                        element
                            .append(readMoreTemplate)
                            .find(".ssm-ellipsis-read-more")
                            .on("click", readMoreElementClick)
                            .on(
                                "transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd",
                                transitionEnd
                            );
                    }
                }
            }

            function isOverflowed(thisElement) {
                var containsEmbeddedPlayers = _.some(EMBEDDED_PLAYERS, function(
                    embeddedPlayer
                ) {
                    return (
                        0 <
                        thisElement[0].getElementsByClassName(embeddedPlayer)
                            .length
                    );
                });
                var overFlowsArea =
                    thisElement[0].scrollHeight >
                    thisElement[0].clientHeight + bufferHeight;
                return overFlowsArea && !containsEmbeddedPlayers;
            }

            function readMoreElementClick() {
                element
                    .css({ height: element[0].scrollHeight + "px" })
                    .removeClass("ssm-ellipsis");
                this.remove();
            }

            function transitionEnd() {
                element.css({ height: "auto" });
            }
        }
    }
})();
