(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("VerifyEmailController", VerifyEmailController);

    function VerifyEmailController(
        $state,
        $stateParams,
        $translate,
        Analytics,
        CurrentUser,
        Logger,
        ssmAuth,
        ssmAuthHelper,
        Tenant,
        verifyResolve
    ) {
        var vm = this;
        vm.success = verifyResolve.success;
        vm.failed = !verifyResolve.success;
        vm.redirect = verifyResolve.redirect;
        vm.enterSite = enterSite;
        vm.resendValidate = resendValidate;
        vm.logoUrl = null;
        vm.user = null;

        activate();

        // User should only get here if user is not signed in or email verification has failed
        function activate() {
            Tenant.getTenant().then(function(tenant) {
                vm.marketplaceLanding = !!tenant.flags.marketplace_landing;
                vm.logoUrl = tenant.logo_url;
            });

            vm.user_id =
                CurrentUser.profile && CurrentUser.profile.id
                    ? CurrentUser.profile.id
                    : "unknown";
            vm.event = vm.success ? "EmailVerified" : "EmailVerificationFailed";
            Analytics.eventTrack(vm.event, {
                category: "RegistrationCtrl",
                label: "user_id:" + vm.user_id
            });
            vm.event2 = vm.success ? "email_verified" : "email_verification_failed";
            Analytics.eventTrack2(vm.event2, {
                category: "RegistrationCtrl",
                label: "user_id:" + vm.user_id
            });
        }

        function resendValidate() {
            Analytics.eventTrack("ResendEmailValidation", {
                category: "RegistrationCtrl",
                label: "user_id:" + vm.user_id
            });
            Analytics.eventTrack2("resend_email_validation", {
                category: "RegistrationCtrl",
                label: "user_id:" + vm.user_id
            });
            ssmAuth
                .resendValidation($stateParams.token)
                .then(function(result) {
                    if (result && result.already_verified) {
                        $translate("LOGIN.SUCCESSFUL_VALIDATION").then(function(
                            translation
                        ) {
                            Logger.success(null, translation);
                        });
                        vm.success = true;
                        vm.failed = false;

                        return;
                    }

                    $translate("LOGIN.SENT_VALIDATION_EMAIL").then(function(
                        translation
                    ) {
                        Logger.success(null, translation);
                    });
                })
                .catch(function(error) {
                    $translate(
                        "LOGIN.ERROR.COULD_NOT_SEND_VALIDATION_EMAIL"
                    ).then(function(translation) {
                        Logger.error(null, translation, error);
                    });
                });
        }

        function enterSite() {
            if (!ssmAuthHelper.isSignedIn) {
                $state.go("login");
            } else if (vm.redirect) {
                $state.go(vm.redirect);
            } else if (vm.marketplaceLanding) {
                $state.go("main.marketplace");
            } else {
                $state.go("main.home");
            }
        }
    }
})();
