(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmCategoryBreadcrumbs")
        .directive("ssmCategoryBreadcrumbs", ssmCategoryBreadcrumbs)
        .controller(
            "ssmCategoryBreadcrumbsController",
            ssmCategoryBreadcrumbsController
        );

    function ssmCategoryBreadcrumbs() {
        return {
            templateUrl:
                "components/ssmCategoryBreadcrumbs/ssmCategoryBreadcrumbs.html",
            restrict: "E",
            replace: true,
            scope: {
                categories: "="
            },
            controller: ssmCategoryBreadcrumbsController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmCategoryBreadcrumbsController($scope, $state) {
        /*jshint validthis:true */
        var vm = this;

        vm.breadcrumbs = [];
        vm.createBreadcrumbs = createBreadcrumbs;
        vm.getCategoryParams = getCategoryParams;

        activate();

        function activate() {
            vm.createBreadcrumbs($state.params.category);

            $scope.$on("$stateChangeSuccess", function() {
                vm.createBreadcrumbs($state.params.category);
            });
        }

        function createBreadcrumbs(id) {
            vm.breadcrumbs.length = 0; //Clear the array without changing the reference

            var nextId = id;
            while (nextId) {
                var category = vm.categories.categoryIdMap[nextId];
                if (!category) {
                    // Log an error, something in our category map is corrupt
                    // Stop processing
                    break;
                }

                vm.breadcrumbs.unshift(category);
                nextId = category.parent_id;
            }
        }

        function getCategoryParams(overrides) {
            overrides = overrides || {};
            var params = $state.params;
            _.assignWith(params, overrides);
            return params;
        }
    }
})();
