(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("ValidateCtrl", ValidateCtrl);

    function ValidateCtrl(
        ssmAuth,
        ssmAuthHelper,
        Analytics,
        CurrentUser,
        Logger,
        $translate,
        $state,
        Tenant
    ) {
        var vm = this;
        vm.resendValidate = resendValidate;
        vm.logout = ssmAuth.logout;
        vm.logoUrl = null;

        activate();

        function activate() {
            //Double check to make sure that we have currentUser since we are not in state 'main'.
            CurrentUser.refresh().then(function(profile) {
                //Check page refresh.
                if (profile.emails[0].verified) {
                    ssmAuth.redirectToLastAttemptedUrl();
                }
            });

            Tenant.getTenant().then(function(tenant) {
                vm.logoUrl = tenant.logo_url;
            });
        }

        function resendValidate() {
            Analytics.eventTrack("Validation", {
                category: "Validation",
                label: "user_id:" + CurrentUser.profile.id
            });
            Analytics.eventTrack2("validation", {
                category: "Validation",
                label: "user_id:" + CurrentUser.profile.id
            });
            ssmAuth
                .resendValidation(ssmAuthHelper.token)
                .then(function() {
                    $translate("LOGIN.SENT_VALIDATION_EMAIL").then(function(
                        translation
                    ) {
                        Logger.success(null, translation);
                    });
                })
                .catch(function(error) {
                    $translate(
                        "LOGIN.ERROR.COULD_NOT_SEND_VALIDATION_EMAIL"
                    ).then(function(translation) {
                        Logger.error(null, translation, error);
                    });
                });
        }
    }
})();
