(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmInputWithSuggestions")
        .directive("ssmInputWithSuggestions", ssmInputWithSuggestions)
        .controller(
            "ssmInputWithSuggestionsController",
            ssmInputWithSuggestionsController
        );

    function ssmInputWithSuggestions() {
        return {
            templateUrl:
                "components/inputWithSuggestions/ssmInputWithSuggestions.html",
            restrict: "E",
            replace: true,
            scope: {
                itemName: "=",
                selectedItem: "=",
                suggestions: "=", //Items to have both name and id defined
                openSuggestionsDropdown: "=",
                onInputCallback: "=", // Should return a promise
                onSelectCallback: "=",
                onAdditionalItemCallback: "=",
                additionalItemLabel: "@",
                placeholder: "@"
            },
            controller: ssmInputWithSuggestionsController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmInputWithSuggestionsController(
        $scope,
        $timeout,
        $translate,
        Logger,
        Organization,
        SSMUtilities,
        User
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.onInputKeyDown = onInputKeyDown;
        vm.onSelectKeyDown = onSelectKeyDown;
        vm.onSelectClick = onSelectClick;
        vm.onAdditionalItemKeyDown = onAdditionalItemKeyDown;
        vm.onAdditionalItemClick = onAdditionalItemClick;

        vm.openSuggestionsDropdown = false;

        var TAB_KEY = 9;
        var ENTER_KEY = 13;
        var SHIFT_KEY = 16;

        activate();

        function activate() {}

        function onInputKeyDown(event) {
            if (event.keyCode !== ENTER_KEY) {
                return;
            }

            if (!vm.onInputCallback) {
                Logger.error("ERROR: ", "No input callback defined");
                return;
            }
            vm.openSuggestionsDropdown = false;

            vm.onInputCallback().then(function(suggestions) {
                vm.suggestions = suggestions;
                vm.openSuggestionsDropdown = true;
            });
        }

        function onSelectKeyDown(event, item) {
            if (event.keyCode === TAB_KEY || event.keyCode === SHIFT_KEY) {
                return;
            }
            return onSelectClick(item);
        }

        function onSelectClick(item) {
            if (!item) {
                vm.selectedItem = null;
                vm.itemName = null;
                vm.openSuggestionsDropdown = false;
                return;
            }

            vm.selectedItem = item;
            vm.itemName = item.name;
            vm.openSuggestionsDropdown = false;

            if (vm.onSelectCallback) {
                return vm.onSelectCallback(item);
            }
        }

        function onAdditionalItemKeyDown(event) {
            if (event.keyCode === TAB_KEY || event.keyCode === SHIFT_KEY) {
                return;
            }
            return onAdditionalItemClick();
        }

        function onAdditionalItemClick() {
            vm.selectedItem = null;
            vm.itemName = null;
            vm.openSuggestionsDropdown = false;

            if (vm.onAdditionalItemCallback) {
                return vm.onAdditionalItemCallback();
            }
        }
    }
})();
