(function() {
    "use strict";

    angular
        .module("ssmAngularApp.inviteMessageForm", [])
        .directive("inviteMessageForm", inviteMessageForm);

    function inviteMessageForm() {
        return {
            templateUrl: "components/inviteMessageForm/inviteMessageForm.html",
            restrict: "E",
            scope: {
                message: "="
            },
            controller: inviteMessageFormCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function inviteMessageFormCtrl($translate) {
        /*jshint validthis:true */
        var vm = this;

        activate();

        function activate() {
            $translate("EXTERNAL_INVITES_WIZARD.MESSAGE_PLACEHOLDER").then(
                function(translation) {
                    vm.message = translation;
                }
            );
        }
    }
})();
