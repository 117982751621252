(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.cookie", ["ssmAngularApp.api"])
        .factory("Cookie", Cookie);

    function Cookie() {
        return {
            create: function(name, value, days) {
                if (days) {
                    var date = new Date();
                    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                    var expireDate = "; expires=" + date.toGMTString();
                } else var expireDate = "";
                document.cookie = name + "=" + value + expireDate + "; path=/";    
            },

            read: function(name) {
                var cookieName = name + "=";
                var cookieArray = document.cookie.split(";");
                for (var i = 0; i < cookieArray.length; i++) {
                    var cookie = cookieArray[i];
                    while (cookie.charAt(0) == " ") cookie = cookie.substring(1, cookie.length);
                    if (cookie.indexOf(cookieName) == 0)
                        return cookie.substring(cookieName.length, cookie.length);
                }
                return null;
            },

            remove: function(name) {
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            }
        }
    }
})();
