(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.vendor.service", ["ssmAngularApp.api"])
        .factory("Vendor", Vendor);

    function Vendor(Api, $q) {
        var vendorApi = Api.all("vendor");
        var vendorManagersApi = vendorApi.all("managers");

        var cached = {};

        var PAGE_LIMIT = 20;

        return {
            STATUS: {
                ACTIVE: 1,
                INACTIVE: 2
            },

            all: all,
            allPaged: allPaged,
            addAdmin: addAdmin,
            addVendor: addVendor,
            addVendorLibraryFile: addVendorLibraryFile,
            deleteAdmin: deleteAdmin,
            demote: demote,
            getAdmins: getAdmins,
            getVendor: getVendor,
            getVendorLibrary: getVendorLibrary,
            patchVendor: patchVendor,
            promote: promote,
            removeVendorLibraryFile: removeVendorLibraryFile
        };

        // get a special paging promise/object that will handle getting vendors in batches
        function allPaged(limit) {
            var paging = {
                vendors: [], // previously retrieved vendors
                disabled: false, // cannot retrieve more if we are already waiting or there are no more to get
                limit: limit ? limit : PAGE_LIMIT,
                next: null
            };

            // This function returns a promise that resolves to the entire paging object. Every time .next resolves, it
            // resolves to the same object with an updated state.
            paging.next = function() {
                if (paging.disabled) {
                    return $q.when(paging);
                }

                paging.disabled = true;

                var query = {
                    skip: paging.vendors.length,
                    limit: paging.limit
                };
                return all(query).then(function(vendors) {
                    if (vendors.plain) {
                        vendors = vendors.plain();
                    }

                    // if we got more vendors, add them to our list and enable paging
                    if (vendors && vendors.length) {
                        paging.disabled = false;
                        paging.vendors = _.union(paging.vendors, vendors);
                    }

                    return $q.when(paging);
                });
            };

            return paging.next();
        }

        function all(query) {
            clearCache(); // We don't need to cache all requests so use this as an opportunity to clear it
            return vendorApi.getList(query);
        }

        function addAdmin(vendor_id, user_id) {
            // TODO this will technically alter the vendor object. we should retrieve the new one and update the cache
            return vendorApi
                .one(vendor_id)
                .all("admins")
                .one(user_id)
                .put();
        }

        function addVendor(vendor) {
            return vendorApi.post(vendor);
        }

        function addVendorLibraryFile(vendor_id, file_id) {
            return vendorApi
                .one(vendor_id)
                .all("library")
                .one(file_id)
                .post();
        }

        function clearCache() {
            cached = {};
        }

        function deleteAdmin(vendor_id, user_id) {
            // TODO this will technically alter the vendor object. we should retrieve the new one and update the cache
            return vendorApi
                .one(vendor_id)
                .all("admins")
                .one(user_id)
                .remove();
        }

        function demote(user_id) {
            return vendorManagersApi.one(user_id).remove();
        }

        function getAdmins(vendor_id) {
            return vendorApi
                .one(vendor_id)
                .all("admins")
                .getList();
        }

        function getVendor(id) {
            if (!cached[id]) {
                cached[id] = vendorApi.one(id).get();
            }

            return $q.when(cached[id]);
        }

        function getVendorLibrary(vendor_id, query) {
            return vendorApi.one(vendor_id, "library").get(query);
        }

        function patchVendor(id, edits) {
            return vendorApi
                .one(id)
                .patch(edits)
                .then(function(newResults) {
                    return updateVendor(id, newResults);
                });
        }

        function promote(user_id) {
            return vendorManagersApi.post({
                user_id: user_id
            });
        }

        function removeVendorLibraryFile(vendor_id, file_id) {
            // NOTE: this only removes from the library but does not delete the file from the DB
            return vendorApi
                .one(vendor_id, "library")
                .one(file_id)
                .remove();
        }

        function updateVendor(id, newResults) {
            if (cached[id]) {
                cached[id].then(function(oldResults) {
                    return angular.extend(oldResults, newResults);
                });
            } else {
                cached[id] = $q.when(newResults);
            }

            return cached[id];
        }
    }
})();
