(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.wallProfile")
        .directive("wallProfile", wallProfile);

    function wallProfile() {
        return {
            templateUrl: "components/wallProfile/wallProfile.html",
            restrict: "E",
            replace: true,
            scope: {
                userData: "=",
                showEdit: "="
            },
            controller: wallProfileCtrl,
            controllerAs: "wallProfile",
            bindToController: true
        };
    }

    function wallProfileCtrl(
        CurrentUser,
        Tenant, 
        Tutorial
        ) {
        /*jshint validthis:true */

        var vm = this;

        vm.tutorialNextStep = tutorialNextStep;
        vm.userProfile = CurrentUser.profile;
        vm.displayEmail;
        
        activate();

        function activate() {
            getTenantAndSetFlags();
            attemptToGetEmailForAvatarCard();
        }

        function getTenantAndSetFlags() {
            Tenant.getTenant()
            .then(function(tenant) {
                vm.tenant = {
                    flags: tenant.flags
                };
                if (tenant.flags.enable_tutorial_mode) {
                    Tutorial.openStep2();
                }
            });
        }

        function attemptToGetEmailForAvatarCard() {
            if (!vm.userData.show_email || vm.userData.show_email == "" || vm.userData.show_email == false) return;
            if (vm.userData.emails && vm.userData.emails.length > 0) {
                vm.displayEmail = vm.userData.emails[0].email;
            }
        }

        function tutorialNextStep() {
            Tutorial.exit();
        }
    }
})();
