(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("SignUpPageCtrl", SignUpPageCtrl);

    function SignUpPageCtrl($state, Tenant) {
        var vm = this;
        vm.logUrl = null;
        vm.searchParams = $state.params;

        Tenant.getTenant().then(function(tenant) {
            vm.logoUrl = tenant.logo_url;
        });
    }
})();
