/* jshint maxparams: 14 */
(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.ssmAuth.service")
        .provider("ssmAuth", ssmAuthProvider);

    function ssmAuthProvider($httpProvider) {
        /* jshint validthis:true */
        this.init = function() {
            $httpProvider.interceptors.push("ssmAuthInterceptor");
        };

        this.$get = ssmAuth;
    }

    function ssmAuth(
        Api,
        ssmAuthHelper,
        ssmAuthInterceptor,
        $rootScope,
        $state,
        $location,
        $interval,
        ssmLocalStorage,
        Logger,
        Tenant,
        $uibModal,
        $q,
        Cookie,
        CurrentUser,
        CurrentUserApi,
        SSM_API_URL,
        SSM_FEATURES
    ) {
        var authApi = Api.all("auth");
        var ahimaSsoApi = Api.all("ahima-sso");
        var apaSsoApi = Api.all("apa-sso");
        var chimeSsoApi = Api.all("chime-sso");
        var tokenCheckFrequency = 60 * 60 * 1000;
        var tokenExpirationGap = 62 * 60 * 1000;

        ssmAuthInterceptor.setUibModal($uibModal);

        return {
            hookEvents: hookEvents,
            signup: signup,
            logout: logout,
            logoutNoReload: logoutNoReload,
            login: login,
            linkedInLogin: linkedInLogin,
            processToken: processToken,
            sendResetPasswordEmail: sendResetPasswordEmail,
            resetPassword: resetPassword,
            changePassword: changePassword,
            verifyEmail: verifyEmail,
            resendValidation: resendValidation,
            redirectToLastAttemptedUrl: redirectToLastAttemptedUrl,
            ahimaSsoLogin: ahimaSsoLogin,
            apaSsoLogin: apaSsoLogin,
            chimeSsoLogin: chimeSsoLogin,
            setDisplayEulaSsoSettings: setDisplayEulaSsoSettings,

            get isSignedIn() {
                return ssmAuthHelper.isSignedIn;
            }
        };

        ////////////////////
        // Service functions
        ////////////////////

        function hookEvents() {
            $interval(checkTokenExpiration, tokenCheckFrequency);
            $rootScope.$on("$stateChangeStart", onStateChangeStart);
            $rootScope.$on("$stateChangeSuccess", onStateChangeSuccess);
        }

        function verifyEmail(token) {
            return authApi.get("verify-email", {
                token: token
            });
        }

        function resendValidation(token, final_redirect_uri) {
            return authApi.get("send-validation", {
                token: token,
                final_redirect_uri: final_redirect_uri
            });
        }

        function signup(user) {
            var deferred = $q.defer();

            Tenant.getTenant().then(function(tenant) {
                if (!tenant.flags.standard_auth) {
                    return deferred.reject(
                        new Error("Signup not supported on this tenant")
                    );
                }

                if (!user.organization_id) {
                    user.organization_id = tenant.organization_id;
                }
                var redirect = ssmLocalStorage.get(
                    ssmLocalStorage.key.LAST_ATTEMPTED_URL
                );
                if (redirect) {
                    user.final_redirect_uri = redirect;
                }
                authApi
                    .all("signup")
                    .post(user)
                    .then(function(token) {
                        processToken(token, true);
                        deferred.resolve(ssmAuthHelper.getUserId());
                    })
                    .catch(function(err) {
                        deferred.reject(_.omitBy(err, "config"));
                    });
            });

            return deferred.promise;
        }

        function processToken(token, prevent_reload) {
            ssmAuthHelper.saveToken(token);
            if (!prevent_reload) {
                redirectToLastAttemptedUrl();
            }
        }

        function logout() {
            CurrentUser.clear();
            ssmAuthHelper.logout(true);
        }

        function logoutNoReload() {
            CurrentUser.clear();
            ssmAuthHelper.logout(false);
        }

        function login(user) {
            var deferred = $q.defer();

            Tenant.getTenant().then(function(tenant) {
                var params = {
                    tenant_slug: tenant.slug
                };
                authApi
                    .all("login")
                    .post(user, params)
                    .then(function(token) {
                        processToken(token);

                        return CurrentUser.refresh();
                    })
                    .then(function() {
                        deferred.resolve(ssmAuthHelper.getUserId());
                    })
                    .catch(function(err) {
                        deferred.reject(_.omitBy(err, "config"));
                    });
            });

            return deferred.promise;
        }

        function ahimaSsoLogin(userToken) {
            return ssoLogin(ahimaSsoApi, userToken);
        }

        function apaSsoLogin(userToken) {
            return ssoLogin(apaSsoApi, userToken);
        }

        function chimeSsoLogin(userToken) {
            return ssoLogin(chimeSsoApi, userToken);
        }

        function ssoLogin(api, userToken) {
            var deferred = $q.defer();

            Tenant.getTenant().then(function(tenant) {
                api
                    .all("login")
                    .post(userToken)
                    .then(function(token) {
                        processToken(token);

                        return CurrentUser.refresh();
                    })
                    .then(function() {
                        deferred.resolve(ssmAuthHelper.getUserId());
                    })
                    .catch(function(err) {
                        deferred.reject(_.omitBy(err, "config"));
                    });
            });

            return deferred.promise;
        }

        function linkedInLogin() {
            var deferred = $q.defer();

            Tenant.getTenant().then(function(tenant) {
                if (!tenant.flags.linked_in) {
                    return deferred.reject(
                        new Error(
                            "LinkedIn login not supported on this tenant."
                        )
                    );
                }
                var redirect_uri = encodeURIComponent(
                    location.href.split("?")[0]
                ).replace("signup", "login");
                var final_redirect_uri = encodeURIComponent(
                    ssmLocalStorage.get(ssmLocalStorage.key.LAST_ATTEMPTED_URL)
                );
                location.assign(
                    SSM_API_URL +
                        "/auth/linkedin?redirect_uri=" +
                        redirect_uri +
                        "&final_redirect_uri=" +
                        final_redirect_uri
                );
            });

            return deferred.promise;
        }

        function sendResetPasswordEmail(email) {
            var deferred = $q.defer();
            authApi
                .get("send-password-reset", {
                    email: email
                })
                .then(function(response) {
                    deferred.resolve();
                })
                .catch(function(err) {
                    deferred.reject(_.omitBy(err, "config"));
                });

            return deferred.promise;
        }

        function resetPassword(email, password, token) {
            return authApi.all("password-reset").post({
                token: token,
                email: email,
                password: password
            });
        }

        function changePassword(old_password, new_password) {
            return authApi.all("change-password").post({
                old_password: old_password,
                new_password: new_password
            });
        }

        function setDisplayEulaSsoSettings(tenant) {
            if (tenant.sso_button) {
                return tenant.sso_button.activate_sso;
            }
            else return false;
        }

        ////////////////////
        // Helper functions
        ////////////////////
        function onStateChangeStart(event, toState) {
            handleRequiresLogin(event, toState);
            handleRequiresAdmin(event, toState);
            handleAllowTenant(event, toState);
            handleShowFeature(event, toState);
        }

        function onStateChangeSuccess(event, toState) {
            var requiresLogin = toState.data && toState.data.requiresLogin;
            var disableLastAttemptedUrl =
                toState.data && toState.data.disableLastAttemptedUrl;
            if (requiresLogin && !disableLastAttemptedUrl) {
                saveLastAttemptedUrl();
            }
        }

        function saveLastAttemptedUrl() {
            ssmLocalStorage.set(
                ssmLocalStorage.key.LAST_ATTEMPTED_URL,
                $location.url()
            );
        }

        function handleRequiresLogin(event, toState) {
            var requiresLogin = toState.data && toState.data.requiresLogin;
            if (requiresLogin) {
                if (!ssmAuthHelper.isSignedIn) {
                    event.preventDefault();
                    saveLastAttemptedUrl();
                    performGuestLoginIfAvailable();
                }
            }
        }

        function performGuestLoginIfAvailable() {
            Tenant.getTenant().then(tenant => {
                const guestAuth = tenant.flags.guest_auth;
                if (guestAuth) {
                    const user = {
                        email: `guest@${tenant.organization_id}.com`,
                        password: tenant.organization_id
                    };

                    var guestCommentCookie = Cookie.read("guestCommentCookie");
                    if (!guestCommentCookie) {
                        Cookie.create("guestCommentCookie", "false", 10);
                    }

                    login(user);
                } else {
                    location.assign("/login");
                }
            });
        }

        function handleShowFeature(event, toState) {
            if (toState.data && toState.data.showFeature) {
                var showFeature = toState.data.showFeature.toUpperCase();
                if (!SSM_FEATURES[showFeature]) {
                    event.preventDefault();
                    location.assign("/404");
                }
            }
        }

        function handleRequiresAdmin(event, toState) {
            if (toState.data && toState.data.requiresAdmin) {
                var currentUser = ssmLocalStorage.get(
                    ssmLocalStorage.key.CURRENT_USER
                );
                if (!currentUser || !currentUser.is_admin) {
                    event.preventDefault();
                    location.assign("/404");
                }
            }
        }

        function handleAllowTenant(event, toState) {
            var allowTenant = toState.data
                ? toState.data.allowTenant
                : undefined;
            if (!allowTenant) {
                return;
            }

            var tenant = Tenant.getTenantSync();
            var allow = tenant.flags[allowTenant];

            if (!allow) {
                event.preventDefault();
                location.assign("/404");
            }
        }

        function checkTokenExpiration() {
            if (!ssmAuthHelper.isSignedIn) {
                return;
            }

            var difference = ssmAuthHelper.secondsUntilTokenExpiration;
            if (difference < tokenExpirationGap) {
                // The token is soon to expire.  Refresh it.
                refreshToken();
            }
        }

        function refreshToken() {
            authApi
                .one("refresh-token")
                .get()
                .then(function(token) {
                    processToken(token, true);
                })
                .catch(function(err) {
                    Logger.warning("Failed to refresh token. err = ", err);
                });
        }

        function redirectToLastAttemptedUrl() {
            CurrentUserApi.getProfile().then(function(user) {
                var tenant = Tenant.getTenantSync();
                var eulaNotAccepted = !user.accepted_eula;
                var displayEulaSsoModal = setDisplayEulaSsoSettings(tenant);
                const disablePhi = tenant.flags.phi_agreement_disabled;

                if (user.is_guest) {
                    return redirectCallback();
                }

                if (userHasNotSignedMostRecentEulaForTenant(user, tenant)
                    || (!disablePhi && !user.accepted_phi_date) 
                    || (displayEulaSsoModal && eulaNotAccepted)
                ) {
                    location.href = window.location.origin +"/update-eula";
                    return;
                }
                redirectCallback();
            });
        }

        function userHasNotSignedMostRecentEulaForTenant(user, tenant) {
            var eulaUpdateDate = new Date("1970-01-01");
            var eulaAcceptDate = new Date(user.accepted_eula_date || null);
            if (tenant.eula_update_date) {
                eulaUpdateDate = new Date(tenant.eula_update_date);
            }

            return eulaAcceptDate < eulaUpdateDate ;
        }

        function redirectCallback() {
            var lastAttempted = ssmLocalStorage.get(ssmLocalStorage.key.LAST_ATTEMPTED_URL) || "/";
            var invalidRedirects = ["/registration"];

            if (isSsoTokenUrl(lastAttempted) 
                || invalidRedirects.indexOf(lastAttempted) >= 0
            ) {
                lastAttempted = "/";
            }

            var tenant = Tenant.getTenantSync();
            var marketplaceLanding = !!tenant.flags.marketplace_landing;

            if (lastAttempted === "/" && marketplaceLanding) {
                location.assign("/marketplace");
            } else {
                location.assign(lastAttempted);
            }
        }

        function isSsoTokenUrl(url) {
            var tokenString = "/callback?ssoToken=";
            return url.indexOf(tokenString) !== -1;
        }
        
    }
})();
