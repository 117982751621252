(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmLocalStorage")
        .factory("ssmLocalStorage", ssmLocalStorage);

    function ssmLocalStorage() {
        var storageCache = {};
        return {
            key: {
                TOKEN: "token",
                CURRENT_USER: "currentUser",
                CURRENT_TENANT: "currentTenant",
                SSM_USER_ID: "ssm_user_id",
                IP_ADDRESS: "ip_address",
                GUEST_TOKEN: "guest_token",
                LAST_ATTEMPTED_URL: "last_attempted_url",
                GUEST_COMMENT: "guest_comment",
                POST_ID: "post_id"
            },

            set: function(key, value) {
                storageCache[key] = value;
                window.localStorage.setItem(key, JSON.stringify(value));
            },

            get: function(key) {
                if (!storageCache[key]) {
                    var localCache = window.localStorage.getItem(key);
                    if (localCache) {
                        storageCache[key] = JSON.parse(localCache);
                    }
                }
                return storageCache[key];
            },

            remove: function(key) {
                delete storageCache[key];
                window.localStorage.removeItem(key);
            },

            clear: function() {
                //Once we move away from setting the tenant dynamically, we can switch this to window.localStorage.clear() and storageCache = {}.
                this.remove(this.key.TOKEN);
                this.remove(this.key.CURRENT_USER);
                this.remove(this.key.SSM_USER_ID);
                this.remove(this.key.LAST_ATTEMPTED_URL);
                this.remove(this.key.GUEST_COMMENT);
                this.remove(this.key.POST_ID);
            },

            // Only remove auth related data
            clearAuth: function() {
                this.remove(this.key.TOKEN);
                this.remove(this.key.CURRENT_USER);
                this.remove(this.key.SSM_USER_ID);
            }
        };
    }
})();
