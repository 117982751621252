(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("main.posts", {
            url: "/posts/{id}",
            templateUrl: "layout/main/posts/posts.html",
            controller: "PostsCtrl",
            controllerAs: "posts",
            resolve: {
                singlePostResolve: singlePostResolve,
                userProfileResolve: userProfileResolve,
                locationResolve: locationResolve
            }
        });

        function singlePostResolve($stateParams, User, $translate, Logger, $q) {
            var deferred = $q.defer();
            User.getPost($stateParams.id)
                .then(deferred.resolve)
                .catch(function(error) {
                    switch (error.status) {
                        case 403: //forbidden
                            $translate("WALL.GET_POSTS_FORBIDDEN_USER").then(
                                function(translation) {
                                    Logger.warningWithoutAlert(
                                        "Private User: ",
                                        translation,
                                        error
                                    );
                                }
                            );
                            break;
                        case 404:
                            return deferred.resolve();
                        case 401:
                            $translate("WALL.GET_POSTS_ERROR").then(function(
                                translation
                            ) {
                                Logger.errorWithoutAlert(
                                    "ERROR: ",
                                    translation,
                                    error
                                );
                            });
                            break;
                        default:
                            $translate("WALL.GET_POSTS_ERROR").then(function(
                                translation
                            ) {
                                Logger.error("ERROR: ", translation, error);
                            });
                            break;
                    }

                    return deferred.reject(error);
                });

            return deferred.promise;
        }

        function userProfileResolve(singlePostResolve, User, WallPost) {
            if (
                !singlePostResolve ||
                singlePostResolve.location.type !== WallPost.LOCATION_TYPE.USER
            ) {
                return null;
            }

            return User.getUser(singlePostResolve.location.id);
        }

        function locationResolve(
            singlePostResolve,
            userProfileResolve,
            CurrentUser,
            WallPost,
            Connection
        ) {
            if (!singlePostResolve || !singlePostResolve.location) {
                return null;
            }

            var location = angular.copy(singlePostResolve.location);

            if (
                location.type === WallPost.LOCATION_TYPE.USER &&
                userProfileResolve
            ) {
                singlePostResolve.location.permissions.canComment =
                    CurrentUser.profile.id === location.id ||
                    (userProfileResolve.connection &&
                        userProfileResolve.connection.status ===
                            Connection.CONNECTION_STATUS.APPROVED);
            }

            // Set the _current_ location to the individual post wall so we can decide if we want to display the post location information
            location.type = WallPost.LOCATION_TYPE.POST;
            location.id = singlePostResolve.id;
            return location;
        }
    });
})();
