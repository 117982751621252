(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmTagSelect", [])
        .constant("TAG_CONFIG", {
            MAX_TAG_LENGTH: 48,
            MAX_TAGS: 20
        })
        .directive("ssmTagSelect", ssmTagSelect)
        .controller("ssmTagSelectController", ssmTagSelectController);

    function ssmTagSelect($timeout, TAG_CONFIG) {
        return {
            templateUrl: "components/ssmTagSelect/ssmTagSelect.html",
            restrict: "E",
            replace: true,
            scope: {
                identifier: "@",
                data: "=",
                label: "@",
                placeholder: "@"
            },
            controller: ssmTagSelectController,
            controllerAs: "vm",
            bindToController: true,
            link: function(scope, element, attrs) {
                $timeout(function() {
                    var input = $(element).find(".ui-select-search");
                    if (input && input.length === 1) {
                        input.attr("maxlength", TAG_CONFIG.MAX_TAG_LENGTH);
                    }

                    var selector = scope.vm.selector;
                    var orig_select = selector.select;
                    selector.select = function() {
                        if (
                            selector.multiple &&
                            selector.selected.length < TAG_CONFIG.MAX_TAGS
                        ) {
                            orig_select.apply(selector, arguments);
                        }
                    };
                });
            }
        };
    }

    function ssmTagSelectController(
        $q,
        $scope,
        $timeout,
        $translate,
        SSMUtilities,
        Tag,
        TAG_CONFIG
    ) {
        /*jshint validthis:true */
        var vm = this;
        vm.selector = {};
        vm.transform_tag = transformTag;

        vm.suggested_hash_tags = [];
        vm.refreshSuggestions = refreshSuggestionsHandler;

        // ui-select forces us to use a non-string as the tag object (or it won't work correctly)
        // We use the current_hash_tags property to hold those objects, and translate back and forth
        // on changes
        vm.current_hash_tags = [];
        vm.onChange = changeHandler;

        vm.tag_limit = "";

        activate();

        function activate() {
            $translate(vm.placeholder).then(function(translation) {
                vm.tag_prompt = translation;
            });

            $translate(vm.label).then(function(translation) {
                vm.tag_label = translation;
            });

            $translate("TAGS.TAG_LIMIT_REACHED").then(function(translation) {
                vm.tag_limit = translation;
            });

            // Respond to programmatic changes to the model
            $scope.$on(
                SSMUtilities.SSM_TAG_SELECT_PROGRAMMATIC_CHANGE_EVENT,
                function(event, identifier) {
                    if (vm.identifier && vm.identifier === identifier) {
                        $timeout(function() {
                            initializeCurrentTags();
                            changeHandler();
                        });
                    }
                }
            );

            initializeCurrentTags();
        }

        function initializeCurrentTags() {
            vm.current_hash_tags = getCurrentTags(vm.data);
        }

        function refreshSuggestionsHandler(tag) {
            if (_.isEmpty(tag)) {
                vm.suggested_hash_tags = [];
                return;
            }

            getSuggestions(tag).then(function(suggestions) {
                vm.suggested_hash_tags = suggestions;
            });
        }

        function transformTag(newTag) {
            return getTransformedTag(newTag);
        }

        function changeHandler() {
            vm.data = _.map(vm.current_hash_tags, "transformed_tag");
        }

        function getCurrentTags(strings) {
            return _.map(_.uniqBy(strings), function(hash_tag) {
                return {
                    raw_tag: hash_tag,
                    transformed_tag: hash_tag
                };
            });
        }

        function getTransformedTag(tag) {
            if (vm.current_hash_tags.length >= TAG_CONFIG.MAX_TAGS) {
                return {
                    raw_tag: "",
                    transformed_tag: vm.tag_limit,
                    is_search_item: false,
                    is_warning_item: true
                };
            }

            var transformed_tag = tag
                .replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter) {
                    return letter.toUpperCase();
                })
                .replace(/\s+/g, "")
                .replace(/\W/g, "");

            if (transformed_tag[0] !== "#") {
                transformed_tag = "#" + transformed_tag;
            }

            transformed_tag =
                transformed_tag.length > TAG_CONFIG.MAX_TAG_LENGTH
                    ? transformed_tag.slice(0, TAG_CONFIG.MAX_TAG_LENGTH)
                    : transformed_tag;

            return {
                raw_tag: tag,
                transformed_tag: transformed_tag,
                is_search_item: false,
                is_warning_item: false
            };
        }

        function getSuggestions(tag) {
            var getSuggestionsPromise = $q.defer();

            if (_.isEmpty(tag) || tag === "#") {
                Tag.getSuggestions()
                    .then(function(suggestions) {
                        suggestions = _.map(suggestions, function(item) {
                            return {
                                raw_tag: item.value,
                                transformed_tag: item.value,
                                is_search_item: false
                            };
                        });
                        getSuggestionsPromise.resolve(suggestions);
                    })
                    .catch(function(error) {
                        getSuggestionsPromise.reject();
                    });
            } else {
                var search_item = getTransformedTag(tag);
                if (!search_item.is_warning_item) {
                    search_item.is_search_item = true;
                }

                Tag.search({ tag_value: search_item.transformed_tag })
                    .then(function(results) {
                        results = _.map(results, function(item) {
                            return {
                                raw_tag: item.value,
                                transformed_tag: item.value,
                                is_search_item: false
                            };
                        });

                        // Add the search item (whatever the user has typed to this point) to the list if it doesn't duplicate an existing
                        // item (matches will always be in slot 0), or there were no matching results at all.
                        if (
                            (results.length > 0 &&
                                results[0].transformed_tag !==
                                    search_item.transformed_tag) ||
                            results.length === 0
                        ) {
                            results.splice(0, 0, search_item);
                        }

                        getSuggestionsPromise.resolve(results);
                    })
                    .catch(function(error) {
                        getSuggestionsPromise.reject();
                    });
            }

            return getSuggestionsPromise.promise;
        }
    }
})();
