(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.libraryFile")
        .directive("libraryFile", libraryFile);

    function libraryFile() {
        return {
            templateUrl: "components/libraryFile/libraryFile.html",
            restrict: "E",
            scope: {
                fileData: "=",
                folderData: "=?",
                currentDirectory: "=?",
                currentDirectoryName: "=?",
                selectedFolder: "=?",
                moveDirs: "=?",
                admins: "=",
                sideBySide: "=",
                removeButton: "@",
                enableInfiniteScroll: "=",
                onNextPage: "&",
                onRemoveFile: "&",
                onRemoveFolder: "&",
                homeLocationId: "@"
            },
            replace: true,
            controller: libraryFileCtrl,
            controllerAs: "libraryFile",
            bindToController: true
        };
    }

    function libraryFileCtrl(
        Audience,
        CurrentUser,
        $translate,
        $q,
        Logger,
        Tenant,
        Analytics,
        Community,
        $uibModal
    ) {
        /*jshint validthis: true */
        var vm = this;
        vm.canRemove = canRemove;
        vm.removeFile = removeFileClicked;
        vm.removeFolder = removeFolderClicked;
        vm.renameFolder = renameFolderClicked;
        vm.changeDirectory = changeDirectoryClicked;
        vm.moveFileToFolder = moveFileToFolderClicked;
        vm.canRemoveFolder = canRemoveFolder;
        vm.editFile = editFileClicked;

        // InfiniteScroll
        vm.disableInfiniteScrollEvents = !vm.enableInfiniteScroll;
        vm.nextPage = nextPageClicked;
        vm.addHashTag = addHashTag;

        activate();

        function activate() {
            vm.currentUser = CurrentUser.profile;
            vm.isAdmin = vm.currentUser.is_admin;
            vm.fileData = vm.fileData || [];
            vm.folderData = vm.folderData || [];

            vm.allFiles = vm.fileData || [];
            vm.allFolders = vm.folderData.concat() || [];
            vm.breadcrumbs = [];
            vm.selectedFolder = "Community Files";
            vm.currentDirectory = vm.currentDirectory || "root";
            vm.currentDirectoryName =
                vm.currentDirectoryName || "Community Files";
            vm.root = {
                name: "Community Files",
                id: "root",
                parent_folder_id: null
            };
            vm.allFolders.push(vm.root);
            vm.moveDirs = getNewMoveDirs();
        }

        function addHashTag(file) {
            var emailAddress = _.head(vm.currentUser.emails).email;

            Tenant.getTenant().then(function(tenant) {
                Audience.updateHashTags(
                    tenant.organization_id,
                    emailAddress,
                    file.hash_tags
                );
            });
        }

        function nextPageClicked() {
            if (vm.onNextPage) {
                if (vm.disableInfiniteScrollEvents) {
                    return;
                }
                vm.disableInfiniteScrollEvents = true;

                var lastPost = _.last(vm.fileData);

                $q.when(vm.onNextPage({ lastPost: lastPost }))
                    .then(function(library) {
                        if (library) {
                            processPosts(library.files);
                            processFolders(library.folders);
                        }
                    })
                    .catch(function(error) {
                        $translate("LIBRARY.LOAD_ERROR").then(function(
                            translation
                        ) {
                            Logger.error("Error: ", translation);
                        });
                    });
            }
        }

        function processPosts(files) {
            if (!files || files.length === 0) {
                return;
            }
            vm.allFiles = files;
            for (var i = 0; i < files.length; i++) {
                var folder_id = files[i].folder_id || "root"; // default to root
                if (folder_id === vm.currentDirectory) {
                    vm.fileData.push(files[i]);
                }
            }
            vm.disableInfiniteScrollEvents = false;
        }

        // Using the folder data retrieved from ssmCommunityLibraryFile
        function processFolders(folders) {
            if (!folders || folders.length === 0) {
                return;
            }
            vm.allFolders = folders;
            vm.allFolders.push(vm.root);
            for (var i = 0; i < folders.length; i++) {
                if (folders[i].parent_folder_id === vm.currentDirectory) {
                    vm.folderData.push(folders[i]);
                }
            }
            vm.moveDirs = getNewMoveDirs();
            vm.disableInfiniteScrollEvents = true;
        }

        //attempting to only put children and parent as options for the move file
        function getNewMoveDirs() {
            var newSubs = [];
            var currentDirectoryFolder = null;
            // grabbing the correct folder object NOTE we should refactor this entire thing in the future not effient at all
            // likey due to most of us learning the nwc system and ssm code while trying to put this out
            for (var i = 0; i < vm.allFolders.length; i++) {
                if (vm.allFolders[i].id === vm.currentDirectory) {
                    currentDirectoryFolder = vm.allFolders[i];
                    break;
                }
            }

            return getSubFolders(newSubs, currentDirectoryFolder);
        }

        function getSubFolders(newSubs, currentDirectoryFolder) {
            var i;
            if (currentDirectoryFolder !== null) {
                for (i = 0; i < vm.allFolders.length; i++) {
                    if (
                        currentDirectoryFolder.parent_folder_id ===
                        vm.allFolders[i].id
                    ) {
                        newSubs.unshift(vm.allFolders[i]);
                        break;
                    }
                }
            }
            for (i = 0; i < vm.allFolders.length; i++) {
                if (vm.allFolders[i].parent_folder_id === vm.currentDirectory) {
                    newSubs.push(vm.allFolders[i]);
                }
            }
            return newSubs;
        }

        // Permission check to see if the user can remove a file
        function canRemove(file) {
            if (!vm.currentUser.id) {
                return false;
            }

            if (vm.currentUser.id === file.owner_data.id) {
                return true;
            }

            if (!vm.admins) {
                return false;
            }

            return _.some(
                vm.admins,
                function(admin_id) {
                    return admin_id == vm.currentUser.id; // jshint ignore:line
                },
                vm
            );
        }

        // Checks to see if the user is an admin. If they are, they can delete the folder
        // TODO test this so it actually only uses admins ?
        function canRemoveFolder(folder) {
            if (folder.name === "Back") {
                return false;
            }
            // if (!vm.currentUser.id || !vm.admins) {
            //     return false;
            // }

            return _.some(
                vm.admins,
                function(admin_id) {
                    return admin_id == vm.currentUser.id; // jshint ignore:line
                },
                vm
            );
        }

        // Function to remove a folder from a library, and all its contents
        // Note that the folder isn't permanently deleted, only removed from the library's list of folders.
        // It still exists in the database, and can be reconnected by adding the folder_id to the library's array 'folder_ids'
        // we also don't delete the files and subfolders, just telling users that since they will lose access to them once they delete the folder.
        function removeFolderClicked(folder) {
            // vm.onRemoveFolder is a variable (spelled on-remove-folder) passed from ssmCommunityLibraryFile, referencing a function.
            // $q uses the variable to run the function with the given folder
            if (vm.onRemoveFolder) {
                var modalInstance = confirmDeleteModal(
                    "This will delete this folder, any subfolders, and files in it!"
                );
                modalInstance.result.then(function(response) {
                    if (response.confirmed) {
                        $q.when(vm.onRemoveFolder({ folder: folder }))
                            .then(function(results) {
                                // On success
                                vm.allFolders.remove(folder);
                                vm.folderData.remove(folder);
                                vm.moveDirs.remove(folder);
                            })
                            .catch(function(error) {
                                // On error
                                $translate("LIBRARY.REMOVE_ERROR").then(
                                    function(translation) {
                                        Logger.error("Error: ", translation);
                                    }
                                );
                            });
                    }
                });
            }
        }

        function removeFileClicked(file) {
            // vm.onRemoveFile is a variable (spelled on-remove-file) passed from ssmCommunityLibraryFile, referencing a function.
            // $q uses the variable to run the function with the given file
            if (vm.onRemoveFile) {
                var modalInstance = confirmDeleteModal(
                    "This will delete the file from the community!"
                );
                modalInstance.result.then(function(response) {
                    if (response.confirmed) {
                        $q.when(vm.onRemoveFile({ file: file }))
                            .then(function(results) {
                                // On success
                                vm.fileData.remove(file);
                                vm.allFiles.remove(file);
                            })
                            .catch(function(error) {
                                // On error
                                $translate("LIBRARY.REMOVE_ERROR").then(
                                    function(translation) {
                                        Logger.error("Error: ", translation);
                                    }
                                );
                            });
                    }
                });
            }
        }

        function renameFolderClicked(folder) {
            var modalInstance = renameFolderModal(folder.name);
            modalInstance.result.then(function(response) {
                if (response.confirmed) {
                    var edits = {
                        name: response.name
                    };
                    Community.updateFolder(folder.id, edits);
                    folder.name = response.name;
                }
            });
        }

        function editFileClicked(file) {
            let oldHashTags = [...file.hash_tags];
            var modalInstance = renameFileModal(file.name, file.hash_tags);
            modalInstance.result.then(function(response) {
                if (!response.confirmed) {
                    file.hash_tags = oldHashTags;
                    return;
                }
                var name = file.name;
                var hashTags = file.hash_tags;
                if (response.name) {
                    name = response.name;
                }
                if (response.hash_tags) {
                    hashTags = response.hash_tags;
                }
                var edits = {
                    name: name,
                    hash_tags: hashTags
                };
                Community.updateNoAuth(file.id, edits);
                file.name = name;
                file.hash_tags = hashTags;
            });
        }

        function confirmDeleteModal(confirmText) {
            return $uibModal.open({
                templateUrl: "components/modal/areYouSure/areYouSure.html",
                controller: "AreYouSureCtrl as vm",
                resolve: {
                    textResolve: function() {
                        return confirmText;
                    }
                }
            });
        }

        function renameFolderModal(text) {
            return $uibModal.open({
                templateUrl: "components/modal/textBox/textBox.html",
                controller: "TextBoxCtrl as vm",
                resolve: {
                    textResolve: function() {
                        return text;
                    }
                }
            });
        }

        function renameFileModal(text, hashTags) {
            return $uibModal.open({
                templateUrl: "components/modal/editFile/editFile.html",
                controller: "EditFileCtrl as vm",
                backdrop: "static",
                resolve: {
                    textResolve: function() {
                        return text;
                    },
                    hashTagsResolve: function() {
                        return hashTags;
                    }
                }
            });
        }

        //TODO find out how to call this function.
        function moveFileToFolderClicked(file) {
            //making a call to the update file api request  - updates backend
            var edits = {
                folder_id: vm.selectedFolder
            };

            Community.updateNoAuth(file.id, edits);

            var folder = null;
            for (var i = 0; i < vm.allFolders.length; i++) {
                if (vm.allFolders[i].id === vm.currentDirectory) {
                    folder = vm.allFolders[i];
                }
            }
            if (folder === null) {
                folder = {
                    name: "Community Files",
                    id: "root"
                };
            }

            //update frontend
            file.folder_id = vm.selectedFolder;
            //reloadFiles

            changeDirectoryClicked(folder);
        }

        // Throws warning: "This function's cyclomatic complexity is too high (15)"
        function changeDirectoryClicked(folder) {
            // Before clearing folderData, check for any new folders and add them to allFolders
            for (var i = 0; i < vm.folderData.length; i++) {
                var shouldAdd = true;
                for (var j = 0; j < vm.allFolders.length; j++) {
                    if (vm.folderData[i].id === vm.allFolders[j].id) {
                        shouldAdd = false;
                    }
                }
                if (shouldAdd) {
                    vm.allFolders.push(vm.folderData[i]);
                }
            }

            // Now it's safe to clear the folder data
            vm.folderData = []; // clear

            // Do the same thing for any new files, adding new ones to allFiles
            for (var i2 = 0; i2 < vm.fileData.length; i2++) {
                var shouldAdd2 = true;
                for (var j2 = 0; j2 < vm.allFiles.length; j2++) {
                    if (vm.fileData[i2].id === vm.allFiles[j2].id) {
                        shouldAdd2 = false;
                    }
                }
                if (shouldAdd2) {
                    vm.allFiles.push(vm.fileData[i2]);
                }
            }

            // Now it's safe to clear the folder data (exit the directory)
            vm.fileData = []; // clear

            // add small delay here, the "back" folder below shows up before other folders are all gone

            //set current directory
            // var parent = vm.allFolders[0]
            // for (i = 0; i < vm.allFolders.length; i++) {
            //     if (vm.allFolders[i].id === folder.parent_folder_id) {
            //             parent = vm.allFolders[i];
            //     }
            // }
            //doesnt find root, add special case for root for above loop

            if (folder.name === "Back") {
                var parent = vm.breadcrumbs.pop();
                vm.currentDirectory = parent.id;
                vm.currentDirectoryName = parent.name;
            } else if (folder.id !== vm.currentDirectory) {
                var previousFolder = {
                    name: vm.currentDirectoryName,
                    id: vm.currentDirectory
                };

                vm.breadcrumbs.push(previousFolder);

                vm.currentDirectory = folder.id;
                vm.currentDirectoryName = folder.name;
            }

            // Create a "Back" folder to go back a directory, if not in root
            // parent_id serves as the back buttons id to go to
            if (vm.currentDirectory !== "root") {
                var parent = vm.breadcrumbs[vm.breadcrumbs.length - 1];
                var backFolder = {
                    name: "Back",
                    id: vm.currentDirectory
                    // id: parent.id,
                    // parent_folder_id: parent.id
                };
                vm.folderData.push(backFolder);
            }
            vm.moveDirs = getNewMoveDirs();
            // Add all folders that have a parent_id equal to the current directory
            for (i = 0; i < vm.allFolders.length; i++) {
                if (vm.allFolders[i].parent_folder_id === vm.currentDirectory) {
                    vm.folderData.push(vm.allFolders[i]);
                }
            }

            // Do the same thing for all files that have a folder_id equal to the current directory
            for (i = 0; i < vm.allFiles.length; i++) {
                var folder_id = vm.allFiles[i].folder_id || "root"; // default to root
                if (folder_id === vm.currentDirectory) {
                    vm.fileData.push(vm.allFiles[i]);
                }
            }
        }
    }
})();
