(function () {
    "use strict";
    angular
        .module("ssmAngularApp.layout")
        .controller("ChimeSsoCtrl", ChimeSsoCtrl)
        .constant("CLIENT_ID", SSM.CLIENT_ID)
        .constant("REDIRECT_URI", SSM.REDIRECT_URI);

    function ChimeSsoCtrl(
        $translate,
        $state,
        Logger,
        Tenant,
        ssmAuthHelper,
        $window,
        $location,
        CLIENT_ID,
        REDIRECT_URI,
        Api
    ) {
        var query = $location.search();
        var request_code = query["code"];

        getChimeTokenAndPerformSSO();

        function getChimeTokenAndPerformSSO() {
            
            Tenant.getTenant().then(function (tenant) {
                const CHIME_ORGANIZATION_ID = "54efe0f32e1f88382143dca8";
                if (tenant.organization_id == CHIME_ORGANIZATION_ID) {
                    const data = {
                        chimeClientId: CLIENT_ID,
                        redirectUri: REDIRECT_URI
                    }
                    var chimeSsoApi = Api.all("chime-sso").all('login')
                    chimeSsoApi
                        .customPOST(data, '', { code: request_code })
                        .then(loginSuccess, onLoginFailed);
                } else {
                    var wrongOrganization = new Error(
                        "CHIME SSO was attempted from the wrong site"
                    );
                    onChimeTokenError(wrongOrganization);
                }
            });

            function loginSuccess(userJwt) {
                ssmAuthHelper.saveToken(userJwt);
                ssmAuthHelper.getUserId();
                $window.location.href = '/';
            }

            function onLoginFailed(error) {
                $translate("LOGIN.ERROR.AVECTRA_LOGIN").then(function (
                    translation
                ) {
                    console.error('Error:', error);
                    Logger.error("ERROR: ", translation, error);
                    $state.go("main.home", {}, { location: "replace" });
                });
            }
        }
    }
})();
