(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmSVG")
        .directive("ssmFileUploadIcon", ssmFileUploadIcon);

    function ssmFileUploadIcon() {
        return {
            templateUrl: "svg/ssmFileUploadIcon/ssmFileUploadIcon.html",
            restrict: "E",
            replace: true
        };
    }
})();
