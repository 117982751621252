(function() {
    "use strict";

    angular.module("ssmAngularApp.ssmCommunitySettingsForm", [
        "ui.router",
        "pascalprecht.translate",
        "ssmAngularApp.community",
        "ssmAngularApp.analytics",
        "mgo-angular-wizard",
        "ssmAngularApp.logger",
        "ssmAngularApp.tenant.service"
    ]);
})();
