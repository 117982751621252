(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.flair", [
            "ssmAngularApp.api",
            "ssmAngularApp.analytics"
        ])
        .factory("Flair", Flair);

    function Flair(Api) {
        var FLAIR_LEVEL = {
            HOUSE: "HOUSE",
            BRONZE: "BRONZE",
            SILVER: "SILVER",
            GOLD: "GOLD",
            PLATINUM: "PLATINUM"
        };

        var FLAIR_PRIORITY = {
            HOUSE: "HOUSE",
            VENDOR: "VENDOR"
        };

        var FLAIR_SIZE = {
            LEADERBOARD: "LEADERBOARD",
            MEDIUM_RECTANGLE: "MEDIUM_RECTANGLE",
            RECTANGLE: "RECTANGLE",
            WIDE_SKYSCRAPER: "WIDE_SKYSCRAPER"
        };

        var FLAIR_STATUS = {
            INACTIVE: "INACTIVE",
            PENDING: "PENDING",
            ACTIVE: "ACTIVE"
        };

        var FLAIR_TOPIC = {
            HOME: "HOME",
            FALLBACK: "FALLBACK",
            PREMIUM_COMMUNITY: "PREMIUM_COMMUNITY"
        };

        function enumStringBuilder(enumX, prefix) {
            var output = {};

            _.forEach(enumX, function(v, key) {
                var thisKey = prefix + key;
                output[v] = thisKey;
            });

            return output;
        }

        return {
            LEVEL: FLAIR_LEVEL,
            LEVEL_STRINGS: enumStringBuilder(FLAIR_LEVEL, "FLAIR.LEVEL."),

            PRIORITY: FLAIR_PRIORITY,
            PRIORITY_STRINGS: enumStringBuilder(
                FLAIR_PRIORITY,
                "FLAIR.PRIORITY."
            ),

            SIZE: FLAIR_SIZE,
            SIZE_STRINGS: enumStringBuilder(FLAIR_SIZE, "FLAIR.SIZE."),

            STATUS: FLAIR_STATUS,
            STATUS_STRINGS: enumStringBuilder(FLAIR_STATUS, "FLAIR.STATUS."),

            TOPIC: FLAIR_TOPIC,

            addVendorFlair: function(vendor_id, data) {
                return Api.all("vendor")
                    .one(vendor_id)
                    .all("flair")
                    .post(data);
            },
            createFlairQuery: function(limit, size, level) {
                return {
                    limit: limit,
                    size: size,
                    level: level
                };
            },
            deleteFlair: function(flair_id) {
                return Api.all("flair")
                    .one(flair_id)
                    .remove();
            },
            patchFlair: function(flair_id, edits) {
                return Api.all("flair")
                    .one(flair_id)
                    .patch(edits);
            },
            getFlair: function(topic, queries) {
                return Api.all("flair")
                    .all(topic)
                    .post(queries);
            },
            getVendorFlair: function(vendor_id) {
                return Api.all("vendor")
                    .one(vendor_id)
                    .all("flair")
                    .getList();
            }
        };
    }
})();
