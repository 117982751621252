(function() {
    "use strict";

    angular
        .module("ssmAngularApp.createContent")
        .controller("createContentController", createContentController);

    function createContentController(
        $translate,
        $uibModalInstance,
        CurrentUser,
        SSM_MARKETPLACE,
        feed,
        feed_id,
        content_type,
        disallowFiles
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.currentUser = CurrentUser.profile;
        vm.feed = feed;
        vm.feed_id = feed_id;
        vm.content_type = content_type;
        vm.disallowFiles = disallowFiles;
        vm.closeModal = closeModal;

        function closeModal() {
            $uibModalInstance.close();
        }
    }
})();
