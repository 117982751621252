(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsEditController", OrgsEditController);

    function OrgsEditController(
        $translate,
        Organization,
        orgProfileResolve,
        SSMTitle
    ) {
        var vm = this;
        vm.profileData = orgProfileResolve;
        vm.canEdit = false;
        vm.canEditAdmins = false;
        vm.canEditSite = false;

        vm.isSiteOrg = isSiteOrg;

        activate();

        function activate() {
            $translate("ORGANIZATION.EDIT.TITLE").then(function(translation) {
                vm.titleString = translation;
                SSMTitle.setTitle(translation);
            });

            Organization.getPermissions(vm.profileData.id).then(function(
                result
            ) {
                vm.canEdit = result && result.can_edit;
                vm.canEditAdmins = result && result.can_edit_admins;
                vm.canEditSite = result && result.can_edit_site;
            });
        }

        function isSiteOrg() {
            return vm.profileData.site && vm.profileData.site.name;
        }
    }
})();
