(function() {
    "use strict";

    angular
        .module("ssmAngularApp.blogPermissions")
        .directive("blogPermissions", blogPermissions);

    function blogPermissions() {
        return {
            templateUrl: "components/blogPermissions/blogPermissions.html",
            restrict: "E",
            replace: true,
            scope: {
                userData: "="
            },
            controller: blogPermissionsCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function blogPermissionsCtrl(Blog, CurrentUser) {
        /*jshint validthis:true */
        var vm = this;

        vm.addBlogAdmin = addBlogAdmin;
        vm.deleteBlogAdmin = deleteBlogAdmin;
        vm.addBlogAuthor = addBlogAuthor;
        vm.deleteBlogAuthor = deleteBlogAuthor;
        vm.showAddBlogAdmin = showAddBlogAdmin;
        vm.showAddBlogAuthor = showAddBlogAuthor;
        vm.showDeleteBlogAdmin = showDeleteBlogAdmin;
        vm.showDeleteBlogAuthor = showDeleteBlogAuthor;

        function showAddBlogAdmin() {
            return CurrentUser.profile.is_admin && !vm.userData.is_blog_admin;
        }

        function showDeleteBlogAdmin() {
            return CurrentUser.profile.is_admin && vm.userData.is_blog_admin;
        }

        function showAddBlogAuthor() {
            return (
                CurrentUser.profile.is_blog_admin && !vm.userData.is_blog_author
            );
        }

        function showDeleteBlogAuthor() {
            return (
                CurrentUser.profile.is_blog_admin && vm.userData.is_blog_author
            );
        }

        function addBlogAdmin() {
            Blog.addBlogAdmin(vm.userData.id)
                .then(function(updated_user) {
                    vm.userData = updated_user;
                    if (vm.userData.id === CurrentUser.profile.id) {
                        CurrentUser.refresh();
                    }
                })
                .catch(function(error) {});
        }

        function deleteBlogAdmin() {
            Blog.deleteBlogAdmin(vm.userData.id)
                .then(function(updated_user) {
                    vm.userData = updated_user;
                    if (vm.userData.id === CurrentUser.profile.id) {
                        CurrentUser.refresh();
                    }
                })
                .catch(function(error) {});
        }

        function addBlogAuthor() {
            Blog.addBlogAuthor(vm.userData.id)
                .then(function(updated_user) {
                    vm.userData = updated_user;
                    if (vm.userData.id === CurrentUser.profile.id) {
                        CurrentUser.refresh();
                    }
                })
                .catch(function(error) {});
        }

        function deleteBlogAuthor() {
            Blog.deleteBlogAuthor(vm.userData.id)
                .then(function(updated_user) {
                    vm.userData = updated_user;
                    if (vm.userData.id === CurrentUser.profile.id) {
                        CurrentUser.refresh();
                    }
                })
                .catch(function(error) {});
        }
    }
})();
