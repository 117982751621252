(function() {
    "use strict";

    angular.module("ssmAngularApp.loading").directive("loading", function() {
        return {
            templateUrl: "components/loading/loading.html",
            restrict: "E",
            replace: true,
            scope: {
                isLoading: "=",
                loadingText: "="
            }
        };
    });
})();
