(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.blog", ["ssmAngularApp.api", "ui.bootstrap"])
        .factory("Blog", Blog);

    var blogStatus = {
        EDITING: 1,
        PUBLISHED: 2,
        DELETED: 3
    };

    function Blog($uibModal, $translate, Analytics, Api, CurrentUser) {
        var blogApi = Api.all("blogs");
        return {
            BLOG_STATUS: blogStatus,
            BLOG_EDITOR_CLICKED_EVENT: "blogEditorClickedEvent",
            all: function(limit) {
                return blogApi.getList(limit);
            },
            getBlog: function(id) {
                return blogApi.get(id, { format: "enhanced" });
            },
            create: function(blog) {
                return blogApi.post(blog);
            },
            update: function(id, updatedBlog) {
                return blogApi.one(id).patch(updatedBlog);
            },
            updateGuestComment: function(blogId, commentId) {
                return blogApi
                    .one(blogId)
                    .one("comments", commentId)
                    .one("guest")
                    .put();
            },
            authors: function() {
                return blogApi.one("authors").get();
            },
            addBlogAuthor: function(userId) {
                return blogApi.all("authors").post({ id: userId });
            },
            deleteBlogAuthor: function(userId) {
                return blogApi
                    .all("authors")
                    .one(userId)
                    .remove();
            },
            addBlogAdmin: function(userId) {
                return blogApi.all("admins").post({ id: userId });
            },
            deleteBlogAdmin: function(userId) {
                return blogApi
                    .all("admins")
                    .one(userId)
                    .remove();
            },
            addComment: function(blogId, comment) {
                return blogApi
                    .one(blogId)
                    .all("comments")
                    .post(comment, { format: "enhanced" });
            },
            deleteComment: function(blogId, commentId) {
                return blogApi
                    .one(blogId)
                    .one("comments", commentId)
                    .remove();
            },
            updateComment: function(blogId, updatedComment) {
                const commentId = updatedComment.id;
                delete updatedComment.id;
                return blogApi
                    .one(blogId, "comments")
                    .one(commentId)
                    .customPUT(updatedComment);
            },
            addLike: function(blogId) {
                return blogApi
                    .one(blogId)
                    .all("likes")
                    .post();
            },
            deleteLike: function(blogId) {
                return blogApi
                    .one(blogId)
                    .one("likes", "mine")
                    .remove();
            },
            addCommentLike: function(blogId, commentId) {
                return blogApi
                    .one(blogId)
                    .one("comments", commentId)
                    .all("likes")
                    .post();
            },
            deleteCommentLike: function(blogId, commentId) {
                return blogApi
                    .one(blogId)
                    .one("comments", commentId)
                    .one("likes", "mine")
                    .remove();
            },
            getBlogPermissions: function() {
                return blogApi.one("permissions").get();
            },
            helper: {
                deleteBlog: function(blogId, authorId) {
                    var uibModalInstance = confirmDeleteModal(
                        "ARE_YOU_SURE.DELETE_BLOG_WARNING"
                    );
                    return uibModalInstance.result.then(function(response) {
                        if (!response.confirmed) {
                            return;
                        }

                        Analytics.eventTrack("deleteBlog", {
                            category: "Blogs",
                            label: "id:" + blogId + ", author: " + authorId
                        });
                        Analytics.eventTrack2("delete_blog", {
                            category: "Blogs",
                            label: "id:" + blogId + ", author: " + authorId
                        });

                        if (authorId !== CurrentUser.profile.id) {
                            Analytics.eventTrack("deleteBlogAsDelegate", {
                                category: "Delegation",
                                label: "id:" + blogId + ", author: " + authorId
                            });
                            Analytics.eventTrack2("delete_blog_as_delegate", {
                                category: "Delegation",
                                label: "id:" + blogId + ", author: " + authorId
                            });
                        }

                        var blog_data = {
                            status: blogStatus.DELETED
                        };

                        // Need to make the api call directly as update field is not visible here
                        return blogApi.one(blogId).patch(blog_data);
                    });

                    function confirmDeleteModal(confirmText) {
                        return $uibModal.open({
                            templateUrl:
                                "components/modal/areYouSure/areYouSure.html",
                            controller: "AreYouSureCtrl as vm",
                            resolve: {
                                textResolve: function() {
                                    return $translate(confirmText);
                                }
                            }
                        });
                    }
                }
            }
        };
    }
})();
