(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmAlerts", ["ui.bootstrap"])
        .directive("ssmAlerts", ssmAlerts)
        .controller("ssmAlertsCtrl", ssmAlertsCtrl);

    function ssmAlerts() {
        return {
            templateUrl: "components/ssmAlerts/ssmAlerts.html",
            restrict: "E",
            replace: true,
            controller: ssmAlertsCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmAlertsCtrl(AlertService) {
        /*jshint validthis:true */
        var vm = this;
        vm.closeAlert = closeAlert;
        vm.allAlerts = allAlerts;

        function allAlerts() {
            return AlertService.getAlerts();
        }

        function closeAlert(index) {
            AlertService.remove(index);
        }
    }
})();
