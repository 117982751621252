(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.logger").factory("Logger", Logger);

    // the WebApi dependency uses the web backend, not server backend.
    // use Api when needing to talk to the server (which is the majority of the time)
    function Logger($log, $state, AlertService, WebApi, Analytics) {
        var logger = WebApi.all("log");

        return {
            showToasts: true,

            error: error,
            errorWithoutAlert: errorWithoutAlert,
            info: info,
            success: success,
            warning: warning,
            warningWithoutAlert: warningWithoutAlert,
            emergency: emergency
        };

        function error(title, message, data, duration) {
            duration = duration || null;
            title = title || "";
            if (data && !data.state && $state && $state.current) {
                data.state = $state.current.name;
            }

            AlertService.add({
                title: title,
                msg: message,
                type: "danger",
                duration: duration,
                animation: "fadeInRight"
            });

            $log.error("Error: " + message, data, new Error().stack);
            //Papertrail
            var logMessage = buildLoggingObject(message, data);
            logger.customPOST(logMessage, "logMessage/error");
            Analytics.eventTrack("error", {
                category: "Logger",
                label: "message: " + message
            });
            Analytics.eventTrack2("logger_error_1", {
                category: "Logger",
                label: "message: " + message
            });
        }

        function errorWithoutAlert(message, data) {
            if (data && !data.state && $state && $state.current) {
                data.state = $state.current.name;
            }

            //Papertrail
            var logMessage = buildLoggingObject(message, data);
            logger.customPOST(logMessage, "logMessage/error");
            Analytics.eventTrack("error", {
                category: "Logger",
                label: "message: " + message
            });
            Analytics.eventTrack2("logger_error_2", {
                category: "Logger",
                label: "message: " + message
            });
        }

        function info(title, message, data, duration) {
            duration = duration || 5;
            title = title || "";
            if (data && !data.state && $state && $state.current) {
                data.state = $state.current.name;
            }

            AlertService.add({
                title: title,
                msg: message,
                type: "info",
                duration: duration,
                animation: "fadeInRight"
            });
            $log.info("Info: " + message, data);
        }

        function success(title, message, data, duration) {
            duration = duration || 5;
            title = title || "";
            if (data && !data.state && $state && $state.current) {
                data.state = $state.current.name;
            }

            AlertService.add({
                title: title,
                msg: message,
                type: "success",
                duration: duration,
                animation: "fadeInRight"
            });
            $log.info("Success: " + message, data);
        }

        function warning(title, message, data, duration) {
            duration = duration || 5;
            title = title || "";
            if (data && !data.state && $state && $state.current) {
                data.state = $state.current.name;
            }

            AlertService.add({
                title: title,
                msg: message,
                type: "warning",
                duration: duration,
                animation: "fadeInRight"
            });
            $log.warn("Warning: " + message, data);
            //Papertrail
            var logMessage = buildLoggingObject(message, data);
            logger.customPOST(logMessage, "logMessage/warn");
        }

        function warningWithoutAlert(message, data) {
            if (data && !data.state && $state && $state.current) {
                data.state = $state.current.name;
            }

            //Papertrail
            var logMessage = buildLoggingObject(message, data);
            logger.customPOST(logMessage, "logMessage/warn");
        }

        function emergency(message, data) {
            if (data && !data.state && $state && $state.current) {
                data.state = $state.current.name;
            }

            $log.log();
            //Papertrail
            var logMessage = buildLoggingObject(message, data);
            logger.customPOST(logMessage, "logMessage/emerg");
            Analytics.eventTrack("emerg", {
                category: "Logger",
                label: "message: " + message
            });
            Analytics.eventTrack2("emerg", {
                category: "Logger",
                label: "message: " + message
            });
        }

        function buildLoggingObject(message, params) {
            var logMessage = params || {};
            logMessage.message = message;
            return logMessage;
        }
    }
})();
