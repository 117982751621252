(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("CommunityLibraryCtrl", CommunityLibraryCtrl);

    function CommunityLibraryCtrl(
        $translate,
        Analytics,
        Community,
        CurrentUser,
        communityProfileResolve,
        SSMTitle
    ) {
        var vm = this;
        vm.profileData = communityProfileResolve;
        vm.currentUserIsAdmin = false;
        vm.files = [];
        vm.moveDirs = [];
        vm.filesToUpload = [];
        vm.folders = [];
        vm.showFileUpload = true;
        vm.fileTypes = "text/*,image/*,application/pdf,xml/*";
        vm.addToLibrary = addToLibrary;
        vm.clearCandidate = clearCandidate;
        vm.createFolder = createFolder;
        vm.canPost = canPost;
        vm.canMakeFolder = canMakeFolder;
        vm.currentDirectory = "root";
        vm.currentDirectoryName = "Community Files";
        vm.newFolderTitle = "";
        vm.id = vm.profileData.is_secondary_library
            ? vm.profileData.secondary_library_redirect_id
            : vm.profileData.id;

        activate();

        function activate() {
            SSMTitle.setTitle(vm.profileData.name);

            vm.currentUserIsAdmin = _.some(vm.profileData.admins, function(
                admin_id
            ) {
                return admin_id === CurrentUser.profile.id;
            });

            $translate("LIBRARY.PAGE_TITLE").then(function(translation) {
                vm.pageTitle = translation;
            });

            vm.allFolders = [];
        }

        function addToLibrary() {
            if (vm.filesToUpload.length === 0) {
                return;
            }
            var file = vm.filesToUpload[0];

            var edits = {
                folder_id: vm.currentDirectory
            };

            // Set the current directory
            file.folder_id = vm.currentDirectory; // THIS DOES NOT WORK?

            Community.updateFile(file.id, edits);
            Analytics.eventTrack("addToLibrary", {
                category: "communityLibrary",
                label: "file_id:" + file.id
            });
            Analytics.eventTrack2('add_to_library', {
                category: "communityLibrary",
                label: "file_id:" + file.id,
            });

            Community.addFile(vm.profileData.id, file.id).then(
                _.bind(function(results) {
                    file = this.filesToUpload.shift();
                    this.files.unshift(file);
                }, vm)
            );
        }

        // "community" is a reference to Community.service.js
        function createFolder(newTitle) {
            var folderToCreate = {
                name: newTitle || "Untitled Folder",
                parent_folder_id: vm.currentDirectory
            };

            // Create the folder first, then pass it to the library's API in services
            Community.createFolder(folderToCreate).then(function(folder) {
                Analytics.eventTrack("addFolderToLibrary", {
                    category: "communityLibrary",
                    label: "folder_id:" + folder.id
                });
                Analytics.eventTrack2('add_folder_to_library', {
                    category: "communityLibrary",
                    label: "folder_id:" + folder.id
                });
                Community.addFolderToLibrary(vm.profileData.id, folder.id).then(
                    _.bind(function(results) {
                        this.folders.push(folder); // Push the created folder to the frontend array so the uesr can see it
                        updateMoveDir(folder);
                    }, vm)
                );
            });
        }

        function updateMoveDir(folder) {
            /*
            var updatedList = []
            for (var i = 0; i < folders.length; i++) {
                if ( folders[i].parent_folder_id === currentDirectory ) {
                    updatedList.push(folders[i]);
                }
            }
            moveDirs = updatedList;
        */
            vm.moveDirs.push(folder);
        }

        function clearCandidate() {
            if (vm.filesToUpload.length <= 0) {
                return;
            }
            vm.filesToUpload.pop();
        }

        function canPost() {
            if (vm.profileData.status !== Community.STATUS.ACTIVE) {
                return false;
            }
            switch (vm.profileData.posting_permissions) {
                case Community.POSTING_PERMISSIONS.ALL:
                    return true;
                case Community.POSTING_PERMISSIONS.PARTICIPATION_ONLY:
                    return vm.currentUserIsAdmin;
                case Community.POSTING_PERMISSIONS.ADMINS_ONLY:
                    return vm.currentUserIsAdmin;
                default:
                    break;
            }

            return false;
        }

        function canMakeFolder() {
            if (vm.profileData.status !== Community.STATUS.ACTIVE) {
                return false;
            }

            return vm.currentUserIsAdmin;
        }
    }
})();
