(function() {
    "use strict";
    /* @ngInject */

    angular.module("ssmAngularApp.api").factory("Api", Api);

    function Api(Restangular, SSM_API_URL) {
        return Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setBaseUrl(SSM_API_URL);
        });
    }
})();
