(function() {
    "use strict";

    angular
        .module("ssmAngularApp.marketplace.services")
        .service("ssmMarketplaces", ssmMarketplaces);

    function ssmMarketplaces(
        ActiveAngular,
        ssmListings,
        ssmVendors,
        ssmCategories
    ) {
        var options = {
            edges: {
                listings: {
                    model: ssmListings
                },
                vendors: {
                    model: ssmVendors
                },
                categories: {
                    model: ssmCategories
                }
            }
        };
        return new ActiveAngular("marketplace/:id", options);
    }
})();
