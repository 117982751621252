(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("CommunityFollowersCtrl", CommunityFollowersCtrl);

    function CommunityFollowersCtrl(
        Community,
        communityProfileResolve,
        $stateParams,
        $translate,
        Logger,
        CurrentUser,
        SSMTitle
    ) {
        var vm = this;
        vm.profileData = communityProfileResolve;
        vm.followers = [];
        vm.nextPage = nextPage;
        vm.disableInfiniteScrollEvents = true;
        vm.numberWithoutNames = 0;
        vm.showPending = $stateParams.pending === "true";

        var MEMBER_LIMIT = 20;

        activate();

        function activate() {
            SSMTitle.setTitle(vm.profileData.name + " followers");
            var query = { skip: 0, limit: MEMBER_LIMIT };
            vm.showPage = true;

            if (communityProfileResolve && vm.showPending) {
                var currentUserIsAdmin = !!_.find(
                    communityProfileResolve.admins,
                    function(admin) {
                        return admin === CurrentUser.profile.id;
                    }
                );
                vm.showPage =
                    currentUserIsAdmin &&
                    communityProfileResolve.status === Community.STATUS.ACTIVE;
            }

            if (vm.showPending) {
                getPendingFollowers(query);
            } else {
                getFollowers(query);
            }
        }

        function getPendingFollowers(query) {
            return Community.getPendingCommunityFollowers(
                vm.profileData.id,
                query
            )
                .then(function(followersList) {
                    if (!followersList || followersList.length === 0) {
                        vm.disableInfiniteScrollEvents = true;
                        return;
                    }

                    var followersWithoutNames = _.remove(
                        followersList,
                        function(item) {
                            return !item || !item.user || !item.user.full_name;
                        }
                    );
                    var numberRemoved = (followersWithoutNames || []).length;
                    vm.numberWithoutNames += numberRemoved;

                    vm.followers = vm.followers.concat(followersList);
                    if (followersList.length + numberRemoved === MEMBER_LIMIT) {
                        vm.disableInfiniteScrollEvents = false;
                    }
                })
                .catch(function(error) {
                    vm.disableInfiniteScrollEvents = true;
                    $translate("COMMUNITY_CREATION.GET_FOLLOWER_ERROR").then(
                        function(translation) {
                            Logger.error("ERROR: ", translation, error);
                        }
                    );
                });
        }

        function getFollowers(query) {
            return Community.getCommunityFollowers(vm.profileData.id, query)
                .then(function(followersList) {
                    if (!followersList || followersList.length === 0) {
                        vm.disableInfiniteScrollEvents = true;
                        return;
                    }

                    var followersWithoutNames = _.remove(
                        followersList,
                        function(item) {
                            return !item || !item.user || !item.user.full_name;
                        }
                    );
                    var numberRemoved = (followersWithoutNames || []).length;
                    vm.numberWithoutNames += numberRemoved;

                    vm.followers = vm.followers.concat(followersList);
                    if (followersList.length + numberRemoved === MEMBER_LIMIT) {
                        vm.disableInfiniteScrollEvents = false;
                    }
                })
                .catch(function(error) {
                    vm.disableInfiniteScrollEvents = true;
                    $translate("COMMUNITY_CREATION.GET_FOLLOWER_ERROR").then(
                        function(translation) {
                            Logger.error("ERROR: ", translation, error);
                        }
                    );
                });
        }

        function nextPage() {
            vm.disableInfiniteScrollEvents = true;
            var query = {
                skip: vm.followers.length + vm.numberWithoutNames,
                limit: MEMBER_LIMIT
            };

            getFollowers(query);
        }
    }
})();
