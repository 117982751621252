(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .factory("marketplaceRoutesHelper", marketplaceRoutesHelper)
        .config(function($stateProvider) {
            $stateProvider
                .state("main.marketplace", {
                    url:
                        "/marketplace?{?id}{?query}{?state}{?city}{?postal_code}",
                    templateUrl: "layout/main/marketplace/marketplace.html",
                    controller: "MarketplaceController",
                    controllerAs: "vm",
                    resolve: {
                        tenantResolve: function(Tenant) {
                            return Tenant.getTenant();
                        },
                        marketplacesResolve: function(
                            marketplaceRoutesHelper,
                            tenantResolve
                        ) {
                            return marketplaceRoutesHelper.getMarketplaces(
                                tenantResolve.organization_id
                            );
                        },
                        marketplaceResolve: function(
                            $stateParams,
                            marketplacesResolve,
                            marketplaceRoutesHelper,
                            $location,
                            $state,
                            $timeout
                        ) {
                            if (!marketplacesResolve.length) {
                                marketplaceRoutesHelper.routeTo404();
                            }
                            var mpPromise;
                            if ($stateParams.id) {
                                mpPromise = marketplaceRoutesHelper.getMarketplace(
                                    $stateParams.id
                                );
                            } else {
                                // Getting the 1st marketplace prevents 404
                                // in case /marketplace route is reached
                                // TODO: Figure out a better routing method for multiple marketplaces
                                mpPromise = marketplaceRoutesHelper.getMarketplace(
                                    marketplacesResolve[0].id
                                );
                            }
                            mpPromise
                                .then(function(marketplace) {
                                    $timeout(function() {
                                        marketplaceRoutesHelper.routeToAllResults(
                                            angular.copy($state.params)
                                        );
                                    });
                                })
                                .catch(function(error) {
                                    marketplaceRoutesHelper.routeTo404();
                                });

                            return mpPromise;
                        }
                    }
                })
                .state("main.marketplace_listing", {
                    url: "/marketplace/listing/{id}",
                    templateUrl:
                        "layout/main/marketplace/marketplaceListing/marketplaceListing.html",
                    controller: "MarketplaceListingController",
                    controllerAs: "vm",
                    resolve: {
                        marketplaceListingResolve: function(
                            $stateParams,
                            marketplaceRoutesHelper
                        ) {
                            return marketplaceRoutesHelper.getMarketplaceListing(
                                $stateParams.id
                            );
                        },
                        marketplaceResolve: function(
                            marketplaceListingResolve,
                            marketplaceRoutesHelper
                        ) {
                            return marketplaceRoutesHelper.getMarketplace(
                                marketplaceListingResolve.marketplace_id
                            );
                        }
                    }
                })
                .state("main.marketplace.results", {
                    url: "/:category",
                    templateUrl:
                        "layout/main/marketplace/marketplaceResults/marketplaceResults.html",
                    controller: "MarketplaceResultsController",
                    controllerAs: "vm"
                })
                .state("main.marketplace_npz", {
                    url: "/npz?{?query}{?state}{?city}{?postal_code}",
                    templateUrl: "layout/main/marketplace/marketplace.html",
                    controller: "MarketplaceController",
                    controllerAs: "vm",
                    resolve: {
                        tenantResolve: function(Tenant) {
                            return Tenant.getTenant();
                        },
                        marketplacesResolve: function(
                            marketplaceRoutesHelper,
                            tenantResolve
                        ) {
                            return marketplaceRoutesHelper.getMarketplaces(
                                tenantResolve.organization_id
                            );
                        },
                        marketplaceResolve: function(
                            marketplacesResolve,
                            marketplaceRoutesHelper,
                            $location,
                            $state,
                            $timeout
                        ) {
                            if (!marketplacesResolve.length) {
                                marketplaceRoutesHelper.routeTo404();
                            }

                            var mpPromise = marketplaceRoutesHelper.getMarketplace(
                                marketplacesResolve[0].id
                            );
                            mpPromise
                                .then(function(marketplace) {
                                    $timeout(function() {
                                        marketplaceRoutesHelper.routeToAllResultsNPZ(
                                            angular.copy($state.params)
                                        );
                                    });
                                })
                                .catch(function(error) {
                                    marketplaceRoutesHelper.routeTo404();
                                });

                            return mpPromise;
                        }
                    }
                })
                .state("main.marketplace_npz_listing", {
                    url: "/npz/listing/{id}",
                    templateUrl:
                        "layout/main/marketplace/marketplaceListing/marketplaceListing.html",
                    controller: "MarketplaceListingController",
                    controllerAs: "vm",
                    resolve: {
                        marketplaceListingResolve: function(
                            $stateParams,
                            marketplaceRoutesHelper
                        ) {
                            return marketplaceRoutesHelper.getMarketplaceListing(
                                $stateParams.id
                            );
                        },
                        marketplaceResolve: function(
                            marketplaceListingResolve,
                            marketplaceRoutesHelper
                        ) {
                            return marketplaceRoutesHelper.getMarketplace(
                                marketplaceListingResolve.marketplace_id
                            );
                        }
                    }
                })
                .state("main.marketplace_npz.results", {
                    url: "/:category",
                    templateUrl:
                        "layout/main/marketplace/marketplaceResults/marketplaceResults.html",
                    controller: "MarketplaceResultsController",
                    controllerAs: "vm"
                });
        });

    function marketplaceRoutesHelper(
        $location,
        $state,
        Logger,
        ssmMarketplace
    ) {
        var service = {
            getMarketplace: getMarketplace,
            getMarketplaces: getMarketplaces,
            getMarketplaceListing: getMarketplaceListing,
            routeToAllResults: routeToAllResults,
            routeToAllResultsNPZ: routeToAllResultsNPZ,
            routeTo404: routeTo404
        };

        return service;

        function getMarketplaces(organizationId) {
            return ssmMarketplace.getMarketplaces(organizationId);
        }

        function getMarketplace(marketplaceId) {
            if (!marketplaceId) {
                Logger.errorWithoutAlert("Could not find Marketplace Id");
                service.routeTo404();
                return;
            }

            return ssmMarketplace.getMarketplace(marketplaceId);
        }

        function getMarketplaceListing(listingId) {
            return ssmMarketplace
                .getMarketplaceListing(listingId)
                .catch(function(error) {
                    if (error.status === 404) {
                        service.routeTo404();
                    }
                });
        }

        function routeToAllResults(sparams) {
            $state.go("main.marketplace.results");
        }

        function routeToAllResultsNPZ(sparams) {
            $state.go("main.marketplace_npz.results");
        }

        function routeTo404() {
            $state.go("404");
        }
    }
})();
