(function() {
    "use strict";
    /**
     * @ngInject
     */
    ('web/config/environment/development.js')
    angular
        .module("ssmAngularApp.layout")
        .controller("LoginCtrl", LoginCtrl)
        .constant("CLIENT_ID", SSM.CLIENT_ID)
        .constant("REDIRECT_URI", SSM.REDIRECT_URI)
        .constant("CHIME_CENTRAL_URL", SSM.CHIME_CENTRAL_URL);
    function LoginCtrl(
        Analytics,
        redirectingResolve,
        ssmAuth,
        SSMTitle,
        $stateParams,
        tenantResolve,
        $translate,
        CLIENT_ID,
        REDIRECT_URI,
        CHIME_CENTRAL_URL
    ) {
        var vm = this;

        vm.signin = signin;
        vm.logoUrl = null;
        vm.linkedInSignin = linkedInSignin;
        vm.allowLinkedIn = false;
        vm.redirecting = redirectingResolve;
        vm.shouldDisableLoginAndSignupOptions;
        vm.ssoSettings = {}
        vm.org_name;
        vm.org_logo;

        activate();

        function activate() {
            setTitle();
            initializeLoadingMessage();
            setupAllTenantValues();
            initializeSsoButton();
            setErrorTextIfParamsHaveErrorDescription();
            determineIfShouldDisableLoginAndSignupOptions();
        }

        window.addEventListener("load", function () {
            if (theCurrentTenantIsAhima()
                && userDidNotJustRedirectFromALogout()
            ) {
                navigateToAhimaSsoLoadingPage();
            }
        });

        function theCurrentTenantIsAhima() {
            var currentHost = window.location.host;
            if (currentHost == "ahima.nextwaveconnect.com"
                || currentHost == "access.ahima.org"
                || currentHost == "ahima.ssm-devbox.local:8443"
                || currentHost == "ahima.staging.nextwaveconnect.com"
            ) {
                return true;
            }
            return false;
        }

        function userDidNotJustRedirectFromALogout() {
            return window.location.href.indexOf("?from-logout") < 0;
        }

        function navigateToAhimaSsoLoadingPage() {
            window.location = window.location.origin + "/ahima-sso/loading";
        }

        function setTitle() {
            $translate("TITLE.LOGIN")
            .then(function(title) {
                SSMTitle.setTitle(title);
            });
        }

        function initializeLoadingMessage() {
            $translate("LOGIN.LOGGING_INTO_APP")
            .then(function(translation) {
                vm.loadingMessage = translation;
            });
        }

        function setupAllTenantValues() {
            vm.logoUrl = tenantResolve.logo_url;
            vm.allowLinkedIn = tenantResolve.flags.linked_in;
            vm.openRegistration = tenantResolve.flags.open_registration;
            vm.forgotPasswordUrl = tenantResolve.forgot_password_url;
            vm.tenantBlockedError = tenantResolve.tenant_blocked_error;
            vm.searchParams = $stateParams;
            vm.organizationId = tenantResolve.organization_id;
            vm.ssoSettings = tenantResolve.sso_button;
        }

        function initializeSsoButton() {
            if(tenantResolve.sso_button){
                vm.org_name = tenantResolve.sso_button.org_name;
                vm.org_logo = tenantResolve.sso_button.org_logo;
                if(window.location.host == "mychime.ssm-devbox.local:8443"){
                    vm.ssoSettings.button_url = `${CHIME_CENTRAL_URL}login?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;
                }
            }
        }

        function setErrorTextIfParamsHaveErrorDescription() {
            if ($stateParams.error_description) {
                vm.errorText = capitalize($stateParams.error_description);
            }
        }

        function capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }

        function determineIfShouldDisableLoginAndSignupOptions() {
            vm.shouldDisableLoginAndSignupOptions = tenantResolve.flags.disable_login_and_signup_options;
        }

        function signin(form, event) {
            //prevent action on form to fire.  We need to keep action on the form for chrome to autofill the form.
            event.preventDefault();

            if (!form.$valid) {
                $translate("LOGIN.ERROR.NOT_VALID_ENTRY")
                .then(function(translation) {
                    vm.errorText = translation;
                });
                return;
            }

            vm.errorText = "";
            vm.isLoading = true;
            ssmAuth.login(vm.user)
                .then(onLoginSuccess)
                .catch(onLoginFailed);
        }

        function linkedInSignin() {
            ssmAuth.linkedInLogin()
                .then(onLoginSuccess)
                .catch(onLoginFailed);
        }

        function onLoginSuccess(user_id) {
            Analytics.trackUTMCampaign($stateParams, "UTMLogin", "Authorization");
            Analytics.setUsername(user_id);
            Analytics.eventTrack("LoginSuccess", { category: "Authentication" });
            Analytics.eventTrack2("login_success", { category: "Authentication", dimension1: user_id });
            vm.isLoading = false;
        }

        function onLoginFailed(err) {
            var message = null;
            switch (err.status) {
                case 0:
                    message = $translate("ERROR.SERVER_DOWN_FOR_MAINTENANCE");
                    break;
                case 403:
                    if (err.data.message === "Not authorized for this environment") {
                        // TODO remove hardcoded string
                        message = $translate("LOGIN.REDIRECTING");
                        location.host = "community.nextwaveconnect.com";
                    }
                    break;
            }

            if (!message) {
                message = $translate("LOGIN.ERROR.NOT_VALID_ENTRY");
            }

            message.then(function(translation) {
                vm.errorText = translation;
            });
            vm.isLoading = false;
        }
    }
})();
