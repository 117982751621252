(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.organization", ["ssmAngularApp.api"])
        .factory("Organization", Organization);

    function Organization(Api) {
        var organizationApi = Api.all("organizations");
        return {
            ORGANIZATION_EXISTS_ERROR: "Organization already exists",

            getPermissions: function(org_id) {
                return organizationApi.one(org_id, "permissions").get();
            },

            getOrganization: function(org_id) {
                return organizationApi.get(org_id);
            },

            getOrganizationsByName: function(query) {
                var params = {
                    organization_name: query.organization_name,
                    site_only: query.site_only
                };
                return organizationApi.all("search").getList(params);
            },

            getAffiliates: function(org_id) {
                return organizationApi
                    .all(org_id)
                    .all("affiliates")
                    .getList();
            },

            updateParent: function(org_id, parent_id) {
                return organizationApi.one(org_id, "parent").patch(parent_id);
            },

            updateAffiliates: function(org_id, user_ids) {
                return organizationApi
                    .one(org_id, "affiliates")
                    .patch(user_ids);
            },

            getMembers: function(org_id) {
                return organizationApi
                    .all(org_id)
                    .all("members")
                    .getList();
            },

            updateMembers: function(org_id, user_ids) {
                return organizationApi.one(org_id, "members").patch(user_ids);
            },

            addMember: function(org_id, user_id) {
                return organizationApi
                    .one(org_id)
                    .one("members")
                    .one(user_id)
                    .post();
            },

            removeMember: function(org_id, user_id) {
                return organizationApi
                    .all(org_id)
                    .one("members")
                    .one(user_id)
                    .remove();
            },

            getAdmins: function(org_id, user_ids) {
                return organizationApi.one(org_id, "admins").getList();
            },

            updateAdmins: function(org_id, user_ids) {
                return organizationApi.one(org_id, "admins").patch(user_ids);
            },

            create: function(organization) {
                return organizationApi.post(organization);
            },

            postOrganizationDelegate: function(org_id, user_id) {
                return organizationApi
                    .one(org_id)
                    .all("delegates")
                    .one(user_id)
                    .put();
            },

            update: function(org_id, updatedOrganization) {
                return organizationApi.one(org_id).patch(updatedOrganization);
            },

            getSlugs: function() {
                return organizationApi.one("slugs").getList();
            },

            getSectors: function() {
                return organizationApi.one("sectors").getList();
            },

            processCSVExport: function(file_id) {
                return organizationApi.all("export").post({
                    file_id: file_id
                });
            },

            processCSVImport: function(file_id, organization_id) {
                return organizationApi.all("import").post({
                    file_id: file_id,
                    organization_id: organization_id
                });
            },

            processCSVInvite: function(file_id, organization_id, message) {
                return organizationApi.all("invite").post({
                    file_id: file_id,
                    organization_id: organization_id,
                    message: message
                });
            },

            processInviteNoCSV: function(
                organization_id,
                message,
                email_address,
                title,
                first_name,
                last_name
            ) {
                return organizationApi.all("inviteNoCSV").post({
                    organization_id: organization_id,
                    message: message,
                    email_address: email_address,
                    title: title,
                    first_name: first_name,
                    last_name: last_name
                });
            }
        };
    }
})();
