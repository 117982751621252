(function() {
    //IE Fix;
    if (!document.location.origin) {
        document.location.origin =
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "");
    }

    angular
        .module("ssmAngularApp.config")
        .constant("ANALYTICS", SSM.ANALYTICS)
        .constant("SSM_SERVER_URL", SSM.SSM_SERVER_URL)
        .constant("SSM_SOCKET_URL", SSM.SSM_SOCKET_URL)
        .constant("SSM_ASSET_URL", document.location.origin)
        .constant("SSM_API_URL", SSM.SSM_API_URL)
        .constant(
            "SSM_DELEGATION_FEATURE_ON",
            SSM.DELEGATION_FEATURE_ON === true
        )
        .constant("SSM_VENDOR_FEATURE_ON", SSM.VENDOR_FEATURE_ON === true)
        .constant(
            "SSM_VENDOR_ADMIN_FEATURE_ON",
            SSM.VENDOR_ADMIN_FEATURE_ON === true
        )
        .constant(
            "SSM_WARNINGS_MISSING_TRANSLATE",
            SSM.WARNINGS_MISSING_TRANSLATE === true
        )
        .constant("SSM_FEATURES", SSM.FEATURES)
        .constant("ADMIN_VENDOR_FOOT_JS_NAME", SSM.ADMIN_VENDOR_FOOT_JS_NAME)
        .constant("ADMIN_JS_NAME", SSM.ADMIN_JS_NAME)
        .constant("ADMIN_TEMPLATES_JS_NAME", SSM.ADMIN_TEMPLATES_JS_NAME)
        .constant("ADMIN_CSS_NAME", SSM.ADMIN_CSS_NAME)
        .constant(
            "MANAGEMENT_VENDOR_FOOT_JS_NAME",
            SSM.MANAGEMENT_VENDOR_FOOT_JS_NAME
        )
        .constant("MANAGEMENT_JS_NAME", SSM.MANAGEMENT_JS_NAME)
        .constant(
            "MANAGEMENT_TEMPLATES_JS_NAME",
            SSM.MANAGEMENT_TEMPLATES_JS_NAME
        );
})();
