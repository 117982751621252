(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.comment.service", [
            "ssmAngularApp.api",
            "ssmAngularApp.currentUser"
        ])
        .factory("Comment", CommentService);

    function CommentService(Api) {
        var userApi = Api.all("user");

        return {
            getNew: function(post_owner_id) {
                return userApi
                    .one(post_owner_id)
                    .all("posts")
                    .all("comments")
                    .getList({
                        limit: 20,
                        after_date: new Date(
                            Date.now() - 1000 * 60 * 60 * 24 * 7
                        ),
                        ignore_own: true
                    });
            }
        };
    }
})();
