/*jshint maxparams: 14 */
(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller(
            "MarketplaceListingController",
            MarketplaceListingController
        );

    function MarketplaceListingController(
        $scope,
        $translate,
        $uibModal,
        Analytics,
        Appa,
        Content,
        CurrentUser,
        marketplaceListingResolve,
        marketplaceResolve,
        ssmFileUpload,
        SSMTitle,
        SSM_MARKETPLACE,
        ssmMarketplace,
        User
    ) {
        var vm = this;

        vm.listing = marketplaceListingResolve;
        var locationId = vm.listing.id;
        vm.marketplace = marketplaceResolve;
        vm.articles = [];
        vm.content = [];
        vm.admins = [];
        vm.adminQty = 3;
        vm.showPartnerName = true;
        vm.customLinkName = "";

        vm.createArticle = createArticle;
        vm.createQuestion = createQuestion;
        vm.clickEmail = clickEmail;
        vm.clickWebsite = clickWebsite;
        vm.clickContact = clickContact;
        vm.onFileUpload = onFileUpload;
        vm.onRemoveFile = onRemoveFile;
        vm.getIconType = getIconType;
        vm.removeFileNameExtension = removeFileNameExtension;

        vm.PHONE_LINE_TYPES = SSM_MARKETPLACE.PHONE_LINE_TYPES;

        var MP_LISTING_OWNER_TYPE = "marketplace listing";

        activate();

        function activate() {
            
            getPartner();

            $translate("MARKETPLACE_LISTING.PAGE_TITLE")
            .then(function(translation) {
                SSMTitle.setTitle(translation + " - " + vm.listing.name);
            });

            vm.isApprovedUser = Appa.isApprovedUser(CurrentUser.profile, vm.marketplace.id);
            vm.listing.isAdmin = !!_.find(vm.listing.admin_ids, function(admin_id) {
                return admin_id === CurrentUser.profile.id;
            });
            vm.listing.phone_numbers = vm.listing.phone_numbers || [];
            vm.files = _.clone(vm.listing.files);
            vm.hasCollaboration = vm.listing.marketplace_level_id === "2" 
                || vm.listing.marketplace_level_id === "3";

            vm.isPremium = vm.listing.marketplace_level_id === "3";

            vm.hasContent = (vm.content && vm.content.length) 
                || (vm.listing && (vm.listing.isAdmin || vm.listing.twitter_widget_id)) 
                || (vm.files && vm.files.length);

            vm.showPartnerName = !vm.marketplace.hide_partner_name_in_listings;

            if (vm.marketplace.listing_website_title) {
                vm.customLinkName = vm.marketplace.listing_website_title;
            }

            if (!vm.listing.feed_ids || !vm.listing.feed_ids.length) {
                createFeed();
            }

            _.forEach(vm.listing.admin_ids, function(admin_id) {
                User.getUser(admin_id).then(function(admin) {
                    vm.admins.push(admin);
                });
            });

            $scope.$watch(
                function() {
                    return vm.content && vm.content.length;
                },
                function(newValue, oldValue) {
                    if (oldValue === newValue) {
                        return;
                    }

                    vm.hasContent =
                        (vm.content && vm.content.length) ||
                        (vm.listing &&
                            (vm.listing.isAdmin ||
                                vm.listing.twitter_widget_id)) ||
                        (vm.files && vm.files.length);
                }
            );
        }

        function getPartner() {
            ssmMarketplace
                .getMarketplacePartner(vm.listing.marketplace_partner_id)
                .then(function(partner) {
                    vm.partner = partner;
                    vm.partner.contacts = vm.partner.contacts || [];
                });
        }

        function clickEmail(emailAddress) {
            Analytics.eventTrack("emailClick", {
                category: "marketplaceListingPage",
                label: "email_address: " + emailAddress,
                dimension2: locationId,
                dimension4: locationId
            });
            Analytics.eventTrack2("email_click", {
                category: "marketplaceListingPage",
                label: "email_address: " + emailAddress,
                dimension2: locationId,
                dimension4: locationId
            });
        }

        function clickWebsite(url) {
            Analytics.eventTrack("websiteClick", {
                category: "marketplaceListingPage",
                label: "Website: " + url,
                dimension2: locationId,
                dimension4: locationId
            });
            Analytics.eventTrack2("website_click", {
                category: "marketplaceListingPage",
                label: "Website: " + url,
                dimension2: locationId,
                dimension4: locationId
            });
        }

        function clickContact() {
            $uibModal.open({
                templateUrl:
                    "components/modal/marketplaceContact/marketplaceContact.html",
                controller: "marketplaceContactController as vm",
                resolve: {
                    listing: vm.listing,
                    partner: vm.partner
                }
            });
        }

        function createQuestion() {
            createContent("question");
        }

        function createArticle() {
            createContent("article");
        }

        function createContent(content_type) {
            if (vm.listing.feed_ids.length < 1) {
                return;
            }

            $uibModal.open({
                templateUrl:
                    "components/modal/createContent/createContent.html",
                controller: "createContentController as vm",
                resolve: {
                    feed: function() {
                        return vm.content;
                    },
                    feed_id: function() {
                        return vm.listing.feed_ids[0];
                    },
                    content_type: function() {
                        return content_type;
                    },
                    disallowFiles: function() {
                        if (vm.listing.marketplace_level_id === "3") {
                            return false;
                        }

                        return true;
                    }
                }
            });
        }

        function createFeed() {
            var params = {
                owner_id: vm.listing.id,
                owner_type: MP_LISTING_OWNER_TYPE
            };
            Content.createFeed(params).then(function(result) {
                vm.listing.feed_ids.unshift(result.id);
            });
        }

        function onFileUpload() {
            var files = _(vm.files)
                .concat(vm.uploadedFiles)
                .compact()
                .uniqBy(function(item) {
                    return item.id;
                })
                .value();

            saveFileChanges(files);
        }

        function onRemoveFile(fileIndex) {
            var files = _.clone(vm.files);
            files.splice(fileIndex, 1);
            saveFileChanges(files);
        }

        function saveFileChanges(files) {
            var params = {};
            params.file_ids = _.map(files, function(file) {
                return file.id || file._id;
            });

            ssmMarketplace
                .updateMarketplaceListing(vm.listing.id, params)
                .then(function(result) {
                    vm.files = files;
                    vm.uploadedFiles = vm.files;
                    result.isAdmin = !!_.find(result.admin_ids, function(
                        admin_id
                    ) {
                        return admin_id === CurrentUser.profile.id;
                    });
                    vm.listing = result;
                });
        }

        function getIconType(file_type) {
            return ssmFileUpload.fileTypeToIcon(file_type);
        }

        function removeFileNameExtension(file_name) {
            if (!file_name) {
                return "";
            }

            var index = file_name.indexOf(".");
            if (index < 0) {
                return file_name;
            }

            return file_name.substring(0, index);
        }
    }
})();
