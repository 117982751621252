(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("VendorController", VendorController);

    function VendorController() {}
})();
