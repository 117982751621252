(function() {
    /*jshint maxparams: 11 */
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.createWallPost")
        .directive("createWallPost", createWallPost);

    function createWallPost() {
        return {
            templateUrl: "components/createWallPost/createWallPost.html",
            restrict: "E",
            scope: {
                placeholder: "@",
                posts: "=",
                onCreated: "&",
                location: "="
            },
            controllerAs: "vm",
            controller: createWallPostCtrl,
            bindToController: true
        };
    }

    function createWallPostCtrl(
        $translate,
        $uibModal,
        Analytics,
        Community,
        Connection,
        CurrentUser,
        Delegation,
        Logger,
        User,
        WallPost,
        SSMInlineContentHelper,
        SSM_FILES
    ) {
        /*jshint validthis:true */
        var vm = this;
        var postTemplate = {
            message: ""
        };
        var pollTemplate = {
            question: "",
            choices: [
                {
                    text: ""
                },
                {
                    text: ""
                }
            ]
        };

        vm.textFormatChoices = {
            Bold: "b",
            Heading: "h3",
            Italics: "i",
            "List Item": "li",
            Underline: "u"
        };

        var MAX_NUMBER_SEARCH_RESULTS = 25;

        vm.isGuest = CurrentUser.profile.is_guest;
        vm.canPostAsOthers =
            vm.location.permissions.canPostAsCommunity ||
            vm.location.permissions.canPostAsDelegate;
        vm.currentUserProfile = null;
        vm.placeholderText = "";
        vm.postingAsDelegate = false;
        vm.POST_AS_TYPE = WallPost.POST_AS_TYPE;
        vm.principalToPostAsId = null;
        vm.selectedPrincipalName = null;
        vm.sortFiles = sortFiles;
        vm.sortImages = sortImages;
        vm.removeFiles = removeFiles;

        vm.togglePoll = togglePoll;
        vm.showDelegateSelection = showDelegateSelection;
        vm.submitPost = submitPost;
        vm.searchCallback = searchCallback;
        vm.textFormatToggle = textFormatToggle;

        vm.connectionPager = Connection.getAllPaged();
        vm.noContent = noContent;
        vm.alterElementClassBasedOnTextFormatLabel = alterElementClassBasedOnTextFormatLabel;

        function initialize() {
            vm.postData = angular.copy(postTemplate);
            vm.showFileUpload = false;

            vm.currentUserProfile = CurrentUser.profile;
            setPlaceholder(CurrentUser.profile.id);
        }

        initialize();

        function setPlaceholder(currentUserId) {
            //If my wall or news feed return placeholder text.
            if (currentUserId === vm.location.id) {
                $translate("WALL.ADD_POST_PLACEHOLDER").then(function(
                    translation
                ) {
                    vm.placeholderText = translation;
                });
                return;
            }
            switch (vm.location.type) {
                case WallPost.LOCATION_TYPE.USER:
                    User.getUser(vm.location.id).then(function(user) {
                        $translate("WALL.ADD_POST_TO_PLACEHOLDER", {
                            name: user.first_name
                        }).then(function(translation) {
                            vm.placeholderText = translation;
                        });
                    });
                    break;
                case WallPost.LOCATION_TYPE.COMMUNITY:
                    Community.getCommunity(vm.location.id).then(function(
                        community
                    ) {
                        $translate("WALL.ADD_POST_TO_PLACEHOLDER", {
                            name: community.name
                        }).then(function(translation) {
                            vm.placeholderText = translation;
                        });
                    });
                    break;
            }
        }

        function togglePoll(form) {
            // if the poll is null, create it
            if (!vm.postData.poll) {
                vm.postData.poll = angular.copy(pollTemplate);
                return;
            }

            // if the poll is clean, remove it
            if (!form.createPollForm || !form.createPollForm.$dirty) {
                vm.postData.poll = null;
                return;
            }

            // if the poll is dirty, ask for confirmation
            var uibModalInstance = confirmDeletePollModal(
                "WALL.DELETE_POLL_WARNING"
            );
            uibModalInstance.result.then(function(response) {
                if (response.confirmed) {
                    vm.postData.poll = null;
                    return;
                }
            });
        }

        function confirmDeletePollModal(confirmText) {
            var promise = $uibModal.open({
                templateUrl: "components/modal/areYouSure/areYouSure.html",
                controller: "AreYouSureCtrl as vm",
                resolve: {
                    textResolve: function() {
                        return $translate(confirmText);
                    }
                }
            });
            return promise;
        }

        function submitPost(postAsType) {
            var postToCreate = angular.copy(vm.postData);
            postToCreate.file_ids = _.map(postToCreate.files, function(file) {
                return file.id;
            });
            postToCreate.files = undefined;
            postToCreate.message = SSMInlineContentHelper.sanitizeUserInput(
                postToCreate.message
            );

            postToCreate.embed = SSMInlineContentHelper.findFirstUrl(
                postToCreate.message
            );

            // SSM-557, this should go back to just calling WallPost.create, with the location object added to the post
            var submitAction;
            switch (vm.location.type) {
                case WallPost.LOCATION_TYPE.USER:
                    submitAction = User.createPost;
                    break;
                case WallPost.LOCATION_TYPE.COMMUNITY:
                    submitAction = Community.createPost;
                    if (postAsType === vm.POST_AS_TYPE.COMMUNITY) {
                        postToCreate.community = {
                            community_id: vm.location.id,
                            posted_as_community: true
                        };
                        postToCreate.author = {
                            document_id: vm.location.id,
                            document_type: WallPost.LOCATION_TYPE.COMMUNITY
                        };
                    } else if (vm.postingAsDelegate) {
                        postToCreate.author = {
                            document_id: vm.principalToPostAsId,
                            document_type: WallPost.LOCATION_TYPE.USER
                        };
                    } else {
                        postToCreate.author = {
                            document_id: vm.currentUserProfile.id,
                            document_type: WallPost.LOCATION_TYPE.USER
                        };
                    }
                    break;
            }

            submitAction(vm.location.id, postToCreate)
                .then(function(hydratedPost) {
                    var dimension2 =
                        vm.location && vm.location.id
                            ? vm.location.id
                            : "Unknown";
                    var dimension4 =
                        hydratedPost &&
                        hydratedPost.location &&
                        hydratedPost.location.id
                            ? hydratedPost.location.id
                            : "Unknown";
                    var hasFiles =
                        hydratedPost.files && hydratedPost.files.length;

                    if (
                        hydratedPost.author.id !== vm.currentUserProfile.id &&
                        hydratedPost.author.author_type ===
                            WallPost.LOCATION_TYPE.USER
                    ) {
                        Analytics.eventTrack("createPostAsDelegate", {
                            category: "Delegation",
                            label:
                                "id:" +
                                hydratedPost.id +
                                ", author: " +
                                hydratedPost.author.id +
                                ", author type: " +
                                hydratedPost.author.author_type +
                                ", poll: " +
                                !!hydratedPost.poll +
                                ", files: " +
                                hasFiles +
                                ", editor: " +
                                vm.currentUserProfile.id,
                            dimension2: dimension2,
                            dimension4: dimension4
                        });
                        Analytics.eventTrack2('create_post_as_delegate', {
                            category: "Delegation",
                            label: "id:" +
                                hydratedPost.id +
                                ", author: " +
                                hydratedPost.author.id +
                                ", author type: " +
                                hydratedPost.author.author_type +
                                ", poll: " +
                                !!hydratedPost.poll +
                                ", files: " +
                                hasFiles +
                                ", editor: " +
                                vm.currentUserProfile.id,
                            dimension2: dimension2,
                            dimension4: dimension4,
                        });
                    }

                    Analytics.eventTrack("submitPost", {
                        category: "createWallPostCtrl",
                        label:
                            "id:" +
                            hydratedPost.id +
                            ", author: " +
                            hydratedPost.author.id +
                            ", author type: " +
                            hydratedPost.author.author_type +
                            ", poll: " +
                            !!hydratedPost.poll +
                            ", files: " +
                            hasFiles,
                        dimension2: dimension2,
                        dimension4: dimension4
                    });
                    Analytics.eventTrack2('submit_post', {
                        category: "createWallPostCtrl",
                        label: "id:" +
                            hydratedPost.id +
                            ", author: " +
                            hydratedPost.author.id +
                            ", author type: " +
                            hydratedPost.author.author_type +
                            ", poll: " +
                            !!hydratedPost.poll +
                            ", files: " +
                            hasFiles,
                        dimension2: dimension2,
                        dimension4: dimension4,
                    });

                    // Make sure that the hydrated post's permissions match our location
                    // Posts on our own wall and user posts need this, as the server won't know about connections when
                    // hydrating posts
                    _.mergeWith(
                        hydratedPost.location.permissions,
                        vm.location.permissions
                    );
                    if (vm.posts) {
                        vm.posts.unshift(hydratedPost);
                    }

                    if (vm.onCreated) {
                        vm.onCreated({
                            post: hydratedPost
                        });
                    }
                    delete postToCreate.files;
                    initialize();
                })
                .catch(function(error) {
                    if (error.data && error.data.message === "guest_user") {
                        return;
                    }
                    switch (error.status) {
                        case 403:
                            $translate("WALL.CREATE_POST_FORBIDDEN").then(
                                function(translation) {
                                    Logger.warning(
                                        "Private Community: ",
                                        translation,
                                        error
                                    );
                                }
                            );
                            break;
                        default:
                            $translate("WALL.CREATE_POST_ERROR").then(function(
                                translation
                            ) {
                                Logger.error("ERROR: ", translation, error);
                            });
                            break;
                    }
                });
        }

        function showDelegateSelection(shouldShow) {
            vm.postingAsDelegate = shouldShow;
        }

        function searchCallback(searchText) {
            return Delegation.getPrincipalsForCommunity(
                vm.currentUserProfile.id,
                vm.location.id,
                {
                    size: MAX_NUMBER_SEARCH_RESULTS,
                    q: searchText
                }
            );
        }

        function noContent() {
            return (
                !vm.postData ||
                (!vm.postData.poll &&
                    (!vm.postData.message || !vm.postData.message.trim()) &&
                    (!vm.postData.files || !vm.postData.files.length))
            );
        }

        function textFormatToggle(formatOption, corresponding_html_tag) {
            var openingTag = "<" + corresponding_html_tag + ">";
            var closingTag = "</" + corresponding_html_tag + ">";

            var editMessage = vm.postData.message;
            if (
                editMessage.length > closingTag.length &&
                editMessage.substring(editMessage.length - closingTag.length) ==
                    closingTag
            ) {
                vm.postData.message = editMessage.concat(" ");
                vm.textFormatLabel = "t";
            } else {
                vm.postData.message = editMessage.concat(
                    openingTag + " " + closingTag
                );
                vm.textFormatLabel = label;
                vm.textFormatLabel = formatOption;
            }
        }

        function alterElementClassBasedOnTextFormatLabel(formatOption) {
            return { "btn-primary": vm.textFormatLabel == formatOption };
        }

        function sortFiles(postFiles) {
            var files = _.filter(postFiles, function(file) {
                return -1 === SSM_FILES.TYPES.IMAGE.indexOf(file.file_type);
            });
            return files;
        }

        function sortImages(postFiles) {
            var images = _.filter(postFiles, function(file) {
                return -1 !== SSM_FILES.TYPES.IMAGE.indexOf(file.file_type);
            });

            return images;
        }

        function removeFiles(fileIndex) {
            vm.postData.files.splice(fileIndex, 1);
        }
    }
})();
