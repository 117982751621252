(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("RegistrationCtrl", RegistrationCtrl);

    /*jshint maxparams: 14 */
    function RegistrationCtrl(
        Analytics,
        CurrentUser,
        $scope,
        ssmAuth,
        ssmAuthHelper,
        ssmLocalStorage,
        SSMUtilities,
        $timeout,
        User,
        WizardHandler,
        invitedConnectionResolve,
        suggestedConnectionResolve,
        communitySuggestionResolve,
        invitedCommunityResolve
    ) {
        var vm = this;
        vm.BROADCAST_IDENTIFIER = "registrationForm";
        vm.employerInvalid = false;
        vm.finishedWizard = finishedWizard;
        vm.showSavedFlag = false;
        vm.user = CurrentUser.profile;
        vm.wizardNext = wizardNext;
        vm.connectionSuggestions = suggestedConnectionResolve;
        vm.invitedConnections = invitedConnectionResolve;
        vm.communitySuggestions = communitySuggestionResolve;
        vm.invitedCommunities = invitedCommunityResolve;

        var DELAY_TIME_HIDING_FLAG = 3000;

        activate();

        function activate() {
            $scope.$on(
                SSMUtilities.SSM_AUTOCOMPLETE_CREATE_BUTTON_CHANGED_EVENT,
                function(event, isShown) {
                    vm.employerInvalid = isShown;
                }
            );
        }

        function finishedWizard() {
            Analytics.eventTrack("AddConnectionsCompleted", {
                category: "RegistrationCtrl",
                label: "user_id:" + vm.user.id
            });
            Analytics.eventTrack2("add_connections_completed", {
                category: "RegistrationCtrl",
                label: "user_id:" + vm.user.id
            });
            Analytics.eventTrack("RegistrationCompleted", {
                category: "RegistrationCtrl",
                label: "user_id:" + vm.user.id
            });
            Analytics.eventTrack2("registration_completed", {
                category: "RegistrationCtrl",
                label: "user_id:" + vm.user.id
            });
            CurrentUser.update({
                completed_registration: true
            }).then(function() {
                ssmAuth.redirectToLastAttemptedUrl();
            });
        }

        function wizardNext(form, event) {
            var valid = form ? form.$valid : true;
            if (valid) {
                if (event === "profileCompleted" && form.$dirty) {
                    updateProfile(form);
                    ssmAuth.resendValidation(
                        ssmAuthHelper.token,
                        ssmLocalStorage.get(
                            ssmLocalStorage.key.LAST_ATTEMPTED_URL
                        )
                    );
                }
                Analytics.eventTrack(event, {
                    category: "RegistrationCtrl",
                    label: "user_id:" + CurrentUser.profile.id
                });
                Analytics.eventTrack2(event, {
                    category: "RegistrationCtrl",
                    label: "user_id:" + CurrentUser.profile.id
                });
                WizardHandler.wizard("New User Setup").next();
            }
        }

        function updateProfile(form) {
            var now = new Date();
            var edits = {
                first_name: vm.user.first_name,
                last_name: vm.user.last_name,
                title: vm.user.title,
                hash_tags: vm.user.hash_tags,
                state: vm.user.state,
                institution: vm.user.institution
            };

            if (vm.user.accepted_phi) {
                edits.accepted_phi = vm.user.accepted_phi;
                edits.accepted_phi_date = now;
            }
            if (vm.user.accepted_eula) {
                edits.accepted_eula = vm.user.accepted_eula;
                edits.accepted_eula_date = now;
            }

            if (vm.user.photo) {
                edits.photo_id = vm.user.photo.id;
            }

            var params = {
                add_affiliate_list: form.add_affiliate_list || [],
                remove_affiliate_list: form.remove_affiliate_list || []
            };

            User.updateAffiliations(CurrentUser.profile.id, params).then(
                function() {
                    CurrentUser.update(edits).then(function(data) {
                        vm.user = data;
                        form.$setPristine();

                        vm.showSavedFlag = true;
                        $timeout(function() {
                            vm.showSavedFlag = false;
                        }, DELAY_TIME_HIDING_FLAG);
                    });
                }
            );
        }
    }
})();
