(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmMarketplace.service")
        .factory("ssmMarketplace", ssmMarketplace);

    function ssmMarketplace($q, CacheFactory, ssmMarketplaceApi) {
        var PAGE_LIMIT = 30;

        if (!CacheFactory.get("marketplace")) {
            CacheFactory.createCache("marketplace", {
                maxAge: 24 * 60 * 60 * 1000, // Items added to this cache expire after 1 day.
                cacheFlushInterval: 180000, // This cache will clear itself every 3 mins.
                deleteOnExpire: "aggressive" // Items will be deleted from this cache right when they expire.
            });
        }

        var cache = CacheFactory.get("marketplace");

        var service = {
            addMarketplacePermissionsForUser: addMarketplacePermissionsForUser,
            createMarketplace: createMarketplace,
            createMarketplaceCategory: createMarketplaceCategory,
            createMarketplaceListing: createMarketplaceListing,
            createMarketplaceMessage: createMarketplaceMessage,
            createMarketplaceOrganization: createMarketplaceOrganization,
            createMarketplacePartner: createMarketplacePartner,
            getMarketplace: getMarketplace,
            getMarketplaceCategories: getMarketplaceCategories,
            getMarketplaceListing: getMarketplaceListing,
            getMarketplaceListings: getMarketplaceListings,
            getAllContentForListing: getAllContentForListing,
            getMarketplaceListingsPaged: getMarketplaceListingsPaged,
            getMarketplacePartner: getMarketplacePartner,
            getMarketplacePartners: getMarketplacePartners,
            getMarketplacePermissions: getMarketplacePermissions,
            getMarketplacePermissionsForUser: getMarketplacePermissionsForUser,
            getMarketplaces: getMarketplaces,
            importMarketplace: importMarketplace,
            queueImport: queueImport,
            getMarketplaceImports: getMarketplaceImports,
            removeMarketplacePermissionsForUser: removeMarketplacePermissionsForUser,
            updateMarketplace: updateMarketplace,
            updateMarketplaceCategory: updateMarketplaceCategory,
            updateMarketplaceListing: updateMarketplaceListing,
            updateMarketplacePartner: updateMarketplacePartner,
            getEditableMarketplaces: getEditableMarketplaces
        };

        return service;

        // TODO: (STUB) This is in progress and will need to be updated once the backend service is changed
        function addMarketplacePermissionsForUser(userId) {
            return ssmMarketplaceApi.addMarketplacePermissionsForUser(userId);
        }

        function createMarketplace(marketplace) {
            return ssmMarketplaceApi.createMarketplace(marketplace);
        }

        function createMarketplaceCategory(marketplaceId, category) {
            return ssmMarketplaceApi.createMarketplaceCategory(
                marketplaceId,
                category
            );
        }

        function createMarketplaceListing(marketplaceId, listing) {
            return ssmMarketplaceApi.createMarketplaceListing(
                marketplaceId,
                listing
            );
        }

        function createMarketplaceMessage(message) {
            return ssmMarketplaceApi.createMarketplaceMessage(message);
        }

        function createMarketplaceOrganization(marketplaceId, organization) {
            return ssmMarketplaceApi.createMarketplaceOrganization(
                marketplaceId,
                organization
            );
        }

        function createMarketplacePartner(marketplaceId, partner) {
            return ssmMarketplaceApi.createMarketplacePartner(
                marketplaceId,
                partner
            );
        }

        function getEditableMarketplaces() {
            return ssmMarketplaceApi.getEditableMarketplaces();
        }

        function getMarketplace(marketplaceId, query, options) {
            var cachePath = marketplaceId + "?" + JSON.stringify(query);
            var cached = cache.get(cachePath);
            if (cached && !_.get(options, "forceRefresh")) {
                return $q.when(cached);
            }

            return ssmMarketplaceApi
                .getMarketplace(marketplaceId, query)
                .then(function(marketplace) {
                    // TODO Remove this workaround once we properly get the level ids
                    marketplace.marketplace_level_ids = ["basic", "premium"];

                    cache.put(cachePath, marketplace);

                    return marketplace;
                });
        }

        function getMarketplaceCategories(marketplaceId, query, options) {
            return ssmMarketplaceApi
                .getMarketplaceCategories(marketplaceId, query)
                .then(function(categories) {
                    categories.categoryMap = _.groupBy(categories, "parent_id");
                    categories.categoryIdMap = _.keyBy(categories, "id");

                    return categories;
                });
        }

        function getMarketplaceListing(listingId, query, options) {
            var cachePath = "/listings/" + listingId + "?" + JSON.stringify(query);
            var cached = cache.get(cachePath);
            if (cached && !_.get(options, "forceRefresh")) {
                return $q.when(cached);
            }

            return ssmMarketplaceApi
                .getMarketplaceListing(listingId, query)
                .then(function(listing) {
                    cache.put(cachePath, listing);
                    return listing;
                });
        }

        function getAllContentForListing(listingId, query) {
            return ssmMarketplaceApi.getAllContentForListing(listingId, query);
        }

        function getMarketplaceListings(marketplaceId, query, options) {
            return ssmMarketplaceApi.getMarketplaceListings(
                marketplaceId,
                query
            );
        }

        function getMarketplaceListingsPaged(marketplaceId, query, options) {

            var cachePath = marketplaceId + "/listings" + "?" + JSON.stringify(query);
            var cached = cache.get(cachePath);
            if (cached && !_.get(options, "forceRefresh")) {
                return $q.when(cached);
            }

            var paging = {
                listings: [], // previously retrieved listings
                disabled: false, // cannot retrieve more if we are already waiting or there are no more to get
                limit: query.limit ? query.limit : PAGE_LIMIT,
                skip: query.skip ? query.skip : 0,
                sort: query.sort,
                sort_order: query.sort_order,
                next: null,
                totalCount: undefined
            };

            // This function returns a promise that resolves to the entire paging object. Every time .next resolves, it
            // resolves to the same object with an updated state.
            paging.next = function() {
                if (paging.disabled) {
                    return $q.when(paging);
                }

                paging.disabled = true;

                _.assign(query, {
                    skip: paging.skip,
                    limit: paging.limit,
                    sort: paging.sort,
                    sort_order: paging.sort_order
                });

                return ssmMarketplaceApi
                    .getMarketplaceListings(marketplaceId, query)
                    .then(function(listings) {
                        paging.listings.push.apply(
                            paging.listings,
                            listings.items
                        );
                        paging.totalCount = listings.total_count;

                        var pageLength = _.get(listings.items, "length", 0);

                        paging.disabled = pageLength < paging.limit;
                        paging.skip += pageLength;

                        return $q.when(paging);
                    });
            };

            cache.put(cachePath, paging);

            return paging.next();
        }

        function getMarketplacePartner(partnerId, options) {
            return ssmMarketplaceApi.getMarketplacePartner(partnerId);
        }

        function getMarketplacePartners(marketplaceId, query, options) {
            var cachePath = marketplaceId + "/partners" + "?" + JSON.stringify(query);
            var cached = cache.get(cachePath);
            if (cached && !_.get(options, "forceRefresh")) {
                return $q.when(cached);
            }

            return ssmMarketplaceApi
                .getMarketplacePartners(marketplaceId, query)
                .then(function(partners) {
                    cache.put(cachePath, partners);

                    return partners;
                });
        }

        // TODO: (STUB) This is in progress and will need to be updated once the backend service is changed
        function getMarketplacePermissions(options) {
            var cachePath = "/marketplace_permissions/";
            var cached = cache.get(cachePath);
            if (cached && !_.get(options, "forceRefresh")) {
                return cached;
            }

            var result = ssmMarketplaceApi.getMarketplacePermissions().$object;

            cache.put(cachePath, result);
            return result;
        }

        // TODO: (STUB) This is in progress and will need to be updated once the backend service is changed
        function getMarketplacePermissionsForUser(userId) {
            return ssmMarketplaceApi.getMarketplacePermissionsForUser(userId);
        }

        function getMarketplaces(organizationId, query, options) {
            var cachePath =
                "/organization/" + organizationId + "?" + JSON.stringify(query);
            var cached = cache.get(cachePath);
            if (cached && !_.get(options, "forceRefresh")) {
                return $q.when(cached);
            }

            return ssmMarketplaceApi
                .getMarketplaces(organizationId)
                .then(function(marketplaces) {
                    cache.put(cachePath, marketplaces);
                    return marketplaces;
                });
        }

        function importMarketplace(data) {
            return ssmMarketplaceApi.importMarketplace(data);
        }

        function queueImport(marketplaceId, data) {
            return ssmMarketplaceApi.queueImport(marketplaceId, data);
        }

        function getMarketplaceImports(query) {
            return ssmMarketplaceApi.getMarketplaceImports(query);
        }

        // TODO: (STUB) This is in progress and will need to be updated once the backend service is changed
        function removeMarketplacePermissionsForUser(userId) {
            return ssmMarketplaceApi.removeMarketplacePermissionsForUser(
                userId
            );
        }

        function updateMarketplace(marketplaceId, marketplace) {
            return ssmMarketplaceApi.updateMarketplace(
                marketplaceId,
                marketplace
            );
        }

        function updateMarketplaceCategory(
            marketplaceId,
            categoryId,
            category
        ) {
            return ssmMarketplaceApi.updateMarketplaceCategory(
                marketplaceId,
                categoryId,
                category
            );
        }

        function updateMarketplaceListing(listingId, listing) {
            return ssmMarketplaceApi.updateMarketplaceListing(
                listingId,
                listing
            );
        }

        function updateMarketplacePartner(partnerId, partner) {
            return ssmMarketplaceApi.updateMarketplacePartner(
                partnerId,
                partner
            );
        }
    }
})();
