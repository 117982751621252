(function() {
    "use strict";

    angular
        .module("ssmAngularApp.setImageSize")
        .directive("setImageSize", setImageSize);

    function setImageSize() {
        return {
            templateUrl: "components/setImageSize/setImageSize.html",
            restrict: "E",
            replace: true,
            scope: {},
            controller: setImageSizeCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function setImageSizeCtrl(Logger, Blog, $scope, $timeout, $translate) {
        /*jshint validthis:true */
        var vm = this;
        vm.setSize = setSize;
        vm.subject = null;
        vm.editor = null;

        vm.SIZES = {
            INVALID: 0,
            TINY: 1,
            SMALL: 2,
            MEDIUM: 3,
            LARGE: 4,
            ORIGINAL: 5
        };

        var SIZE_WIDTHS = {
            TINY: "20%",
            SMALL: "33%",
            MEDIUM: "66%",
            LARGE: "98%",
            ORIGINAL: "100%"
        };

        vm.titles = SIZE_WIDTHS;

        activate();

        function activate() {
            $translate("BLOG.SET_IMAGE_SIZE").then(function(translation) {
                vm.resizeToggleTitle = translation;
            });

            // The event BLOG_EDITOR_CLICKED_EVENT is emitted when the user clicks on the blog editor
            $scope.$on(Blog.BLOG_EDITOR_CLICKED_EVENT, function(
                event,
                clickTarget,
                editor
            ) {
                vm.editor = editor;

                // If user clicks away from an image, hide the resize dialog
                if (!clickTarget) {
                    hideResizeDialog();
                    return;
                }

                // Compute the quill start, end, and index values for the target
                getTargetIndex(clickTarget);

                // Select the image in the editor
                selectTargetImage(clickTarget);

                // Show the resize dialog
                setResizeBoxCoordinates(clickTarget);
                setCurrentResizeChoice();
                vm.subject = clickTarget;

                // Trigger a digest cycle to propagate the change to vm.subject
                $timeout(function() {
                    $scope.$apply(function() {
                        $scope.$eval(vm.subject);
                    });
                });
            });
        }

        function setSize(size) {
            if (!vm.subject || !vm.editor) {
                return;
            }

            switch (size) {
                case vm.SIZES.TINY:
                    vm.editor.formatText(
                        vm.start,
                        vm.end,
                        "width",
                        SIZE_WIDTHS.TINY,
                        "user"
                    );
                    vm.current = vm.SIZES.TINY;
                    break;
                case vm.SIZES.SMALL:
                    vm.editor.formatText(
                        vm.start,
                        vm.end,
                        "width",
                        SIZE_WIDTHS.SMALL,
                        "user"
                    );
                    vm.current = vm.SIZES.SMALL;
                    break;
                case vm.SIZES.MEDIUM:
                    vm.editor.formatText(
                        vm.start,
                        vm.end,
                        "width",
                        SIZE_WIDTHS.MEDIUM,
                        "user"
                    );
                    vm.current = vm.SIZES.MEDIUM;
                    break;
                case vm.SIZES.LARGE:
                    // Use 98% instead of 100% because 100% is default in quill
                    vm.editor.formatText(
                        vm.start,
                        vm.end,
                        "width",
                        SIZE_WIDTHS.LARGE,
                        "user"
                    );
                    vm.current = vm.SIZES.LARGE;
                    break;
                default:
                    // Setting to 100% removes size formatting in quill
                    vm.editor.formatText(
                        vm.start,
                        vm.end,
                        "width",
                        SIZE_WIDTHS.ORIGINAL,
                        "user"
                    );
                    vm.current = vm.SIZES.ORIGINAL;
                    break;
            }

            // Make sure the resize box follows the image size change
            setResizeBoxCoordinates(vm.subject);
        }

        // Converts the quill values to setImageSize enum (e.g., '20%' to TINY)
        function setCurrentResizeChoice() {
            /*jshint maxcomplexity:9 */
            if (!vm.index && vm.index !== 0) {
                vm.current = vm.SIZES.INVALID;
                return;
            }

            var item = vm.editor.getContents().ops[vm.index];
            if (!item.attributes) {
                vm.current = vm.SIZES.INVALID;
                return;
            }

            if (!item.attributes.width) {
                vm.current = vm.SIZES.ORIGINAL;
                return;
            }

            switch (item.attributes.width) {
                case SIZE_WIDTHS.TINY:
                    vm.current = vm.SIZES.TINY;
                    break;
                case SIZE_WIDTHS.SMALL:
                    vm.current = vm.SIZES.SMALL;
                    break;
                case SIZE_WIDTHS.MEDIUM:
                    vm.current = vm.SIZES.MEDIUM;
                    break;
                case SIZE_WIDTHS.LARGE:
                    vm.current = vm.SIZES.LARGE;
                    break;
                case SIZE_WIDTHS.ORIGINAL:
                    vm.current = vm.SIZES.ORIGINAL;
                    break;
                default:
                    vm.current = vm.SIZES.INVALID;
                    break;
            }
        }

        function selectTargetImage(image) {
            $timeout(function() {
                vm.editor.setSelection(vm.start, vm.end);
            });
        }

        function getTargetIndex(image) {
            // Find out the index of the image by counting the insert characters and lengths
            var contents = vm.editor.getContents().ops;
            var start;
            var end;

            var count = 0;
            for (var i = 0; i < contents.length; i++) {
                var item = contents[i];
                if (!item.insert) {
                    continue;
                }

                if (
                    item.attributes &&
                    item.attributes.image &&
                    item.attributes.image === image.src
                ) {
                    start = count;
                    end = count + 1;
                    vm.index = i;
                    break;
                }

                if ((typeof item.insert).toLowerCase() === "number") {
                    count += item.insert;
                } else if ((typeof item.insert).toLowerCase() === "string") {
                    count += item.insert.length;
                } else {
                    hideResizeDialog();
                    $translate("BLOG.EDIT_ERROR").then(function(translation) {
                        Logger.error("ERROR: ", translation);
                    });
                    return;
                }
            }

            vm.start = start;
            vm.end = end;
        }

        function setResizeBoxCoordinates(image) {
            if (!image) {
                return;
            }
            var node = angular.element(".set-image-size-container")[0];
            node.style.position = "absolute";
            node.style.left = computeResizeBoxLeft(image);
            node.style.top = computeResizeBoxTop(image, node);
        }

        function computeResizeBoxLeft(image) {
            var result = image.offsetLeft;
            return result.toString() + "px";
        }

        function computeResizeBoxTop(image, node) {
            var MARGIN = 80;
            var result = image.offsetTop;
            result += image.height;

            if (node.parentNode && node.parentNode.offsetHeight) {
                result =
                    result + MARGIN < node.parentNode.offsetHeight
                        ? result
                        : node.parentNode.offsetHeight - MARGIN;
                result = result > 0 ? result : 0;
            }

            return result.toString() + "px";
        }

        function hideResizeDialog() {
            vm.subject = null;
            vm.start = null;
            vm.end = null;
            vm.index = null;
            vm.current = vm.SIZES.INVALID;

            //trigger a digest cycle to propagate the change to vm.subject
            $timeout(function() {
                $scope.$apply(function() {
                    $scope.$eval(vm.subject);
                });
            });
        }
    }
})();
