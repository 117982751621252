(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("VendorIndexController", VendorIndexController);

    function VendorIndexController(
        vendorPagerResolve,
        VendorPermissions,
        $translate,
        SSMTitle
    ) {
        var vm = this;
        vm.pageTitle = "";
        vm.canCreate = false;

        vm.vendorPager = vendorPagerResolve;
        vm.setTitle = setTitle;

        activate();

        function activate() {
            $translate("VENDOR.ALL_VENDOR").then(vm.setTitle);

            VendorPermissions.canAddVendor().then(function(allowed) {
                vm.canCreate = allowed;
            });
        }

        function setTitle(title) {
            SSMTitle.setTitle(title);
            vm.pageTitle = title;
        }
    }
})();
