(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmArticleBrief")
        .directive("ssmArticleBrief", ssmArticleBrief)
        .controller("ssmArticleBriefController", ssmArticleBriefController);

    function ssmArticleBrief() {
        return {
            templateUrl: "components/ssmArticleBrief/ssmArticleBrief.html",
            restrict: "E",
            replace: true,
            scope: {
                content: "=",
                owner: "=",
                showChildren: "&",
                handleRemove: "&",
                handleRemoveEmbed: "&"
            },
            controller: ssmArticleBriefController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmArticleBriefController(
        $scope,
        $translate,
        $uibModal,
        Analytics,
        CurrentUser,
        User,
        WallPost
    ) {
        /*jshint validthis:true */
        var vm = this;
        vm.canDelete =
            vm.content.author &&
            vm.content.author.document_id === CurrentUser.profile.id;

        vm.respondToContent = respondToContent;
        vm.removeContent = removeContent;
        vm.showDetails = showDetails;
        vm.removeEmbed = removeEmbed;

        activate();

        function activate() {
            $translate("ARTICLE_BRIEF.COMMENTS").then(function(translation) {
                vm.responseText = translation;
            });

            var author_id = _.get(vm.content, "author.document_id");
            var author_type = _.get(vm.content, "author.document_type");

            vm.isAuthorAdmin =
                author_id &&
                !!_.find(vm.owner.admin_ids, function(admin_id) {
                    return admin_id === author_id;
                });
            vm.hasEmbed =
                vm.content && vm.content.embed && vm.content.embed.length;

            if (author_id && author_type === WallPost.LOCATION_TYPE.USER) {
                User.getUser(author_id).then(function(results) {
                    vm.content.authorDetails = results;
                    if (results.affiliations && results.affiliations.length) {
                        vm.content.organization =
                            results.affiliations[0].organization;
                    }
                });
            }

            $scope.$watch(
                function() {
                    return vm.content && vm.content.embed;
                },
                function(newValue, oldValue) {
                    if (oldValue === newValue) {
                        return;
                    }

                    vm.hasEmbed =
                        vm.content &&
                        vm.content.embed &&
                        vm.content.embed.length;
                }
            );
        }

        function respondToContent() {
            $uibModal
                .open({
                    templateUrl:
                        "components/modal/createResponse/createResponse.html",
                    controller: "createResponseController as vm",
                    resolve: {
                        content_item: function() {
                            return vm.content;
                        }
                    }
                })
                .result.then(function() {
                    showDetails();
                });
        }

        function removeContent() {
            if (vm.handleRemove) {
                vm.handleRemove({ content: vm.content });
            }
        }

        function removeEmbed() {
            if (vm.handleRemoveEmbed) {
                vm.handleRemoveEmbed({ content: vm.content });
            }
        }

        function showDetails() {
            if (vm.showChildren) {
                vm.showChildren({ parent: vm.content });
            }
        }
    }
})();
