"use strict";

angular.module("ssmAngularApp.layout").config(function($stateProvider) {
    $stateProvider.state("404", {
        url: "/404",
        templateUrl: "layout/pageNotFound/pageNotFound.html",
        controller: "PageNotFoundCtrl",
        controllerAs: "pageNotFound"
    });
});
