/* jshint maxstatements:50 */
(function() {
    "use strict";

    angular
        .module("ssmAngularApp.communityMembersForm", [])
        .directive("communityMembersForm", communityMembersForm);

    function communityMembersForm() {
        return {
            templateUrl:
                "components/communityMembersForm/communityMembersForm.html",
            restrict: "E",
            scope: {
                membersData: "=",
                memberType: "@",
                communityId: "@",
                domainId: "@",
                minimum: "@",
                maximum: "@",
                onAddMember: "&",
                onRemoveMember: "&"
            },
            controller: communityMembersFormCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function communityMembersFormCtrl(
        $q,
        $translate,
        CurrentUser,
        User,
        Community,
        Tenant,
        Logger,
        Search
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.isFollowerStep = vm.memberType === "follower";
        vm.isAdminStep = vm.memberType === "admin";
        vm.creatorId = null;
        vm.memberToAdd = null;
        vm.showNotFoundError = false;
        vm.isMemberInputValid = true;
        vm.showMoreButtons = false;
        vm.showAlreadyMemberText = false;
        vm.enterNamePlaceholder = "MEMBER_FORM.SEARCH_PLACEHOLDER";
        vm.showAdminRequiredError = showAdminRequiredError;
        vm.removeMember = removeMember;
        vm.nextPage = nextPage;
        vm.showCloseButton = showCloseButton;
        vm.membersAutocomplete = {
            deactivate: false,
            suggestions: [],
            searchCallback: searchCallback,
            onSelect: onSelect
        };
        vm.disableAdd = disableAdd;
        vm.org_super_admin = [];

        vm.minimumRequired = angular.isDefined(vm.minimum)
            ? vm.minimum
            : vm.isAdminStep
            ? 1
            : 0;

        // This is used in the string table, so if it changes here it should change there. COMMUNITY_CREATION.MEMBERS.SHOW_NEXT_BUTTON
        var MEMBER_LIMIT = 20;
        var MAX_NUMBER_SEARCH_RESULTS = 50;

        // Dictionary for converting the Follow status enum into a string table id for displaying.
        var statusEnumToStringIdDict = {};
        var currentAccessList = null;

        activate();

        function activate() {
            Tenant.getTenant().then(tenant => {
                vm.org_super_admin = tenant.org_super_admin;
            });
            setLabels();
            statusEnumToStringIdDict = setupStringtoEnumDictionary(Community);

            // if the communityId does not exist, we are creating a new community.
            if (!vm.communityId) {
                setupInitialList(CurrentUser.profile);
                if (vm.isAdminStep) {
                    vm.creatorId = CurrentUser.profile.id;
                }
                currentAccessList = [];
            } else {
                // If editing the community, retrieve the existing community access list. (hydrated, first 20)
                if (vm.isFollowerStep) {
                    getAccessList({ skip: 0, limit: MEMBER_LIMIT }).then(
                        function() {
                            if (vm.membersData.members.length >= MEMBER_LIMIT) {
                                vm.showMoreButtons = true;
                            }
                        }
                    );
                }
            }
        }

        function searchCallback(searchText) {
            vm.showNotFoundError = false;
            vm.showAlreadyMemberText = false;
            vm.membersAutocomplete.deactivate =
                vm.isFollowerStep &&
                searchText &&
                searchText.indexOf("@") === 0;

            return Search.suggestusers({
                searchid: searchText,
                size: MAX_NUMBER_SEARCH_RESULTS,
                q: searchText,
                domain: vm.domainId
            });
        }

        function onSelect(item) {
            if (vm.isFollowerStep) {
                addSelectedFollower(item);
            } else {
                addMemberFromSearchResult(item);
            }
        }

        function setLabels() {
            if (vm.isFollowerStep) {
                vm.enterNamePlaceholder =
                    "COMMUNITY_CREATION.FOLLOWER_ENTER_NAME_PLACEHOLDER";
            }

            $translate(vm.enterNamePlaceholder).then(function(translation) {
                vm.enterNamePlaceholder = translation;
            });
        }

        // When creating a new community the current user is by default an Admin and Follower
        function setupInitialList(currentUserProfile) {
            var status = null;
            var pending = true;
            if (vm.isFollowerStep) {
                status = Community.FOLLOW_STATUS.FOLLOWING;
            }
            var member = {
                id: currentUserProfile.id,
                user: { full_name: currentUserProfile.full_name },
                followStatus: status,
                pending: pending
            };
            addMember(member);

            Tenant.getTenant().then(tenant => {
                for (var admin in tenant.org_super_admin) {
                    User.getUser(tenant.org_super_admin[admin]).then(function(
                        admin
                    ) {
                        var member = {
                            id: admin.id,
                            user: { full_name: admin.full_name },
                            followStatus: status,
                            pending: pending
                        };
                        addMember(member);
                    });
                }
            });
        }

        // Access list for private communities, for public communities this is just a list of current members with no access implications.
        function getAccessList(query) {
            return Community.getCommunityAccessList(vm.communityId, query)
                .then(function(accessList) {
                    if (!accessList || accessList.length === 0) {
                        vm.showMoreButtons = false;
                        return;
                    }
                    _.each(accessList, function(retrievedMember) {
                        if (retrievedMember.user) {
                            var url =
                                retrievedMember.user &&
                                retrievedMember.user.photo
                                    ? retrievedMember.user.photo
                                    : "invalid";
                        }
                        var member = {
                            domain: retrievedMember.domain,
                            id: retrievedMember.user_id,
                            followStatus: retrievedMember.status,
                            user: retrievedMember.user
                        };
                        addMember(member);
                    });
                })
                .catch(function(error) {
                    // Error getting blogs, don't add any more and leave infinite scroll disabled
                    $translate("COMMUNITY_CREATION.GET_FOLLOWER_ERROR").then(
                        function(translation) {
                            Logger.error("ERROR: ", translation, error);
                        }
                    );
                    if (!currentAccessList) {
                        currentAccessList = [];
                    }
                });
        }

        function getFullAccessList() {
            var fullAccessListPromise = $q.defer();
            if (currentAccessList === null && vm.communityId) {
                var query = { idsOnly: true };
                Community.getCommunityAccessList(vm.communityId, query)
                    .then(function(accessList) {
                        currentAccessList = accessList;
                        fullAccessListPromise.resolve();
                    })
                    .catch(function(error) {
                        $translate(
                            "COMMUNITY_CREATION.GET_FOLLOWER_ERROR"
                        ).then(function(translation) {
                            Logger.error("ERROR: ", translation, error);
                        });
                        fullAccessListPromise.reject();
                    });
            } else {
                fullAccessListPromise.resolve();
            }

            return fullAccessListPromise.promise;
        }

        function addSelectedFollower(item) {
            vm.isMemberInputValid = true;
            getFullAccessList().then(function() {
                // If adding an email domain don't search for any user by name.
                if (vm.memberToAdd && vm.memberToAdd.indexOf("@") === 0) {
                    vm.isMemberInputValid = memberInputPattern(vm.memberToAdd);

                    if (vm.isMemberInputValid) {
                        var member = { domain: vm.memberToAdd.toLowerCase() };
                        addMember(member);
                    }
                } else {
                    vm.membersAutocomplete.deactivate = false;
                    addMemberFromSearchResult(item);
                }
            });
        }

        function addMemberFromSearchResult(result) {
            if (!result) {
                return;
            }
            var member = { id: result.user.id, user: result.user };
            addMember(member);
        }

        function addMember(member) {
            // Check if the admin has already been added
            if (!isAlreadyAdmin(member)) {
                // Follower
                if (vm.isFollowerStep) {
                    return addFollower(member);
                } else {
                    // Add new members to the top of the list that is displayed to the user.
                    vm.membersData.members.unshift(member);
                    if (vm.onAddMember) {
                        vm.onAddMember({ member: member });
                    }
                }
            } else {
                vm.showAlreadyMemberText = true;
            }
        }

        function addFollower(member) {
            setStatusString(member, statusEnumToStringIdDict);

            if (!isAlreadyFollower(member)) {
                if (
                    member.followStatus ===
                        "COMMUNITY_CREATION.MEMBERS.INVITING_STATUS" ||
                    member.followStatus ===
                        "COMMUNITY_CREATION.MEMBERS.PENDING_STATUS"
                ) {
                    currentAccessList = currentAccessList || [];
                    currentAccessList.push({
                        user_id: member.id,
                        domain: member.domain
                    });
                    // Add to list of members shown to the user
                    vm.membersData.members.unshift(member);
                    // Add to the list of newly added followers, which is sent to back-end follow API
                    var status =
                        member.followStatus ===
                        "COMMUNITY_CREATION.MEMBERS.PENDING_STATUS"
                            ? Community.FOLLOW_STATUS.FOLLOWING
                            : Community.FOLLOW_STATUS.INVITED;
                    vm.membersData.followerData.added_followers.push({
                        user_id: member.id,
                        domain: member.domain,
                        status: status
                    });
                    // Make sure we never do both an add and remove of the same member.
                    _.remove(
                        vm.membersData.followerData.removed_followers,
                        function(follower) {
                            return (
                                (follower.user_id &&
                                    follower.user_id === member.id) ||
                                (follower.domain &&
                                    follower.domain === member.domain)
                            );
                        }
                    );
                } else {
                    vm.membersData.members.push(member);
                }
            } else {
                vm.showAlreadyMemberText = true;
            }
        }

        function isAlreadyFollower(member) {
            return _.find(currentAccessList, function(existingMember) {
                return (
                    (existingMember.user_id &&
                        existingMember.user_id === member.id) ||
                    (existingMember.domain &&
                        existingMember.domain === member.domain)
                );
            });
        }

        function isAlreadyAdmin(member) {
            return _.find(vm.membersData.members, function(existingMember) {
                return existingMember.id === member.id;
            });
        }

        function removeMember(member) {
            var index = vm.membersData.members.indexOf(member);
            if (index !== -1) {
                vm.membersData.members.removeAt(index);
                if (vm.onRemoveMember) {
                    vm.onRemoveMember({ member: member });
                }
                if (vm.isFollowerStep) {
                    // Add to the list of newly removed members, which is sent to back-end follow API
                    vm.membersData.followerData.removed_followers.push({
                        user_id: member.id,
                        domain: member.domain
                    });
                    // Make sure we never add and remove the same member.
                    _.remove(
                        vm.membersData.followerData.added_followers,
                        function(follower) {
                            return (
                                (follower.user_id &&
                                    follower.user_id === member.id) ||
                                (follower.domain &&
                                    follower.domain === member.domain)
                            );
                        }
                    );
                    getFullAccessList().then(function() {
                        _.remove(currentAccessList, function(follower) {
                            return (
                                (follower.user_id &&
                                    follower.user_id === member.id) ||
                                (follower.domain &&
                                    follower.domain === member.domain)
                            );
                        });
                    });
                }
            }
        }

        function showAdminRequiredError() {
            return vm.minimumRequired > vm.membersData.members.length;
        }

        function nextPage(all) {
            var limit = MEMBER_LIMIT;
            if (all === "all") {
                limit = null;
                vm.showMoreButtons = false;
            }
            if (vm.isFollowerStep) {
                var query = {
                    skip:
                        vm.membersData.members.length -
                        vm.membersData.followerData.added_followers.length,
                    limit: limit
                };

                getAccessList(query);
            }
        }

        function showCloseButton(member, org_super_admin) {
            return !(
                CurrentUser.profile.id == member.id ||
                org_super_admin.includes(member.id)
            );
        }

        // If the maximum number of allowed members is reached or not.  Used to disabled the input to add more.
        function disableAdd() {
            // If there is no maximum specified, the max is never reached.
            if (!angular.isDefined(vm.maximum)) {
                return false;
            }

            return vm.membersData.members.length >= vm.maximum;
        }
    }

    // Helper functions that don't require any controller/vm scope.

    // If the input starts with @ make sure it is valid email domain pattern.
    function memberInputPattern(memberToAdd) {
        var regexp = /^@[a-z0-9-]+(\.[a-z0-9-]+)/;
        if (memberToAdd.indexOf("@") !== 0) {
            return true;
        }
        return regexp.test(memberToAdd.toLowerCase());
    }

    function setupStringtoEnumDictionary(Community) {
        var dictionary = {};
        dictionary[Community.FOLLOW_STATUS.FOLLOWING] =
            "COMMUNITY_CREATION.MEMBERS.FOLLOWING_STATUS";
        dictionary[Community.FOLLOW_STATUS.INVITED] =
            "COMMUNITY_CREATION.MEMBERS.INVITED_STATUS";
        return dictionary;
    }

    // Convert the status Enum to a string table ID for displaying to the user.
    function setStatusString(member, statusEnumToStringIdDict) {
        if (typeof member.followStatus !== "undefined") {
            member.followStatus = statusEnumToStringIdDict[member.followStatus];
            if (member.pending) {
                member.followStatus =
                    "COMMUNITY_CREATION.MEMBERS.PENDING_STATUS";
            }
        } else {
            member.followStatus = "COMMUNITY_CREATION.MEMBERS.INVITING_STATUS";
        }
    }
})();
