(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .config(function($stateProvider, SSM_FEATURES) {
            if (!SSM_FEATURES || !SSM_FEATURES.NOTIFICATIONLIST) {
                return;
            }

            $stateProvider
                .state("main.notification", {
                    abstract: true,
                    templateUrl: "layout/main/notification/notification.html"
                })
                .state("main.notification.index", {
                    url: "/notifications/{user_id}",
                    templateUrl:
                        "layout/main/notification/notificationIndex/notificationIndex.html",
                    controller: "NotificationIndexController",
                    controllerAs: "vm"
                });
        });
})();
