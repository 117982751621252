(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("UpdateEulaAcceptCtrl", UpdateEulaAcceptCtrl)
        .controller("UpdateEulaModalCtrl", UpdateEulaModalCtrl);

    function UpdateEulaAcceptCtrl(
        $uibModal,
        $translate,
        SSMTitle,
        tenantResolve
    ) {
        var vm = this;

        activate();

        function activate() {
            $translate("EULA.TITLE").then(SSMTitle.setTitle);

            $uibModal.open({
                templateUrl:
                    "layout/eula/updateEulaAccept/updateEulaAccept.html",
                controller: "UpdateEulaModalCtrl",
                controllerAs: "vm",
                backdrop: "static",
                resolve: {
                    tenantResolve: function() {
                        return tenantResolve;
                    }
                }
            });
        }
    }

    function UpdateEulaModalCtrl(
        $uibModalInstance,
        tenantResolve,
        CurrentUser,
        ssmAuth,
        ssmLocalStorage
    ) {
        var vm = this;
        var currentTenant = ssmLocalStorage.get(
            ssmLocalStorage.key.CURRENT_TENANT
        );
        vm.acceptedEula = false;
        vm.acceptedPhi = false;
        vm.acceptedSiteEula = false;
        vm.acceptTerms = acceptTerms;
        vm.logoUrl = tenantResolve.logo_url;
        vm.logout = logout;
        vm.disablePhi = tenantResolve.flags.phi_agreement_disabled;
        vm.siteName = currentTenant.name;
        vm.ssoAdditionalTermsUrl = tenantResolve.sso_button.additional_terms;
        vm.displayEulaSsoModal = ssmAuth.setDisplayEulaSsoSettings(tenantResolve);
        vm.isAcceptEnabled = setAcceptButtonRequirements();

        function acceptTerms(acceptedPhi, acceptedEula) {
            if (vm.isAcceptEnabled()) {
                var now = new Date();
                var edits = {
                    accepted_phi: acceptedPhi,
                    accepted_phi_date: now,
                    accepted_eula: acceptedEula,
                    accepted_eula_date: now
                };

                CurrentUser.update(edits).then(function(data) {
                    ssmAuth.redirectToLastAttemptedUrl();
                });
            }
        }

        function setAcceptButtonRequirements() {
            if (vm.disablePhi) {
                vm.acceptedPhi = true;
            }
            if (!vm.ssoAdditionalTermsUrl) {
                vm.acceptedSiteEula = true;
            }
            return () => vm.acceptedEula && vm.acceptedPhi && vm.acceptedSiteEula;
        }

        function logout() {
            ssmAuth.logout();
            $uibModalInstance.dismiss();
        }
    }
})();
