(function() {
    "use strict";

    angular.module("ssmAngularApp.ssmArticleBrief", [
        "ssmAngularApp.analytics",
        "pascalprecht.translate",
        "ssmAngularApp.currentUser",
        "ui.bootstrap",
        "ssmAngularApp.alert.service"
    ]);
})();
