(function() {
    "use strict";

    angular.module("ssmAngularApp.services", [
        "ssmAngularApp.alert.service",
        "ssmAngularApp.appa.service",
        "ssmAngularApp.audience",
        "ssmAngularApp.blog",
        "ssmAngularApp.comment.service",
        "ssmAngularApp.community",
        "ssmAngularApp.CommunityMembership.service",
        "ssmAngularApp.connection.service",
        "ssmAngularApp.content",
        "ssmAngularApp.cookie",
        "ssmAngularApp.currentUser",
        "ssmAngularApp.externalInvites.service",
        "ssmAngularApp.feedback.service",
        "ssmAngularApp.ssmFiles.service",
        "ssmAngularApp.ssmFolders.service",
        "ssmAngularApp.findOrg",
        "ssmAngularApp.findUser",
        "ssmAngularApp.flair",
        "ssmAngularApp.httpStatus.service",
        "ssmAngularApp.image.service",
        "ssmAngularApp.ip.service",
        "ssmAngularApp.like.service",
        "ssmAngularApp.linktracker.service",
        "ssmAngularApp.logger",
        "ssmAngularApp.missingTranslationHandler",
        "ssmAngularApp.notification.service",
        "ssmAngularApp.organization",
        "ssmAngularApp.profileQuestions",
        "ssmAngularApp.search.service",
        "ssmAngularApp.sharedVariable.service",
        "ssmAngularApp.socket.service",
        "ssmAngularApp.ssmFileUpload.service",
        "ssmAngularApp.ssmLocalStorage",
        "ssmAngularApp.ssmSlack.service",
        "ssmAngularApp.ssmTitle",
        "ssmAngularApp.ssmUtilities",
        "ssmAngularApp.tenant.service",
        "ssmAngularApp.user.service",
        "ssmAngularApp.vendor.service",
        "ssmAngularApp.vendorpermissions.service",
        "ssmAngularApp.wallPost.service",
        "ssmAngularApp.ssmInlineContentHelper",
        "ssmAngularApp.ssmMarketplace.service",
        "ssmAngularApp.Permissions.service",
        "ssmAngularApp.ssmWebNotification",
        "ssmAngularApp.tutorial.service",
    ]);
})();
