(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmWebNotification")
        .constant("SSM_WEB_NOTIFICATIONS", {
            DEFAULTS: {
                icon: "assets/images/nwc-app-icon-512.png",
                dir: "auto",
                lang: "en",
                delay: 7000,
                focusWindowOnClick: true
            },
            LOCAL_STORAGE_KEY: "ssmDisableWebNotifications"
        });
})();
