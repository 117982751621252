(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmEnter", [])
        .directive("ssmEnter", ssmEnter);

    function ssmEnter() {
        return function(scope, element, attrs) {
            element.bind("keydown keypress", function(event) {
                if (event.which === 13) {
                    scope.$apply(function() {
                        scope.$eval(attrs.ssmEnter, {
                            event: event
                        });
                    });

                    event.preventDefault();
                }
            });
        };
    }
})();
