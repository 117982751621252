(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmInlineContentInput", [
            "ssmAngularApp.ssmInlineContentHelper",
            "ssmAngularApp.search.service"
        ])
        .constant("INLINE_CONFIG", {
            MAX_NUMBER_SEARCH_RESULTS: 50,
            MAX_HASH_TAG_LENGTH: 48
        })
        .directive("ssmInlineContentInput", ssmInlineContentInput)
        .controller(
            "ssmInlineContentInputController",
            ssmInlineContentInputController
        );

    function ssmInlineContentInput() {
        return {
            templateUrl:
                "components/ssmInlineContentInput/ssmInlineContentInput.html",
            restrict: "E",
            replace: true,
            scope: {
                data: "=",
                placeholder: "@",
                open: "=?",
                editing: "=?",
                hints: "=?"
            },
            controller: ssmInlineContentInputController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmInlineContentInputController(
        $q,
        SSMInlineContentHelper,
        ImageService,
        INLINE_CONFIG,
        Search,
        Tag
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.onFocus = onFocus;
        vm.onPaste = onPaste;
        vm.mentionables = [];
        vm.getMentionables = _.throttle(getMentionables, 200);
        vm.getMentionableText = getMentionableText;

        vm.suggested_hash_tags = [];
        vm.getSuggestedHashTags = getSuggestedHashTags;
        vm.getHashTagText = getHashTagText;

        function onFocus() {
            vm.open = true;
        }

        function onBlur() {
            vm.open = false;
        }

        function onPaste(event) {
            // Cancel event
            event.preventDefault();
            // Grab plaintext and push that instead -- this will give us essentially the same input the textarea had
            var plainText = event.clipboardData.getData("text/plain");
            plainText = SSMInlineContentHelper.escapeHtml(plainText);

            document.execCommand("insertHtml", false, plainText);
        }

        var deferredGetMentionables;
        function getMentionables(term) {
            var hints = _.filter(vm.hints, function(item) {
                return _.includes(
                    item.full_name.toLowerCase(),
                    term.toLowerCase()
                );
            });
            hints = _.map(hints, convertUserToMentionable);

            // reject in progress search
            if (deferredGetMentionables) {
                deferredGetMentionables.reject("Newer search started");
            }

            // we need a reference to the latest search but the closure below
            // should only know about the deferred created for this attempt
            var deferred = $q.defer();
            deferredGetMentionables = deferred;

            var apiPromise = Search.suggestusers({
                searchid: term,
                size: INLINE_CONFIG.MAX_NUMBER_SEARCH_RESULTS,
                q: term
            }).then(function(search_results) {
                deferred.resolve(search_results);
            });

            deferred.promise
                .then(function(search_results) {
                    var hits = _.map(search_results.results, function(item) {
                        if (!item || !item.user) {
                            return null;
                        }

                        return convertUserToMentionable(item.user);
                    });

                    vm.mentionables = _.uniqBy(
                        _.compact(hints.concat(hits)),
                        function(mentionable) {
                            return mentionable.id;
                        }
                    );
                })
                .catch(function(error) {
                    // ignore error since auto-complete should fail silently
                })
                .finally(function() {
                    // clean up the saved reference if it happens to be us
                    if (deferredGetMentionables === deferred) {
                        deferredGetMentionables = null;
                    }
                });
        }

        function getMentionableText(item) {
            return SSMInlineContentHelper.createMentionInlineContent(
                item.id,
                item.label
            );
        }

        function getTransformedTag(term) {
            var transformed_tag = term
                .replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter) {
                    return letter.toUpperCase();
                })
                .replace(/\s+/g, "")
                .replace(/\W/g, "");

            if (transformed_tag[0] !== "#") {
                transformed_tag = "#" + transformed_tag;
            }

            transformed_tag =
                transformed_tag.length > INLINE_CONFIG.MAX_HASH_TAG_LENGTH
                    ? transformed_tag.slice(
                          0,
                          INLINE_CONFIG.MAX_HASH_TAG_LENGTH
                      )
                    : transformed_tag;

            return transformed_tag;
        }

        function getSuggestedHashTags(term) {
            if (_.isEmpty(term) || term === "#") {
                vm.suggested_hash_tags = [];
            } else {
                var search_item = getTransformedTag(term);

                Tag.search({ tag_value: search_item })
                    .then(function(results) {
                        vm.suggested_hash_tags = _.map(results, function(item) {
                            return { label: item.value };
                        });

                        // Add the search item (whatever the user has typed to this point) to the list if it doesn't duplicate an existing
                        // item (matches will always be in slot 0), or there were no matching results at all.
                        if (
                            (vm.suggested_hash_tags.length > 0 &&
                                vm.suggested_hash_tags[0].label !==
                                    search_item) ||
                            vm.suggested_hash_tags.length === 0
                        ) {
                            vm.suggested_hash_tags.splice(0, 0, {
                                label: search_item
                            });
                        }
                    })
                    .catch(function(error) {});
            }
        }

        function getHashTagText(item) {
            return SSMInlineContentHelper.createHashTagInlineContent(
                item.label
            );
        }

        function convertUserToMentionable(user) {
            var imagePath = ImageService.getPath(user.photo, "small", "users");
            var orgName;
            if (user.organization) {
                orgName = user.organization.name;
            }

            return {
                label: user.full_name,
                title: user.title,
                orgName: orgName,
                imageUrl: imagePath,
                id: user.id,
                mention_type: "user"
            };
        }
    }
})();
