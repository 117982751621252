(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("main.blogs.edit", {
            url: "/blogs/{id}/edit",
            templateUrl: "layout/main/blog/blogEdit/blogEdit.html",
            controller: "BlogEditCtrl",
            controllerAs: "blog",
            resolve: {
                blogProfileResolve: function($stateParams, $state, Blog) {
                    return Blog.getBlog($stateParams.id);
                }
            }
        });
    });
})();
