(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.wallComments")
        .factory("PostComments", PostComments);

    function PostComments(Api) {
        var posts = Api.all("posts");

        return {
            addComment: function(postId, comment) {
                return posts
                    .one(postId)
                    .all("comments")
                    .post(comment, { format: "enhanced" });
            },
            delete: function(postId, commentId) {
                return posts
                    .one(postId)
                    .one("comments", commentId)
                    .remove();
            },
            update: function(postId, commentId, text) {
                return posts
                    .one(postId)
                    .one("comments", commentId)
                    .customPUT(text);
            },
            updateGuestComment: function(postId, commentId) {
                return posts
                    .one(postId)
                    .one("comments", commentId)
                    .one("guest")
                    .put();
            },
            like: function(postId, commentId) {
                return posts
                    .one(postId)
                    .one("comments", commentId)
                    .all("likes")
                    .post();
            },
            unLike: function(postId, commentId) {
                return posts
                    .one(postId)
                    .one("comments", commentId)
                    .one("likes", "mine")
                    .remove();
            },
            checkIfLiked: function(postId, commentId) {
                return posts
                    .one(postId)
                    .one("comments", commentId)
                    .all("likes")
                    .get("length");
            },
            allLikes: function(postId, commentId) {
                return posts
                    .one(postId)
                    .one("comments", commentId)
                    .all("likes")
                    .getList();
            }
        };
    }
})();
