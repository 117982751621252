(function() {
    "use strict";

    angular.module("ssmAngularApp.components", [
        "ssmAngularApp.adminTaskCard",
        "ssmAngularApp.analytics",
        "ssmAngularApp.appaSideNav",
        "ssmAngularApp.avatarCard",
        "ssmAngularApp.bannerAd",
        "ssmAngularApp.bannerAdModifier",
        "ssmAngularApp.blogCardBrief",
        "ssmAngularApp.blogEditor",
        "ssmAngularApp.blogPermissions",
        "ssmAngularApp.closeButton",
        "ssmAngularApp.communitiesNav",
        "ssmAngularApp.communityCard",
        "ssmAngularApp.communityCardBrief",
        "ssmAngularApp.communityInformationForm",
        "ssmAngularApp.communityJoin",
        "ssmAngularApp.communityMembersForm",
        "ssmAngularApp.communityPrivate",
        "ssmAngularApp.communityPropertiesForm",
        "ssmAngularApp.connectionCard",
        "ssmAngularApp.createWallPost",
        "ssmAngularApp.editButton",
        "ssmAngularApp.eventsNav",
        "ssmAngularApp.inviteCardBrief",
        "ssmAngularApp.inviteCommunitiesForm",
        "ssmAngularApp.inviteEmailsForm",
        "ssmAngularApp.inviteMessageForm",
        "ssmAngularApp.inviteResults",
        "ssmAngularApp.libraryFile",
        "ssmAngularApp.libraryFolder",
        "ssmAngularApp.loading",
        "ssmAngularApp.mainHeader",
        "ssmAngularApp.mainHeader.helper",
        "ssmAngularApp.modal",
        "ssmAngularApp.orgCardBrief",
        "ssmAngularApp.outOfNetworkBadge",
        "ssmAngularApp.profileSettingsForm",
        "ssmAngularApp.recentlyViewedCommunities",
        "ssmAngularApp.searchResults",
        "ssmAngularApp.setImageSize",
        "ssmAngularApp.ssmAffiliatesForm",
        "ssmAngularApp.ssmArticleBrief",
        "ssmAngularApp.ssmAlerts",
        "ssmAngularApp.ssmAutocompleteInput",
        "ssmAngularApp.ssmCategoryBreadcrumbs",
        "ssmAngularApp.ssmCategoryNav",
        "ssmAngularApp.ssmCommunityLibraryFile",
        "ssmAngularApp.ssmCommunityProfile",
        "ssmAngularApp.ssmCommunitySettingsForm",
        "ssmAngularApp.ssmContentCreate",
        "ssmAngularApp.ssmContentFeed",
        "ssmAngularApp.ssmContentRespond",
        "ssmAngularApp.ssmContentResponse",
        "ssmAngularApp.ssmCountryProvinceSelect",
        "ssmAngularApp.ssmCreateComment",
        "ssmAngularApp.ssmEditDeleteDropDown",
        "ssmAngularApp.ssmFlair",
        "ssmAngularApp.ssmIcon",
        "ssmAngularApp.ssmInlineContentInput",
        "ssmAngularApp.ssmInputWithSuggestions",
        "ssmAngularApp.ssmKeywordFilter",
        "ssmAngularApp.ssmMarketplaceFilterList",
        "ssmAngularApp.ssmMarketplaceListing",
        "ssmAngularApp.ssmNotFound",
        "ssmAngularApp.ssmNotificationItem",
        "ssmAngularApp.ssmNotificationMenu",
        "ssmAngularApp.ssmOrganizationEdit",
        "ssmAngularApp.ssmQuestionBrief",
        "ssmAngularApp.ssmResetPassword",
        "ssmAngularApp.ssmSavedFlag",
        "ssmAngularApp.ssmSearchField",
        "ssmAngularApp.ssmStateDropdown",
        "ssmAngularApp.ssmTagSelect",
        "ssmAngularApp.ssmTrackableLink",
        "ssmAngularApp.ssmUserVendorManager",
        "ssmAngularApp.ssmUserVendorManager",
        "ssmAngularApp.ssmVendorAdminForm",
        "ssmAngularApp.ssmVendorCardBrief",
        "ssmAngularApp.ssmVendorFlairForm",
        "ssmAngularApp.ssmVendorFlairList",
        "ssmAngularApp.ssmVendorProfileForm",
        "ssmAngularApp.ssmVote",
        "ssmAngularApp.ssmWallPoll",
        "ssmAngularApp.ssmYouTubePlayer",
        "ssmAngularApp.ssoButton",
        "ssmAngularApp.userCardBrief",
        "ssmAngularApp.userConnect",
        "ssmAngularApp.wallComments",
        "ssmAngularApp.wallPost",
        "ssmAngularApp.wallPostCard",
        "ssmAngularApp.wallProfile"
    ]);
})();
