(function() {
    "use strict";

    angular.module("ssmAngularApp.ssmCreateComment", [
        "ssmAngularApp.wallPost",
        "ssmAngularApp.api",
        "ssmAngularApp.analytics",
        "ssmAngularApp.cookie"
    ]);
})();
