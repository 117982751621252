(function() {
    "use strict";

    angular
        .module("ssmAngularApp.communityPropertiesForm", ["ssmAngularApp.tag"])
        .directive("communityPropertiesForm", communityPropertiesForm)
        .controller("communityPropertiesFormCtrl", communityPropertiesFormCtrl);

    function communityPropertiesForm() {
        return {
            templateUrl:
                "components/communityPropertiesForm/communityPropertiesForm.html",
            restrict: "E",
            scope: {
                propertiesData: "=",
                createState: "@"
            },
            controller: communityPropertiesFormCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function communityPropertiesFormCtrl($translate, CurrentUser) {
        /*jshint validthis:true */
        var vm = this;
        vm.postPermissions = [];

        activate();

        function activate() {
            vm.user_has_slack_id = !!CurrentUser.profile.slack_id;
            vm.slackable = vm.createState === "true";

            // post permissions value must match the Typescript Enum CommunityPostingPermissions.ts on the back-end
            $translate(
                "COMMUNITY_CREATION.PROPERTIES.POSTING_PERMISSION_ALL"
            ).then(function(translation) {
                // 0 is ALL
                vm.postPermissions.push({ value: 0, name: translation });
            });
            $translate(
                "COMMUNITY_CREATION.PROPERTIES.POSTING_PERMISSION_PARTICIPATION_ONLY"
            ).then(function(translation) {
                // 1 is PARTICIPATION_ONLY
                vm.postPermissions.push({ value: 1, name: translation });
            });
            $translate(
                "COMMUNITY_CREATION.PROPERTIES.POSTING_PERMISSION_ADMINS_ONLY"
            ).then(function(translation) {
                // 2 is ADMINS_ONLY
                vm.postPermissions.push({ value: 2, name: translation });
            });
        }
    }
})();
