(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.findUser", []).factory("FindUser", FindUser);

    function FindUser(Search) {
        var vm = {
            data: {},
            initFields: initFields,
            search: search
        };

        var fields;

        Search.fields().then(function(results) {
            fields = results.plain();
            initFields();
        });

        function initFields() {
            var FieldType = {
                OrganizationTypeOfService: 319,
                AreasOfExpertise: 317,
                StaffedInpatientBeds: 327
            };

            vm.data.area_of_expertise = getOptions(FieldType.AreasOfExpertise);
            vm.data.type_of_service = getOptions(
                FieldType.OrganizationTypeOfService
            );
            vm.data.beds = getOptions(FieldType.StaffedInpatientBeds);

            function getField(legacy_id) {
                return _.find(fields, { legacy_id: legacy_id });
            }

            function getOptions(legacy_id) {
                var options = _.map(arguments, function(legacy_id) {
                    var field = getField(legacy_id);
                    return _.map(field.field_options, function(option) {
                        return {
                            field_id: field.id,
                            option_id: option.id,
                            text: option.text
                        };
                    });
                });

                options = _(options)
                    .flatten()
                    .sortBy("text")
                    .value();

                return {
                    type: "multiselect",
                    options: options
                };
            }
        }

        function getSelectedOptionIds(options) {
            var option_ids = options.map(function(option) {
                return option.selected ? option.option_id : null;
            });

            return _.compact(option_ids);
        }

        function getSearchDataForField(field) {
            switch (field.type) {
                case "multiselect":
                    var optionsByField = _.groupBy(field.options, "field_id");

                    // Build an array of { field_id: foo, option_ids: [foo] }
                    var search_data = _.map(optionsByField, function(
                        options,
                        field_id
                    ) {
                        return {
                            field_id: field_id,
                            option_ids: getSelectedOptionIds(options)
                        };
                    });

                    // Remove any items where the option_ids array is empty.
                    search_data = _.filter(search_data, function(data) {
                        return data.option_ids.length;
                    });

                    return search_data;
            }

            return null;
        }

        vm.buildSearchData = function() {
            var data = [
                getSearchDataForField(vm.data.area_of_expertise),
                getSearchDataForField(vm.data.type_of_service),
                getSearchDataForField(vm.data.beds)
            ];

            data = _(data)
                .flatten()
                .compact()
                .value();
            return data;
        };

        function search(params) {
            // Cook up a search data object.
            var search_data = vm.buildSearchData();
            // Fire off an async call to get the results
            return Search.users(search_data, params);
        }

        return vm;
    }
})();
