(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.editFileModal")
        .controller("EditFileCtrl", EditFileCtrl);

    function EditFileCtrl(
        textResolve,
        hashTagsResolve,
        $uibModalInstance,
        $scope,
        $translate
    ) {
        var vm = this;
        vm.name = textResolve;
        vm.hashTags = hashTagsResolve;
        vm.oldHashTags;
        vm.confirm = confirm;
        vm.reject = reject;
        vm.addHashTag = addHashTag;
        vm.removeHashTag = removeHashTag;
        vm.renameText = "Rename:";
        vm.hashTagsText = "Add Hash Tag:";
        vm.currentTagsText = "Current Tags:";
        vm.textBoxText = "";
        vm.add = "Add";
        activate();

        function activate() {
            $translate("Edit").then(function(translation) {
                vm.titleText = "Edit " + vm.name + ":";
            });
            vm.oldHashTags = [...vm.hashTags];
        }

        function reject() {
            vm.hashTags = vm.oldHashTags;
            $uibModalInstance.close({ confirmed: false });
        }

        function confirm() {
            $uibModalInstance.close({
                confirmed: true,
                name: vm.textBoxText,
                hash_tags: vm.hashTags
            });
        }

        function addHashTag() {
            var tagAlreadyExists = false;
            _.forEach(vm.hashTags, function(tag) {
                if (tag === vm.hashTag) {
                    tagAlreadyExists = true;
                }
            });
            if (tagAlreadyExists === false) {
                vm.hashTags.push(vm.hashTag);
            }
        }

        function removeHashTag(tagToRemove) {
            _.remove(vm.hashTags, function(tag) {
                return tagToRemove === tag;
            });
        }

        $scope.$on("$locationChangeStart", function() {
            $uibModalInstance.close({ confirmed: false });
        });
    }
})();
