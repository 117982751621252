(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("BlogEditCtrl", BlogEditCtrl);

    function BlogEditCtrl(
        $uibModal,
        $state,
        $stateParams,
        $translate,
        Analytics,
        Blog,
        blogProfileResolve,
        CurrentUser,
        WallPost
    ) {
        /*jshint validthis: true */
        var vm = this;
        vm.postData = blogProfileResolve;
        vm.navigateToMyBlogs = navigateToMyBlogs;
        vm.navigateToThisBlog = navigateToThisBlog;
        vm.setBlogStatus = setBlogStatus;

        activate();

        function activate() {
            vm.id = $stateParams.id;
            vm.location = { id: vm.id, type: WallPost.LOCATION_TYPE.BLOG };
        }

        function navigateToMyBlogs() {
            $state.go("main.blogs.mine", { id: CurrentUser.profile.id });
        }

        function navigateToThisBlog() {
            $state.go("main.blogs.show", { id: vm.postData.id });
        }

        function setBlogStatus(status) {
            if (status === Blog.BLOG_STATUS.DELETED) {
                var modalInstance = confirmDeleteModal(
                    "ARE_YOU_SURE.DELETE_BLOG_WARNING"
                );
                modalInstance.result.then(function(response) {
                    if (response.confirmed) {
                        vm.postData.status = status;
                        Analytics.eventTrack("deleteBlog", {
                            category: "Blogs",
                            label:
                                "id:" +
                                vm.postData.id +
                                ", author: " +
                                vm.postData.author.id
                        });
                        Analytics.eventTrack2('delete_blog', {
                            category: "Blogs",
                            label: "id:" +
                                vm.postData.id +
                                ", author: " +
                                vm.postData.author.id
                        });

                        if (vm.postData.author.id !== CurrentUser.profile.id) {
                            Analytics.eventTrack("deleteBlogAsDelegate", {
                                category: "Delegation",
                                label:
                                    "id:" +
                                    vm.postData.id +
                                    ", author: " +
                                    vm.postData.author.id
                            });
                            Analytics.eventTrack2('delete_blog_as_delegate', {
                                category: "Delegation",
                                label: "id:" +
                                    vm.postData.id +
                                    ", author: " +
                                    vm.postData.author.id
                            });
                        }
                    }
                });
            } else {
                vm.postData.status = status;
            }
        }

        function confirmDeleteModal(confirmText) {
            return $uibModal.open({
                templateUrl: "components/modal/areYouSure/areYouSure.html",
                controller: "AreYouSureCtrl as vm",
                resolve: {
                    textResolve: function() {
                        return $translate(confirmText);
                    }
                }
            });
        }
    }
})();
