(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.layout").controller("MainCtrl", MainCtrl);

    function MainCtrl() {

        var rootElement = document.documentElement;
        var topButton = document.querySelector(".top-button");
        var vm = this;
        vm.scrollToTop = scrollToTop;

        document.addEventListener("scroll", showTopButton);

        function scrollToTop() {
            rootElement.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }

        function showTopButton() {
            var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
            if ((rootElement.scrollTop / scrollTotal ) > 0.50) {
                topButton.classList.add("show-top-button")
            } else {
                topButton.classList.remove("show-top-button")
            }
        }
    }
})();
