(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.wallPost.service", ["ssmAngularApp.api"])
        .factory("WallPost", WallPost);

    function WallPost(Api) {
        var posts = Api.all("posts");
        return {

            LOCATION_TYPE: {
                UNSUPPORTED: 0,
                USER: 1,
                COMMUNITY: 2,
                BLOG: 3,
                POST: 4
            },

            POST_AS_TYPE: { 
                UNSUPPORTED: 0, 
                USER: 1, 
                COMMUNITY: 2 
            },

            all: function(limit) {
                return posts.getList(limit);
            },

            create: function(post) {
                return posts.post(post);
            },

            find: function(postId) {
                return posts.get(postId, { format: "enhanced" });
            },

            update: function(postId, updatedPost) {
                return posts.one(postId).customPUT(updatedPost);
            },

            delete: function(postId) {
                return posts.one(postId).remove();
            },

            approve: function(postId) {
                return posts
                    .one(postId)
                    .one("approve")
                    .put();
            },

            like: function(postId) {
                return posts
                    .one(postId)
                    .all("likes")
                    .post();
            },

            allLikes: function(postId) {
                return posts
                    .one(postId)
                    .all("likes")
                    .getList();
            },

            unLike: function(postId) {
                return posts
                    .one(postId)
                    .one("likes", "mine")
                    .remove();
            },

            getSharedContent: function(postId) {
                return this.find(postId);
            },

            checkIfLiked: function(postId) {
                return posts
                    .one(postId)
                    .all("likes")
                    .get("length");
            },

            updatePoll: function(postId, updatedPoll) {
                return posts.one(postId, "poll").customPUT(updatedPoll);
            },

            vote: function(postId, choice_id) {
                return posts
                    .one(postId, "poll")
                    .one(choice_id)
                    .post();
            },

            updateComment: function(postId, updatedComment) {
                const commentId = updatedComment.id;
                delete updatedComment.id;
                return posts
                    .one(postId, "comments")
                    .one(commentId)
                    .customPUT(updatedComment);
            }

        };
    }
})();
