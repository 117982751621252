(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmNotificationItem")
        .directive("ssmNotificationItem", ssmNotificationItem);

    function ssmNotificationItem() {
        return {
            templateUrl:
                "components/ssmNotificationItem/ssmNotificationItem.html",
            restrict: "E",
            replace: true,
            scope: {
                data: "=",
                onVisit: "&",
                onIgnore: "&",
                notificationBadges: "="
            },
            controller: ssmNotificationItemCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmNotificationItemCtrl(Analytics, CurrentUser, Notification) {
        /*jshint validthis:true */

        var vm = this;
        vm.currentUser = CurrentUser.profile;
        vm.notificationIgnoreClick = notificationIgnoreClick;
        vm.notificationItemClick = notificationItemClick;

        function notificationIgnoreClick(e, notification) {
            Analytics.eventTrack("notification.ignore", {
                category: "mainHeaderCtrl",
                label: "type:" + notification.type
            });
            Analytics.eventTrack2('notification_ignore', {
                category: "mainHeaderCtrl",
                label: "type:" + notification.type,
            });

            if (vm.onIgnore) {
                vm.onIgnore({ e: e, notification: notification });
            }
        }

        function notificationItemClick(notification) {
            Analytics.eventTrack("notification.click", {
                category: "ssmNotificationItem",
                label: "type:" + notification.type
            });
            Analytics.eventTrack2('notification_click', {
                category: "ssmNotificationItem",
                label: "type:" + notification.type,
            });

            var filteredContentBadgeItems;
            if (notification.hidden !== true && notification.notification_id) {
                filteredContentBadgeItems = _.filter(
                    vm.notificationBadges,
                    function(badgeId) {
                        return badgeId !== notification.notification_id;
                    }
                );
                vm.notificationBadges = filteredContentBadgeItems;
            }

            Notification.reviewNotification(vm.currentUser.id, notification);

            if (vm.onVisit) {
                vm.onVisit({ notification: notification });
            }
        }
    }
})();
