(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.ssmCreateComment")
        .directive("ssmCreateComment", ssmCreateComment)
        .controller("ssmCreateCommentCtrl", ssmCreateCommentCtrl);

    function ssmCreateComment() {
        return {
            templateUrl: "components/ssmCreateComment/ssmCreateComment.html",
            restrict: "E",
            scope: {
                postData: "=",
                parent: "=",
                location: "=",
                collection: "="
            },
            controller: ssmCreateCommentCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmCreateCommentCtrl(
        $translate,
        Analytics,
        Audience,
        Blog,
        Community,
        Cookie,
        CurrentUser,
        Logger,
        PostComments,
        Tenant,
        WallPost,
        SSMInlineContentHelper,
        ssmLocalStorage,
        $uibModal
    ) {
        /*jshint validthis: true */
        var vm = this;

        var dimension2 = vm.location 
            && vm.location.id ? vm.location.id : "Unknown";

        var dimension4 = vm.postData 
            && vm.postData.location 
            && vm.postData.location.id ? vm.postData.location.id : "Unknown";

        var communityForPost = null;
        vm.commentPlaceholder = $translate.instant("WALL.COMMENT_PLACEHOLDER");

        vm.POST_AS_TYPE = WallPost.POST_AS_TYPE;

        vm.addComment = addComment;
        vm.newCommentMessage = "";
        vm.guestCommentDisable = false;
        vm.canCommentAsCommunity = false;

        activate();

        function activate() {
            vm.currentUser = CurrentUser.profile;
            vm.actionName = vm.parent ? "WALL.REPLY_ACTION" : "WALL.COMMENT_ACTION";

            Tenant.getTenant().then(function(tenant) {
                vm.guestCommentDisable = tenant.flags.guest_comment_disable;
            });

            if (vm.postData.location 
                && vm.postData.location.type 
                && vm.postData.location.id
            ) {
                switch (vm.postData.location.type) {
                    case WallPost.LOCATION_TYPE.COMMUNITY:
                        Community.getCommunity(vm.postData.location.id, true)
                        .then(function(community) {
                            communityForPost = community;
                            // TODO this should be hydrated into comment.community.url_name on the back end
                            vm.community_name_url = community.url_name;

                            vm.canCommentAsCommunity = _.includes(
                                community.admins,
                                vm.currentUser.id
                            );
                        });
                        break;
                    default:
                        vm.canCommentAsCommunity = false;
                        break;
                }
            } else {
                vm.canCommentAsCommunity = false;
            }

            var guestComment = ssmLocalStorage.get(
                ssmLocalStorage.key.GUEST_COMMENT
            );
            var guestPostID = ssmLocalStorage.get(ssmLocalStorage.key.POST_ID);
            if (guestComment != undefined && vm.postData.id == guestPostID) {
                vm.newCommentMessage = guestComment;
                ssmLocalStorage.remove(ssmLocalStorage.key.GUEST_COMMENT);
                ssmLocalStorage.remove(ssmLocalStorage.key.POST_ID);
                vm.postData.focusComment = true;
            }
        }

        function addComment(text, postAsType) {
            Analytics.eventTrack("addComment", {
                category: "ssmCreateCommentCtrl",
                label: "post_id:" + vm.postData.id,
                dimension2: dimension2,
                dimension4: dimension4
            });
            Analytics.eventTrack2('add_comment', {
                category: "ssmCreateCommentCtrl",
                label: "post_id:" + vm.postData.id,
                dimension2: dimension2,
                dimension4: dimension4
            });

            var emailAddress = _.head(vm.currentUser.emails).email;

            vm.commentFocus = false;

            var hasComment = Cookie.read("guestCommentCookie");

            const apa_org_sso_guest_user_id = "5fca5fc58b9f7c1703844f1d";
            if (CurrentUser.profile.id == apa_org_sso_guest_user_id
                || CurrentUser.profile.is_guest 
                    && (hasComment == "true" || vm.guestCommentDisable == true)
            ) {
                ssmLocalStorage.set(ssmLocalStorage.key.GUEST_COMMENT, text);
                ssmLocalStorage.set(ssmLocalStorage.key.POST_ID, vm.postData.id);
                guestRegistration();
                return;
            }

            Cookie.create("guestCommentCookie", "true", 100);

            if (text) {
                var comment = {
                    parent_id: _.get(vm.parent, "id"),
                    message: SSMInlineContentHelper.sanitizeUserInput(text)
                };
                switch (vm.collection) {
                    case "posts":
                        if (postAsType === vm.POST_AS_TYPE.COMMUNITY) {
                            comment.community = {
                                posted_as_community: true,
                                community_id: vm.postData.location.id
                            };
                        }
                        PostComments.addComment(vm.postData.id, comment)
                            .then(function(data) {
                                vm.newCommentMessage = "";
                                var inline_hash_tags = [];
                                if (vm.parent) {
                                    inline_hash_tags = vm.parent.inline_hash_tags;
                                } else {
                                    inline_hash_tags = vm.postData.inline_hash_tags;
                                }

                                if (CurrentUser.profile.is_guest) {
                                    var lastComment = _.last(data.comments);
                                    Cookie.create("guestCommentId", lastComment.id, 100);
                                    Cookie.create("guestPostId", vm.postData.id, 100);
                                    Cookie.create("guestCommentType", "posts", 100);
                                }

                                Tenant.getTenant().then(function(tenant) {
                                    Audience.updateHashTags(
                                        tenant.organization_id,
                                        emailAddress,
                                        inline_hash_tags
                                    );
                                });
                                angular.merge(vm.postData, data); // need to perserve stickied flag
                                if (vm.limit) {
                                    vm.limit++;
                                }
                                if (vm.parent) {
                                    vm.parent.addingChild = false;
                                }
                            })
                            .catch(function(error) {
                                if (error.data 
                                    && error.data.message === "guest_user"
                                ) {
                                    return;
                                }
                                $translate("WALL.COMMENT_ERROR").then(function(translation) {
                                    Logger.error("ERROR: ", translation, error);
                                });
                            });
                        break;
                    case "blogs":
                        Blog.addComment(vm.postData.id, comment)
                            .then(function(data) {
                                vm.newCommentMessage = "";
                                angular.merge(vm.postData, data); // need to perserve stickied flag
                                if (vm.limit) {
                                    vm.limit++;
                                }
                                if (vm.parent) {
                                    vm.parent.addingChild = false;
                                }

                                if (CurrentUser.profile.is_guest) {
                                    var lastComment = _.last(data.comments);
                                    Cookie.create("guestCommentId", lastComment.id, 100);
                                    Cookie.create("guestPostId", vm.postData.id, 100);
                                    Cookie.create("guestCommentType", "blogs", 100);
                                }
                            })
                            .catch(function(error) {
                                $translate("WALL.COMMENT_ERROR").then(function(translation) {
                                    Logger.error("ERROR: ", translation, error);
                                });
                            });
                        break;
                }
            }
        }

        function guestRegistration() {
            var postInfo = {};
            var modalDeclaration = {
                templateUrl: "components/modal/guestRegistration/guestRegistration.html",
                controller: "guestRegistrationController as guestRegistration",
                controllerAs: "vm",
                resolve: {
                    postInfo: function() {
                        return postInfo;
                    }
                }
            };

            $uibModal.open(modalDeclaration);
        }
    }
})();
