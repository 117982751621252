(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").directive("signup", signup);

    function signup() {
        return {
            templateUrl: "layout/signup/signup.html",
            restrict: "E",
            scope: {
                searchParams: "="
            },
            replace: true,
            controller: SignupCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function SignupCtrl(
        Analytics,
        Blog,
        Cookie,
        CurrentUser,
        ExternalInvites,
        PostComments,
        $scope,
        ssmAuth,
        SSMTitle,
        $state,
        Tenant,
        $translate,
        $window
    ) {
        var vm = this;
        vm.login = login;
        vm.signup = signup;
        vm.linkedInSignin = linkedInSignin;
        vm.user = {};
        vm.simpleRegistration = false;
        vm.isSignedIn = CurrentUser.profile.id;
        vm.enableAgreements = false;
        vm.disablePhi = false;
        vm.acceptedEula = false;
        vm.acceptedPhi = false;
        vm.institutionRequired = false;
        vm.sso_button;

        activate();

        function activate() {
            Tenant.getTenant().then(tenant => {
                vm.logoUrl = tenant.logo_url;
                vm.simpleRegistration = tenant.flags.open_registration;
                vm.guestRegisterPrompt = tenant.guest_register_prompt;
                vm.enableAgreements = tenant.flags.simple_registration;
                vm.disablePhi = tenant.flags.phi_agreement_disabled;
                vm.institutionRequired = tenant.flags.institution_isRequired;
                vm.organizationId = tenant.organization_id;

                vm.user.simple_registration = tenant.flags.simple_registration;
                vm.sso_button = tenant.sso_button;
            });

            ExternalInvites.getInvite($state.params.id).then(function(invite) {
                if (invite) {
                    vm.user.administrative_options = JSON.parse(
                        invite.registration_data
                    );

                    vm.user.email = invite.email;
                    vm.user.first_name =
                        vm.user.administrative_options.firstName;
                    vm.user.last_name = vm.user.administrative_options.lastName;
                    vm.inviteExists = true;
                }
            });

            setTranslatedTitle();
            setTranslatedLoadingMessage();
        }

        function setTranslatedTitle() {
            $translate("TITLE.REGISTRATION").then(function(title) {
                SSMTitle.setTitle(title);
            });
        }

        function setTranslatedLoadingMessage() {
            $translate("LOGIN.REGISTERING_USER").then(function(translation) {
                vm.loadingMessage = translation;
            });
        }

        function login() {
            $scope.$emit("signupClicked");
        }

        function signup(form) {
            if (!form.$valid) {
                handleIrregularForm(form);
                return;
            }

            vm.errorText = "";
            vm.isLoading = true;
            ssmAuth
                .signup(vm.user)
                .then(onLoginSuccess)
                .catch(onLoginFailed);
        }

        function handleIrregularForm(form) {
            let error;
            if (form.phiAgreement && form.phiAgreement.$invalid) {
                error = "LOGIN.ERROR.PHI_USER_AGREEMENT";
            } 
            else if (form.userAgreement && form.userAgreement.$invalid) {
                error = "LOGIN.ERROR.END_USER_AGREEMENT";
            } 
            else {
                error = "LOGIN.ERROR.NOT_VALID_ENTRY";
            }
            $translate(error).then(function(translation) {
                vm.errorText = translation;
            });
        }

        function onLoginSuccess(user) {
            $scope.$emit("signupClicked");
            CurrentUser.refresh().then(function(result) {
                vm.isLoading = false;
                let registration = {
                    category: "RegistrationCtrl",
                    label: "user_id:" + CurrentUser.profile.id
                };
                Analytics.eventTrack("RegistrationStarted", registration);
                Analytics.eventTrack2("registration_started", registration);
                Analytics.trackUTMCampaign($state.params, "UTMSignUp", "SignUpCtrl");

                if (vm.inviteExists) {
                    let external_invite = {
                        category: "ExternalInvitesCtrl",
                        label: "user_id:" + CurrentUser.profile.id
                    };
                    Analytics.eventTrack("ExternalInviteSignUp", external_invite);
                    Analytics.eventTrack2("external_invite_signup", external_invite);
                }

                updateCommentIfGuestSignsUp();

                if (vm.user.simple_registration) {
                    $window.location.href = vm.user.final_redirect_uri || "/";
                } else {
                    $state.go("registration");
                }
            });
        }

        function onLoginFailed(err) {
            switch (err.status) {
                case 0:
                    $translate("ERROR.SERVER_DOWN_FOR_MAINTENANCE").then(function(translation) {
                        vm.errorText = translation;
                    });
                    break;
                case 409:
                    $translate("REGISTRATION.ERROR.EMAIL_ALREADY_IN_USE").then(function(translation) {
                        vm.errorText = translation;
                    });
                    break;
                default:
                    $translate("LOGIN.ERROR.NOT_VALID_ENTRY").then(function(translation) {
                        vm.errorText = translation;
                    });
                    break;
            }
            vm.isLoading = false;
        }

        function linkedInSignin() {
            ssmAuth
                .linkedInLogin()
                .then(onLoginSuccess)
                .catch(onLoginFailed);
        }

        function updateCommentIfGuestSignsUp() {
            var guestCommentId = Cookie.read("guestCommentId");
            var guestPostId = Cookie.read("guestPostId");
            var guestCommentType = Cookie.read("guestCommentType");

            if (guestCommentId && guestPostId) {
                if (guestCommentType == "posts") {
                    PostComments.updateGuestComment(
                        guestPostId,
                        guestCommentId
                    );
                } else if (guestCommentType == "blogs") {
                    Blog.updateGuestComment(guestPostId, guestCommentId);
                }
            }

            Cookie.remove("guestCommentId");
            Cookie.remove("guestPostId");
            Cookie.remove("guestCommentType");
        }
    }
})();
