(function() {
    "use strict";

    angular
        .module("ssmAngularApp.externalInvites.service", ["ssmAngularApp.api"])
        .service("ExternalInvites", ExternalInvites);

    function ExternalInvites($q, Api, CurrentUser) {
        var externalInvites = Api.all("invites");

        var PAGE_LIMIT = 20;

        return {
            createInvites: function(params) {
                return externalInvites.all("external").post(params);
            },
            getAccepted: function(id) {
                return externalInvites
                    .all("external")
                    .all(id)
                    .all("accepted")
                    .getList();
            },
            getInvite: function(id) {
                return externalInvites.one("externals", id).get();
            },
            getPending: function(id) {
                return externalInvites
                    .all("external")
                    .all(id)
                    .all("pending")
                    .getList();
            },
            pendingPaged: function(limit) {
                var paging = {
                    pendingInvites: [], // previously retrieved pendingInvites
                    disabled: false, // cannot retrieve more if we are already waiting or there are no more to get
                    limit: limit ? limit : PAGE_LIMIT,
                    next: null
                };

                paging.next = function() {
                    if (paging.disabled) {
                        return $q.when(paging);
                    }

                    paging.disabled = true;
                    var query = {
                        skip: paging.pendingInvites.length,
                        limit: paging.limit
                    };
                    return externalInvites
                        .all("external")
                        .all(CurrentUser.profile.id)
                        .all("pending")
                        .getList(query)
                        .then(function(pendingInvites) {
                            var formattedInvites = _.map(
                                pendingInvites,
                                function(invite) {
                                    invite.create_date = new Date(
                                        invite.create_date
                                    ).toLocaleDateString();
                                    return invite;
                                }
                            );

                            // if we got more pendingInvites, add them to our list and enable paging
                            if (pendingInvites && pendingInvites.length) {
                                paging.disabled = false;
                                paging.pendingInvites = _.union(
                                    paging.pendingInvites,
                                    formattedInvites
                                );
                            }

                            return $q.when(paging);
                        });
                };

                return paging.next();
            },
            acceptedPaged: function(limit) {
                var paging = {
                    acceptedInvites: [], // previously retrieved acceptedInvites
                    disabled: false, // cannot retrieve more if we are already waiting or there are no more to get
                    limit: limit ? limit : PAGE_LIMIT,
                    next: null
                };

                paging.next = function() {
                    if (paging.disabled) {
                        return $q.when(paging);
                    }

                    paging.disabled = true;
                    var query = {
                        skip: paging.acceptedInvites.length,
                        limit: paging.limit
                    };
                    return externalInvites
                        .all("external")
                        .all(CurrentUser.profile.id)
                        .all("accepted")
                        .getList(query)
                        .then(function(accepted) {
                            // if we got more acceptedInvites, add them to our list and enable paging
                            if (accepted && accepted.length) {
                                paging.disabled = false;
                                paging.acceptedInvites = _.union(
                                    paging.acceptedInvites,
                                    accepted
                                );
                            }

                            return $q.when(paging);
                        });
                };

                return paging.next();
            }
        };
    }
})();
