(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider
            .state("aehiaauth", {
                url: "/auth/login/aehia/callback",
                controller: "AEHIXAuthCtrl",
                controllerAs: "aehixauth"
            })
            .state("aehisauth", {
                url: "/auth/login/aehis/callback",
                controller: "AEHIXAuthCtrl",
                controllerAs: "aehixauth"
            })
            .state("aehitauth", {
                url: "/auth/login/aehit/callback",
                controller: "AEHIXAuthCtrl",
                controllerAs: "aehixauth"
            });
    });
})();
