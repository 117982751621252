(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmCommunityProfile")
        .directive("ssmCommunityProfile", ssmCommunityProfile)
        .controller("ssmCommunityProfileCtrl", ssmCommunityProfileCtrl);

    function ssmCommunityProfile() {
        return {
            templateUrl:
                "components/ssmCommunityProfile/ssmCommunityProfile.html",
            restrict: "E",
            replace: true,
            scope: {
                communityData: "="
            },
            controller: ssmCommunityProfileCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmCommunityProfileCtrl() {
        /*jshint validthis:true */
        var vm = this;
    }
})();
