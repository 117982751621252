(function() {
    "use strict";

    angular
        .module("ssmAngularApp.guestRegistration")
        .controller("guestRegistrationController", guestRegistrationController);

    function guestRegistrationController(
        Tenant, 
        $uibModalInstance, 
        $scope
    ) {
        var vm = this;
        
        vm.logoUrl = null;
        vm.shouldDisableLoginAndSignupOptions;
        vm.closeModal = closeModal;
        setLogo();
        determineIfShouldDisableLoginAndSignupOptions();
        $scope.$on("signupClicked", closeModal);


        function setLogo() {
            Tenant.getTenant().then(function(tenant) {
                vm.logoUrl = tenant.logo_url;
            });
        }

        function determineIfShouldDisableLoginAndSignupOptions() {
            Tenant.getTenant().then(function(tenant) {
                vm.shouldDisableLoginAndSignupOptions = tenant.flags.disable_login_and_signup_options;
            });
        }

        function closeModal() {
            $uibModalInstance.close();
        }
    }
})();
