(function() {
    "use strict";

    angular.module("ssmAngularApp.marketplaceContact", [
        "pascalprecht.translate",
        "ssmAngularApp.currentUser",
        "ui.bootstrap",
        "ssmAngularApp.alert.service"
    ]);
})();
