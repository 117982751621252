(function() {
    "use strict";

    angular
        .module("ssmAngularApp.createResponse")
        .controller("createResponseController", createResponseController);

    function createResponseController($uibModalInstance, content_item) {
        /*jshint validthis:true */
        var vm = this;

        vm.content_item = content_item;

        vm.closeModal = closeModal;

        function closeModal() {
            $uibModalInstance.close();
        }
    }
})();
