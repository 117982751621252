(function() {
    "use strict";

    angular.module("ssmAngularApp.wallPost", [
        "ssmAngularApp.currentUser",
        "ssmAngularApp.api",
        "ssmAngularApp.wallPost.service",
        "ssmAngularApp.photoGallery",
        "ssmAngularApp.fileGallery",
        "ui.bootstrap"
    ]);
})();
