(function() {
    "use strict";

    angular.module("ssmAngularApp.ssmDelegationPost", [
        "ssmAngularApp.wallPost",
        "ssmAngularApp.currentUser",
        "pascalprecht.translate",
        "ssmAngularApp.ssmWallPoll"
    ]);
})();
