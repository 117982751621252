(function() {
    "use strict";

    angular.module("ssmAngularApp.layout", [
        "ct.ui.router.extras.core",
        "ct.ui.router.extras.future",
        "oc.lazyLoad",
        "infinite-scroll",
        "isteven-multi-select",
        "ngFileSaver",
        "ssmAngularApp.alert.service",
        "ssmAngularApp.analytics",
        "ssmAngularApp.community",
        "ssmAngularApp.cookie",
        "ssmAngularApp.core",
        "ssmAngularApp.currentUser",
        "ssmAngularApp.delegation.service",
        "ssmAngularApp.logger",
        "ssmAngularApp.tenant.service",
        "ssmAngularApp.ssmDelegationBlog",
        "ssmAngularApp.ssmContainerClasses",
        "ssmAngularApp.ssmTitle",
        "ssmAngularApp.vendor.service",
        "ssmAngularApp.vendorpermissions.service"
    ]);
})();
