(function() {
    "use strict";

    angular
        .module("ssmAngularApp.marketplace.services")
        .service("ssmVendors", ssmVendors);

    function ssmVendors(ActiveAngular) {
        return new ActiveAngular("marketplace_partners/:id");
    }
})();
