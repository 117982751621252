(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmMarketplace.service")
        .factory("ssmMarketplaceApi", ssmMarketplaceApi);

    function ssmMarketplaceApi(Api, SSM_MARKETPLACE) {
        var marketplaceApi = Api.all("marketplace");
        var marketplaceMessagesApi = Api.all("marketplace_messages");
        var marketplaceImportApi = Api.all("marketplace_imports");
        var marketplaceListingApi = Api.all("marketplace_campaigns");
        var marketplacePartnerApi = Api.all("marketplace_partners");
        var marketplacePermissionsApi = Api.all("marketplace_permissions");
        var editable_marketplacesApi = Api.all("marketplace_admin/editable_marketplaces");

        var api = {
            addMarketplacePermissionsForUser: addMarketplacePermissionsForUser,
            createMarketplace: createMarketplace,
            createMarketplaceCategory: createMarketplaceCategory,
            createMarketplaceListing: createMarketplaceListing,
            createMarketplaceMessage: createMarketplaceMessage,
            createMarketplaceOrganization: createMarketplaceOrganization,
            createMarketplacePartner: createMarketplacePartner,
            getMarketplace: getMarketplace,
            getMarketplaceCategories: getMarketplaceCategories,
            getMarketplaceListing: getMarketplaceListing,
            getAllContentForListing: getAllContentForListing,
            getMarketplaceListings: getMarketplaceListings,
            getMarketplacePartner: getMarketplacePartner,
            getMarketplacePartners: getMarketplacePartners,
            getMarketplacePermissions: getMarketplacePermissions,
            getMarketplacePermissionsForUser: getMarketplacePermissionsForUser,
            getMarketplaces: getMarketplaces,
            queueImport: queueImport,
            getMarketplaceImports: getMarketplaceImports,
            removeMarketplacePermissionsForUser: removeMarketplacePermissionsForUser,
            updateMarketplace: updateMarketplace,
            updateMarketplaceCategory: updateMarketplaceCategory,
            updateMarketplaceListing: updateMarketplaceListing,
            updateMarketplacePartner: updateMarketplacePartner,
            getEditableMarketplaces: getEditableMarketplaces
        };

        return api;

        function getEditableMarketplaces() {
            return editable_marketplacesApi.getList();
        }

        function createMarketplaceMessage(message) {
            return marketplaceMessagesApi
                .post(message)
                .then(function(response) {
                    return response.plain();
                });
        }

        // TODO: (STUB) This is in progress and will need to be updated once the backend service is changed
        function addMarketplacePermissionsForUser(userId) {
            return marketplacePermissionsApi
                .one(userId)
                .customPUT()
                .then(function(response) {
                    return response.plain();
                });
        }

        function createMarketplace(marketplace) {
            return marketplaceApi.post(marketplace).then(function(response) {
                return response.plain();
            });
        }

        function createMarketplaceCategory(marketplaceId, category) {
            return marketplaceApi
                .one(marketplaceId)
                .all("categories")
                .customPUT(category)
                .then(function(response) {
                    return response.plain();
                });
        }

        function createMarketplaceListing(marketplaceId, listing) {
            return marketplaceApi
                .one(marketplaceId)
                .all("marketplace_campaigns")
                .post(listing)
                .then(function(response) {
                    return response.plain();
                });
        }

        function createMarketplaceOrganization(marketplaceId, organization) {
            return marketplaceApi
                .one(marketplaceId)
                .all("organizations")
                .post(organization)
                .then(function(response) {
                    return response.plain();
                });
        }

        function createMarketplacePartner(marketplaceId, partner) {
            return marketplaceApi
                .one(marketplaceId)
                .all("marketplace_partners")
                .post(partner)
                .then(function(response) {
                    return response.plain();
                });
        }

        function getMarketplace(marketplaceId, query) {
            return marketplaceApi
                .one(marketplaceId)
                .get(query)
                .then(function(response) {
                    return response.plain();
                });
        }

        function getMarketplaceCategories(marketplaceId, query) {
            return marketplaceApi
                .one(marketplaceId)
                .all("categories")
                .getList(query)
                .then(function(response) {
                    return response.plain();
                });
        }

        function getMarketplaceListing(listingId, query) {
            return marketplaceListingApi
                .one(listingId)
                .get(query)
                .then(function(response) {
                    return response.plain();
                });
        }

        function getAllContentForListing(listingId, query) {
            return marketplaceListingApi
                .one(listingId)
                .all("content")
                .getList(query)
                .then(function(response) {
                    return response.plain();
                });
        }

        function getMarketplaceListings(marketplaceId, query) {
            return marketplaceApi
                .one(marketplaceId)
                .customGET("marketplace_campaigns", query)
                .then(function(response) {
                    return response.plain();
                });
        }

        function getMarketplacePartner(partnerId) {
            return marketplacePartnerApi
                .one(partnerId)
                .get()
                .then(function(response) {
                    return response.plain();
                });
        }

        function getMarketplacePartners(marketplaceId, query) {
            return marketplaceApi
                .one(marketplaceId)
                .all("marketplace_partners")
                .getList(query)
                .then(function(response) {
                    return response.plain();
                });
        }

        // TODO: (STUB) This is in progress and will need to be updated once the backend service is changed
        function getMarketplacePermissions() {
            return marketplacePermissionsApi.customGET();
        }

        // TODO: (STUB) This is in progress and will need to be updated once the backend service is changed
        function getMarketplacePermissionsForUser(userId) {
            return marketplacePermissionsApi
                .one(userId)
                .get()
                .then(function(response) {
                    return response.plain();
                });
        }

        function getMarketplaces(organizationId, query) {
            return marketplaceApi
                .one("organization", organizationId)
                .getList(query)
                .then(function(response) {
                    return response.plain();
                });
        }

        function queueImport(data) {
            return marketplaceImportApi.post(data);
        }

        function getMarketplaceImports(query) {
            return marketplaceImportApi.getList(query);
        }

        // TODO: (STUB) This is in progress and will need to be updated once the backend service is changed
        function removeMarketplacePermissionsForUser(userId) {
            return marketplacePermissionsApi
                .one(userId)
                .remove()
                .then(function(response) {
                    return response.plain();
                });
        }

        function updateMarketplace(marketplaceId, marketplace) {
            return marketplaceApi
                .one(marketplaceId)
                .patch(marketplace)
                .then(function(response) {
                    return response.plain();
                });
        }

        function updateMarketplaceCategory(
            marketplaceId,
            categoryId,
            category
        ) {
            return marketplaceApi
                .one(marketplaceId)
                .one("categories", categoryId)
                .patch(category)
                .then(function(response) {
                    return response.plain();
                });
        }

        function updateMarketplaceListing(listingId, listing) {
            return marketplaceListingApi
                .one(listingId)
                .patch(listing)
                .then(function(response) {
                    return response.plain();
                });
        }

        function updateMarketplacePartner(partnerId, partner) {
            return marketplacePartnerApi
                .one(partnerId)
                .patch(partner)
                .then(function(response) {
                    return response.plain();
                });
        }
    }
})();
