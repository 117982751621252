(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.CommunityMembership.service", [
            "ssmAngularApp.api",
            "ssmAngularApp.currentUser"
        ])
        .factory("CommunityMembership", CommunityMembership);

    function CommunityMembership(Api, CurrentUser) {
        var userApi = Api.all("user");

        return {
            MEMBERSHIP_TYPE: { MEMBER: 0, INVITED: 1 },
            getAll: function(query) {
                //query : {skip:number, limit:number}
                return userApi
                    .one(CurrentUser.profile.id)
                    .all("follows")
                    .getList(query);
            },

            getPendingFollows: function(query) {
                return userApi
                    .one(CurrentUser.profile.id)
                    .all("pending-follows")
                    .getList(query);
            },

            createMembership: function(
                community_id,
                hide_in_feed,
                disable_notifications,
                pending
            ) {
                var params = {
                    community_id: community_id
                };
                if (hide_in_feed !== null) {
                    params["hide_in_feed"] = hide_in_feed;
                }
                if (disable_notifications !== null) {
                    params["disable_notifications"] = disable_notifications;
                }
                if (pending !== null) {
                    params["pending"] = pending;
                }
                return userApi
                    .one(CurrentUser.profile.id)
                    .all("follows")
                    .post(params);
            },

            endMembership: function(community_id) {
                return userApi
                    .one(CurrentUser.profile.id)
                    .all("follows")
                    .one(community_id)
                    .remove();
            },

            setPendingFollow: function(is_accepted, user_id, community_id) {
                var params = {
                    is_accepted: is_accepted
                };
                return userApi
                    .one(user_id)
                    .all("pending-follows")
                    .all(community_id)
                    .post(params);
            }
        };
    }
})();
