(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .factory("communityRoutesHelper", communityRoutesHelper)
        .config(function($stateProvider, $urlRouterProvider) {
            $stateProvider
                .state("main.communities_index", {
                    url: "/communities",
                    templateUrl:
                        "layout/main/community/communityIndex/communityIndex.html",
                    controller: "CommunityIndexCtrl",
                    controllerAs: "communities",
                    data: {
                        analyticsName: "CommunityDirectory"
                    }
                })
                .state("main.communities_following", {
                    url: "/communities/following",
                    templateUrl:
                        "layout/main/community/communityIndex/communityIndex.html",
                    controller: "CommunityIndexCtrl",
                    controllerAs: "communities",
                    data: {
                        analyticsName: "MyCommunities"
                    }
                })
                .state("main.communities_edit", {
                    url: "/communities/{url_name}/{id}/edit",
                    templateUrl:
                        "layout/main/community/communityEdit/communityEdit.html",
                    controller: "CommunitiesEditCtrl",
                    controllerAs: "vm",
                    resolve: {
                        communityProfileResolve: function(
                            communityRoutesHelper,
                            $stateParams,
                            $translate,
                            Logger
                        ) {
                            return communityRoutesHelper.communityProfileResolve(
                                $stateParams,
                                $translate,
                                Logger
                            );
                        }
                    }
                })

                .state("main.community_advertisements", {
                    url: "/community/{id}/advertisements",
                    templateUrl: "components/bannerAdModifier/bannerAdModifier.html",
                    controller: "BannerAdModifierController",
                    controllerAs: "adModifier",
                })

                .state("main.communities_create", {
                    url: "/communities/create",
                    templateUrl:
                        "layout/main/community/communityEdit/communityEdit.html",
                    controller: "CommunitiesEditCtrl",
                    controllerAs: "vm",
                    resolve: {
                        // community create has no previous profile to get, but this is needed or the controller can't resolve the dependency.
                        communityProfileResolve: function() {
                            return null;
                        }
                    }
                })
                .state("main.communities_followers", {
                    url: "/communities/{url_name}/{id}/followers?{pending}",
                    templateUrl:
                        "layout/main/community/communityFollowers/communityFollowers.html",
                    controller: "CommunityFollowersCtrl",
                    controllerAs: "vm",
                    resolve: {
                        communityProfileResolve: function(
                            communityRoutesHelper,
                            $stateParams,
                            $translate,
                            Logger
                        ) {
                            return communityRoutesHelper.communityProfileResolve(
                                $stateParams,
                                $translate,
                                Logger
                            );
                        }
                    }
                })
                .state("main.communities_library", {
                    url: "/communities/{url_name}/{id}/library",
                    templateUrl:
                        "layout/main/community/communityLibrary/communityLibrary.html",
                    controller: "CommunityLibraryCtrl",
                    controllerAs: "community",
                    data: {
                        analyticsName: "CommunityLibrary"
                    },
                    resolve: {
                        communityProfileResolve: function(
                            communityRoutesHelper,
                            $stateParams,
                            $translate,
                            Logger
                        ) {
                            return communityRoutesHelper.communityProfileResolve(
                                $stateParams,
                                $translate,
                                Logger
                            );
                        }
                    }
                })
                .state("main.communities_private", {
                    url: "/communityPrivate/{url_name}/{id}",
                    templateUrl:
                        "layout/main/community/communityPrivate/communityPrivate.html",
                    controller: "CommunityPrivateCtrl",
                    controllerAs: "communityPrivate",
                    resolve: {
                        communityProfileResolveNoAuth: function(
                            communityRoutesHelper,
                            $stateParams,
                            $translate,
                            Logger
                        ) {
                            return communityRoutesHelper.communityProfileResolveNoAuth(
                                $stateParams,
                                $translate,
                                Logger
                            );
                        }
                    }
                })
                .state("main.communities_show", {
                    url: "/communities/{url_name}/{id}?{pending}",
                    templateUrl:
                        "layout/main/community/communityShow/communityShow.html",
                    controller: "CommunityShowCtrl",
                    controllerAs: "vm",
                    data: {
                        analyticsName: "Community"
                    },
                    resolve: {
                        communityProfileResolve: function(
                            communityRoutesHelper,
                            $stateParams,
                            $translate,
                            Logger
                        ) {
                            return communityRoutesHelper.communityProfileResolve(
                                $stateParams,
                                $translate,
                                Logger
                            );
                        },
                        resolvedCommunityPermissions: function(
                            communityRoutesHelper,
                            $stateParams
                        ) {
                            return communityRoutesHelper.resolveCommunityPermissions(
                                $stateParams.id
                            );
                        }
                    }
                });
            // Handle the case where old URL bookmarks didn't have the url_name.  Redirect to the URL using community as a generic url_name.
            // This has to be at the end after the known community routes or something like /communities/following will get redirected by the /communities/:id pattern below.
            $urlRouterProvider.when(
                "/communities/:id",
                "/communities/community/:id"
            );
            $urlRouterProvider.when(
                "/communities/:id/library",
                "/communities/community/:id/library"
            );
            $urlRouterProvider.when(
                "/communities/:id/edit",
                "/communities/community/:id/edit"
            );
            $urlRouterProvider.when(
                "/communities/:id/followers",
                "/communities/community/:id/followers"
            );
        });

    function communityRoutesHelper(Community, $state) {
        var helper = {
            communityProfileResolve: communityProfileResolve,
            communityProfileResolveNoAuth: communityProfileResolveNoAuth,
            resolveCommunityPermissions: resolveCommunityPermissions
        };

        return helper;

        function communityProfileResolve($stateParams, $translate, Logger) {
            return Community.getCommunity($stateParams.id).catch(function(
                error
            ) {
                if (error.status === 403) {
                    //If directly from a url.  Remove that url from back button history.
                    if (!$state.current.name) {
                        $state.go(
                            "main.communities_private",
                            {
                                id: $stateParams.id,
                                url_name: $stateParams.url_name
                            },
                            { location: "replace" }
                        );
                    } else {
                        $state.go("main.communities_private", {
                            id: $stateParams.id,
                            url_name: $stateParams.url_name
                        });
                    }
                }
            });
        }

        function communityProfileResolveNoAuth(
            $stateParams,
            $translate,
            Logger
        ) {
            return Community.getCommunityNoAuth($stateParams.id).catch(function(
                error
            ) {
                if (error.status === 403) {
                    //If directly from a url.  Remove that url from back button history.
                    if (!$state.current.name) {
                        $state.go(
                            "main.communities_private",
                            {
                                id: $stateParams.id,
                                url_name: $stateParams.url_name
                            },
                            { location: "replace" }
                        );
                    } else {
                        $state.go("main.communities_private", {
                            id: $stateParams.id,
                            url_name: $stateParams.url_name
                        });
                    }
                }
            });
        }

        function resolveCommunityPermissions(id) {
            return Community.getCommunityPostPermissions(id);
        }
    }
})();
