(function() {
    "use strict";

    angular.module("ssmAngularApp.communitiesNav", [
        "ssmAngularApp.CommunityMembership.service",
        "ssmAngularApp.logger",
        "ssmAngularApp.ssmMarketplace.service",
        "ssmAngularApp.tenant.service"
    ]);
})();
