(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.tag", ["ssmAngularApp.api"])
        .factory("Tag", Tag);

    function Tag(Api) {
        var tagsApi = Api.all("tags");
        return {
            getSuggestions: function() {
                return tagsApi.get("suggest");
            },

            search: function(query) {
                return tagsApi.all("search").getList(query);
            },

            searchAll: function(query) {
                return tagsApi.all("searchAll").getList(query);
            },

            putTag: function(id, data) {
                return tagsApi.one(id).customPUT(data);
            }
        };
    }
})();
