(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("AvectraCtrl", AvectraCtrl);

    function AvectraCtrl($translate, $state, ssmAuth, Logger, $location) {
        var query = $location.search();

        var user = {
            email: "AvectraSingleSignOn|" + new Date(),
            password:
                query["ssoToken"] ||
                query["&ssoToken"] ||
                query["&amp;ssoToken"] ||
                query["amp;ssoToken"]
        };

        ssmAuth
            .login(user)
            .then(onLoginSuccess)
            .catch(onLoginFailed);

        function onLoginSuccess() {
            //Try hitting home in case they are already signed in.  Home will redirect them to login if they are not authenticated.
            $state.go("main.home", {}, { location: "replace" });
        }

        function onLoginFailed(error) {
            $translate("LOGIN.ERROR.AVECTRA_LOGIN").then(function(translation) {
                Logger.error("ERROR: ", translation, error);
                //Try hitting home in case they are already signed in.  Home will redirect them to login if they are not authenticated.
                $state.go("main.home", {}, { location: "replace" });
            });
        }
    }
})();
