(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsEditDigestController", OrgsEditDigestController);

    function OrgsEditDigestController(
        $translate,
        Logger,
        Organization,
        orgProfileResolve,
        SSMTitle
    ) {
        var vm = this;
        vm.org = orgProfileResolve;
        vm.saveForm = saveForm;
        vm.resetForm = resetForm;
        vm.getColorExampleStyles = getColorExampleStyles;

        activate();

        function activate() {
            $translate("ORGANIZATION.EDIT.SITE.DIGEST.TITLE").then(
                SSMTitle.setTitle
            );
        }

        function saveForm(form) {
            var params = {
                site: vm.org.site
            };

            return Organization.update(vm.org.id, params)
                .then(function(results) {
                    updateOrg(results, form);
                    vm.flashSavedFlag();
                })
                .catch(function(error) {
                    Logger.error(
                        "ERROR: ",
                        "HTTP Server Error: " + error.status
                    );
                });
        }

        function resetForm(form) {
            Organization.getOrganization(vm.org.id)
                .then(function(results) {
                    updateOrg(results, form);
                    if (form && form.$setPristine) {
                        form.$setPristine();
                    }
                })
                .catch(function(error) {
                    Logger.error(
                        "ERROR: ",
                        "HTTP Server Error: " + error.status
                    );
                });
        }

        function getColorExampleStyles(color, txt) {
            return {
                "background-color": color,
                color: txt,
                border: "none"
            };
        }

        function updateOrg(data, form) {
            if (!data) {
                return;
            }

            if (!vm.org) {
                vm.org = {};
            }

            vm.org.id = data.id;
            vm.org.site = data.site;

            if (vm.org.site) {
                var hosts = vm.org.site.hosts || [];

                hosts = _.map(hosts, function(host) {
                    return host.trim();
                });

                vm.org.site.hosts = _.uniqBy(hosts);

                vm.org.site.images = vm.org.site.images || [];
            }

            if (form && form.$setPristine) {
                form.$setPristine();
            }
        }
    }
})();
