(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("VendorAdminsController", VendorAdminsController);

    function VendorAdminsController(
        $translate,
        vendorProfileResolve,
        SSMTitle
    ) {
        var vm = this;
        vm.vendorData = vendorProfileResolve;

        activate();

        function activate() {
            $translate("VENDOR.PAGE_TITLE.ADMINS").then(SSMTitle.setTitle);
        }
    }
})();
