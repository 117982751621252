(function() {
    "use strict";

    angular.module("ssmAngularApp.eventsNav").directive("eventsNav", eventsNav);

    function eventsNav() {
        return {
            templateUrl: "components/eventsNav/eventsNav.html",
            restrict: "E",
            scope: {},
            controller: eventsNavCtrl,
            controllerAs: "eventsNav",
            bindToController: true
        };
    }

    var development_mode = false;

    function eventsNavCtrl(Logger, Api, $translate, Tenant) {
        /*jshint validthis:true */
        var vm = this;

        vm.showMyEvents = false;

        Tenant.getTenant().then(function(siteOrganization) {
            vm.siteOrganization = siteOrganization;

            var eventsApi = Api.all("events");
            var upcomingOptions = {
                limit: 4,
                after_date: development_mode ? undefined : new Date(),
                organization_id: siteOrganization.organization_id
            };
            eventsApi
                .getList(upcomingOptions)
                .then(function(events) {
                    vm.upcomingEvents = events;
                })
                .catch(function(error) {
                    $translate("EVENTS_NAV.ERROR.UPCOMING_EVENTS_FAILED").then(
                        function(translation) {
                            Logger.error("ERROR: ", translation, error);
                        }
                    );
                });

            var myOptions = {
                after_date: development_mode ? undefined : new Date(),
                organization_id: siteOrganization.organization_id
            };
            eventsApi
                .getList(myOptions)
                .then(function(events) {
                    vm.myEvents = events.filter(function(event) {
                        if (development_mode) {
                            event.attending = Math.random() > 0.95;
                        }
                        return event.attending;
                    });
                })
                .catch(function(error) {
                    $translate("EVENTS_NAV.ERROR.MY_EVENTS_FAILED").then(
                        function(translation) {
                            Logger.error("ERROR: ", translation, error);
                        }
                    );
                });
        });
    }
})();
