(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.layout")
        .controller("MarketplaceController", MarketplaceController);

    function MarketplaceController() {
        var vm = this;
    }
})();
