(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("napauth", {
            url: "/auth/login/nap/callback",
            controller: "NAPAuthCtrl",
            controllerAs: "napauth"
        });
    });
})();
