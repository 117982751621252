(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmBindCompile", [])
        .directive("ssmBindCompile", ssmBindCompile);

    function ssmBindCompile($compile) {
        return {
            restrict: "A",
            link: link
        };

        function link(scope, element, attrs) {
            var ensureCompileRunsOnce = scope.$watch(
                function(scope) {
                    // watch the 'compile' expression for changes
                    return scope.$eval(attrs.ssmBindCompile);
                },
                function(value) {
                    // when the 'compile' expression changes
                    // assign it into the current DOM
                    element.html(value);

                    // compile the new DOM and link it to the current
                    // scope.
                    // NOTE: we only compile .childNodes so that
                    // we don't get into infinite loop compiling ourselves
                    $compile(element.contents())(scope);

                    // Use Angular's un-watch feature to ensure compilation only happens once.
                    ensureCompileRunsOnce();
                }
            );
        }
    }
})();
