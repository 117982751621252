(function() {
    "use strict";

    angular
        .module("ssmAngularApp")
        .factory("ssmLoginRoutesHelper", ssmLoginRoutesHelper)
        .config(function($stateProvider) {
            $stateProvider.state("login", {
                url:
                    "/login?{token}{error}{error_description}{final_redirect_uri}{utm_source}{utm_medium}{utm_campaign}",
                templateUrl: "layout/login/login.html",
                controller: "LoginCtrl",
                controllerAs: "vm",
                resolve: {
                    redirectingResolve: function(
                        $stateParams,
                        ssmLoginRoutesHelper
                    ) {
                        return ssmLoginRoutesHelper.redirectingResolve(
                            $stateParams.token
                        );
                    },

                    tenantResolve: function(ssmLoginRoutesHelper) {
                        return ssmLoginRoutesHelper.tenantResolve();
                    }
                },
                data: {
                    disableLastAttemptedUrl: true
                }
            });
        });

    function ssmLoginRoutesHelper($q, CurrentUser, ssmAuth, Tenant) {
        return {
            redirectingResolve: redirectingResolve,
            tenantResolve: tenantResolve
        };

        function redirectingResolve(token) {
            var deferred = $q.defer();

            var redirecting = false;

            if (token) {
                ssmAuth.processToken(token, true);
                CurrentUser.refresh().then(function() {
                    ssmAuth.redirectToLastAttemptedUrl();
                });
                redirecting = true;
            } else if (ssmAuth.isSignedIn && !CurrentUser.profile.is_guest) {
                ssmAuth.redirectToLastAttemptedUrl();
                redirecting = true;
            }

            deferred.resolve(redirecting);

            return deferred.promise;
        }

        function tenantResolve() {
            return Tenant.getTenant();
        }
    }
})();
