(function() {
    "use strict";

    angular.module("ssmAngularApp.directives", [
        "ssmAngularApp.focusMe",
        "ssmAngularApp.ssmEnter",
        "ssmAngularApp.inputMatch",
        "ssmAngularApp.ellipsis",
        "ssmAngularApp.fileSize",
        "ssmAngularApp.ssmBindCompile",
        "ssmAngularApp.ssmDateValidation",
        "ssmAngularApp.ssmDisableBackspace",
        "ssmAngularApp.ssmFileModel",
        "ssmAngularApp.ssmSmartKeystroke",
        "ssmAngularApp.ssmContentEditable",
        "ssmAngularApp.ssmContainerClasses"
    ]);
})();
