(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("avectra", {
            url: "/auth/login/avectra/callback",
            controller: "AvectraCtrl",
            controllerAs: "avectra"
        });
    });
})();
