(function() {
    "use strict";

    angular
        .module("ssmAngularApp.connectionCard")
        .directive("connectionCard", connectionCard);

    function connectionCard() {
        return {
            templateUrl: "components/connectionCard/connectionCard.html",
            restrict: "EA",
            controller: connectionCardCtrl,
            controllerAs: "connectionCard",
            bindToController: true
        };
    }

    function connectionCardCtrl() {
        /*jshint validthis:true */
        var vm = this;
    }
})();
