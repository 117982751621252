(function() {
    "use strict";

    angular.module("ssmAngularApp.ssmIcon").directive("ssmIcon", ssmIcon);

    function ssmIcon($compile, $templateCache) {
        return {
            scope: {
                icon: "@"
            },
            restrict: "E",
            replace: true,
            link: function(scope, element, attrs) {
                var template = angular.element(
                    $templateCache.get(scope.icon + ".svg")
                );
                _.forEach(attrs.$attr, function(key, value) {
                    template.attr(key, attrs[value]);
                });
                element.replaceWith($compile(template)(scope.$parent));
            }
        };
    }
})();
