(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.currentUser")
        .factory("CurrentUser", CurrentUser)
        .factory("CurrentUserApi", CurrentUserApi);

    function CurrentUser(
        CurrentUserApi, 
        ssmLocalStorage, 
        $q
    ) {
        var loadDeferred = $q.defer();

        var profile = {};

        reloadProfile();

        function reloadProfile() {
            var stored_profile = ssmLocalStorage.get(ssmLocalStorage.key.CURRENT_USER);
            if (stored_profile) {
                angular.copy(stored_profile, profile);
                loadDeferred.resolve();
                return $q.when(profile);
            }

            return CurrentUserApi.getProfile()
                .then(function(updated_profile) {
                    angular.copy(updated_profile, profile);
                    ssmLocalStorage.set(
                        ssmLocalStorage.key.CURRENT_USER,
                        profile
                    );
                    loadDeferred.resolve();
                    return profile;
                })
                .catch(function(err) {
                    return $q.reject(err);
                });
        }

        return {
            profile: profile,

            ensureLoaded: function() {
                return loadDeferred.promise;
            },

            refresh: function() {
                this.clear();
                return reloadProfile();
            },

            clear: function() {
                angular.copy({}, profile);
                ssmLocalStorage.remove(ssmLocalStorage.key.CURRENT_USER);
            },

            update: function(update) {
                return CurrentUserApi.update(update).then(function(
                    updated_profile
                ) {
                    ssmLocalStorage.set(
                        ssmLocalStorage.key.CURRENT_USER,
                        updated_profile
                    );
                    return reloadProfile();
                });
            },

            hasNeverUsedSso: function() {
                return (this.profile.external_ids == undefined 
                    || this.profile.external_ids == null
                    || this.profile.external_ids.length <= 0
                    || this.profile.external_ids[0] == undefined
                    || this.profile.external_ids[0] == null);
            },

            didNotSsoInFromAhima: function() {
                var externalId = this.profile.external_ids[0];
                var sso_sender = externalId.split("|")[0];
                return sso_sender != "ahima-sso";
            }
    
        };
    }

    function CurrentUserApi(Api, ssmAuthHelper, $q) {
        var userApi = Api.all("user");

        return {
            getProfile: function() {
                var user_id = ssmAuthHelper.getUserId();
                if (!user_id) {
                    return $q.reject();
                }

                return userApi.get(user_id + "/profile");
            },

            update: function(update) {
                var user_id = ssmAuthHelper.getUserId();
                if (!user_id) {
                    return $q.reject();
                }

                return userApi.one(user_id + "/profile").patch(update);
            }
        };
    }
})();
