(function() {
    "use strict";

    // Defeats schema-form dependency checking so ocLazyLoad works
    angular.module("ui.sortable", []);
    angular.module("angularSpectrumColorpicker", []);

    angular
        .module("ssmAngularApp.layout")
        .config(function(
            $futureStateProvider,
            $ocLazyLoadProvider,
            $urlRouterProvider,
            ADMIN_VENDOR_FOOT_JS_NAME,
            ADMIN_JS_NAME,
            ADMIN_TEMPLATES_JS_NAME,
            ADMIN_CSS_NAME
        ) {
            $futureStateProvider.addResolve(function($q, $timeout) {
                var d = $q.defer();
                $timeout(function() {
                    d.resolve(
                        "When this resolves, future state provider will re-sync the state/url"
                    );
                }, 1000);
                return d.promise;
            });

            $ocLazyLoadProvider.config({
                debug: true,
                modules: [
                    {
                        reconfig: true,
                        name: "ssmAdminApp",
                        files: [
                            ADMIN_VENDOR_FOOT_JS_NAME,
                            ADMIN_JS_NAME,
                            ADMIN_TEMPLATES_JS_NAME,
                            ADMIN_CSS_NAME
                        ]
                    }
                ]
            });

            $futureStateProvider.futureState({
                stateName: "admin",
                urlPrefix: "/ssmadmin",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.delegate",
                urlPrefix: "/delegate",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.digest",
                urlPrefix: "/digest",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.communityAdmin",
                urlPrefix: "/communityadmin",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.communityProperties",
                urlPrefix: "/communityProperties",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.flair",
                urlPrefix: "/flair",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.linkReport",
                urlPrefix: "/linkReport",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.signedUrlCache",
                urlPrefix: "/signedUrlCache",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.communityReport",
                urlPrefix: "/communityReport",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.hashTags",
                urlPrefix: "/hashTags",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.orgEdit",
                urlPrefix: "/orgEdit",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.orgEditAdvanced",
                urlPrefix: "/orgEditAdvanced",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.updateDnsRecord",
                urlPrefix: "/updateDnsRecord",
                module: "ssmAdminApp",
                type: "ngload"
            });
            $futureStateProvider.futureState({
                stateName: "admin.marketplace",
                urlPrefix: "/marketplace",
                module: "ssmAdminApp",
                type: "ngload"
            });

            var templateLoadStateFactory = function(
                $q,
                $timeout,
                $ocLazyLoad,
                $templateCache,
                futureState
            ) {
                var d = $q.defer();

                $ocLazyLoad.load(futureState.module).then(function() {
                    /* jshint ignore:start */
                    // this function will be defined by loading the admin-templates.js script,
                    // but jshint doesn't like that.
                    switch (futureState.module) {
                        case "ssmAdminApp":
                            loadAdminTemplates($templateCache);
                            break;
                        default:
                        //Nothing to load
                    }
                    /* jshint ignore:end */

                    d.resolve();
                });

                return d.promise; // ui-router state promise returned
            };

            $futureStateProvider.stateFactory(
                "ngload",
                templateLoadStateFactory
            );

            // future state provide overrides otherwise. redefine it here.
            $urlRouterProvider.otherwise("/404");
        });
})();
