(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.profileQuestions", [])
        .factory("ProfileQuestions", ProfileQuestions);

    function ProfileQuestions() {
        return {};
    }
})();
