(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("UsersFindResultsCtrl", UsersFindResultsCtrl);

    function UsersFindResultsCtrl(FindUser, User, $state, $translate, Logger) {
        /*jshint validthis: true */
        var vm = this;
        vm.downloadPDF = downloadPDF;
        vm.searchResults = FindUser.search($state.params);
        vm.pdfGenerated = false;

        function downloadPDF() {
            User.getPDF(FindUser.buildSearchData())
                .then(function(data) {
                    vm.pdfGenerated = true;
                })
                .catch(function(error) {
                    switch (error.status) {
                        case 403:
                            $translate("PDF.INVALID_EMAIL").then(function(
                                translation
                            ) {
                                Logger.warning(
                                    "Invalid Email: ",
                                    translation,
                                    error
                                );
                            });
                            break;
                        case 404:
                            $translate("PDF.NO_RESULTS_FOUND").then(function(
                                translation
                            ) {
                                Logger.warning(
                                    "No Results: ",
                                    translation,
                                    error
                                );
                            });
                            break;
                        case 500:
                            $translate("PDF.INTERNAL_SERVER_ERROR").then(
                                function(translation) {
                                    Logger.warning(
                                        "Error creating PDF: ",
                                        translation,
                                        error
                                    );
                                }
                            );
                            break;
                        default:
                            $translate("PDF.INTERNAL_SERVER_ERROR").then(
                                function(translation) {
                                    Logger.error(
                                        "Error creating PDF: ",
                                        translation,
                                        error
                                    );
                                }
                            );
                            break;
                    }
                });
        }
    }
})();
