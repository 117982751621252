(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("CommunityManagersController", CommunityManagersController);

    function CommunityManagersController(User, Logger, $translate, SSMTitle) {
        var vm = this;

        activate();
        function activate() {
            $translate("TITLE.COMMUNITY_MANAGERS").then(function(title) {
                SSMTitle.setTitle(title);
            });

            User.getCommunityManagers()
                .then(function(data) {
                    vm.list = data;
                })
                .catch(function(error) {
                    Logger.errorWithoutAlert(
                        "Error getting community managers.",
                        error
                    );
                });
        }
    }
})();
