(function() {
    "use strict";

    angular
        .module("ssmAngularApp.communitiesNav")
        .directive("communitiesNav", communitiesNav)
        .controller("communitiesNavController", communitiesNavController);

    function communitiesNav() {
        return {
            templateUrl: "components/communitiesNav/communitiesNav.html",
            restrict: "E",
            replace: true,
            scope: {},
            controller: communitiesNavController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function communitiesNavController(
        CommunityMembership,
        CurrentUser,
        Logger,
        Analytics,
        ssmMarketplace,
        Tenant
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.memberships = [];
        
        vm.sideNavExtras = [];

        vm.collaboration_off = true;
        vm.blogs_isHidden = true;
        vm.myLeadership_isHidden = true;

        vm.hideForGuest = false;
        const isGuest = CurrentUser.profile.is_guest;
        vm.customGuestCommunityTitle = "";
        vm.guestCommunityTitle = "My Communities";

        vm.marketplaceRoute = "";
        vm.marketplaces = [];

        var namaOrganizationId = "5e6149be7ede1b6e0efc1ef7";

        vm.communityClick = communityClick;
        //CSA reesource library exceptions code, add the community ID that should have access to the library
        const availableCSAResourceLibraryCommunities = [
            '60009d9a5ad9c321a86650eb',
            '60009fce5ad9c321a866510c',
            '60009bf35ad9c321a86650d8',
            '60009d055ad9c321a86650e4',
        ];
        let userCommunitiesIds = [];
        let isCSAResourceLibraryCompatible = false;        

        activate();

        async function activate() {
            if (CurrentUser.profile.appa_settings) {
                var appaMemberBenefitsFlag = CurrentUser.profile.appa_settings.member_benefits_flag;
                var appaCustomerType = CurrentUser.profile.appa_settings.customer_type;
            }
            var up_to_limit = { limit: 75 };
            await CommunityMembership.getAll(up_to_limit).then(

                function(data) {
                    _.each(data, function(communitySet) {
                        _.each(communitySet.communities, function(community){
                            userCommunitiesIds.push(community.id);
                        })
                        vm.memberships = vm.memberships.concat(
                            communitySet.communities
                        );
                    });
                    if(_.isEmpty(getCommonCACommunities(userCommunitiesIds, availableCSAResourceLibraryCommunities))){
                        isCSAResourceLibraryCompatible = true;
                    }
                },
                function(error) {
                    Logger.errorWithoutAlert("Error getting communities to show or to hide CA Resource Library.", error);
                }
            );
            await Tenant.getTenant().then(function(siteOrganization) {
                vm.siteOrganization = siteOrganization;
                vm.sideNavExtras = siteOrganization.side_nav_extras;
                vm.customGuestCommunityTitle = siteOrganization.custom_guest_community_title;
                vm.collaboration_off = !!siteOrganization.flags.collaboration_off;
                vm.blogs_isHidden = !!siteOrganization.flags.blogs_isHidden;
                vm.myLeadership_isHidden = !!siteOrganization.flags.myLeadership_isHidden;

                if (isGuest) {
                    vm.hideForGuest = !!siteOrganization.flags.hideForGuest;
                    if (vm.customGuestCommunityTitle) {
                        vm.guestCommunityTitle = vm.customGuestCommunityTitle;
                    }
                }

                // TODO: Figure out better way of handling alternate route names for marketplace
                vm.marketplaceRoute = "main.marketplace({id: marketplace.id})";
                if (siteOrganization.organization_id == namaOrganizationId) {
                    vm.marketplaceRoute = "main.marketplace_npz";
                }

                ssmMarketplace.getMarketplaces(siteOrganization.organization_id).then(
                    function(marketplaces) {
                        vm.marketplaces = marketplaces;
                        _.forEach(vm.marketplaces, marketplace => {
                            if (!marketplace.display_name) {
                                marketplace.display_name = "Resource Center";
                            }
                        });

                        if(isCSAResourceLibraryCompatible){
                            _.remove(vm.marketplaces, function(marketplace) {
                                return marketplace.display_name == "CA Resource Library";
                            });
                        }
                        if (isGuest) {
                            _.remove(vm.marketplaces, function(marketplace) {
                                return marketplace.hide_for_guest == true;
                            });
                        } else if (CurrentUser.profile.appa_settings){
                            _.remove(vm.marketplaces, function(marketplace) {
                                return isAppaResourceCenterHidden(marketplace, appaMemberBenefitsFlag, appaCustomerType);
                            })
                        }
                    });
            });
        }

        function isAppaResourceCenterHidden(marketplace, benefitsFlag, customerType) {
            // Specific APPA communities have specific viewing criteria due to request
            // TODO: Figure out a way to make this more generic or this ruling less convoluted
            if(marketplace.id == "5f404dc261cc9013b701fde3") {
                return benefitsFlag == "0";
            } else if(marketplace.id == "5f3c3903f2feba301a8ff625") {
                return !(customerType == "Individual" 
                    || customerType == "Lifetime" 
                    || customerType == "Student");
            }
        }        
        const getCommonCACommunities = (userCommunities, availableCommunities) => {
            const intersection = userCommunities.filter((id) => availableCommunities.includes(id));
            return intersection;
        }

        function communityClick(community) {
            Analytics.eventTrack("communityClick", {
                category: "communitiesNav",
                label: "community_id:" + community.id
            });
            Analytics.eventTrack2('community_click', {
                category: "communitiesNav",
                label: "community_id:" + community.id,
            });
        }
    }
})();
