(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("validate", {
            url: "/validate",
            templateUrl: "layout/validate/validate.html",
            controller: "ValidateCtrl",
            controllerAs: "validate",
            data: {
                requiresLogin: true,
                disableLastAttemptedUrl: true
            }
        });
    });
})();
