(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider
            .state("main.users", {
                url: "/users",
                redirectTo: "main.users_connections"
            })
            .state("main.users_aces", {
                url: "/users/aces",
                templateUrl: "layout/main/users/usersAces/usersAces.html",
                controller: "UsersAceController",
                controllerAs: "vm"
            })
            .state("main.users_community_managers", {
                url: "/users/usersCommunityManagers",
                templateUrl:
                    "layout/main/users/usersCommunityManagers/usersCommunityManagers.html",
                controller: "CommunityManagersController",
                controllerAs: "vm"
            })
            .state("main.users_connections", {
                url: "/users/connections",
                templateUrl:
                    "layout/main/users/usersConnections/usersConnections.html",
                controller: "UsersConnectionsController",
                controllerAs: "vm"
            })
            .state("main.users_find", {
                url: "/users/find",
                templateUrl: "layout/main/users/usersFind/usersFind.html",
                controller: "UsersFindCtrl",
                controllerAs: "find",
                data: {
                    allowTenant: "find_someone"
                }
            })
            .state("main.users_find_results", {
                url: "/users/find_results?{?continuationtoken}{?start?}{?size}",
                templateUrl:
                    "layout/main/users/usersFindResults/usersFindResults.html",
                controller: "UsersFindResultsCtrl",
                controllerAs: "results",
                data: {
                    allowTenant: "find_someone"
                }
            })
            .state("main.users_show", {
                url: "/users/{id}",
                templateUrl: "layout/main/users/usersShow/usersShow.html",
                controller: "UsersShowCtrl",
                controllerAs: "userProfile",
                resolve: {
                    userProfileResolve: userProfileResolve
                },
                data: {
                    analyticsName: "UserWall"
                }
            });
    });

    function userProfileResolve($stateParams, User) {
        return User.getUser($stateParams.id);
    }
})();
