(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.libraryFolder")
        .directive("libraryFolder", libraryFolder);

    function libraryFolder() {
        return {
            templateUrl: "components/libraryFolder/libraryFolder.html",
            restrict: "E",
            scope: {
                folderData: "=",
                admins: "=",
                sideBySide: "=",
                removeButton: "=",
                enableInfiniteScroll: "=",
                onNextPage: "&",
                onRemoveFolder: "&",
                homeLocationId: "@"
            },
            replace: true,
            controller: libraryFolderCtrl,
            controllerAs: "libraryFolder",
            bindToController: true
        };
    }

    function libraryFolderCtrl(CurrentUser, $translate, $q, Logger) {
        /*jshint validthis: true */
        var vm = this;
        vm.canRemove = canRemove;
        vm.removeFolder = removeFolderClicked;

        // InfiniteScroll
        vm.disableInfiniteScrollEvents = !vm.enableInfiniteScroll;
        vm.nextPage = nextPageClicked;

        activate();

        function activate() {
            vm.currentUser = CurrentUser.profile;
            vm.folderData = vm.folderData || [];
        }

        function nextPageClicked() {
            if (vm.onNextPage) {
                if (vm.disableInfiniteScrollEvents) {
                    return;
                }
                vm.disableInfiniteScrollEvents = true;

                var lastPost = _.last(vm.folderData);

                $q.when(vm.onNextPage({ lastPost: lastPost }))
                    .then(function(folders) {
                        if (folders) {
                            processPosts(folders.folders);
                        }
                    })
                    .catch(function(error) {
                        $translate("LIBRARY.LOAD_ERROR").then(function(
                            translation
                        ) {
                            Logger.error("Error: ", translation);
                        });
                    });
            }
        }

        function processPosts(folders) {
            if (!folders || folders.length === 0) {
                return;
            }
            vm.folderData.push.apply(vm.folderData, folders);
            vm.disableInfiniteScrollEvents = false;
        }

        function canRemove(folder) {
            if (!vm.currentUser.id) {
                return false;
            }

            if (vm.currentUser.id === folder.owner_data.id) {
                return true;
            }

            if (!vm.admins) {
                return false;
            }

            return _.some(
                vm.admins,
                function(admin_id) {
                    return admin_id == vm.currentUser.id; // jshint ignore:line
                },
                vm
            );
        }

        function removeFolderClicked(folder) {
            if (vm.onRemoveFolder) {
                $q.when(vm.onRemoveFolder({ folder: folder }))
                    .then(function(results) {
                        // OnAccept
                        vm.folderData.remove(folder);
                    })
                    .catch(function(error) {
                        $translate("LIBRARY.REMOVE_ERROR").then(function(
                            translation
                        ) {
                            Logger.error("Error: ", translation);
                        });
                    });
            }
        }
    }
})();
