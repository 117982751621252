(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.ssmFlair").directive("ssmFlair", ssmFlair);

    function ssmFlair() {
        return {
            templateUrl: "components/ssmFlair/ssmFlair.html",
            restrict: "E",
            replace: true,
            scope: {
                data: "=",
                stacked: "@",
                grid: "@"
                // TODO horizontal: '@'
            },
            controller: ssmFlairCtrl,
            controllerAs: "ssmFlair",
            bindToController: true
        };
    }

    function ssmFlairCtrl(Analytics) {
        /*jshint validthis:true */

        var vm = this;
        vm.flairClick = flairClick;

        function flairClick(flair) {
            Analytics.flairClickEvents([flair], "ssmFlair");
        }
    }
})();
