(function() {
    "use strict";

    angular.module("ssmAngularApp.communityInformationForm", [
        "pascalprecht.translate",
        "ssmAngularApp.analytics",
        "ssmAngularApp.logger",
        "ssmAngularApp.fileUpload"
    ]);
})();
