(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.adminTaskCard")
        .directive("adminTaskCard", adminTaskCard)
        .controller("adminTaskCardCtrl", adminTaskCardCtrl);

    function adminTaskCard() {
        return {
            templateUrl: "components/adminTaskCard/adminTaskCard.html",
            restrict: "EA",
            replace: true,
            scope: {
                title: "@",
                description: "@",
                targetState: "@"
            },
            controller: adminTaskCardCtrl,
            controllerAs: "adminTask",
            bindToController: true
        };
    }

    function adminTaskCardCtrl() {
        /*jshint validthis:true */
        var vm = this;
    }
})();
