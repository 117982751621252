(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("VendorFlairController", VendorFlairController);

    function VendorFlairController(
        $state,
        $translate,
        vendorFlairListResolve,
        Flair,
        SSMTitle
    ) {
        var vm = this;
        vm.flairData = vendorFlairListResolve;
        vm.showCreate = true;
        vm.deleteFlair = deleteFlair;
        vm.editFlair = editFlair;

        activate();

        function activate() {
            $translate("VENDOR.PAGE_TITLE.FLAIR").then(SSMTitle.setTitle);
        }

        function deleteFlair(flair) {
            _.remove(vm.flairData, { id: flair.id });
            return Flair.deleteFlair(flair.id);
        }

        function editFlair(flair) {
            $state.go("main.vendor.show.flair.edit", { flair_id: flair.id });
        }
    }
})();
