(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmVendorCardBrief", [
            "ssmAngularApp.vendor.service"
        ])
        .directive("ssmVendorCardBrief", ssmVendorCardBrief);

    function ssmVendorCardBrief() {
        return {
            templateUrl:
                "components/ssmVendorCardBrief/ssmVendorCardBrief.html",
            restrict: "E",
            replace: true,
            scope: {
                subject: "=",
                restrictedHeight: "@",
                border: "@",
                noJoin: "@",
                noLink: "@"
            },
            controller: ssmVendorCardBriefController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmVendorCardBriefController(Vendor) {
        /*jshint validthis:true */
        var vm = this;

        vm.isActive = vm.subject.status === Vendor.STATUS.ACTIVE;
        vm.isInactive = vm.subject.status === Vendor.STATUS.INACTIVE;
    }
})();
