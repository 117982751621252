(function() {
    angular
        .module("ssmAngularApp.ssmContainerClasses", [])
        .directive("ssmContainerClasses", ssmContainerClasses);

    function ssmContainerClasses() {
        return {
            restrict: "A",
            scope: {},
            link: link
        };

        function link(scope, elem) {
            scope.$on("$stateChangeSuccess", function(
                event,
                toState,
                toParams,
                fromState
            ) {
                var fromClassnames =
                    angular.isDefined(fromState.data) &&
                    angular.isDefined(fromState.data.containerClasses)
                        ? fromState.data.containerClasses
                        : null;
                var toClassnames =
                    angular.isDefined(toState.data) &&
                    angular.isDefined(toState.data.containerClasses)
                        ? toState.data.containerClasses
                        : null;

                // don't do anything if they are the same
                if (fromClassnames && fromClassnames !== toClassnames) {
                    elem.removeClass(fromClassnames);
                }

                if (toClassnames) {
                    elem.addClass(toClassnames);
                }
            });
        }
    }
})();
