(function() {
    "use strict";

    angular
        .module("ssmAngularApp.userConnect")
        .directive("userConnect", userConnect);

    function userConnect() {
        return {
            templateUrl: "components/userConnect/userConnect.html",
            restrict: "E",
            replace: true,
            scope: {
                userData: "=",
                oneBtn: "@"
            },
            controller: userConnectCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function userConnectCtrl($state, Connection, Logger, CurrentUser) {
        /*jshint validthis:true */
        var vm = this;
        vm.sendApproveConnection = sendApproveConnection;
        vm.sendDeleteConnection = sendDeleteConnection;
        vm.sendConnectionRequest = sendConnectionRequest;
        vm.isGuest = CurrentUser.profile.is_guest;

        vm.isConnectionConnectee = false;
        vm.isConnection = false;
        vm.showConnectionCancel = false;

        activate();

        function activate() {
            if (vm.userData.connection && vm.userData.connection.created) {
                vm.isConnectionConnectee =
                    CurrentUser.profile.id === vm.userData.connection.connectee;
                connectionHandler(parseInt(vm.userData.connection.status));
            } else {
                connectionHandler();
            }
        }

        function connectionHandler(connection_status) {
            switch (connection_status) {
                case 0:
                    connectionRequested();
                    break;
                case 1:
                    connectionApproved();
                    break;
                case 3:
                    connectionBlocked();
                    break;
                default:
                    canConnect();
                    break;
            }
        }

        function connectionRequested() {
            vm.canRequestConnection = false;
            vm.showConnectionPendingAction = vm.isConnectionConnectee
                ? true
                : false;
            vm.showConnectionCancel = vm.isConnectionConnectee ? false : true;
        }

        function connectionApproved() {
            vm.showConnectionCancel = false;
            vm.canRequestConnection = false;
            vm.showConnectionPendingAction = false;
            vm.isConnection = true;
        }

        function connectionBlocked() {
            vm.isCurrentUserBlocked = true;
        }

        function canConnect() {
            vm.isConnectionRequester = false;
            vm.isConnection = false;
            vm.showConnectionPendingAction = false;
            vm.showConnectionCancel = false;
            vm.canRequestConnection = true;
        }

        function sendApproveConnection() {
            Connection.approveRequest(vm.userData.id)
                .then(function() {
                    // This is necessary because we basically want to refresh the whole state (need new profile pulled,
                    // lots of things on the page get updated.  DON'T copy this unless you know what you're doing
                    $state.reload();
                })
                .catch(function(error) {
                    Logger.errorWithoutAlert(
                        "Error approving connection request.",
                        error
                    );
                });
        }

        function sendDeleteConnection() {
            Connection.deleteConnection(vm.userData.id)
                .then(function() {
                    canConnect();
                })
                .catch(function(error) {
                    Logger.errorWithoutAlert(
                        "Error deleting connection. ",
                        error
                    );
                });
        }

        function sendConnectionRequest() {
            Connection.createRequest(vm.userData.id)
                .then(function() {
                    vm.isConnectionConnectee = false;
                    connectionRequested();
                })
                .catch(function(error) {
                    Logger.errorWithoutAlert(
                        "Error creating connection request.",
                        error
                    );
                });
        }
    }
})();
