(function() {
    "use strict";
    /**
     * @ngInject
     */
 
    angular
       .module("ssmAngularApp.bannerAd")
       .directive("bannerAd", BannerAd);
 
    function BannerAd() {
       return {
          templateUrl: "components/bannerAd/bannerAd.html",
          restrict: "E",
          scope: {
                redirectUrl: "@",
                imageSource: "@",
                ahimaAnnouncementId: "@"
          },
          replace: true,
          controller: BannerAdController,
          controllerAs: "vm",
          bindToController: true
       };
    }
 
    function BannerAdController() {
      var vm = this;
      vm.stopLinkPropagation = function(event){
         //event.stopPropagation();
         console.log(event);
      }
   }
 })();
 