(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmSampleComponent")
        .directive("ssmSampleComponent", ssmSampleComponent)
        .controller(
            "ssmSampleComponentController",
            ssmSampleComponentController
        )
        .factory("ssmSampleComponentHelper", ssmSampleComponentHelper);

    function ssmSampleComponent() {
        return {
            templateUrl:
                "components/ssmSampleComponent/ssmSampleComponent.html",
            restrict: "E",
            replace: true,
            scope: {
                // This is where we put data that is passed into or out of the component
                twoWayBoundData: "=", // Changes to the data are tracked by the component and the component owner
                oneWayBoundData: "@" // Changes to the data are only tracked by the component
            },
            controller: ssmSampleComponentController, // Make this descriptive so it's easy to identify in debugger stack
            controllerAs: "vm", // Standard is to always name this 'vm'
            bindToController: true
        };
    }

    // The controller should only hold things which are necessary for the view.  Private business logic should go into
    // a factory (when not reusable or is a reusable instance) or a service (when reusable and not an instance)
    /* @ngInject */
    function ssmSampleComponentController(
        /* Dependencies go here */ WallPost,
        ssmSampleComponentHelper,
        $translate,
        Logger
    ) {
        /*jshint validthis:true */
        var vm = this;

        // Constants or reusable templates should be CAPITALIZED and WRITTEN_WITH_UNDERSCORES
        var POST_LIMIT = 20;

        // Templates should end with _TEMPLATE.  Use angular.copy() to create a clone of a template
        var QUERY_TEMPLATE = { limit: POST_LIMIT, sort_by_activity: true };

        // Declare private variables before public variables
        var posts = [];

        // Variables accessible through the view model should be declared on the view model
        vm.topPoster = null;

        // Publicly available functions should be declared as functions and added to the view model this way.
        // Keep these declarations at the top
        vm.getTopPoster = getTopPoster;

        activate();

        function activate() {
            // Put longer running initialization tasks (like getting the current user profile) here
        }

        function getTopPoster() {
            var query = angular.copy(QUERY_TEMPLATE);
            query.limit = 50;

            WallPost.all(query)
                .then(function(posts) {
                    vm.topPoster = ssmSampleComponentHelper.getTopPoster(
                        vm.posts
                    );
                })
                .catch(function(error) {
                    $translate("WALL.GET_POSTS_ERROR").then(function(
                        translation
                    ) {
                        Logger.error("ERROR: ", translation, error);
                    });
                });
        }
    }

    // I think that we should call private factories which aren't being shared Helpers and keep them in the same file
    // If you start using it in multiple controllers, then we should call them Utilties and move them to different files
    /* @ngInject */
    function ssmSampleComponentHelper() {
        // These functions are shared between all references to them, so be careful here with any private variables

        return {
            getTopPoster: getTopPoster
        };

        function getTopPoster(posts) {
            if (!posts || !posts.length) {
                return null;
            }

            var topPoster = _(posts)
                .map(function(post) {
                    return post.author.full_name;
                })
                .countBy(function(full_name) {
                    return full_name;
                })
                .toPairs()
                .sortBy(function(pair) {
                    return pair[1];
                })
                .reverse()
                .map(function(pair) {
                    return pair[0];
                })
                .value()
                .first();

            return topPoster;
        }
    }
})();
