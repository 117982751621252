/*jshint maxparams: 13 */
/*jshint maxstatements:50*/
(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.sharePostModal")
        .controller("SharePostCtrl", SharePostCtrl);

    function SharePostCtrl(
        $uibModalInstance,
        $scope,
        $translate,
        Analytics,
        Audience,
        Community,
        CurrentUser,
        Delegation,
        Logger,
        postInfo,
        Search,
        SSMInlineContentHelper,
        Tenant,
        User,
        WallPost
    ) {
        var vm = this;
        var sharedPostTemplate = { message: "" };
        var searchParamsTemplate = { filter: null, q: null };
        var MAX_NUMBER_SEARCH_RESULTS = 25;

        $scope.location = postInfo.location;
        $scope.postType = postInfo.postType;
        $scope.posts = postInfo.posts;
        $scope.postData = postInfo.postData;

        var dimension2 =
            $scope.location && $scope.location.id
                ? $scope.location.id
                : "Unknown";
        var dimension4 =
            $scope.postData &&
            $scope.postData.location &&
            $scope.postData.location.id
                ? $scope.postData.location.id
                : "Unknown";

        vm.shouldOpenSearchMenu = false;
        vm.showNotFoundError = false;
        vm.searchQuery = null;
        vm.searchResult = null;
        vm.POST_AS_TYPE = WallPost.POST_AS_TYPE;
        vm.postingAsDelegate = false;
        vm.principalToPostAsId = null;

        vm.submitSharePost = submitSharePost;
        vm.getSearchResults = getSearchResults;
        vm.searchMenuToggled = searchMenuToggled;
        vm.setSearchResult = setSearchResult;
        vm.clearSearchResult = clearSearchResult;
        vm.closeModal = closeModal;
        vm.canPostAsCommunity = canPostAsCommunity;
        vm.canPostAsOthers = canPostAsOthers;
        vm.canPostAsDelegate = canPostAsDelegate;
        vm.showDelegateSelection = showDelegateSelection;
        vm.searchCallback = searchCallback;

        activate();

        function activate() {
            var modalStrings = {};
            if (!$scope.location) {
                $scope.location = { type: WallPost.LOCATION_TYPE.POST };
            }
            switch ($scope.location.type) {
                case WallPost.LOCATION_TYPE.BLOG:
                    vm.blogShare = true;
                    modalStrings = {
                        title: "WALL.SHARE_POST_MODAL.TITLE_BLOG",
                        placeholder: "WALL.SHARE_POST_MODAL.PLACEHOLDER_BLOG"
                    };
                    break;
                default:
                    vm.blogShare = false;
                    modalStrings = {
                        title: "WALL.SHARE_POST_MODAL.TITLE_POST",
                        placeholder: "WALL.SHARE_POST_MODAL.PLACEHOLDER_POST"
                    };
                    break;
            }

            $translate(modalStrings.title).then(function(translation) {
                vm.pageTitle = translation;
            });

            $translate(modalStrings.placeholder).then(function(translation) {
                vm.placeholder = translation;
            });

            vm.newPost = angular.copy(sharedPostTemplate);
            vm.currentUser = CurrentUser.profile;

            vm.shareTargets = [
                {
                    name: "WALL.SHARE_POST_MODAL.SHARE_TO_MY_WALL",
                    location: {
                        id: vm.currentUser.id,
                        type: WallPost.LOCATION_TYPE.USER
                    }
                },
                {
                    name: "WALL.SHARE_POST_MODAL.SHARE_TO_USER",
                    filter: "users"
                },
                {
                    name: "WALL.SHARE_POST_MODAL.SHARE_TO_COMMUNITY",
                    filter: "communities"
                }
            ];

            vm.shareTarget = vm.shareTargets[0];

            $scope.$on("$locationChangeStart", function() {
                $uibModalInstance.close();
            });
        }

        function closeModal() {
            $uibModalInstance.dismiss("cancel");
        }

        // When search menu closes we want focus back to the input.
        function searchMenuToggled(open) {
            vm.focusTrigger = !open;
        }

        function getSearchResults() {
            if (!vm.searchQuery || vm.searchQuery.length < 3) {
                return;
            }

            var searchParams = angular.copy(searchParamsTemplate);
            searchParams.filter = vm.shareTarget.filter;
            searchParams.q = vm.searchQuery;

            Search.search(searchParams)
                .then(function(results) {
                    if (!results.results) {
                        return;
                    }
                    vm.searchResults = results.results;
                    vm.showNotFoundError = vm.searchResults.length < 1;
                    vm.shouldOpenSearchMenu = vm.searchResults.length > 0;
                })
                .catch(function() {
                    vm.showNotFoundError = true;
                    vm.shouldOpenSearchMenu = false;
                });
        }

        function submitSharePost(newPost, postAsType) {
            var post = {};
            post.message =
                SSMInlineContentHelper.sanitizeUserInput(newPost.message) || "";
            // SSM-557, this should go back to just calling WallPost.create, with the location object added to the post
            // also shared_post_id and shared_type will go to shared_location
            post.shared_post_id = $scope.postData.id;

            var emailAddress = _.head(vm.currentUser.emails).email;
            Tenant.getTenant().then(function(tenant) {
                Audience.updateHashTags(
                    tenant.organization_id,
                    emailAddress,
                    $scope.postData.hash_tags,
                    $scope.postData.inline_hash_tags
                );
            });

            var submitAction,
                targetLocation = vm.shareTarget.location;
            switch (targetLocation.type) {
                case WallPost.LOCATION_TYPE.USER:
                    submitAction = User.createPost;
                    break;
                case WallPost.LOCATION_TYPE.COMMUNITY:
                    submitAction = Community.createPost;
                    if (postAsType === vm.POST_AS_TYPE.COMMUNITY) {
                        post.community = {
                            community_id: vm.shareTarget.location.id,
                            posted_as_community: true
                        };
                        post.author = {
                            document_id: vm.shareTarget.location.id,
                            document_type: WallPost.LOCATION_TYPE.COMMUNITY
                        };
                    } else if (vm.postingAsDelegate) {
                        post.author = {
                            document_id: vm.principalToPostAsId,
                            document_type: WallPost.LOCATION_TYPE.USER
                        };
                    } else {
                        post.author = {
                            document_id: vm.currentUser.id,
                            document_type: WallPost.LOCATION_TYPE.USER
                        };
                    }
                    break;
            }

            submitAction(targetLocation.id, post)
                .then(function(hydratedPost) {
                    switch ($scope.location.type) {
                        case WallPost.LOCATION_TYPE.BLOG:
                            Analytics.eventTrack("submitShareBlog", {
                                category: "SharePostCtrl",
                                label:
                                    "id: " +
                                    hydratedPost.id +
                                    ", shared_post_id: " +
                                    post.shared_post_id,
                                dimension2: dimension2,
                                dimension4: dimension4
                            });
                            Analytics.eventTrack2('submit_share_blog', {
                                category: "SharePostCtrl",
                                label: "id: " +
                                    hydratedPost.id +
                                    ", shared_post_id: " +
                                    post.shared_post_id,
                                dimension2: dimension2,
                                dimension4: dimension4
                            });
                            break;
                        default:
                            Analytics.eventTrack("submitSharePost", {
                                category: "SharePostCtrl",
                                label:
                                    "id: " +
                                    hydratedPost.id +
                                    ", shared_post_id: " +
                                    post.shared_post_id,
                                dimension2: dimension2,
                                dimension4: dimension4
                            });
                            Analytics.eventTrack2('submit_share_post', {
                                category: "SharePostCtrl",
                                label: "id: " +
                                    hydratedPost.id +
                                    ", shared_post_id: " +
                                    post.shared_post_id,
                                dimension2: dimension2,
                                dimension4: dimension4
                            });
                            break;
                    }
                    if (
                        hydratedPost.author &&
                        hydratedPost.author.id !== vm.currentUser.id &&
                        hydratedPost.author.author_type ===
                            WallPost.LOCATION_TYPE.USER
                    ) {
                        Analytics.eventTrack("sharedPostAsDelegate", {
                            category: "Delegation",
                            label:
                                "id:" +
                                hydratedPost.id +
                                ", author: " +
                                hydratedPost.author.id +
                                ", author type: " +
                                hydratedPost.author.author_type +
                                ", shared_post_id: " +
                                post.shared_post_id,
                            dimension2: dimension2,
                            dimension4: dimension4
                        });
                        Analytics.eventTrack2('shared_post_as_delegate', {
                            category: "Delegation",
                            label: "id:" +
                                hydratedPost.id +
                                ", author: " +
                                hydratedPost.author.id +
                                ", author type: " +
                                hydratedPost.author.author_type +
                                ", shared_post_id: " +
                                post.shared_post_id,
                            dimension2: dimension2,
                            dimension4: dimension4
                        });
                    }
                    // Don't unshift the post to the wall because it's not appropriate if we're not on the target location
                    vm.closeModal();
                })
                .catch(function(error) {
                    if (error.data && error.data.message === "guest_user") {
                        return;
                    }
                    switch (error.status) {
                        case 403:
                            var reason;
                            switch (targetLocation.type) {
                                case WallPost.LOCATION_TYPE.USER:
                                    reason =
                                        "WALL.SHARE_POST_MODAL.FORBIDDEN_USER";
                                    break;
                                case WallPost.LOCATION_TYPE.COMMUNITY:
                                    reason =
                                        "WALL.SHARE_POST_MODAL.FORBIDDEN_COMMUNITY";
                                    break;
                                case WallPost.LOCATION_TYPE.BLOG:
                                    reason =
                                        "WALL.SHARE_POST_MODAL.FORBIDDEN_BLOG";
                                    break;
                                default:
                                    reason =
                                        "WALL.SHARE_POST_MODAL.FORBIDDEN_UNKNOWN";
                                    break;
                            }
                            $translate(reason).then(function(translation) {
                                Logger.warning("WARNING: ", translation, error);
                            });
                            break;
                        default:
                            $translate("WALL.CREATE_POST_ERROR").then(function(
                                translation
                            ) {
                                Logger.error("ERROR: ", translation, error);
                            });
                            break;
                    }
                });
        }

        function clearSearchResult() {
            vm.searchQuery = null;
            vm.searchResult = null;
            vm.shareTarget.location = null;
        }

        function setSearchResult(result) {
            switch (result.result_type) {
                case "user":
                    vm.shareTarget.location = {
                        id: result.user.id,
                        type: WallPost.LOCATION_TYPE.USER
                    };
                    break;
                case "community":
                    vm.shareTarget.location = {
                        id: result.community.id,
                        type: WallPost.LOCATION_TYPE.COMMUNITY
                    };
                    Community.getCommunityPostPermissions(
                        result.community.id
                    ).then(function(postingPermissions) {
                        vm.shareTarget.location.permissions = postingPermissions;
                    });
                    break;
                default:
                    // log error
                    return;
            }

            // Save the search result and close the search menu
            vm.searchResult = result;
            vm.shouldOpenSearchMenu = false;
        }

        function canPostAsCommunity() {
            if (
                vm.shareTarget &&
                vm.shareTarget.location &&
                vm.shareTarget.location.permissions &&
                vm.shareTarget.location.type ===
                    WallPost.LOCATION_TYPE.COMMUNITY &&
                vm.searchResult &&
                vm.searchResult.community
            ) {
                return vm.shareTarget.location.permissions.canPostAsCommunity;
            } else {
                return false;
            }
        }

        function canPostAsDelegate() {
            if (
                vm.shareTarget &&
                vm.shareTarget.location &&
                vm.shareTarget.location.permissions &&
                vm.shareTarget.location.type ===
                    WallPost.LOCATION_TYPE.COMMUNITY &&
                vm.searchResult &&
                vm.searchResult.community
            ) {
                return vm.shareTarget.location.permissions.canPostAsDelegate;
            } else {
                return false;
            }
        }

        function canPostAsOthers() {
            return canPostAsCommunity() || canPostAsDelegate();
        }

        function showDelegateSelection(shouldShow) {
            vm.postingAsDelegate = shouldShow;
        }

        function searchCallback(searchText) {
            return Delegation.getPrincipalsForCommunity(
                vm.currentUser.id,
                vm.shareTarget.location.id,
                {
                    size: MAX_NUMBER_SEARCH_RESULTS,
                    q: searchText
                }
            );
        }
    }
})();
