(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("VendorProfileController", VendorProfileController);

    function VendorProfileController(
        $translate,
        vendorProfileResolve,
        Vendor,
        SSMTitle
    ) {
        var vm = this;
        vm.vendorData = vendorProfileResolve;
        vm.updateProfile = updateProfile;

        activate();

        function activate() {
            $translate("VENDOR.PAGE_TITLE.PROFILE").then(SSMTitle.setTitle);
        }

        function updateProfile(edits) {
            return Vendor.patchVendor(vm.vendorData.id, edits);
        }
    }
})();
