(function() {
    "use strict";

    angular
        .module("ssmAngularApp.avatarCard")
        .directive("avatarCard", avatarCard)
        .factory("ssmAvatarHelper", ssmAvatarHelper);

    function avatarCard() {
        return {
            templateUrl: "components/avatarCard/avatarCard.html",
            restrict: "E",
            replace: true,
            scope: {
                noLink: "@",
                avatarType: "@",
                item: "=",
                collection: "@",
                photoObject: "="
            },
            controller: avatarCardCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function avatarCardCtrl(ImageService, ssmAvatarHelper) {
        /*jshint validthis:true */
        var vm = this;
        vm.getAvatarClasses = getAvatarClasses;
        vm.getTargetSref = getTargetSref;
        vm.getContainerClass = getContainerClass;
        vm.getAvatar = getAvatar;
        vm.getName = getName;
        vm.getOverlay = getOverlay;

        activate();

        function activate() {
            vm.getOverlay();
        }

        function getTargetSref() {
            if (!vm.item || !vm.collection) {
                return "main.home";
            }

            var params = {
                id: vm.item.id
            };

            if (vm.collection === "communities") {
                params.url_name = vm.item.url_name;
            }

            return (
                "main." +
                vm.collection +
                "_show(" +
                JSON.stringify(params) +
                ")"
            );
        }

        function getName() {
            if (!vm.item) {
                return "";
            }

            return vm.item.full_name || vm.item.name;
        }

        function getAvatar() {
            if (!vm.item) {
                return "";
            }

            var avatar_size = vm.avatarType === "main" ? "medium" : "small";
            var avatarFileObject = vm.photoObject
                ? vm.photoObject
                : vm.item.photo;

            return ImageService.getPath(
                avatarFileObject,
                avatar_size,
                vm.collection
            );
        }

        function getAvatarClasses(noLink) {
            var classes = "";

            var imgShape = "circle";
            if (
                vm.collection === "communities" ||
                vm.collection === "organizations"
            ) {
                imgShape = "square";
            }

            classes += "custom-img-" + imgShape;
            classes += " ";
            classes += "avatar-" + vm.avatarType;
            if (noLink) {
                classes += " kill-click";
            }
            if (vm.overlayIcon) {
                classes += " half-opacity";
            }
            return classes;
        }

        function getContainerClass() {
            return "avatar-size-" + vm.avatarType;
        }

        function getOverlay() {
            if (!vm.item) {
                return;
            }
            vm.overlay = ssmAvatarHelper.statusToOverlay(vm.item.status);
        }
    }

    function ssmAvatarHelper(ssmAvatarCardConstants) {
        var service = {
            statusToOverlay: statusToOverlay
        };

        function statusToOverlay(status) {
            switch (status) {
                case ssmAvatarCardConstants.ITEM_STATUS.ARCHIVED:
                    return ssmAvatarCardConstants.OVERLAYS.ARCHIVED;
                default:
                    return null;
            }
        }

        return service;
    }
})();
