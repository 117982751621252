(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmAffiliatesForm")
        .directive("ssmAffiliatesForm", ssmAffiliatesForm)
        .controller("ssmAffiliatesFormController", ssmAffiliatesFormController);

    function ssmAffiliatesForm() {
        return {
            templateUrl: "components/affiliatesForm/ssmAffiliatesForm.html",
            restrict: "E",
            replace: true,
            scope: {
                formData: "=",
                userModel: "=",
                orgModel: "=",
                createButtonCallback: "=",
                newButtonShown: "=",
                updateUsers: "@",
                identifier: "@",
                disableEdits: "@"
            },
            controller: ssmAffiliatesFormController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmAffiliatesFormController(
        $scope,
        $timeout,
        $translate,
        Logger,
        Organization,
        SSMUtilities,
        Tenant,
        User
    ) {
        /*jshint validthis:true */
        var vm = this;

        Tenant.getTenant().then(function(tenant) {
            vm.tenant = {
                flags: tenant.flags
            };
        });

        vm.affiliateTracker = affiliateTracker;
        vm.onAffiliateSelectKeyDown = onAffiliateSelectKeyDown;
        vm.onAffiliateSelectClick = onAffiliateSelectClick;
        vm.removeAffiliate = removeAffiliate;

        vm.onSelectSuggestedOrgClick = onSelectSuggestedOrgClick;
        vm.onOrgNameInput = onOrgNameInput;
        vm.openOrgsDropdown = false;

        vm.onSelectSuggestedUserClick = onSelectSuggestedUserClick;
        vm.onUserNameInput = onUserNameInput;
        vm.openUsersDropdown = false;

        vm.hideAffiliationTypes = true;

        var TAB_KEY = 9;
        var SHIFT_KEY = 16;

        activate();

        function activate() {
            setUpAffiliations();

            if (vm.newButtonShown) {
                vm.firstItemCallback = vm.createButtonCallback;
                $translate("PROFILE.ORGANIZATION_CREATE_BUTTON_LABEL").then(
                    function(translation) {
                        vm.firstItemLabel = translation;
                    }
                );
            }

            User.affiliation_types().then(function(affiliation_types) {
                vm.affiliation_types = affiliation_types;
            });

            // Respond to programmatic changes to the model
            $scope.$on(
                SSMUtilities.SSM_AFFILIATION_PROGRAMMATIC_CHANGE_EVENT,
                function(event, identifier) {
                    if (vm.identifier && vm.identifier === identifier) {
                        $timeout(function() {
                            $scope.$apply(setUpAffiliations);
                        });
                    }
                }
            );

            if (vm.userModel && vm.userModel.createdOrg) {
                Organization.getOrganization(vm.userModel.createdOrg).then(
                    function(newOrg) {
                        vm.selectedOrg = newOrg;
                        vm.orgName = newOrg.name;
                        vm.hideAffiliationTypes = false;
                    }
                );
            }
        }

        function onSelectSuggestedUserClick(user) {
            vm.hideAffiliationTypes = false;
        }

        function onUserNameInput() {
            return User.searchByName({ name: vm.userName });
        }

        function onSelectSuggestedOrgClick(org) {
            vm.hideAffiliationTypes = false;
        }

        function onOrgNameInput(event) {
            var query = {
                organization_name: vm.orgName
            };
            return Organization.getOrganizationsByName(query);
        }

        function onAffiliateSelectKeyDown(event, item) {
            if (event.keyCode === TAB_KEY || event.keyCode === SHIFT_KEY) {
                return;
            }
            return onAffiliateSelectClick(item);
        }

        function onAffiliateSelectClick(item) {
            var newAffiliation = {
                organization: vm.updateUsers ? vm.orgModel : vm.selectedOrg,
                user_id: vm.updateUsers ? vm.selectedUser.id : vm.userModel.id,
                user_name: vm.updateUsers
                    ? vm.selectedUser.name
                    : vm.userModel.full_name,
                relationship: item
            };
            addAffiliate(newAffiliation);

            vm.orgName = null;
            vm.selectedOrg = null;
            vm.userName = null;
            vm.selectedUser = null;
            vm.hideAffiliationTypes = true;
        }

        function removeAffiliate(affiliate) {
            var index = findIndexByAffiliation(vm.affiliations, affiliate);
            if (index < 0) {
                return;
            }
            vm.affiliations.splice(index, 1);

            if (vm.formData && vm.formData.$setDirty) {
                vm.formData.$setDirty();
            }

            var removed = false;
            var itemToPush = createItemToPush(affiliate);
            index = findIndexByAffiliation(
                vm.formData.add_affiliate_list,
                itemToPush
            );
            while (index >= 0) {
                removed = true;
                vm.formData.add_affiliate_list.splice(index, 1);
                index = findIndexByAffiliation(
                    vm.formData.add_affiliate_list,
                    itemToPush
                );
            }
            // Instead of adding to remove list, we just need to remove from add list
            if (removed) {
                return;
            }

            index = findIndexByAffiliation(
                vm.formData.remove_affiliate_list,
                itemToPush
            );
            if (index < 0) {
                vm.formData.remove_affiliate_list.push(itemToPush);
            }
        }

        function addAffiliate(affiliate) {
            var index = findIndexByAffiliation(vm.affiliations, affiliate);
            if (index >= 0) {
                return;
            }
            vm.affiliations.push(affiliate);

            if (vm.formData && vm.formData.$setDirty) {
                vm.formData.$setDirty();
            }

            var removed = false;
            var itemToPush = createItemToPush(affiliate);
            index = findIndexByAffiliation(
                vm.formData.remove_affiliate_list,
                itemToPush
            );
            while (index >= 0) {
                removed = true;
                vm.formData.remove_affiliate_list.splice(index, 1);
                index = findIndexByAffiliation(
                    vm.formData.remove_affiliate_list,
                    itemToPush
                );
            }
            // Instead of adding to add list, we just need to remove from remove list
            if (removed) {
                return;
            }

            index = findIndexByAffiliation(
                vm.formData.add_affiliate_list,
                itemToPush
            );
            if (index < 0) {
                vm.formData.add_affiliate_list.push(itemToPush);
            }
        }

        function affiliateTracker(affiliate) {
            if (!affiliate) {
                return null;
            }
            return (
                affiliate.organization.id +
                "->" +
                affiliate.user_id +
                "->" +
                affiliate.relationship
            );
        }

        function findIndexByAffiliation(affiliations, affiliate) {
            return _.findIndex(affiliations, function(item) {
                return areAffiliatesEqual(item, affiliate);
            });
        }

        function createItemToPush(affiliate) {
            var itemToPush = {
                relationship: affiliate.relationship
            };
            if (vm.updateUsers) {
                itemToPush.user_id = affiliate.user_id;
                itemToPush.organization_id = vm.orgModel.id;
            } else {
                itemToPush.user_id = vm.userModel.id;
                itemToPush.organization_id = affiliate.organization.id;
            }
            return itemToPush;
        }

        function areAffiliatesEqual(affiliate1, affiliate2) {
            var organization_id1 =
                affiliate1.organization_id ||
                (affiliate1.organization ? affiliate1.organization.id : null);
            var organization_id2 =
                affiliate2.organization_id ||
                (affiliate2.organization ? affiliate2.organization.id : null);
            return (
                organization_id1 === organization_id2 &&
                affiliate1.user_id === affiliate2.user_id &&
                affiliate1.relationship === affiliate2.relationship
            );
        }

        function setUpAffiliations() {
            vm.formData.add_affiliate_list = [];
            vm.formData.remove_affiliate_list = [];
            if (vm.updateUsers) {
                vm.affiliations = _.cloneWith(vm.orgModel.affiliations || []);
            } else {
                vm.affiliations = _.cloneWith(vm.userModel.affiliations || []);
            }
        }
    }
})();
