(function() {
    "use strict";

    angular
        .module("ssmAngularApp.communityCardBrief")
        .directive("communityCardBrief", communityCardBrief);

    function communityCardBrief() {
        return {
            templateUrl:
                "components/communityCardBrief/communityCardBrief.html",
            restrict: "E",
            replace: true,
            scope: {
                subject: "=",
                restrictedHeight: "@",
                border: "@",
                noJoin: "@",
                noLink: "@"
            },
            controller: communityCardBriefCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function communityCardBriefCtrl() {
        /*jshint validthis:true */
        var vm = this;
    }
})();
