(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmSlack.service")
        .factory("ssmSlackApi", ssmSlackApi);

    function ssmSlackApi(Api) {
        var slackApi = Api.all("slack");

        var api = {
            startSlackConnection: startSlackConnection
        };

        function startSlackConnection(marketplace) {
            return slackApi.get("connection");
        }

        return api;
    }
})();
