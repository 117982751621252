(function() {
    "use strict";
    /* @ngInject */

    angular.module("ssmAngularApp.tenant.service").factory("Tenant", Tenant);

    function Tenant(
        $q,
        $location,
        Api,
        Logger,
        SSM_ASSET_URL,
        ssmLocalStorage
    ) {
        var currentHost = $location.host();
        var currentTenant;
        var tenantPromise;
        var fetchingTenant = false;

        // TODO: load these values from an organizations endpoint
        var slugToOrganizationIdMap = {
            chime: "54efe0f32e1f88382143dca8",
            ssm: "54efe0f32e1f88382143dce2",
            nwc: "54efe0f32e1f88382143dce0",
            nap: "560d3744d737aa434581bde9"
        };

        var organizationIdToSlugMap = _.invertBy(slugToOrganizationIdMap);

        // Only add new "true" slugs below
        var slugToRequiresEmailSupportLinkMap = {
            chime: true,
            default: false
        };

        var slugToRequiresOrganizationSupportLinkMap = {
            chime: true,
            default: false
        };

        // This object should always have values for every key a raw tenant can have.
        var defaultTenant = {
            site: {
                hosts: [currentHost],
                organization_id: null,

                name: "Next Wave Connect",
                slug: "nwc",
                title: "NWC",
                description:
                    "Communities for smarter healthcare. Welcome to Next Wave Connect, the first healthcare industry-specific enterprise social networking application, connecting healthcare professionals and peers, of all types, from across healthcare tenants, giving them a place to collaboratively solve our industry''s most challenging problems.",

                flags: {
                    open_content: false,
                    calendar: false,
                    invites: true,
                    find_organization: false,
                    find_someone: false,
                    disable_login_and_signup_options: false,
                    linked_in: true,
                    standard_auth: true,
                    privacy_policy: true,
                    membership_agreement: true,
                    edit_profile_questions: true,
                    enable_digest: true,
                    prevent_profile_question_sort: false,
                    marketplace_landing: false,
                    collaboration_off: false,
                    blogs_isHidden: false,
                    myLeadership_isHidden: false,
                    guest_auth: false,
                    guest_comment_disable: false,
                    open_registration: false,
                    simple_registration: false,
                    phi_agreement_disabled: false,
                    institution_isRequired: false,
                    hideForGuest: false,
                    enable_tutorial_mode: false
                },

                user_profile_slugs: [],
                forgot_password_url: undefined,

                tenant_blocked_error:
                    "Oops, the NWC API is unable to load!\n This could be caused by a firewall, server issues, or something else. Please contact an administrator if you continue experiencing this error",

                guest_register_prompt:
                    "Create a user account to gain access to new content resources, engage in the conversation, and connect with your peers.",

                requiresEmailSupportLink: false,
                requiresOrganizationSupportLink: false,

                announcement_banner_img: "foo",
                announcement_banner_url: "foo",

                announcement_banner_img_2: "foo",
                announcement_banner_url_2: "foo",

                announcement_banner_img_3: "foo",
                announcement_banner_url_3: "foo",

                headerLinks: [],
                side_nav_extras: [],
                custom_guest_community_title: "foo",

                org_super_admin: [],
                apa_sso_return_url: ""
            }
        };

        function isValidTenant(tenant) {
            var now = new Date();

            if (!tenant || !tenant.hosts) {
                return false;
            }

            return _.includes(tenant.hosts, currentHost);
        }

        function getTenant() {
            var currentIsValid = isValidTenant(currentTenant);

            // If the cached tenant data is stale, throw away any existing promise.
            if (!currentIsValid && !fetchingTenant) {
                tenantPromise = null;
            }

            // If we already have a promise, return it.
            // This will only run if the cached tenant is valid, or we have a pending
            // request to the domains api.
            if (tenantPromise) {
                return tenantPromise.promise;
            }

            // By the time we get here, we don't have a promise, and the cached data is no good.
            // Make a promise, and make an API call so we can later resolve it.
            tenantPromise = $q.defer();
            var organizationsByHostApi = Api.all("organizations").all(
                "by-host"
            );
            fetchingTenant = true;

            organizationsByHostApi
                .one(currentHost)
                .get()
                .then(function(organization) {
                    setCurrentTenant(organization.plain());
                })
                .catch(function(error) {
                    Logger.emergency(
                        "Could not load domain styling information from the api server."
                    );
                    setCurrentTenant(defaultTenant);
                });

            return tenantPromise.promise;
        }

        function setCurrentTenant(tenant) {
            fetchingTenant = false;
            currentTenant = processRawTenant(tenant);
            tenantPromise.resolve(currentTenant);
            ssmLocalStorage.set(ssmLocalStorage.key.CURRENT_TENANT, tenant);
        }

        function processRawTenant(tenant) {
            var site = tenant.site;
            var logo_url = getLogoUrl(site);

            return {
                getTitle: function(title) {
                    return site.title + " | " + title;
                },

                flags: site.flags,
                marketplace_preferences: site.marketplace_preferences,
                guest_register_prompt: site.guest_register_prompt,
                tenant_blocked_error: site.tenant_blocked_error,
                user_profile_slugs: site.user_profile_slugs,
                forgot_password_url: site.forgot_password_url,
                requiresEmailSupportLink: getRequiresEmailSupportLink(
                    site.slug
                ),
                requiresOrganizationSupportLink: getRequiresOrganizationSupportLink(
                    site.slug
                ),
                logo_url: logo_url,
                organization_id: tenant.id,
                slug: site.slug,
                description: site.description,
                announcement_banner_img: site.announcement_banner_img,
                announcement_banner_url: site.announcement_banner_url,
                announcement_banner_img_2: site.announcement_banner_img_2,
                announcement_banner_url_2: site.announcement_banner_url_2,
                announcement_banner_img_3: site.announcement_banner_img_3,
                announcement_banner_url_3: site.announcement_banner_url_3,
                images: site.images,
                marketplace_header: site.marketplace_header,
                colors: site.colors,
                eula_update_date: site.eula_update_date,
                hosts: site.hosts,
                headerLinks: site.headerLinks,
                side_nav_extras: site.side_nav_extras,
                custom_guest_community_title: site.custom_guest_community_title,
                org_super_admin: site.org_super_admin,
                apa_sso_return_url: site.apa_sso_return_url,
                sso_button: site.sso_button,
                disable_login_and_signup_options: site.disable_login_and_signup_options,
            };
        }

        function getLogoUrl(site) {
            var default_prefix = SSM_ASSET_URL + "/assets/images/";
            var default_logo_file = "logo_default.png";
            var logo_url = default_prefix + default_logo_file;

            if (site.images && site.images.length) {
                var main_logo_key = "logo_main";
                var image = _.find(site.images, {
                    name: main_logo_key
                });

                if (image) {
                    if (image.external_url) {
                        logo_url = image.external_url;
                    } else if (image.url) {
                        logo_url = default_prefix + image.url;
                    }
                }
            }

            return logo_url;
        }

        function getSlugByOrganizationId(org_id) {
            if (!org_id) {
                return "nwc"; // NWC is default slug
            }

            return organizationIdToSlugMap[org_id];
        }

        function getOrganizationIdBySlug(slug) {
            if (!slug) {
                return null;
            }

            return slugToOrganizationIdMap[slug];
        }

        function getRequiresEmailSupportLink(slug) {
            if (!slug) {
                return false;
            }

            return slugToRequiresEmailSupportLinkMap[slug];
        }

        function getRequiresOrganizationSupportLink(slug) {
            if (!slug) {
                return false;
            }

            return slugToRequiresOrganizationSupportLinkMap[slug];
        }

        return {
            getTenantSync: function() {
                if (isValidTenant(currentTenant)) {
                    return currentTenant;
                }

                var tenant =
                    ssmLocalStorage.get(ssmLocalStorage.key.CURRENT_TENANT) ||
                    defaultTenant;
                return processRawTenant(tenant);
            },

            getTenant: getTenant,
            getSlugByOrganizationId: getSlugByOrganizationId,
            getOrganizationIdBySlug: getOrganizationIdBySlug
        };
    }
})();
