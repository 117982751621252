(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .config(function($stateProvider, SSM_VENDOR_FEATURE_ON) {
            if (!SSM_VENDOR_FEATURE_ON) {
                return;
            }

            $stateProvider
                .state("main.vendor", {
                    abstract: true,
                    templateUrl: "layout/main/vendor/vendor.html"
                })
                .state("main.vendor.index", {
                    url: "/vendor",
                    templateUrl:
                        "layout/main/vendor/vendorIndex/vendorIndex.html",
                    controller: "VendorIndexController",
                    controllerAs: "vm",
                    resolve: {
                        vendorPagerResolve: resolveVendorPager
                    }
                })
                // vendor.create route needs to be above vendor.show because of how route matching works
                .state("main.vendor.create", {
                    url: "/vendor/create",
                    templateUrl:
                        "layout/main/vendor/vendorCreate/vendorCreate.html",
                    controller: "VendorCreateController",
                    controllerAs: "vm"
                })
                .state("main.vendor.show", {
                    url: "/vendor/{vendor_id}",
                    templateUrl:
                        "layout/main/vendor/vendorShow/vendorShow.html",
                    controller: "VendorShowController",
                    controllerAs: "vm",
                    redirectTo: "main.vendor.show.profile",
                    resolve: {
                        vendorProfileResolve: vendorProfileResolve
                    }
                })
                .state("main.vendor.show.profile", {
                    url: "/profile",
                    templateUrl:
                        "layout/main/vendor/vendorShow/vendorProfile/vendorProfile.html",
                    controller: "VendorProfileController",
                    controllerAs: "vm"
                })
                .state("main.vendor.show.flair", {
                    url: "/flair",
                    templateUrl:
                        "layout/main/vendor/vendorShow/vendorFlair/vendorFlair.html",
                    controller: "VendorFlairController",
                    controllerAs: "vm",
                    resolve: {
                        vendorFlairListResolve: resolveVendorFlairList
                    }
                })
                .state("main.vendor.show.flair.create", {
                    url: "/create",
                    controller: "VendorFlairCreateController",
                    controllerAs: "vm",
                    resolve: {
                        vendorLibraryResolve: resolveVendorLibrary
                    }
                })
                .state("main.vendor.show.flair.edit", {
                    url: "/{flair_id}",
                    controller: "VendorFlairEditController",
                    controllerAs: "vm",
                    resolve: {
                        vendorFlairResolve: resolveVendorFlair,
                        vendorLibraryResolve: resolveVendorLibrary
                    }
                })
                .state("main.vendor.show.library", {
                    url: "/library",
                    templateUrl:
                        "layout/main/vendor/vendorShow/vendorLibrary/vendorLibrary.html",
                    controller: "VendorLibraryController",
                    controllerAs: "vm",
                    resolve: {
                        vendorLibraryResolve: resolveVendorLibrary
                    },
                    data: {
                        analyticsName: "VendorLibrary"
                    }
                })
                .state("main.vendor.show.admins", {
                    url: "/admins",
                    templateUrl:
                        "layout/main/vendor/vendorShow/vendorAdmins/vendorAdmins.html",
                    controller: "VendorAdminsController",
                    controllerAs: "vm"
                });

            function resolveVendorPager(Vendor) {
                return Vendor.allPaged();
            }

            function vendorProfileResolve(
                $q,
                $stateParams,
                $translate,
                Logger,
                Vendor
            ) {
                var deferred = $q.defer();

                Vendor.getVendor($stateParams.vendor_id)
                    .then(deferred.resolve)
                    .catch(function(error) {
                        switch (error.status) {
                            case 403: //forbidden
                                $translate("VENDOR.ERROR.FORBIDDEN").then(
                                    function(translation) {
                                        Logger.warning(
                                            "Private Vendor: ",
                                            translation,
                                            error
                                        );
                                    }
                                );
                                break;
                            case 404:
                                return deferred.resolve();
                            default:
                                $translate("VENDOR.ERROR.DEFAULT").then(
                                    function(translation) {
                                        Logger.error(
                                            "ERROR: ",
                                            translation,
                                            error
                                        );
                                    }
                                );
                                break;
                        }
                        return deferred.reject();
                    });

                return deferred.promise;
            }

            function resolveVendorFlairList(
                $q,
                $stateParams,
                $translate,
                Logger,
                Flair
            ) {
                var deferred = $q.defer();

                Flair.getVendorFlair($stateParams.vendor_id)
                    .then(deferred.resolve)
                    .catch(function(error) {
                        switch (error.status) {
                            case 403: //forbidden
                                $translate("VENDOR.ERROR.FORBIDDEN").then(
                                    function(translation) {
                                        Logger.warning(
                                            "Private Vendor: ",
                                            translation,
                                            error
                                        );
                                    }
                                );
                                break;
                            case 404:
                                return deferred.resolve();
                            default:
                                $translate("VENDOR.ERROR.DEFAULT").then(
                                    function(translation) {
                                        Logger.error(
                                            "ERROR: ",
                                            translation,
                                            error
                                        );
                                    }
                                );
                                break;
                        }
                        return deferred.reject();
                    });

                return deferred.promise;
            }

            // We already have the full list. Find the item and return it.
            function resolveVendorFlair($stateParams, vendorFlairListResolve) {
                return _.find(vendorFlairListResolve, {
                    id: $stateParams.flair_id
                });
            }

            function resolveVendorLibrary(
                $q,
                $stateParams,
                $translate,
                Logger,
                Vendor
            ) {
                var deferred = $q.defer();

                Vendor.getVendorLibrary($stateParams.vendor_id, { limit: 20 })
                    .then(deferred.resolve)
                    .catch(function(error) {
                        switch (error.status) {
                            case 403: //forbidden
                                $translate("VENDOR.ERROR.FORBIDDEN").then(
                                    function(translation) {
                                        Logger.warning(
                                            "Private Vendor: ",
                                            translation,
                                            error
                                        );
                                    }
                                );
                                break;
                            case 404:
                                return deferred.resolve();
                            default:
                                $translate("VENDOR.ERROR.DEFAULT").then(
                                    function(translation) {
                                        Logger.error(
                                            "ERROR: ",
                                            translation,
                                            error
                                        );
                                    }
                                );
                                break;
                        }
                        return deferred.reject();
                    });

                return deferred.promise;
            }
        });
})();
