(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .factory("ssmMainRoutesHelper", ssmMainRoutesHelper)
        .config(function($stateProvider) {
            $stateProvider.state("main", {
                url: "?{notification-type}",
                templateUrl: "layout/main/main.html",
                controller: "MainCtrl",
                controllerAs: "main",
                data: {
                    requiresLogin: true
                },
                resolve: {
                    ensureCurrentUser: function(ssmMainRoutesHelper) {
                        return ssmMainRoutesHelper.ensureCurrentUser();
                    },
                    validateCurrentUser: function(
                        ensureCurrentUser,
                        ssmMainRoutesHelper
                    ) {
                        return ssmMainRoutesHelper.validateCurrentUser();
                    }
                }
            });
        });

    function ssmMainRoutesHelper(CurrentUser, $state) {
        return {
            ensureCurrentUser: function() {
                return CurrentUser.ensureLoaded();
            },

            validateCurrentUser: function() {
                const simple_registration =
                    CurrentUser.profile.simple_registration;
                if (
                    !CurrentUser.profile.completed_registration &&
                    !simple_registration
                ) {
                    return $state.go("registration");
                }

                var emails = CurrentUser.profile.emails;
                var verified = emails && emails.length && emails[0].verified;

                if (!verified && !simple_registration) {
                    return $state.go("validate");
                }
            }
        };
    }
})();
