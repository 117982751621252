// This file is for deprecating states and redirecting them to an appropriate place. The redirectTo hook will create a
// warning if data.deprecated is set.

(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("externalInvites", {
            redirectTo: "main.externalInvites",
            data: {
                deprecated: true
            }
        });
    });
})();
