(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsShowCtrl", OrgsShowCtrl);

    function OrgsShowCtrl(
        $state,
        $translate,
        Logger,
        Organization,
        orgProfileResolve,
        SSMTitle
    ) {
        var vm = this;
        vm.profileData = orgProfileResolve;
        vm.affiliates = [];
        vm.nextPage = nextPage;
        vm.disableInfiniteScrollEvents = true;
        vm.canEdit = false;
        vm.editOrg = editOrg;

        vm.products_and_services = vm.profileData.products_and_services
            ? vm.profileData.products_and_services.split(",")
            : [];
        vm.products_and_services = _.uniqBy(vm.products_and_services);

        var ORG_LIMIT = 20;

        activate();

        function activate() {
            SSMTitle.setTitle(vm.profileData.name);

            var query = { skip: 0, limit: ORG_LIMIT };
            getAffiliates(query);

            Organization.getPermissions(vm.profileData.id).then(function(
                result
            ) {
                vm.canEdit = result && result.can_edit;
            });
        }

        function getAffiliates(query) {
            return Organization.getAffiliates(vm.profileData.id)
                .then(function(affiliates) {
                    if (!affiliates || !affiliates.length) {
                        vm.disableInfiniteScrollEvents = true;
                        return;
                    }

                    vm.affiliates = vm.affiliates.concat(affiliates);
                    if (affiliates.length === ORG_LIMIT) {
                        vm.disableInfiniteScrollEvents = false;
                    }
                })
                .catch(function(error) {
                    vm.disableInfiniteScrollEvents = true;
                    $translate("ORGANIZATION.GET_MEMBERS_ERROR").then(function(
                        translation
                    ) {
                        Logger.error("ERROR: ", translation, error);
                    });
                });
        }

        function nextPage() {
            vm.disableInfiniteScrollEvents = true;
            var query = {
                skip: vm.affiliates.length,
                limit: ORG_LIMIT
            };

            getAffiliates(query);
        }

        function editOrg() {
            $state.go("main.orgs.edit", { id: vm.profileData.id });
        }
    }
})();
