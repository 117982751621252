(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsLeadershipController", OrgsLeadershipController);

    function OrgsLeadershipController(
        Organization,
        orgProfileResolve,
        Logger,
        $state,
        $translate,
        SSMTitle
    ) {
        var vm = this;
        vm.profileData = orgProfileResolve;
        vm.leaders = [];
        vm.disableInfiniteScrollEvents = true;

        activate();
        function activate() {
            $translate("ORGANIZATION.DIRECTORY.TITLE").then(function(title) {
                SSMTitle.setTitle(title);
            });

            var query = { skip: 0, limit: 25 };
            getLeadership(query);
        }

        function getLeadership(query) {
            return Organization.getAffiliates(vm.profileData.id)
                .then(function(leaders) {
                    if (!leaders || !leaders.length) {
                        vm.disableInfiniteScrollEvents = true;
                        return;
                    }

                    vm.leaders = vm.leaders.concat(leaders);
                    if (leaders.length === 25) {
                        vm.disableInfiniteScrollEvents = false;
                    }
                })
                .catch(function(error) {
                    vm.disableInfiniteScrollEvents = true;
                    $translate("ORGANIZATION.GET_MEMBERS_ERROR").then(function(
                        translation
                    ) {
                        Logger.error("ERROR: ", translation, error);
                    });
                });
        }
    }
})();
