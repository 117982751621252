(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmNotificationMenu")
        .directive("ssmNotificationMenu", ssmNotificationMenu);

    function ssmNotificationMenu() {
        return {
            templateUrl:
                "components/ssmNotificationMenu/ssmNotificationMenu.html",
            restrict: "E",
            replace: true,
            scope: {
                data: "=",
                notificationBadges: "=",
                limit: "@?",
                onVisit: "&",
                onIgnore: "&"
            },
            controller: ssmNotificationMenuCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmNotificationMenuCtrl(Analytics) {
        /*jshint validthis:true */

        var vm = this;
        vm.notificationIgnoreClick = notificationIgnoreClick;

        function notificationIgnoreClick(e, notification) {
            Analytics.eventTrack("notification.ignore", {
                category: "mainHeaderCtrl",
                label: "type:" + notification.type
            });
            Analytics.eventTrack2('notification_ignore', {
                category: "mainHeaderCtrl",
                label: "type:" + notification.type,
            });

            if (vm.onIgnore) {
                vm.onIgnore({ e: e, notification: notification });
            }
        }
    }
})();
