(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsEditSiteController", OrgsEditSiteController);

    function OrgsEditSiteController() {
        var vm = this;
    }
})();
