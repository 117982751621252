(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.Permissions.service")
        .service("Permissions", Permissions);

    function Permissions(Api) {
        var permissions = Api.all("permissions");

        return {
            addPermission: function(id, permission_name, subject) {
                return permissions.customPOST(
                    { owner_id: id, name: permission_name, subject: subject },
                    "add"
                );
            },

            removePermission: function(id, permission_name, subject) {
                return permissions.customPOST(
                    { owner_id: id, name: permission_name, subject: subject },
                    "remove"
                );
            }
        };
    }
})();
