/* jshint maxstatements:50 */
(function() {
    "use strict";

    angular
        .module("ssmAngularApp.inviteCommunitiesForm", [])
        .directive("inviteCommunitiesForm", inviteCommunitiesForm);

    function inviteCommunitiesForm() {
        return {
            templateUrl:
                "components/inviteCommunitiesForm/inviteCommunitiesForm.html",
            restrict: "E",
            scope: {
                invitedCommunities: "="
            },
            controller: InviteCommunitiesFormCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function InviteCommunitiesFormCtrl(
        CommunityMembership,
        $location,
        $translate,
        Community,
        Tenant
    ) {
        /*jshint validthis:true */
        var vm = this;
        vm.addToInvitedList = addToInvitedList;
        vm.clearFilter = clearFilter;
        vm.communities = [];
        vm.displayCommunities = [];
        vm.filterText = "";
        vm.filterCommunities = filterCommunities;
        vm.removeFromInvitedList = removeFromInvitedList;
        vm.organizationId = "";

        activate();

        function activate() {
            var query = { orgId: [] };
            Tenant.getTenant().then(function(tenant) {
                vm.organizationId = tenant.organization_id;
                query.orgId = vm.organizationId;
                Community.getInvitableCommunities(query).then(function(
                    communities
                ) {
                    vm.communities = communities || [];
                    vm.displayCommunities = vm.communities;
                });
            });
        }

        function addToInvitedList(community) {
            var index = vm.communities.indexOf(community);
            if (index < 0) {
                return;
            }

            vm.communities.splice(index, 1);
            vm.invitedCommunities.push(community);
            resetSelectionList();
        }

        function clearFilter() {
            vm.filterText = "";
            vm.displayCommunities = vm.communities;
        }

        function filterCommunities(filterText) {
            var regText = filterText.replace(/[$-\/?[-^{|}]/g, "\\$&");
            var regex =
                filterText.length < 3
                    ? new RegExp("^" + regText, "i")
                    : new RegExp(regText, "i");
            vm.displayCommunities = _.filter(vm.communities, function(
                community
            ) {
                return regex.test(community.name);
            });
        }

        function removeFromInvitedList(community) {
            var index = vm.invitedCommunities.indexOf(community);
            if (index < 0) {
                return;
            }
            vm.invitedCommunities.splice(index, 1);
            vm.communities.push(community);
            resetSelectionList();
        }

        function resetSelectionList() {
            vm.displayCommunities = vm.communities;
            filterCommunities(vm.filterText);
            resortLists();
        }

        function resortLists() {
            vm.invitedCommunities = sortCommunities(vm.invitedCommunities);
            vm.communities = sortCommunities(vm.communities);
            vm.displayCommunities = sortCommunities(vm.displayCommunities);
        }

        function sortCommunities(communities) {
            return _.sortBy(communities, "name");
        }
    }
})();
