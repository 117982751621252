(function() {
    "use strict";

    angular
        .module("ssmAngularApp.marketplace.services")
        .service("ssmListings", ssmListings);

    function ssmListings(ActiveAngular) {
        return new ActiveAngular("marketplace_campaigns/:id");
    }
})();
