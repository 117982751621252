(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("ProfilePasswordsController", ProfilePasswordsController);

    function ProfilePasswordsController(CurrentUser, Tenant, SSMTitle) {
        var vm = this;
        vm.user = CurrentUser.profile;

        SSMTitle.setTitle(vm.user.full_name);

        activate();

        function activate() {
            Tenant.getTenant().then(function(tenant) {
                vm.standardAuth = tenant.flags.standard_auth;
                vm.forgotPasswordUrl = tenant.forgot_password_url;
            });
        }
    }
})();
