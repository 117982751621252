(function() {
    "use strict";
    /* @ngInject */

    angular.module("ssmAngularApp.webapi").factory("WebApi", WebApi);

    function WebApi(Restangular) {
        return Restangular.withConfig(function(RestangularConfigurer) {
            RestangularConfigurer.setBaseUrl("/");
        });
    }
})();
