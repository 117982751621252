(function() {
    "use strict";

    angular.module("ssmAngularApp").config(function($stateProvider) {
        $stateProvider.state("ahima-sso/loading", {
            url: "/ahima-sso/loading",
            templateUrl: "layout/ssoLoadingScreen/ssoLoadingScreen.html",
            controller: "SsoLoadingScreenCtrl",
            controllerAs: "vm",
        });
    });
})();
