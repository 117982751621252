(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("CommunityShowCtrl", CommunityShowCtrl);

    function CommunityShowCtrl(
        $q,
        $stateParams,
        Community,
        communityProfileResolve,
        WallPost,
        $translate,
        AlertService,
        Logger,
        SSMTitle,
        SSM_ASSET_URL,
        SSM_FEATURES,
        resolvedCommunityPermissions,
        Tenant,
        Tutorial,
        CurrentUser
    ) {
        var vm = this;

        vm.iconUrl = SSM_ASSET_URL + "/assets/images/icon-lock.svg";

        vm.posts = [];
        vm.stickiedPosts = [];
        vm.hiddenPosts = [];

        vm.showPending = $stateParams.pending === "true";
        vm.disableInfiniteScrollEvents = false;

        vm.profileData = communityProfileResolve;

        vm.aceQty = 3;
        vm.adminQty = 3;
        vm.location = {
            id: vm.profileData.id,
            type: WallPost.LOCATION_TYPE.COMMUNITY,
            permissions: resolvedCommunityPermissions
        };

        vm.nextPage = nextPage;
        vm.processPosts = processPosts;
        vm.showPost = showPost;
        vm.onTogglePostSticky = onTogglePostSticky;
        vm.onCreatedPost = onCreatedPost;
        vm.memberCountText = null;

        vm.userIsArchived = userIsArchived;
        vm.areThereAdminsToShow = areThereAdminsToShow;
        vm.thereAreAdvertisementsToShow = thereAreAdvertisementsToShow;
        vm.getListOfAvailableAdvertisements = getListOfAvailableAdvertisements;

        activate();

        function activate() {
            addCurrentCommunityToListOfRecentlyVisitedCommunitiesForCurrentUser();
            SSMTitle.setTitle(vm.profileData.name);
            getAdmins();
            getAces();

            if (vm.profileData.follower_count) {
                $translate("COMMUNITY.MEMBERS").then(function(translation) {
                    vm.memberCountText =
                        translation + vm.profileData.follower_count;
                });
            }

            if (vm.profileData.status === Community.STATUS.ARCHIVED) {
                $translate("COMMUNITY_SHOW.NAME_TOOLTIP").then(function(
                    translation
                ) {
                    vm.tooltip = translation;
                });
            }

            if (!SSM_FEATURES.COMMUNITY_STICKIES_OFF) {
                loadStickiedPost().then((post) => processStickiedPost(post));
            }

            vm.nextPage();

            Tenant.getTenant().then(function(tenant) {
                if (tenant.flags.enable_tutorial_mode) {
                    Tutorial.openStep11to13();
                }
            });
        }

        function addCurrentCommunityToListOfRecentlyVisitedCommunitiesForCurrentUser() {
            var community_being_visited = {  
                name_of_community_just_visited: communityProfileResolve.name, 
                id_of_community_just_visited: communityProfileResolve.id
            };
            CurrentUser.update(community_being_visited);
        }

        function processStickiedPost(post) {

            if (!post) {
                return;
            }

            _.set(post, "flags.isStickied", true);
            vm.stickiedPosts.unshift(post);
            vm.hiddenPosts.unshift(post.id);
            return post;
        }

        function thereAreAdvertisementsToShow() {
            if (vm.profileData.advertisements) {
                return vm.profileData.advertisements.length >= 1;
            }
            else {
                return false;
            }
        }

        function getListOfAvailableAdvertisements() {
            // no null checking required because this function only gets called iff thereAreAdvertisementsToShow() returns true
            return vm.profileData.advertisements;
        }

        function getAdmins() {
            Community.getCommunityAdmins(vm.profileData.id)
            .then(function(_admins) {
                var admins = _admins;
                vm.adminList = admins;

                var adminsWhoWantToBeShownLast = admins.filter(
                    admin =>
                        admin.community_management_admin_sort_preference ==
                        "last"
                );

                if (adminsWhoWantToBeShownLast.length > 0) {
                    adminsWhoWantToBeShownLast.forEach(function(_admin) {
                        var index = admins.indexOf(_admin);
                        vm.adminList.splice(index, 1);
                        vm.adminList.push(_admin);
                    });

                }
            });
        }

        function getAces() {
            Community.getCommunityAces(vm.profileData.id).then(function(aces) {
                vm.aceList = aces;
            });
        }

        function nextPage() {
            var lastPost = _.last(vm.posts);
            var POST_LIMIT = 2;
            var query = {
                limit: POST_LIMIT,
                sort_by_activity: true,
                format: "enhanced"
            };

            if (lastPost && !vm.showPending) {
                query.before_date = lastPost.activity_date;
            }

            if (lastPost && vm.showPending) {
                query.after_date = lastPost.activity_date;
            }

            if (vm.showPending) {
                query.requires_approval = true;
                query.sort_by_activity = false;
            }

            vm.disableInfiniteScrollEvents = true;

            Community.getPosts(vm.profileData.id, query)
                .then(function(posts) {
                    vm.processPosts(posts);
                })
                .catch(function(error) {
                    logResults(error);
                });
        }

        function processPosts(posts) {
            if (listIsFalsey(posts)) {
                return;
            }
            vm.posts.push.apply(vm.posts, posts);
            vm.disableInfiniteScrollEvents = false;
        }

        function listIsFalsey(list) {
            return !list || list.length === 0;
        }

        function logResults(error) {
            if (error.status == 403) {
                $translate("WALL.GET_POSTS_FORBIDDEN").then(function(
                    translation
                ) {
                    Logger.warning("Private Community: ", translation, error);
                });
            } else {
                $translate("WALL.GET_POSTS_ERROR").then(function(translation) {
                    Logger.error("ERROR: ", translation, error);
                });
            }
        }

        function loadStickiedPost() {
            var postId = _.last(vm.profileData.stickied_posts);

            if (!postId) {
                return $q.when(null);
            }
            
            return WallPost.find(postId);
        }

        function clearStickiedPost(post) {
            if (!post) {
                post = _.head(vm.stickiedPosts);
                return;
            }

            _.set(post, "flags.isStickied", false);
            vm.stickiedPosts.remove(post);
            vm.hiddenPosts.remove(post.id);
            return post;
        }

        function showPost(post) {
            return !_.includes(vm.hiddenPosts, post.id);
        }

        function onCreatedPost(post) {
            if (!vm.profileData.posts_require_approval) {
                vm.stickiedPosts.unshift(post); // keep a new post at the top of the wall...
                vm.posts.unshift(post); // ... but remember it as a non-stickied post
                vm.hiddenPosts.unshift(post.id); // ... but don't display it twice
            } else {
                AlertService.add({
                    msg: "Thank you for your post! You will be notified when your post is approved.",
                    type: "info",
                    animation: "fadeInRight",
                    duration: 10000
                });
            }
        }

        function onTogglePostSticky(post) {
            if (_.get(post, "flags.isStickied")) {
                return onDeletePostSticky(post);
            } else {
                return onAddPostSticky(post);
            }
        }

        function onDeletePostSticky(post) {
            return Community.deletePostSticky(vm.profileData.id, post.id).then(
                function(result) {
                    if (!vm.profileData.stickied_posts) {
                        vm.profileData.stickied_posts = [];
                    }
                    vm.profileData.stickied_posts.remove(post.id);
                    clearStickiedPost(post);
                    return loadStickiedPost().then(processStickiedPost);
                }
            );
        }

        function onAddPostSticky(post) {
            return Community.addPostSticky(vm.profileData.id, post.id).then(
                function(result) {
                    if (!vm.profileData.stickied_posts) {
                        vm.profileData.stickied_posts = [];
                    } else {
                        vm.profileData.stickied_posts.length = 0;
                    }
                    vm.profileData.stickied_posts.unshift(post.id);
                    clearStickiedPost();
                    return processStickiedPost(post);
                }
            );
        }

        function userIsArchived() {
            const archived = "4";
            return vm.profileData.status == archived;
        }

        function areThereAdminsToShow() {
            if (vm.adminList) {
                return !!vm.adminList.length;
            }
            else {
                return false;
            }
            
        }
    }
})();
