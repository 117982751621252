/*jshint maxparams: 14 */
(function () {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("BlogShowCtrl", BlogShowCtrl);

    function BlogShowCtrl(
        Blog,
        blogProfileResolve,
        CurrentUser,
        FileSaver,
        WallPost,
        $uibModal,
        $state,
        $stateParams,
        $translate,
        Analytics,
        Logger,
        SSMTitle
    ) {
        /*jshint validthis: true */
        var vm = this;
        var blogHTML = "";
        vm.postData = blogProfileResolve;
        vm.userData = CurrentUser.profile;
        vm.likeBlog = likeBlog;
        vm.unlikeBlog = unlikeBlog;
        vm.shareBlog = shareBlog;
        vm.navigateToEdit = navigateToEdit;
        vm.downloadBlog = downloadBlog;
        vm.deleteBlog = deleteBlog;
        vm.canEdit = false;
        vm.isAdmin = false;

        activate();

        function activate() {
            SSMTitle.setTitle(vm.postData.title);

            vm.id = $stateParams.id;
            vm.location = { id: vm.id, type: WallPost.LOCATION_TYPE.BLOG };
            vm.canEdit =
                vm.userData && vm.postData.author && vm.postData.canEdit;
            vm.isAdmin = vm.userData.is_admin;

            vm.isPublished = vm.postData.status === Blog.BLOG_STATUS.PUBLISHED;

            if (!vm.postData.likes) {
                vm.postData.likes = {};
                vm.postData.likes.total_likes = 0;
            }

            blogHTML = generateHTMLDownload();
        }

        function likeBlog(blog) {
            Analytics.eventTrack("likeBlog", {
                category: "blogShow",
                label: "blog_id:" + blog.id,
            });
            Analytics.eventTrack2('like_blog', {
                category: "blogShow",
                label: "blog_id:" + blog.id,
            });
            vm.postData.likes.is_liked_by_you = true;
            vm.postData.likes.total_likes++;

            Blog.addLike(blog.id)
                .then(function (results) {
                    //Success
                })
                .catch(function (error) {
                    vm.postData.likes.is_liked_by_you = false;
                    vm.postData.likes.total_likes--;
                    $translate("WALL.LIKE_ERROR").then(function (translation) {
                        Logger.error("Error: ", translation);
                    });
                });
        }

        function unlikeBlog(blog) {
            Analytics.eventTrack("unlike", {
                category: "blogShow",
                label: "blog_id:" + vm.id,
            });
            Analytics.eventTrack2('unlike_blog', {
                category: "blogShow",
                label: "blog_id:" + vm.id,
            });
            vm.postData.likes.is_liked_by_you = false;
            vm.postData.likes.total_likes--;

            Blog.deleteLike(blog.id)
                .then(function (results) {
                    //Success
                })
                .catch(function (error) {
                    vm.postData.likes.is_liked_by_you = true;
                    vm.postData.likes.total_likes++;
                    $translate("WALL.UNLIKE_ERROR").then(function (
                        translation
                    ) {
                        Logger.error("Error: ", translation);
                    });
                });
        }

        function shareBlog() {
            var info = {
                postData: vm.postData,
                location: vm.location,
            };

            $uibModal.open({
                templateUrl: "components/modal/sharePost/sharePost.html",
                controller: "SharePostCtrl as sharePost",
                resolve: {
                    postInfo: function () {
                        return info;
                    },
                },
            });
        }

        function navigateToEdit() {
            $state.go("main.blogs.edit", { id: vm.id });
        }

        function downloadBlog() {
            const blob = new Blob([blogHTML], { type: "text/html" });

            FileSaver.saveAs(blob, vm.postData.title + ".html");
        }

        function deleteBlog() {
            Blog.helper
                .deleteBlog(vm.id, vm.userData.id)
                .then(function (result) {
                    $state.go("main.blogs.mine", { id: vm.userData.id });
                });
        }

        function generateHTMLDownload() {
            var editor = new Quill(".blog-show-body", { readOnly: true });
            var body = JSON.parse(vm.postData.body);
            editor.setContents({ ops: body.quill_ops });
            var blogContent = editor.root.innerHTML;
            var styleOpenTag = "<style>";
            var styleCloseTag = "</style>";
            var listOpenTag = "<ul>";
            var listCloseTag = "</ul>";
            var lineCSS =
                ".ql-line {margin-bottom: 22px !important; font-size: 14px; font-family: Helvetica,Arial,sans-serif;}";
            var titleCSS = ".blog-title {font-size: 20px !important;}";
            var titleHTML =
                '<div class="ql-line blog-title">' +
                vm.postData.title +
                "</div>";
            var hashTagsList = "";
            _.forEach(vm.postData.hash_tags, function (hashTag) {
                var hashTagHTML = "<li> <div>" + hashTag + "</div> </li>";
                hashTagsList = hashTagsList.concat(hashTagHTML);
            });
            var styling = styleOpenTag + lineCSS + titleCSS + styleCloseTag;
            var hashTags = listOpenTag + hashTagsList + listCloseTag;
            var generatedHTML = styling + titleHTML + blogContent + hashTags;
            return generatedHTML;
        }
    }
})();
