(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.sharedVariable.service", ["ssmAngularApp.api"])
        .service("sharedVariableService", sharedVariableService);

    function sharedVariableService() {
        var shared = {};
        var valid = false;

        // TODO: Key shared data off a unique key to prevent shared data getting clobbered by multiple service users
        return {
            getShared: function() {
                return shared;
            },

            getSharedAndInvalidate: function() {
                valid = false;
                return shared;
            },

            setShared: function(value) {
                shared = value;
                valid = true;
            },

            getValid: function() {
                return valid;
            },

            setValid: function(value) {
                valid = value;
            }
        };
    }
})();
