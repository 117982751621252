(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .factory("profileRoutesHelper", profileRoutesHelper)
        .config(function($stateProvider) {
            $stateProvider
                .state("main.profile", {
                    abstract: true,
                    templateUrl: "layout/main/profile/profile.html",
                    controller: "ProfileCtrl",
                    controllerAs: "vm"
                })
                .state("main.profile.settings", {
                    url: "/profile/settings",
                    templateUrl:
                        "layout/main/profile/profileSettings/profileSettings.html",
                    controller: "ProfileSettingsCtrl",
                    controllerAs: "vm",
                    params: {
                        organization_id: null
                    },
                    resolve: {
                        tenantResolve: function(profileRoutesHelper) {
                            return profileRoutesHelper.tenantResolve();
                        }
                    }
                })
                .state("main.profile.questions", {
                    url: "/profile/questions",
                    templateUrl:
                        "layout/main/profile/profileQuestions/profileQuestions.html",
                    controller: "ProfileQuestionsCtrl",
                    controllerAs: "vm",
                    resolve: {
                        questionResolve: function(profileRoutesHelper) {
                            return profileRoutesHelper.questionResolve();
                        },
                        tenantResolve: function(profileRoutesHelper) {
                            return profileRoutesHelper.tenantResolve();
                        }
                    }
                })
                .state("main.profile.notifications", {
                    url: "/profile/notifications",
                    templateUrl:
                        "layout/main/profile/profileNotifications/profileNotifications.html",
                    controller: "ProfileNotificationsCtrl",
                    controllerAs: "vm"
                })
                .state("main.profile.passwords", {
                    url: "/profile/passwords",
                    templateUrl:
                        "layout/main/profile/profilePasswords/profilePasswords.html",
                    controller: "ProfilePasswordsController",
                    controllerAs: "vm"
                })
                .state("main.profile.delegations", {
                    url: "/profile/delegations",
                    templateUrl:
                        "layout/main/profile/profileDelegations/profileDelegations.html",
                    controller: "ProfileDelegationsController",
                    controllerAs: "vm",
                    resolve: {
                        delegatesResolve: function(profileRoutesHelper) {
                            return profileRoutesHelper.delegatesResolve();
                        }
                    }
                });
        });

    function profileRoutesHelper($q, CurrentUser, Delegation, Tenant, User) {
        var helper = {
            questionResolve: questionResolve,
            tenantResolve: tenantResolve,
            delegatesResolve: delegatesResolve
        };

        return helper;

        function questionResolve() {
            return Tenant.getTenant().then(function(tenant) {
                var slugs =
                    tenant.user_profile_slugs &&
                    tenant.user_profile_slugs.length
                        ? tenant.user_profile_slugs
                        : [tenant.slug];
                var promises = [];
                slugs.forEach(function(slug) {
                    promises.push(
                        User.getQuestions(CurrentUser.profile.id, slug)
                    );
                });

                return $q.all(promises);
            });
        }

        function tenantResolve() {
            return Tenant.getTenant();
        }

        function delegatesResolve() {
            return Delegation.getDelegates(CurrentUser.profile.id);
        }
    }
})();
