(function() {
    "use strict";
    /* @ngInject */

    angular.module("ssmAngularApp.user.service").service("User", User);

    function User(Api) {
        var user = Api.all("user");
        var users = Api.all("users");
        var posts = Api.all("posts");

        return {
            affiliation_types: function() {
                return user.all("affiliation_types").getList({});
            },

            getUser: function(id) {
                return user.one(id, "profile").get();
            },

            getAllUsersAssociatedWith: function(organization_name) {
                return new Promise((resolve, _) => {
                    var route = "getAllUsersAssociatedWith/" + organization_name;
                    return resolve(user.one(route).getList());
                });
            },

            getOrgsCurrentUserIsApartOfAsync: function(id) {
                return new Promise((resolve, _) => {
                    var route = "affiliatedOrganizations/" + id;
                    return resolve(user.one(route).getList());
                });
            },

            getAces: function() {
                return user.all("aces").getList({});
            },

            getBlogs: function(id, query) {
                return user.one(id, "blogs").get(query);
            },

            getCommunityManagers: function() {
                return user.all("community_managers").getList();
            },

            getPosts: function(id, query) {
                //query : { limit:number, before_date:Date }
                return user
                    .one(id)
                    .all("posts")
                    .getList(query);
            },

            getPDF: function(searchObject, params) {
                return user.customPOST(searchObject, "user_pdf", params);
            },

            getQuestions: function(id, field_provider) {
                return user
                    .one(id)
                    .all("profile_questions")
                    .get(field_provider);
            },

            saveQuestions: function(id, answers) {
                return user
                    .one(id)
                    .all("profile_questions")
                    .patch(answers);
            },

            getPost: function(id) {
                return posts.one(id).get({ format: "enhanced" });
            },

            createPost: function(id, post) {
                return user
                    .one(id)
                    .all("posts")
                    .post(post, { format: "enhanced" });
            },

            createDigest: function(id, sendto) {
                if (sendto) {
                    return user
                        .one(id)
                        .all("createdigest")
                        .post({ sendto: sendto });
                } else {
                    return user
                        .one(id)
                        .all("createdigest")
                        .post();
                }
            },

            previewDigest: function(id) {
                return user.one(id, "previewdigest").get();
            },

            postCommunityManager: function(id, community_manager) {
                return user
                    .one(id)
                    .all("community_manager")
                    .post({ community_manager: community_manager });
            },

            postSiteAdministrator: function(id, site_administrator) {
                return user
                    .one(id)
                    .all("site_administrator")
                    .post({ site_administrator: site_administrator });
            },

            postOrganizationalSiteAdministrator: function(id, enable) {
                return user
                    .one(id)
                    .all("organizational_site_administrator")
                    .post({ enable: enable });
            },

            report: function(query) {
                var format = query.format || "application/json";
                var params = {
                    skip: query.skip,
                    limit: query.limit,
                    end_date: query.end_date,
                    start_date: query.start_date,
                    date_format: query.date_format
                };

                if (query.platforms) {
                    params["platforms"] = query.platforms;
                }

                return user.one("user_report").get(params, { Accept: format });
            },

            searchByName: function(params) {
                return users.one("search").get(params);
            },

            updateAffiliations: function(id, params) {
                return user.one(id, "affiliations").patch(params);
            },

            updateSlack: function(id, params) {
                return user.one(id, "slack").patch(params);
            },

            updateProfile: function(id, params) {
                return user.one(id, "profile").patch(params);
            }
        };
    }
})();
