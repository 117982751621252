(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.socket.service", ["ssmAngularApp.config"])
        .factory("Socket", SocketService);

    function SocketService(SSM_SOCKET_URL, Logger) {
        var socket = null;
        var disabled = false;

        var disable = function() {
            if (socket) {
                socket.disconnect();
            }
            socket = null;
            disabled = true;
        };

        var socketConnect = function(user_id) {
            if (!user_id) {
                return;
            }
            if (socket || disabled) {
                return;
            }

            // TODO this should be https
            socket = io.connect(
                SSM_SOCKET_URL,
                {
                    query: "ssm_user_id=" + user_id,
                    "sync disconnect on unload": true
                }
            );

            //allow us to attempt reconnection after we are disconnected
            socket.on("disconnect", function(source) {
                socket = undefined;
            });
        };

        var socketEmit = function(event, data) {
            if (!socket || disabled) {
                // TODO SSM-135 log/analytics
                return;
            }
            // TODO SSM-135 log/analytics
            socket.emit(event, data);
        };

        var socketListen = function(event, callback) {
            if (!socket || disabled) {
                // TODO SSM-135 log/analytics
                return;
            }
            // TODO SSM-135 log/analytics
            socket.on(event, function(arg) {
                var promise = callback(arg);
                if (promise) {
                    promise.catch(function(err) {
                        socket.disconnect();
                        socket = undefined;
                    });
                }
            });
        };

        return {
            socketConnect: socketConnect,
            socketEmit: socketEmit,
            socketListen: socketListen,
            disable: disable
        };
    }
})();
