"use strict";

angular.module("ssmAngularApp.layout").config(function($stateProvider) {
    $stateProvider.state("main.search", {
        url: "/search",
        abstract: true,
        templateUrl: "layout/main/search/search.html",
        controller: "SearchCtrl",
        controllerAs: "vm"
    });
});
