(function() {
    "use strict";
    /* @ngInject */
    /*jshint maxparams: 12 */

    angular
        .module("ssmAngularApp.wallComments")
        .directive("wallComments", wallComments)
        .controller("wallCommentsCtrl", wallCommentsCtrl);

    function wallComments() {
        return {
            templateUrl: "components/wallComments/wallComments.html",
            restrict: "E",
            scope: {
                postData: "=",
                location: "=",
                collection: "=",
                maxDisplayed: "@"
            },
            controller: wallCommentsCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function wallCommentsCtrl(
        $uibModal,
        $scope,
        $translate,
        Analytics,
        Audience,
        Blog,
        Community,
        CurrentUser,
        Logger,
        PostComments,
        Tenant,
        WallPost
    ) {
        /*jshint validthis: true */
        var vm = this;

        var dimension2 = vm.location 
            && vm.location.id ? vm.location.id : "Unknown";

        var dimension4 = vm.postData 
            && vm.postData.location 
            && vm.postData.location.id ? vm.postData.location.id : "Unknown";

        vm.commentPlaceholder = $translate.instant("WALL.COMMENT_PLACEHOLDER");

        vm.isGuest = CurrentUser.profile.is_guest;
        vm.addComment = addComment;
        vm.likeComment = likeComment;
        vm.unlikeComment = unlikeComment;
        vm.removeComment = removeComment;
        vm.getTargetSref = getTargetSref;
        vm.viewLikes = viewLikes;
        vm.getCollection = getCollection;
        vm.canDelete = canDelete;
        vm.canComment = canComment;
        vm.noContent = noContent;
        vm.showAll = showAll;
        vm.countCommentDescendants = countCommentDescendants;
        vm.processComments = processComments;
        vm.newCommentMessage = "";
        vm.beginEditingComment = beginEditingComment;
        vm.finishEditingComment = finishEditingComment;
        vm.cancelEditingComment = cancelEditingComment;

        vm.canCommentAsCommunity = false;
        vm.POST_AS_TYPE = WallPost.POST_AS_TYPE;

        activate();

        function activate() {
            vm.currentUser = CurrentUser.profile;
            vm.processComments();

            $scope.$watchCollection("vm.postData.comments", function() {
                vm.processComments();
            });

            if (vm.postData.location 
                && vm.postData.location.type 
                && vm.postData.location.id
            ) {
                switch (vm.postData.location.type) {
                    case WallPost.LOCATION_TYPE.COMMUNITY:
                        Community.getCommunity(vm.postData.location.id, true).then(
                            function(community) {
                                // TODO this should be hydrated into comment.community.url_name on the back end
                                vm.community_name_url = community.url_name;

                                vm.canCommentAsCommunity = _.includes(
                                    community.admins,
                                    vm.currentUser.id
                                );
                            }
                        );
                        break;
                    default:
                        vm.canCommentAsCommunity = false;
                        break;
                }
            } else {
                vm.canCommentAsCommunity = false;
            }
        }

        function countCommentDescendants(comment) {
            comment.descendant_count =
                _.sumBy(vm.commentsMap[comment.id], vm.countCommentDescendants) 
                    + _.get(vm.commentsMap[comment.id], "length", 0);
            return comment.descendant_count;
        }

        function addComment(comment) {
            comment.addingChild = true;
        }

        function likeComment(comment) {
            Analytics.eventTrack("likeComment", {
                category: "wallCommentsCtrl",
                label:
                    "post_id:" + vm.postData.id + ", comment_id:" + comment.id,
                dimension2: dimension2,
                dimension4: dimension4
            });
            Analytics.eventTrack2('like_comment', {
                category: "wallCommentsCtrl",
                label: "post_id:" + vm.postData.id + ", comment_id:" + comment.id,
                dimension2: dimension2,
                dimension4: dimension4
            });

            comment.likes.is_liked_by_you = true;
            comment.likes.total_likes++;

            var emailAddress = _.head(vm.currentUser.emails).email;

            Tenant.getTenant().then(function(tenant) {
                Audience.updateHashTags(
                    tenant.organization_id,
                    emailAddress,
                    comment.inline_hash_tags
                );
            });

            switch (vm.collection) {
                case "posts":
                    PostComments.like(vm.postData.id, comment.id)
                    .catch(function(error) {
                        comment.likes.is_liked_by_you = false;
                        comment.likes.total_likes--;
                        if (error.status !== 403) {
                            $translate("WALL.LIKE_ERROR")
                            .then(function(translation) {
                                Logger.error("ERROR: ", translation, error);
                            });
                        }
                    });
                    break;

                case "blogs":
                    Blog.addCommentLike(vm.postData.id, comment.id)
                    .catch(function(error) {
                        comment.likes.is_liked_by_you = false;
                        comment.likes.total_likes--;
                        if (error.status !== 403) {
                            $translate("WALL.LIKE_ERROR").then(function(translation) {
                                Logger.error("ERROR: ", translation, error);
                            });
                        }
                    });
                    break;
            }
        }

        function processComments() {
            vm.commentsMap = _.groupBy(vm.postData.comments, "parent_id");
            vm.commentsMap[undefined] = vm.commentsMap[undefined] || [];
            _.each(vm.commentsMap[undefined], vm.countCommentDescendants);
            vm.limit = +vm.maxDisplayed 
                && +vm.maxDisplayed + 1 === vm.commentsMap[undefined].length
                    ? vm.commentsMap[undefined].length
                    : +vm.maxDisplayed;
        }

        function unlikeComment(comment) {
            Analytics.eventTrack("unLikeComment", {
                category: "wallCommentsCtrl",
                label: "post_id:" + vm.postData.id + ", comment_id:" + comment.id,
                dimension2: dimension2,
                dimension4: dimension4
            });
            Analytics.eventTrack2('unlike_comment', {
                category: "wallCommentsCtrl",
                label: "post_id:" + vm.postData.id + ", comment_id:" + comment.id,
                dimension2: dimension2,
                dimension4: dimension4
            });

            comment.likes.is_liked_by_you = false;
            comment.likes.total_likes--;

            switch (vm.collection) {
                case "posts":
                    PostComments.unLike(vm.postData.id, comment.id)
                    .catch(function(error) {
                        comment.likes.is_liked_by_you = true;
                        comment.likes.total_likes++;
                        $translate("WALL.UNLIKE_ERROR").then(function(translation) {
                            Logger.error("ERROR: ", translation, error);
                        });
                    });
                    break;

                case "blogs":
                    Blog.deleteCommentLike(vm.postData.id, comment.id)
                    .catch(function(error) {
                        comment.likes.is_liked_by_you = true;
                        comment.likes.total_likes++;
                        $translate("WALL.UNLIKE_ERROR").then(function(translation) {
                            Logger.error("ERROR: ", translation, error);
                        });
                    });
                    break;
            }
        }

        function removeComment(comment) {
            Analytics.eventTrack("removeComment", {
                category: "wallCommentsCtrl",
                label: "comment_id:" + comment.id,
                dimension2: dimension2,
                dimension4: dimension4
            });
            Analytics.eventTrack2('remove_comment', {
                category: "wallCommentsCtrl",
                label: "comment_id:" + comment.id,
                dimension2: dimension2,
                dimension4: dimension4
            });

            switch (vm.collection) {
                case "posts":
                    PostComments.delete(vm.postData.id, comment.id)
                        .then(function(data) {
                            vm.postData = data;
                        })
                        .catch(function(error) {
                            $translate("WALL.COMMENT_DELETE_ERROR").then(
                                function(translation) {
                                    Logger.error("ERROR: ", translation, error);
                                }
                            );
                        });
                    break;

                case "blogs":
                    Blog.deleteComment(vm.postData.id, comment.id)
                        .then(function(data) {
                            vm.postData = data;
                        })
                        .catch(function(error) {
                            $translate("WALL.COMMENT_DELETE_ERROR").then(
                                function(translation) {
                                    Logger.error("ERROR: ", translation, error);
                                }
                            );
                        });
                    break;
            }
        }

        function getTargetSref(target) {
            var target_type = target.author_type || target.type;
            var params = {
                id: target.id
            };
            switch (target_type) {
                case WallPost.LOCATION_TYPE.USER:
                    return "main.users_show(" + JSON.stringify(params) + ")";
                case WallPost.LOCATION_TYPE.COMMUNITY:
                    params.url_name = vm.postData.community.url_name;
                    return (
                        "main.communities_show(" + JSON.stringify(params) + ")"
                    );
            }
        }

        function showAll() {
            vm.limit = 0;
        }

        function viewLikes(comment) {
            if (comment.likes && comment.likes.total_likes) {
                $uibModal.open({
                    templateUrl: "components/modal/viewLikes/viewLikes.html",
                    controller: "viewLikesCtrl as viewLikes",
                    resolve: {
                        likesResolve: function(PostComments) {
                            return PostComments.allLikes(
                                vm.postData.id,
                                comment.id
                            );
                        }
                    }
                });
            }
        }

        function getCollection(comment) {
            var collection = comment.community && comment.community.posted_as_community
                    ? "communities"
                    : "users";
            return collection;
        }

        function canDelete(comment) {
            return (
                (vm.postData.community && vm.postData.location.permissions.canEditAsCommunityPosts) 
                    
                    ||

                (
                    (!vm.postData.community || vm.postData.location.permissions.canComment) 
                
                        && // Parent post is either not located in a community or the community permits comments
                   
                    (
                        (comment.is_yours && !vm.isGuest) 
                        
                            ||

                        (comment.author && comment.author.author_type === WallPost.LOCATION_TYPE.COMMUNITY && vm.canCommentAsCommunity)
                    )
                )

            ); // Comment is either yours or a community comment and you can post as community
        }

        function canComment() {
            return (vm.postData 
                && vm.postData.location 
                && vm.postData.location.permissions 
                && vm.postData.location.permissions.canComment);
        }

        function noContent() {
            return (!vm.postData.comments 
                || !vm.newCommentMessage 
                || !vm.newCommentMessage.trim());
        }

        function beginEditingComment(comment) {
            vm.oldComment = comment.message.slice(0);
            comment.editing = true;
        }

        function finishEditingComment(comment) {
            comment.editing = false;
            const updatedComment = {
                id: comment.id,
                message: comment.message
            };
            switch (vm.collection) {
                case "posts":
                    WallPost.updateComment(vm.postData.id, updatedComment);
                    break;
                case "blogs":
                    Blog.updateComment(vm.postData.id, updatedComment);
                    break;
            }
        }

        function cancelEditingComment(comment) {
            comment.message = vm.oldComment;
            comment.editing = false;
        }
    }
})();
