(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsEditProfileController", OrgsEditProfileController);

    function OrgsEditProfileController(
        $scope,
        $translate,
        Analytics,
        Logger,
        Organization,
        orgProfileResolve,
        SSMTitle,
        SSMUtilities
    ) {
        var vm = this;
        vm.org = orgProfileResolve;
        vm.saveForm = saveForm;
        vm.resetForm = resetForm;
        vm.modelData = {};

        vm.BROADCAST_IDENTIFIER = "OrgsEditProfileController";

        activate();

        function activate() {
            $translate("ORGANIZATION.EDIT.PROFILE.TITLE").then(
                SSMTitle.setTitle
            );
            resetForm(null);
        }

        function saveForm(form) {
            var params = {
                name: vm.modelData.name,
                bio: vm.modelData.bio,
                sector:
                    vm.modelData.sector !== "None" ? vm.modelData.sector : "",
                photo_id: vm.modelData.photo_id,
                hash_tags: vm.modelData.hash_tags,
                addresses: vm.modelData.addresses
            };

            Analytics.eventTrack("updateOrganization", {
                category: "editProfile",
                label:
                    params.name +
                    " address: " +
                    params.addresses +
                    ", photo_id: " +
                    params.photo_id
            });
            Analytics.eventTrack2("update_organization", {
                category: "editProfile",
                label:
                    params.name +
                    " address: " +
                    params.addresses +
                    ", photo_id: " +
                    params.photo_id
            });

            return Organization.update(vm.org.id, params)
                .then(function(results) {
                    updateOrg(results, form);
                    vm.flashSavedFlag();
                })
                .catch(function(error) {
                    if (error.status === 409) {
                        $translate("ORGANIZATION.CREATE.ERROR.DUPLICATE").then(
                            function(translation) {
                                Logger.error("Error: ", translation);
                            }
                        );
                    } else {
                        $translate("ORGANIZATION.EDIT.ERROR.GENERAL").then(
                            function(translation) {
                                Logger.error("Error: ", translation);
                            }
                        );
                    }
                });
        }

        function resetForm(form) {
            Organization.getOrganization(vm.org.id)
                .then(function(results) {
                    updateOrg(results, form);
                    if (form && form.$setPristine) {
                        form.$setPristine();
                    }
                })
                .catch(function(error) {
                    Logger.error(
                        "ERROR: ",
                        "HTTP Server Error: " + error.status
                    );
                });
        }

        function updateOrg(data, form) {
            /*jshint maxcomplexity:9 */
            if (!data) {
                return;
            }

            if (
                !vm.modelData ||
                !vm.modelData.addresses ||
                !vm.modelData.addresses.length
            ) {
                vm.modelData = {
                    addresses: [{}]
                };
            }

            vm.modelData.name = data.name;
            vm.modelData.bio = data.bio;
            vm.modelData.sector = data.sector || "None";
            vm.modelData.photo_id = data.photo_id;
            vm.modelData.photo = data.photo;
            vm.modelData.uploadedImage = [];
            vm.modelData.hash_tags = data.hash_tags || [];
            $scope.$broadcast(
                SSMUtilities.SSM_TAG_SELECT_PROGRAMMATIC_CHANGE_EVENT,
                vm.BROADCAST_IDENTIFIER
            );

            if (data.addresses && data.addresses.length && data.addresses[0]) {
                vm.modelData.addresses = data.addresses;
            }

            if (form && form.$setPristine) {
                form.$setPristine();
            }
        }
    }
})();
