(function() {
    "use strict";

    angular.module("ssmAngularApp.libraryFile", [
        "ssmAngularApp.layout",
        "ssmAngularApp.api",
        "ssmAngularApp.analytics",
        "ssmAngularApp.modal",
        "ngFileUpload",
        "ssmAngularApp.ssmFiles.service"
    ]);
})();
