(function() {
    "use strict";
    /**
     * @ngInject
     */

    // Based off of angular-enhance-text
    // https://github.com/Raydiation/angular-enhance-text

    angular
        .module("ssmAngularApp.ssmEnhanceText", [
            "ngSanitize",
            "ssmAngularApp.ssmInlineContentHelper",
            "ssmAngularApp.currentUser"
        ])
        .filter("ssmEnhanceText", ssmEnhanceText)
        .factory("ssmEnhanceTextHelper", ssmEnhanceTextHelper)
        .factory("ssmHtmlEmbedders", ssmHtmlEmbedders);

    function ssmEnhanceText(ssmEnhanceTextHelper) {
        var defaults = {
            cache: true,
            embedLinks: true,
            embeddedLinkTarget: "_blank",
            embedMp3s: true,
            embedYouTube: false,
            prettyLinks: {}, // e.g., prettyLinks["http://bing.com"] = { redirect: "http://google.com" }
            inlineHashTags: []
        };

        return function(text, options) {
            if (!text) {
                return text;
            }

            var config = angular.extend({}, defaults, options);
            text = ssmEnhanceTextHelper.enhanceText(text, config);
            return text;
        };
    }

    function ssmEnhanceTextHelper(ssmHtmlEmbedders, SSMInlineContentHelper) {
        return {
            enhanceText: enhanceText
        };

        function enhanceText(text, options) {
            if (!text) {
                return text;
            }

            text = escapeHtml(text);

            // if for some reason options is broken at least return the escaped text so we don't let
            // bad code through
            if (!options) {
                return text;
            }

            text = SSMInlineContentHelper.hydrateInlineContentForWall(
                text,
                options.inlineHashTags
            );

            // Order is important here.  We want mp3 and youtube handled before we embed any remaining links

            if (options.embedMp3s) {
                text = ssmHtmlEmbedders.embedMp3s(text);
            }

            if (options.embedYouTube) {
                text = ssmHtmlEmbedders.embedYouTube(text);
            }

            if (options.embedLinks) {
                text = ssmHtmlEmbedders.embedLinks(
                    text,
                    options.embeddedLinkTarget,
                    options.id,
                    options.prettyLinks
                );
            }

            text = checkForMarkUp(text);

            return text;
        }

        function escapeHtml(str) {
            var div = document.createElement("div");
            div.appendChild(document.createTextNode(str));
            return div.innerHTML;
        }

        function checkForMarkUp(text) {
            if (!text) {
                return text;
            }
            var regexoptions = [
                /\[(b)\](.*?)\[\/b\]/gi,
                /\[(i)\](.*?)\[\/i\]/gi,
                /\[(u)\](.*?)\[\/u\]/gi,
                /\[(h3)\](.*?)\[\/h3\]/gi,
                /\[(li)\](.*?)\[\/li\]/gi
            ];

            var matches = [];
            var match;

            for (var option in regexoptions) {
                while ((match = regexoptions[option].exec(text)) !== null) {
                    matches.push({
                        replace: match[0],
                        tag: match[1],
                        innerText: match[2]
                    });
                }

                _.forEach(matches, function(markup) {
                    var html =
                        "<" +
                        markup.tag +
                        ">" +
                        markup.innerText +
                        "</" +
                        markup.tag +
                        ">";
                    text = text.replace(markup.replace.trim(), html);
                });
            }

            return text;
        }
    }

    function ssmHtmlEmbedders(CurrentUser) {
        return {
            embedLinks: embedLinks,
            embedMp3s: embedMp3s,
            embedYouTube: embedYouTube
        };

        function createLink(url, target, postId, linkText) {
            if (!linkText) {
                linkText = url;
            }
            /*
            return (
                '<ssm-trackable-link url="' +
                url +
                '" target="' +
                target +
                '" link-text="' +
                linkText +
                '" post-id="' +
                postId +
                '" event-type="link"></ssm-trackable-link>'
            );
            */
            return (
                `<ssm-trackable-link  
                    url="${linkText}"
                    target="${target}"
                    link-text="${linkText}"  
                    post-id="${postId}"  
                    event-type="link">
                </ssm-trackable-link>`
            );
        }

        function embedLinks(text, target, postId, prettyLinks) {
            if (!text) {
                return text;
            }

            prettyLinks = prettyLinks || {};

            var emails = CurrentUser.profile.emails[0].email;

            // ignore any links that were previously formatted
            var regex = /((href|src|link\-text|url)=["']|)(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

            return text.replace(regex, function(
                fullMatch,
                hrefOrSrc,
                hrefOrSrcPosition,
                url
            ) {
                var display = url;

                if (prettyLinks[url]) {
                    // if (  )
                    url = prettyLinks[url].redirect || url;
                }
                if (!hrefOrSrc && emails) {
                    url =
                        url.indexOf("?") === -1
                            ? url + `?email=${emails}`
                            : url + `&email=${emails}`;
                }
                return hrefOrSrc
                    ? fullMatch
                    : createLink(url, target, postId, display);
            });
        }

        function embedMp3s(text) {
            if (!text) {
                return text;
            }

            var html = [];
            var regex = /((?:https?):\/\/\S*\.(?:mp3))/gi;

            // Embed an audio player for every browser except IE9, which won't show the controls
            if (!window.isIE9) {
                html.push(
                    '<audio class="embeddedMp3" src="$1" controls preload="none"><p>Your browser does not support the <code>audio</code> element.</p></audio>'
                );
            }

            // leave the link in so we also have a download link
            html.push('<div class="embeddedMp3DownloadLink">$1</div>');

            return text.replace(regex, html.join(""));
        }

        function embedYouTube(text) {
            if (!text) {
                return text;
            }

            var regex = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/ytscreeningroom\?v=|\/feeds\/api\/videos\/|\/user\S*[^\w\-\s]|\S*[^\w\-\s]))([\w\-]{11})[?=&+%\w-]*/gi;

            var html =
                '<ssm-you-tube-player video-id="$1"></ssm-you-tube-player>';

            return text.replace(regex, html);
        }
    }
})();
