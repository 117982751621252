(function() {
    "use strict";

    angular.module("ssmAngularApp").config(function($stateProvider) {
        $stateProvider.state("signup", {
            url: "/signup?{?id}{utm_source}{utm_medium}{utm_campaign}",
            templateUrl: "layout/signUpPage/signUpPage.html",
            controller: "SignUpPageCtrl",
            controllerAs: "vm",
            data: {
                allowTenant: "standard_auth",
                disableLastAttemptedUrl: true
            }
        });
    });
})();
