(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("main.blogs.show", {
            url: "/blogs/{id}",
            templateUrl: "layout/main/blog/blogShow/blogShow.html",
            controller: "BlogShowCtrl",
            controllerAs: "blog",
            resolve: {
                blogProfileResolve: function($state, $stateParams, Blog) {
                    if (!$stateParams) {
                        return $state.go("404", {}, { location: "replace" });
                    }

                    return Blog.getBlog($stateParams.id).catch(function(error) {
                        return $state.go("404", {}, { location: "replace" });
                    });
                }
            }
        });
    });
})();
