(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmWallPoll")
        .directive("ssmWallPoll", ssmWallPoll);

    function ssmWallPoll() {
        return {
            templateUrl: "components/ssmWallPoll/ssmWallPoll.html",
            restrict: "E",
            scope: {
                postData: "=",
                createView: "@",
                location: "=",
                editView: "@"
            },
            replace: true,
            controller: ssmWallPollControl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmWallPollControl(WallPost, $translate, Analytics, Logger) {
        var vm = this;
        vm.views = {};
        vm.maxPollChoices = 20;

        var dimension2 =
            vm.location && vm.location.id ? vm.location.id : "Unknown";
        var dimension4 =
            vm.postData && vm.postData.location && vm.postData.location.id
                ? vm.postData.location.id
                : "Unknown";

        vm.choice_id = null;
        vm.showCreate = showCreate;
        vm.showEdit = showEdit;
        vm.showVote = showVote;
        vm.showResults = showResults;
        vm.getTotalVotes = getTotalVotes;
        vm.getPercentOfTotal = getPercentOfTotal;
        vm.addChoice = addChoice;
        vm.removeChoice = removeChoice;
        vm.removePoll = removePoll;
        vm.vote = vote;

        // Logic for which view to show
        function showCreate() {
            return vm.postData && vm.postData.poll && !!vm.createView;
        }

        function showEdit() {
            return (
                vm.postData &&
                vm.postData.poll &&
                vm.editView &&
                getTotalVotes() === 0
            );
        }

        function showVote() {
            return (
                vm.postData &&
                vm.postData.poll &&
                !vm.showCreate() &&
                !vm.showEdit() &&
                !vm.postData.poll.you_voted
            );
        }

        function showResults() {
            return (
                vm.postData &&
                vm.postData.poll &&
                !vm.showCreate() &&
                !vm.showEdit() &&
                !vm.showVote()
            );
        }

        // Calculation logic for votes
        function getPercentOfTotal(numVotes) {
            var totalVotes = getTotalVotes();

            if (numVotes <= 0 || numVotes > totalVotes) {
                return 0;
            }

            return totalVotes > 0
                ? Math.round((100 * numVotes) / getTotalVotes())
                : 0;
        }

        function getTotalVotes() {
            return _.reduce(
                vm.postData.poll.choices,
                function(subtotal, choice) {
                    return subtotal + (choice.num_votes ? choice.num_votes : 0);
                },
                0
            );
        }

        // Editing the poll
        function addChoice() {
            vm.postData.poll.choices.push({ text: "" });
        }

        function removeChoice(item) {
            if (vm.postData.poll.choices.length < 3) {
                return;
            }

            _.remove(vm.postData.poll.choices, item);
        }

        function removePoll() {
            delete vm.postData.poll;
        }

        // Working with the poll
        function vote(choice_id) {
            WallPost.vote(vm.postData.id, choice_id)
                .then(function(hydratedPost) {
                    Analytics.eventTrack("vote", {
                        category: "ssmWallPollCtrl",
                        label:
                            "post_id: " +
                            vm.postData.id +
                            ", choice_id: " +
                            choice_id,
                        dimension2: dimension2,
                        dimension4: dimension4
                    });
                    Analytics.eventTrack2('vote', {
                        category: "ssmWallPollCtrl",
                        label: "post_id: " +
                            vm.postData.id +
                            ", choice_id: " +
                            choice_id,
                        dimension2: dimension2,
                        dimension4: dimension4
                    });
                    vm.postData = hydratedPost;
                })
                .catch(function(error) {
                    if (error.status !== 403) {
                        $translate("WALL.POLL.VOTE_ERROR").then(function(
                            translation
                        ) {
                            Logger.error("ERROR: ", translation, error);
                        });
                    }
                });
        }
    }
})();
