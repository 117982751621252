(function() {
    "use strict";
    /* @ngInject */

    angular.module("ssmAngularApp.analytics").factory("Analytics", Analytics);

    function Analytics($analytics, $state, CurrentUser, ssmLocalStorage, $window) {
        var EVENT_DEFAULT_PROPERTIES = {
            // Google Analytics requires that we set a category.  If we don't provide one set it to 'Uncategorized'
            category: "Uncategorized",
            label: "",

            //These are google analytics custom dimensions.  The custom dimensions are defined in the GA
            //admin tool.  The dimensions have a friendly name in GA but not when you push values up to the
            //server.  Finally GA doesn't like nulls so I'm pushing unknowns for unset values.
            //
            //  Currently:
            //      dimension1 - UserID
            //      dimension2 - LocationID - this is where the action is being taken userwall, community, etc
            //      dimension3 - slug - this is the super community id
            //      dimension4 - HomeLocation - this is the actual home location of the item.  Community/UserWall
            dimension1: "Unknown",
            dimension2: "Unknown",
            dimension3: "Unknown",
            dimension4: "Unknown"
        };

        var service = {
            eventTrack: eventTrack,
            pageTrack: pageTrack,
            setUsername: setUsername,
            flairClickEvents: flairClickEvents,
            flairDisplayEvents: flairDisplayEvents,
            trackUTMCampaign: trackUTMCampaign,
            determineAnalyticsCategory: determineAnalyticsCategory,
            eventTrack2: eventTrack2
        };

        return service;

        //see EVENT_DEFAULT_PROPERTIES constant to check how the dimensions are declared
        function eventTrack2(eventName, userProperties){

            var current_user_id =
                !CurrentUser.profile || !CurrentUser.profile.id
                    ? "Unknown"
                    : CurrentUser.profile.id;

            var tenant = ssmLocalStorage.get(
                ssmLocalStorage.key.CURRENT_TENANT
            );
            let dim3 = !tenant || !tenant.site || !tenant.site.slug
                    ? "Unknown"
                    : tenant.site.slug;
            
            let user_from_local_storage = ssmLocalStorage.get(
                ssmLocalStorage.key.CURRENT_USER
            );

            let properties = {
                'event': eventName,
                category: userProperties.category || "Uncategorized",
                label: userProperties.label || "",
                dimension1: current_user_id,
                dimension2: userProperties.dimension2 || "unknown",
                dimension3: dim3,
                dimension4: userProperties.dimension4 || "unknown",
                //userID2: user_from_local_storage || current_user_id
                user_id: user_from_local_storage.id || current_user_id
            }
            
            $window.dataLayer = $window.dataLayer || [];
            $window.dataLayer.push(properties);
        }

        function eventTrack(action, properties) {
            _.defaults(properties, angular.copy(EVENT_DEFAULT_PROPERTIES));

            var current_user_id =
                !CurrentUser.profile || !CurrentUser.profile.id
                    ? "Unknown"
                    : CurrentUser.profile.id;
            properties.dimension1 = current_user_id;

            var tenant = ssmLocalStorage.get(
                ssmLocalStorage.key.CURRENT_TENANT
            );
            properties.dimension3 =
                !tenant || !tenant.site || !tenant.site.slug
                    ? "Unknown"
                    : tenant.site.slug;

            service.setUsername(current_user_id);
            $analytics.eventTrack(action, properties);
        }

        function pageTrack(path) {
            var current_user_id =
                !CurrentUser.profile || !CurrentUser.profile.id
                    ? "Unknown"
                    : CurrentUser.profile.id;
            service.setUsername(current_user_id);
            $analytics.pageTrack(path);
        }

        function setUsername(userId) {
            // This is Google analytics specific until angulartics updates the GA provider
            // https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#user
            if (window.ga) {
                window.ga("set", "userId", userId);
            }
            $analytics.setUsername(userId);
        }

        function flairClickEvents(flair, category) {
            _.chain(flair)
                .flatten()
                .each(function(thisFlair) {
                    if (thisFlair && thisFlair.id) {
                        service.eventTrack("flairClick", {
                            category: category,
                            label: "flair: " + thisFlair.id
                        });
                    }
                });
        }

        function flairDisplayEvents(flair, category) {
            _.chain(flair)
                .flatten()
                .each(function(thisFlair) {
                    if (thisFlair && thisFlair.id) {
                        service.eventTrack("flairDisplay", {
                            category: category,
                            label: "flair: " + thisFlair.id
                        });
                    }
                });
        }

        function trackUTMCampaign(campaignParams, event, category) {
            if (campaignParams && campaignParams.utm_campaign) {
                service.eventTrack(event, {
                    category: category,
                    label: campaignParams.utm_campaign,
                    dimension2: campaignParams.utm_source,
                    dimension4: campaignParams.utm_medium
                });
            }
        }

        // return a different category depending on what state the user was in when performing the action.
        // Useful for determining where a common component is called from. i.e. Follow/Unfollow community can be done from the community page, community directories, or user registration.
        function determineAnalyticsCategory() {
            if ($state.$current.data && $state.$current.data.analyticsName) {
                return $state.$current.data.analyticsName;
            } else {
                return $state.$current.name;
            }
        }
    }
})();
