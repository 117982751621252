(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.fileUpload")
        .constant("FILE_UPLOAD_RESET_EVENT", "FILE_UPLOAD_RESET_EVENT")
        .directive("fileUpload", fileUpload)
        .controller("fileUploadCtrl", fileUploadCtrl)
        .filter("validationFailures", validationFailures);

    function fileUpload() {
        return {
            templateUrl: "components/fileUpload/fileUpload.html",
            restrict: "E",
            scope: {
                files: "=",
                maxFiles: "@",
                maxFileSize: "@",
                accept: "@",
                fileRole: "@",
                onUpload: "&"
            },
            replace: true,
            controller: fileUploadCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function fileUploadCtrl(
        $scope,
        $timeout,
        SSM_FILES,
        ssmFileUpload,
        FILE_UPLOAD_RESET_EVENT
    ) {
        /*jshint validthis: true */
        var vm = this;

        vm.removeProgress = removeProgress;
        vm.uploadFile = uploadFile;
        vm.uploadFiles = uploadFiles;
        vm.validateUpload = validateUpload;
        vm.selectedFiles = [];

        activate();

        function activate() {
            vm.uploadedFiles = 0;
            vm.files = vm.files || [];
            vm.accept = vm.accept || SSM_FILES.ROLE[vm.fileRole].accept;
            vm.imagePreview = SSM_FILES.ROLE[vm.fileRole].isImage || false;
            vm.isMultiUpload = vm.maxFiles > 1;
            vm.maxFileSize = vm.maxFileSize || "1GB";

            $scope.$on(FILE_UPLOAD_RESET_EVENT, resetFileUpload);
        }

        function resetFileUpload(event) {
            vm.selectedFiles.length = 0;
            vm.files.length = 0;
        }

        function removeProgress(file, removeRemaining) {
            var fileIndex = _.findIndex(vm.selectedFiles, file);
            if (fileIndex >= 0) {
                vm.selectedFiles.splice(
                    fileIndex,
                    removeRemaining ? vm.selectedFiles.length : 1
                );
            }
        }

        function uploadFile(fileToUpload) {
            vm.selectedFiles.push(fileToUpload);

            var fileInfo = {
                size: fileToUpload.size,
                file_type: fileToUpload.type,
                role: vm.fileRole,
                name: fileToUpload.name
            };

            function onProgress(percentageEvent) {
                if (percentageEvent.total !== 0) {
                    fileToUpload.progress =
                        (percentageEvent.loaded / percentageEvent.total) * 100;
                }
            }

            ssmFileUpload
                .upload(fileToUpload, fileInfo, onProgress)
                .then(function(updatedFile) {
                    if (vm.imagePreview) {
                        vm.removeProgress(fileToUpload); // only remove the finished progress bar if we are going to display the image preview.
                    }

                    if (!vm.isMultiUpload) {
                        //If single upload, nuke existing file.
                        vm.files = [];
                    }
                    vm.files.push(updatedFile);
                    $scope.fileUpload.$setDirty();

                    if (!!vm.onUpload) {
                        $timeout(function() {
                            vm.onUpload();
                        });
                    }
                })
                .catch(function(err) {
                    vm.uploadedFiles--;
                    vm.removeProgress(fileToUpload);
                });
        }

        function uploadFiles(filesToUpload) {
            vm.selectedFiles = [];

            if (_.isEmpty(filesToUpload)) {
                return;
            }

            vm.uploadedFiles = vm.files.length;
            // Clone array because vm.uploadFile removes items from vm.selectedFiles
            _.each(_.cloneWith(filesToUpload), vm.uploadFile);
        }

        function validateUpload(fileToUpload) {
            // Check count limit
            if (vm.isMultiUpload) {
                if (vm.uploadedFiles >= vm.maxFiles) {
                    return {
                        message: "FILE.FILE_COUNT_LIMIT_ERROR",
                        values: {
                            limit: vm.maxFiles
                        }
                    };
                }
            }

            vm.uploadedFiles++;
            return true;
        }
    }

    function validationFailures() {
        return function(invalidFiles) {
            return _.map(invalidFiles, function(invalidFile) {
                var validationFailure = null;
                switch (invalidFile.$error) {
                    case "validateFn":
                        validationFailure = invalidFile.$errorParam;
                        break;
                    case "pattern":
                        validationFailure = {
                            message: "FILE.FILE_TYPE_ERROR"
                        };
                        break;
                    case "maxSize":
                        validationFailure = {
                            message: "FILE.FILE_SIZE_LIMIT_ERROR"
                        };
                        break;
                    default:
                        validationFailure = {
                            message: "FILE.FILE_GENERIC_ERROR"
                        };
                }
                invalidFile.$validationFailure = validationFailure;
                return invalidFile;
            });
        };
    }
})();
