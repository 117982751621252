(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmSmartKeystroke", [])
        .directive("ssmSmartKeystroke", ssmSmartKeystroke);

    function ssmSmartKeystroke($timeout, $translate, $compile) {
        var DELAY_TIME_BEFORE_POSTING = 3000;
        var DELAY_TIME_HIDING_FLAG = 3000;

        return {
            restrict: "A",
            scope: {
                ssmSmartKeystroke: "&",
                ssmTime: "@",
                ssmSavedFlag: "@"
            },
            link: function(scope, element) {
                DELAY_TIME_BEFORE_POSTING =
                    scope.ssmTime || DELAY_TIME_BEFORE_POSTING;

                var currentTimeout;
                var hideSavedFlagPromise;

                $timeout(function() {
                    var elementInputs = element.find('input[type="text"]');
                    var elementSelect = element.find(
                        'select, input[type="checkbox"]'
                    );

                    if (scope.ssmSavedFlag) {
                        $translate("FORMS.SAVED_FORM").then(function(
                            translation
                        ) {
                            var newElement = angular.element(
                                '<div class="ssm-saved-flag bg-primary" ng-show="savedFlagToggle">' +
                                    translation +
                                    "</div>"
                            );
                            element.append($compile(newElement)(scope));
                        });
                    }
                    elementSelect.bind("change", function() {
                        timeoutCall(true);
                    });
                    elementInputs.bind("keydown keypress", function() {
                        timeoutCall(false);
                    });
                });

                function timeoutCall(skipDelay) {
                    scope.savedFlagToggle = false;
                    scope.$apply();
                    $timeout.cancel(currentTimeout);
                    currentTimeout = $timeout(
                        function() {
                            scope.savedFlagToggle = true;
                            $timeout.cancel(hideSavedFlagPromise);
                            hideSavedFlagPromise = $timeout(function() {
                                scope.savedFlagToggle = false;
                            }, DELAY_TIME_HIDING_FLAG);
                            evalFunction();
                        },
                        skipDelay ? 0 : DELAY_TIME_BEFORE_POSTING
                    );
                }

                function evalFunction() {
                    scope.$apply(function() {
                        scope.ssmSmartKeystroke();
                    });
                }
            }
        };
    }
})();
