(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.communityCard")
        .directive("communityCard", communityCard);

    function communityCard() {
        return {
            templateUrl: "components/communityCard/communityCard.html",
            restrict: "E",
            controller: communityCardCtrl,
            controllerAs: "communityCard",
            bindToController: true
        };
    }

    function communityCardCtrl() {
        /*jshint validthis:true */
        var vm = this;
    }
})();
