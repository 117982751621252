(function() {
    "use strict";

    angular.module("ssmAngularApp.libraryFolder", [
        "ssmAngularApp.layout",
        "ssmAngularApp.api",
        "ssmAngularApp.analytics",
        "ngFileUpload",
        "ssmAngularApp.ssmFolders.service"
    ]);
})();
