(function() {
    "use strict";

    angular
        .module("ssmAngularApp.inviteResults", [])
        .directive("inviteResults", inviteResults);

    function inviteResults() {
        return {
            templateUrl: "components/inviteResults/inviteResults.html",
            restrict: "E",
            scope: {
                existing: "=",
                invites: "="
            },
            controller: inviteResultsCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function inviteResultsCtrl() {
        /*jshint validthis:true */
        var vm = this;

        activate();

        function activate() {}
    }
})();
