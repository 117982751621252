(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmSVG")
        .directive("ssmHeaderBreak", ssmHeaderBreak);

    function ssmHeaderBreak() {
        return {
            templateUrl: "svg/ssmHeaderBreak/ssmHeaderBreak.html",
            restrict: "E",
            replace: true,
            scope: {
                small: "@"
            }
        };
    }
})();
