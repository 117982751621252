(function() {
    angular.module("ssmAngularApp.core", [
        /*
         *  Angular modules
         */
        "ngAnimate",
        "ngSanitize",

        /*
         *  Reusable cross app code modules
         */
        "ssmAngularApp.config",
        "ssmAngularApp.ssmAuth.service",
        "ssmAngularApp.ssmSVG",
        "ssmAngularApp.branding",

        /*
         *  3rd Party modules
         */
        "ui.router",
        "monospaced.elastic",
        "pascalprecht.translate",
        "ui.bootstrap",
        "restangular",
        "ui.bootstrap.showErrors",
        "mgo-angular-wizard",
        "ngImgCrop",
        "angulartics",
        "angulartics.google.analytics",
        "ui.bootstrap.dropdown",
        "ngFileUpload",
        "angularMoment",
        "infinite-scroll",
        "ui.calendar",
        "ngScroll",
        "colorpicker.module",
        "agGrid",
        "ui.select",
        "mentio",
        "angular-cache",
        "notification"
    ]);
})();
