(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("main.orgs", {
            abstract: true,
            templateUrl: "layout/main/orgs/orgs.html"
        });

        $stateProvider.state("main.orgs.find_results", {
            url: "/orgs/find_results?{?continuationtoken}{?start?}{?size}",
            templateUrl:
                "layout/main/orgs/orgsFindResults/orgsFindResults.html",
            controller: "OrgsFindResultsCtrl",
            controllerAs: "results",
            data: {
                allowTenant: "find_organization"
            }
        });

        $stateProvider.state("main.orgs.find", {
            url: "/orgs/find",
            templateUrl: "layout/main/orgs/orgsFind/orgsFind.html",
            controller: "OrgsFindCtrl",
            controllerAs: "find",
            data: {
                allowTenant: "find_organization"
            }
        });

        $stateProvider.state("main.orgs.create", {
            url: "/orgs/create?params",
            templateUrl: "layout/main/orgs/create/orgsCreate.html",
            controller: "OrgsCreateController as vm"
        });

        $stateProvider.state("main.organizations_show", {
            url: "/orgs/{id}",
            templateUrl: "layout/main/orgs/orgsShow/orgsShow.html",
            controller: "OrgsShowCtrl",
            controllerAs: "org",
            resolve: {
                orgProfileResolve: orgProfileResolve
            }
        });

        $stateProvider.state("main.orgs.leadership", {
            url: "/orgs/{id}/affiliates",
            templateUrl: "layout/main/orgs/orgsLeadership/orgsLeadership.html",
            controller: "OrgsLeadershipController",
            controllerAs: "vm",
            resolve: {
                orgProfileResolve: orgProfileResolve
            }
        });

        $stateProvider
            .state("main.orgs.edit", {
                url: "/orgs/{id}/edit",
                templateUrl: "layout/main/orgs/edit/orgsEdit.html",
                controller: "OrgsEditController as vm",
                redirectTo: "main.orgs.edit.profile",
                resolve: {
                    orgProfileResolve: orgProfileResolve,
                    orgPermissionsResolve: orgPermissionsResolve
                }
            })
            .state("main.orgs.edit.admins", {
                url: "/admins",
                templateUrl: "layout/main/orgs/edit/admins/editAdmins.html",
                controller: "OrgsEditAdminsController as vm",
                resolve: {
                    orgAdminsResolve: orgAdminsResolve
                }
            })
            .state("main.orgs.edit.affiliates", {
                url: "/affiliates",
                templateUrl:
                    "layout/main/orgs/edit/affiliates/editAffiliates.html",
                controller: "OrgsEditAffiliatesController as vm"
            })
            .state("main.orgs.edit.profile", {
                url: "/profile",
                templateUrl: "layout/main/orgs/edit/profile/editProfile.html",
                controller: "OrgsEditProfileController as vm"
            })
            .state("main.orgs.edit.site", {
                url: "/site",
                templateUrl: "layout/main/orgs/edit/site/editSite.html",
                controller: "OrgsEditSiteController as vm",
                redirectTo: "main.orgs.edit.site.hosting"
            })
            .state("main.orgs.edit.site.branding", {
                url: "/branding",
                templateUrl:
                    "layout/main/orgs/edit/site/branding/editBranding.html",
                controller: "OrgsEditBrandingController as vm"
            })
            .state("main.orgs.edit.site.digest", {
                url: "/digest",
                templateUrl:
                    "layout/main/orgs/edit/site/digest/editDigest.html",
                controller: "OrgsEditDigestController as vm"
            })
            .state("main.orgs.edit.site.hosting", {
                url: "/hosting",
                templateUrl:
                    "layout/main/orgs/edit/site/hosting/editHosting.html",
                controller: "OrgsEditHostingController as vm"
            })
            .state("main.orgs.edit.site.members", {
                url: "/members",
                templateUrl:
                    "layout/main/orgs/edit/site/members/editMembers.html",
                controller: "OrgsEditMembersController as vm"
            });
    });

    function orgProfileResolve(
        Logger,
        Organization,
        $state,
        $stateParams,
        $translate
    ) {
        return Organization.getOrganization($stateParams.id).catch(function(
            error
        ) {
            switch (error.status) {
                case 403:
                case 404:
                    $state.go("404", {}, { location: "replace" });
                    break;
                default:
                    $translate("ORGANIZATION.EDIT.ERROR.DEFAULT").then(function(
                        translation
                    ) {
                        Logger.error("Error: ", translation);
                    });
                    break;
            }
        });
    }

    function orgAdminsResolve($stateParams, $translate, Logger, Organization) {
        return Organization.getAdmins($stateParams.id).catch(function(error) {
            $translate("ORGANIZATION.EDIT.ERROR.DEFAULT").then(function(
                translation
            ) {
                Logger.error("Error: ", translation);
            });
        });
    }

    function orgPermissionsResolve($q, $state, $stateParams, Organization) {
        return Organization.getPermissions($stateParams.id)
            .then(function(result) {
                if (!result || !result.can_edit) {
                    $state.go("404", {}, { location: "replace" });
                }

                return $q.when(result);
            })
            .catch(function(error) {
                $state.go("404", {}, { location: "replace" });
            });
    }
})();
