(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmSlack.service")
        .factory("ssmSlack", ssmSlack);

    function ssmSlack(ssmSlackApi) {
        var service = {
            startSlackConnection: startSlackConnection
        };

        return service;

        function startSlackConnection() {
            return ssmSlackApi.startSlackConnection();
        }
    }
})();
