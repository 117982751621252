(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("passwordReset", {
            url: "/password-reset?{token}",
            templateUrl: "layout/passwordReset/passwordReset.html",
            controller: "PasswordResetCtrl",
            controllerAs: "vm"
        });
    });
})();
