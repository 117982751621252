(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmVendorAdminForm", [
            "ssmAngularApp.vendor.service"
        ])
        .directive("ssmVendorAdminForm", ssmVendorAdminForm)
        .controller(
            "ssmVendorAdminFormController",
            ssmVendorAdminFormController
        )
        .factory("ssmVendorAdminFormHelper", ssmVendorAdminFormHelper);

    function ssmVendorAdminForm() {
        return {
            templateUrl:
                "components/ssmVendorAdminForm/ssmVendorAdminForm.html",
            restrict: "E",
            scope: {
                vendorData: "="
            },
            controller: ssmVendorAdminFormController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmVendorAdminFormController(
        $scope,
        $q,
        Vendor,
        ssmVendorAdminFormHelper
    ) {
        /*jshint validthis: true */
        var vm = this;

        if (!vm.vendorData.admins) {
            vm.vendorData.admins = [];
        }

        vm.administrators = {
            members: []
        };

        vm.updateForm = updateForm;

        activate();

        function activate() {
            Vendor.getAdmins(vm.vendorData.id)
                .then(ssmVendorAdminFormHelper.convertAdmins)
                .then(function(admins) {
                    vm.administrators.members = admins;
                });
            $scope.$watch("vm.administrators.members", updateForm, true);
        }

        function updateForm(newValue, oldValue) {
            var newAdmins = _.map(newValue, "id");
            var oldAdmins = _.map(oldValue, "id");
            var addedAdmins = _.difference(newAdmins, oldAdmins);
            var removedAdmins = _.difference(oldAdmins, newAdmins);

            var addPromises = _.map(addedAdmins, function(admin) {
                return Vendor.addAdmin(vm.vendorData.id, admin);
            });

            var deletePromises = _.map(removedAdmins, function(admin) {
                return Vendor.deleteAdmin(vm.vendorData.id, admin);
            });

            return $q.all(_.union(addPromises, deletePromises));
        }
    }

    function ssmVendorAdminFormHelper() {
        return {
            convertAdmin: convertAdmin,
            convertAdmins: convertAdmins
        };

        function convertAdmin(admin) {
            if (!admin || !admin.id) {
                return null;
            }

            return { id: admin.id, user: admin };
        }

        function convertAdmins(admins) {
            return _.map(admins, convertAdmin);
        }
    }
})();
