(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.photoGallery")
        .directive("photoGallery", photoGallery);

    function photoGallery() {
        return {
            templateUrl: "components/photoGallery/photoGallery.html",
            restrict: "E",
            scope: {
                files: "=",
                createView: "@"
            },
            replace: true,
            controller: photoGalleryCtrl,
            controllerAs: "photoGallery",
            bindToController: true
        };
    }

    function photoGalleryCtrl(SSM_FILES, ssmFileUpload, Logger) {
        /*jshint validthis: true */
        var vm = this;

        vm.editImages = editImages;
        vm.viewImages = viewImages;
        vm.remove = remove;
        vm.fileCount = 0;
        vm.isImageLoading = false;
        vm.currentImages = [];

        function editImages() {
            vm.currentImages = _.filter(vm.files, function(file) {
                return -1 !== SSM_FILES.TYPES.IMAGE.indexOf(file.file_type);
            });

            if (vm.fileCount < vm.currentImages.length) {
                if (vm.isImageLoading == false) {
                    if(isImageType) {
                        loadImages(_.last(vm.currentImages));
                        ++vm.fileCount;
                    }
                }
            }

            return vm.currentImages;
        }

        function viewImages() {
            _.filter(vm.files, function(file) {
                return isImageType(file);
            });

            if (vm.isImageLoading == false) {
                _.forEach(vm.files, function(img, data) {
                    loadImages(img);
                });
            }

            return vm.files;
        }

        function loadImages(image) {
            vm.isImageLoading = true;
            loadImage(image.urls.download, function(img, data) {
                img.crossOrigin = "anonymous";
                if (img.type === "error") {
                    Logger.error("Error loading image " + image.urls.download);
                } else {
                    EXIF.getData(img, function() {
                        var orientation = EXIF.getTag(this, "Orientation");
                        var canvas = loadImage.scale(img, {
                            orientation: orientation || 0,
                            canvas: true
                        });
                        var imageID = image.id;
                        var imageElem = document.getElementById(imageID);
                        imageElem.appendChild(canvas);
                        if (canvas.className != "img-responsive") {
                            canvas.className = "img-responsive";
                        }
                        vm.isImageLoading = false;
                    });
                }
            });
        }

        function isImageType(file) {
            return -1 === SSM_FILES.TYPES.IMAGE.indexOf(file.file_type);
        }

        function remove(event, index) {
            --vm.fileCount;

            event.preventDefault();
            if (index < 0 || index >= vm.currentImages.length) {
                return;
            }
            var fileIndex = getFilesIndex(vm.currentImages, index);
            if (vm.handleRemove) {
                vm.handleRemove({ fileIndex: fileIndex });
            } else {
                vm.files.splice(fileIndex, 1);
            }
        }

        function getFilesIndex(currentImages, index) {
            var targetId = currentImages[index].id;
            var fileIndex = _.findIndex(vm.files, function(file) {
                return file.id === targetId;
            });

            return fileIndex;
        }
    }
})();
