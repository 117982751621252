(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmMarketplaceListing")
        .directive("ssmMarketplaceListing", ssmMarketplaceListing)
        .controller(
            "ssmMarketplaceListingController",
            ssmMarketplaceListingController
        );

    function ssmMarketplaceListing() {
        return {
            templateUrl:
                "components/ssmMarketplaceListing/ssmMarketplaceListing.html",
            restrict: "E",
            replace: true,
            scope: {
                listing: "=",
                marketplaceId: "=",
                marketplaceRoute: "="
            },
            controller: ssmMarketplaceListingController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmMarketplaceListingController($location, Analytics) {
        /*jshint validthis:true */
        var vm = this;
        vm.listingClick = listingClick;

        if (vm.listing.files && vm.listing.files.length) {
            vm.photoObject = vm.listing.files[0];
        }

        function listingClick() {
            Analytics.eventTrack("listingClick", {
                category: "marketplaceListingCard",
                label: "listing_id: " + vm.listing.id,
                dimension2: vm.marketplaceId,
                dimension4: vm.listing.id
            });
            Analytics.eventTrack2('listing_click', {
                category: "marketplaceListingCard",
                label: "listing_id: " + vm.listing.id,
                dimension2: vm.marketplaceId,
                dimension4: vm.listing.id
            });
        }
    }
})();
