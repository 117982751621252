(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmVendorFlairList", ["ssmAngularApp.flair"])
        .directive("ssmVendorFlairList", ssmVendorFlairList)
        .controller(
            "ssmVendorFlairListController",
            ssmVendorFlairListController
        );

    function ssmVendorFlairList() {
        return {
            templateUrl:
                "components/ssmVendorFlairList/ssmVendorFlairList.html",
            restrict: "E",
            scope: {
                flairData: "=",
                onDelete: "&",
                onEdit: "&"
            },
            controller: ssmVendorFlairListController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmVendorFlairListController(Flair) {
        /*jshint validthis: true */
        var vm = this;

        // TODO belongs in a config somewhere
        vm.showDestination = true;
        vm.showLevel = true;
        vm.showPriority = false;
        vm.showSize = false;
        vm.showStatus = true;
        vm.showTopic = true;
        vm.showStartDate = true;
        vm.showEndDate = true;

        vm.showDelete = angular.isDefined(vm.onDelete);
        vm.showEdit = angular.isDefined(vm.onEdit);

        vm.levelStrings = Flair.LEVEL_STRINGS;
        vm.priorityStrings = Flair.PRIORITY_STRINGS;
        vm.sizeStrings = Flair.SIZE_STRINGS;
        vm.statusStrings = Flair.STATUS_STRINGS;

        if (!vm.flairData) {
            vm.flairData = [];
        }

        vm.isWarning = isWarning;
        vm.buttonClickDelete = buttonClickDelete;
        vm.buttonClickEdit = buttonClickEdit;

        function isWarning(flair) {
            return flair.status !== Flair.STATUS.ACTIVE;
        }

        function buttonClickDelete(item) {
            return vm.onDelete({ flair: item });
        }

        function buttonClickEdit(item) {
            return vm.onEdit({ flair: item });
        }
    }
})();
