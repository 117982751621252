(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller(
            "OrgsEditAffiliatesController",
            OrgsEditAffiliatesController
        );

    function OrgsEditAffiliatesController(
        $scope,
        $translate,
        Logger,
        Organization,
        orgProfileResolve,
        SSMTitle,
        SSMUtilities
    ) {
        var vm = this;
        vm.org = orgProfileResolve;

        vm.saveForm = saveForm;
        vm.resetForm = resetForm;

        vm.broadcastIdentifier = "OrgsEditAffiliatesController";

        activate();

        function activate() {
            $translate("ORGANIZATION.EDIT.AFFILIATES.TITLE").then(
                SSMTitle.setTitle
            );
            resetForm();
        }

        function saveForm(form) {
            var params = {
                add_affiliate_list: form.add_affiliate_list,
                remove_affiliate_list: form.remove_affiliate_list
            };
            Organization.updateAffiliates(vm.org.id, params)
                .then(function(results) {
                    vm.flashSavedFlag();
                    form.add_affiliate_list = [];
                    form.remove_affiliate_list = [];
                    if (form && form.$setPristine) {
                        form.$setPristine();
                    }
                })
                .catch(function(error) {
                    switch (error.status) {
                        case 403: //forbidden
                            $translate(
                                "ORGANIZATION.EDIT.ERROR.FORBIDDEN"
                            ).then(function(translation) {
                                Logger.error("Error: ", translation);
                            });
                            break;
                        default:
                            $translate("ORGANIZATION.EDIT.ERROR.DEFAULT").then(
                                function(translation) {
                                    Logger.error("Error: ", translation);
                                }
                            );
                            break;
                    }
                });
        }

        function resetForm(form) {
            if (form) {
                form.add_affiliate_list = [];
                form.remove_affiliate_list = [];
            }
            vm.org.affiliations = [];
            Organization.getAffiliates(vm.org.id)
                .then(function(results) {
                    vm.org.affiliations = _(results)
                        .map(function(user) {
                            var result = _.clone(user);
                            _.forEach(result.affiliations || [], function(
                                affiliation
                            ) {
                                affiliation.user_id = user.id;
                                affiliation.user_name = user.full_name;
                            });
                            return result;
                        })
                        .map("affiliations")
                        .flatten()
                        .compact()
                        .filter(function(affiliation) {
                            return (
                                affiliation.organization &&
                                affiliation.organization.id === vm.org.id
                            );
                        })
                        .uniqBy(function(affiliation) {
                            return (
                                affiliation.user_id +
                                "->" +
                                affiliation.relationship
                            );
                        })
                        .value();
                    $scope.$broadcast(
                        SSMUtilities.SSM_AFFILIATION_PROGRAMMATIC_CHANGE_EVENT,
                        vm.broadcastIdentifier
                    );
                    if (form && form.$setPristine) {
                        form.$setPristine();
                    }
                })
                .catch(function(error) {
                    $translate("ORGANIZATION.EDIT.ERROR.DEFAULT").then(function(
                        translation
                    ) {
                        Logger.error("Error: ", translation);
                    });
                });
        }
    }
})();
