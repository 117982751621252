(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.ssmKeywordFilter")
        .directive("ssmKeywordFilter", ssmKeywordFilter)
        .controller("ssmKeywordFilterController", ssmKeywordFilterController);

    function ssmKeywordFilter() {
        return {
            templateUrl: "components/ssmKeywordFilter/ssmKeywordFilter.html",
            restrict: "E",
            replace: true,
            scope: {
                buttonText: "@",
                inputPlaceholder: "@",
                paramName: "@"
            },
            controller: "ssmKeywordFilterController as vm",
            bindToController: true
        };
    }

    function ssmKeywordFilterController($scope, $state) {
        /*jshint validthis:true */

        var vm = this;

        vm.setUrlParam = setUrlParam;
        vm.getKeyword = getKeyword;

        activate();

        function activate() {
            vm.getKeyword();

            $scope.$on("$stateChangeSuccess", function() {
                vm.getKeyword();
            });
        }

        function getKeyword() {
            vm.keyword = "";
            if ($state.params[vm.paramName]) {
                vm.keyword = $state.params[vm.paramName];
            }
        }

        function setUrlParam(keyword) {
            vm.keyword = keyword;
            var filter = {};
            filter[vm.paramName] = vm.keyword;

            $state.go(".", filter);
        }
    }
})();
