(function() {
    "use strict";

    angular.module("ssmAngularApp.mainHeader").constant("mainHeaderConstants", {
        SEARCH_DROPDOWN: {
            ALL: "MAINHEADER.SEARCH_DROPDOWN.ALL",
            MARKETPLACE: "MAINHEADER.SEARCH_DROPDOWN.MARKETPLACE"
        },
        SEARCH_ALL_PLACEHOLDER: "MAINHEADER.SEARCH_PLACEHOLDER"
    });
})();
