(function() {
    "use strict";

    angular
        .module("ssmAngularApp.avatarCard")
        .constant("ssmAvatarCardConstants", {
            OVERLAYS: {
                ARCHIVED: {
                    ICON: "icon-lock-yellow",
                    TEXT: "AVATAR_CARD.OVERLAYS.ARCHIVED"
                }
            },
            ITEM_STATUS: {
                ARCHIVED: 4
            }
        });
})();
