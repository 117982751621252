(function() {
    "use strict";

    angular
        .module("ssmAngularApp.search.service", ["ssmAngularApp.api"])
        .service("Search", Search);

    function Search(Api, Tenant) {
        var searchApi = Api.one("search");
        var suggestUsersApi = Api.one("search/suggestusers");
        var organizationId;

        return {
            search: function(searchObject) {
                return Tenant.getTenant().then(function(tenant) {
                    organizationId = tenant.organization_id;
                    searchObject.organization_id = organizationId;
                    return searchApi.get(searchObject);
                });
            },

            users: function(searchObject, params) {
                return Tenant.getTenant().then(function(tenant) {
                    organizationId = tenant.organization_id;
                    searchObject.organization_id = organizationId;
                    return searchApi.customPOST(searchObject, "users", params);
                });
            },

            orgs: function(searchObject, params) {
                return Tenant.getTenant().then(function(tenant) {
                    organizationId = tenant.organization_id;
                    searchObject.organization_id = organizationId;
                    return searchApi.customPOST(searchObject, "orgs", params);
                });
            },

            fields: function() {
                return searchApi
                    .all("users")
                    .all("fields")
                    .getList();
            },

            org_search_terms: function() {
                return searchApi
                    .all("orgs")
                    .one("search_terms")
                    .get();
            },

            suggestusers: function(searchObject) {
                return Tenant.getTenant().then(function(tenant) {
                    organizationId = tenant.organization_id;
                    searchObject.organization_id = organizationId;
                    return suggestUsersApi.get(searchObject);
                });
            }
        };
    }
})();
