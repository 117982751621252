(function() {
    "use strict";

    angular.module("ssmAngularApp.communityJoin", [
        "ssmAngularApp.community",
        "ssmAngularApp.CommunityMembership.service",
        "ssmAngularApp.logger",
        "ssmAngularApp.analytics"
    ]);
})();
