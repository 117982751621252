(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("PageNotFoundCtrl", PageNotFoundCtrl);

    function PageNotFoundCtrl(ssmAuth, SSMTitle) {
        var vm = this;

        activate();
        SSMTitle.setTitle("404 Not Found");

        function activate() {
            vm.isSignedIn = ssmAuth.isSignedIn;
        }
    }
})();
