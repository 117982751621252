(function() {
    "use strict";
    /**
     * @ngInject
     */

    /*jshint maxparams: 17 */

    angular
        .module("ssmAngularApp.mainHeader")
        .directive("mainHeader", mainHeader);

    function mainHeader() {
        return {
            templateUrl: "components/mainHeader/mainHeader.html",
            restrict: "E",
            scope: {},
            controller: mainHeaderCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function mainHeaderCtrl(
        $interval,
        $q,
        $location,
        $scope,
        $state,
        $stateParams,
        $translate,
        Analytics,
        CurrentUser,
        Logger,
        MainHeaderHelper,
        Notification,
        Socket,
        Tenant,
        Tutorial,
        ssmAuth,
        ssmAuthHelper,
        ssmMarketplace,
        ssmWebNotification,
        Api
    ) {
        /*jshint maxstatements: 58 */
        /*jshint validthis: true */

        var vm = this;

        var contentMenuIsOpen = false;
        var requestsMenuIsOpen = false;
        var engagementInterval = null;

        vm.isAdmin = CurrentUser.profile.is_admin;

        vm.searchQuery = $stateParams.q || "";
        vm.logout = logout;
        vm.login = login;
        vm.register = register;
        vm.runSearch = runSearch;
        vm.logoUrl = null;
        vm.logoSref = "main.home";
        vm.enableInvites = true;
        vm.enableFindSomeone = false;
        vm.enableFindOrganization = false;
        vm.enablePrivacyPolicy = true;
        vm.enableMembershipAgreement = true;
        (vm.isGuest = CurrentUser.profile.is_guest), (vm.hasTenant = false);
        vm.notificationBadgeIgnore = [];
        vm.collaboration_off = true;
        vm.blogs_isHidden = true;
        vm.myLeadership_isHidden = true;
        vm.pendingItems = [];
        vm.pendingBadgeItems = [];
        vm.openPendingItems = openPendingItems;
        vm.marketplaces = [];
        vm.newContentItems = [];
        vm.newContentBadgeItems = [];
        vm.openNewContentItems = openNewContentItems;

        vm.ignoreNotification = ignoreNotification;
        vm.visitNotification = visitNotification;
        vm.toggleMobileSearch = toggleMobileSearch;
        vm.handleMobileSearch = handleMobileSearch;
        vm.showMobileSearch = false;

        vm.sendWebNotification = sendWebNotification;
        vm.setupWebNotifications = setupWebNotifications;
        vm.webNotificationSentIds = [];
        vm.showNavlinks = false;
        vm.navlinks = [];
        vm.headerLinks = [];
        vm.hasHeaderLinks = true;
        vm.closeTutorialWindow = closeTutorialWindow;

        activate();

        function activate() {
            const actualToken = jwt_decode(localStorage.getItem("token"))
            const token_expiration_date = new Date(actualToken.exp * 1000)
            const now_date = new Date()
            console.log(CurrentUser.profile)
            Tenant.getTenant().then(tenant => {
                if (tenant.slug === "ahima" && token_expiration_date > now_date.setTime(now_date.getTime() + 5 * 60 * 60 * 1000)){
                    Logger.warning(null, `User ${CurrentUser.profile.id} has been logged out due re-login needed`)
                    CurrentUser.clear();
                    ssmAuthHelper.logout(true);
                }
            })

            vm.currentUser = CurrentUser.profile;
            if (vm.currentUser.id) {
                getAllNotifications(vm.currentUser.id);

                Socket.socketConnect(vm.currentUser.id);

                Socket.socketListen(
                    Notification.NOTIFICATION_SOCKET_EVENTS
                        .EVENT_NOTIFICATION_CREATED,
                    onNotificationCreated
                );
                Socket.socketListen(
                    Notification.NOTIFICATION_SOCKET_EVENTS
                        .EVENT_NOTIFICATION_DELETED,
                    onNotificationDeleted
                );
            }
            vm.setupWebNotifications();
            Tenant.getTenant().then(function(tenant) {
                vm.marketplace_landing = !!tenant.flags.marketplace_landing;
                if (!vm.marketplace_landing) {
                    ssmMarketplace
                        .getMarketplaces(tenant.organization_id)
                        .then(function(marketplaces) {
                            vm.marketplaces = marketplaces;
                        });
                }

                vm.enableInvites = tenant.flags.invites;
                vm.enablePrivacyPolicy = tenant.flags.privacy_policy;
                vm.enableMembershipAgreement = tenant.flags.membership_agreement;
                vm.enableFindSomeone = tenant.flags.find_someone;
                vm.enableFindOrganization = tenant.flags.find_organization;
                vm.logoUrl = tenant.logo_url;

                vm.collaboration_off = !!tenant.flags.collaboration_off;
                vm.blogs_isHidden = !!tenant.flags.blogs_isHidden;
                vm.myLeadership_isHidden = !!tenant.flags.myLeadership_isHidden;
                vm.logoSref = vm.marketplace_landing
                    ? "main.marketplace.results"
                    : "main.home";

                vm.headerLinks = tenant.headerLinks;
                vm.hasHeaderLinks = vm.headerLinks.length > 0;
                var orgId = tenant.organization_id;
                var navlinksApi = Api.all("navlinks");
                navlinksApi
                    .getList({
                        organization_id: orgId
                    })
                    .then(function(navlinks) {
                        vm.navlinks = navlinks;
                        if (navlinks.length > 0) {
                            vm.showNavlinks = true;
                        }
                    });

                vm.hasTenant = true; //Set for dynamic logo sref

                openTutorial(tenant.flags.enable_tutorial_mode);
            });

            engagementInterval = $interval(function() {
                Analytics.eventTrack("engagedUser", {
                    category: "MainHeader",
                    label: "user_id: " + vm.currentUser.id
                });
                Analytics.eventTrack2('engaged_user', {
                    category: "MainHeader",
                    label: "user_id: " + vm.currentUser.id,
                });
                $interval.cancel(engagementInterval);
            }, 30000);
        }

        function closeTutorialWindow() {
            Tutorial.exit();
        }

        function openTutorial(enable_tutorial_mode) {
            if ($state.includes("main.home") && enable_tutorial_mode) {
                if (CurrentUser.profile.tutorial_status == 2) {
                    Tutorial.openStep8to9();
                } else {
                    Tutorial.openStep1();
                }
            }
        }

        function toggleMobileSearch() {
            vm.showMobileSearch = !vm.showMobileSearch;
        }

        function handleMobileSearch(open) {
            if (open) {
                vm.showMobileSearch = false;
            }
        }

        function login() {
            ssmAuth.logout();
        }

        function register() {
            $state.go("signup");
        }

        function logout() {
            Analytics.eventTrack("logout", {
                category: "mainHeaderCtrl",
                label:
                    "user_id:" +
                    (vm.currentUser ? vm.currentUser.id : "Invalid User")
            });
            Analytics.eventTrack2('logout', {
                category: "mainHeaderCtrl",
                label: "user_id:" +
                    (vm.currentUser ? vm.currentUser.id : "Invalid User"),
            });
            Analytics.setUsername(null);

            closeTutorialWindow();

            ssmAuth.logout();
        }

        function runSearch(searchText) {
            if (searchText) {
                Analytics.eventTrack("runSearch", {
                    category: "mainHeaderCtrl",
                    label: "search_text: " + searchText
                });
                Analytics.eventTrack2('run_search', {
                    category: "mainHeaderCtrl",
                    label: "search_text: " + searchText
                });
                var filter = $state.params.filter || "_all";
                $state.go("main.search.results", {
                    filter: filter,
                    q: searchText,
                    continuationtoken: undefined,
                    start: undefined
                });
                vm.showMobileSearch = false;
            }
        }

        function ignoreNotification(e, notification) {
            if (!e || !notification) {
                return;
            }

            // prevent the menu from closing
            e.preventDefault();
            e.stopPropagation();

            _.remove(vm.pendingItems, notification);
            _.remove(vm.newContentItems, notification);

            Notification.clearNotifications(vm.currentUser.id, notification);
        }

        function visitNotification(notification) {
            notification.unvisited = false;
            Notification.onNotificationVisted(vm.currentUser.id, notification);
        }

        function openNewContentItems(open) {
            if (open) {
                Analytics.eventTrack("notification.contentMenu.open", {
                    category: "mainHeaderCtrl",
                    label: "badge_count:" + vm.newContentBadgeItems.length
                });
                Analytics.eventTrack2('notification_contentMenu_open', {
                    category: "mainHeaderCtrl",
                    label: "badge_count:" + vm.newContentBadgeItems.length
                });

                vm.notificationBadgeIgnore = _.union(
                    vm.notificationBadgeIgnore,
                    vm.newContentBadgeItems
                );
            } else {
                if (vm.contentMenuIsOpen) {
                    Analytics.eventTrack("notification.contentMenu.close", {
                        category: "mainHeaderCtrl",
                        label: "badge_count:" + vm.newContentBadgeItems.length
                    });
                    Analytics.eventTrack2('notification_contentMenu_close', {
                        category: "mainHeaderCtrl",
                        label: "badge_count:" + vm.newContentBadgeItems.length
                    });
                }
                removeMenuUrlParams();
            }

            vm.handleMobileSearch(open);
        }

        function openPendingItems(open) {
            if (open) {
                Analytics.eventTrack("notification.requestsMenu.open", {
                    category: "mainHeaderCtrl",
                    label: "badge_count:" + vm.pendingBadgeItems.length
                });
                Analytics.eventTrack2('notification_requestsMenu_open', {
                    category: "mainHeaderCtrl",
                    label: "badge_count:" + vm.pendingBadgeItems.length
                });

                vm.notificationBadgeIgnore = _.union(
                    vm.notificationBadgeIgnore,
                    vm.pendingBadgeItems
                );
                vm.pendingBadgeItems = [];
                Notification.onNotificationsReviewed(
                    vm.currentUser.id,
                    vm.pendingItems
                );
            } else {
                if (vm.pendingMenuIsOpen) {
                    Analytics.eventTrack("notification.requestsMenu.close", {
                        category: "mainHeaderCtrl",
                        label: "badge_count:" + vm.pendingBadgeItems.length
                    });
                    Analytics.eventTrack2('notification_requestsMenu_close', {
                        category: "mainHeaderCtrl",
                        label: "badge_count:" + vm.pendingBadgeItems.length
                    });
                }
                removeMenuUrlParams();
            }

            vm.handleMobileSearch(open);
        }

        // Receives actual notification objects -- not hydrated notifications
        function onNotificationCreated(created) {
            _.forEach(_.map(created, "id"), getNotification);
        }

        // Receives actual notification objects -- not hydrated notifications
        function onNotificationDeleted(deleted) {
            var deleted_ids = _.map(deleted, "id");

            if (deleted_ids.length) {
                vm.newContentItems = _.reject(vm.newContentItems, function(
                    notification
                ) {
                    return _.includes(
                        deleted_ids,
                        notification.notification_id
                    );
                });
                vm.pendingItems = _.reject(vm.pendingItems, function(
                    notification
                ) {
                    return _.includes(
                        deleted_ids,
                        notification.notification_id
                    );
                });

                vm.newContentBadgeItems = MainHeaderHelper.loadNotificationBadges(
                    vm.newContentItems
                );
                vm.pendingBadgeItems = MainHeaderHelper.loadNotificationBadges(
                    vm.pendingBadgeItems
                );
                $scope.$apply();
            }
        }

        // get an individual notification if we don't have it yet and aren't explicitly ignoring it
        function getNotification(notificationId) {
            var match = _.find(vm.pendingItems, {
                notification_id: notificationId
            });
            if (!match) {
                match = _.find(vm.newContentItems, {
                    notification_id: notificationId
                });
            }

            // if we already have this, just reuse it
            if (match) {
                return $q.when(match);
            }

            // do not retrieve something that was already ignored
            if (_.includes(vm.notificationBadgeIgnore, notificationId)) {
                return $q.reject();
            }

            return Notification.getNotification(
                vm.currentUser.id,
                notificationId
            )
                .then(function(result) {
                    return loadNotifications([result]);
                })
                .catch(function(error) {
                    // something went wrong; ignore this notification
                    vm.notificationBadgeIgnore.push(notificationId);
                });
        }

        function getAllNotifications(user_id) {
            return Notification.getNotifications(user_id).then(
                loadNotifications
            );
        }

        function removeMenuUrlParams() {
            vm.openNotificationMenu = $stateParams["notification-type"];
            if (vm.openNotificationMenu) {
                $state.transitionTo($state.current, {}, { reload: false });
            }
        }

        function sendWebNotification(title, item, message) {
            item.params = item.params || {};

            var options = {
                body: message
            };

            ssmWebNotification.create(title, options, function() {
                vm.visitNotification(item);
                $location.path(item.link).search(item.params);
            });
        }

        function trackPresentedWebNotifications(sentNotifications) {
            var sentIds = _.map(sentNotifications, "notification_id");
            vm.webNotificationSentIds = vm.webNotificationSentIds.concat(
                sentIds
            );
        }

        function setupWebNotifications() {
            $scope.$watch(
                "vm.pendingItems",
                addWebNotifications(
                    vm.pendingItems,
                    "pending",
                    "MAIN_HEADER.NEW_REQUESTS"
                )
            );
            $scope.$watch(
                "vm.newContentItems",
                addWebNotifications(
                    vm.newContentItems,
                    "content",
                    "MAIN_HEADER.NEW_ACTIVITY"
                )
            );
        }

        function addWebNotifications(
            notificationItems,
            notificationType,
            notificationAlertMessage
        ) {
            var newItems = _.reject(notificationItems, function(n) {
                return (
                    n.hidden ||
                    _.includes(vm.webNotificationSentIds, n.notification_id)
                );
            });
            if (newItems.length === 1) {
                var newItem = newItems[0];
                $translate([newItem.header, "MAIN_HEADER.NEW"]).then(function(
                    translations
                ) {
                    if (translations) {
                        vm.sendWebNotification(
                            translations["MAIN_HEADER.NEW"] +
                                translations[newItem.header] +
                                "!",
                            newItem,
                            newItem.blurb
                        );
                    }
                });
            } else if (newItems.length > 1) {
                var groupedItem = {
                    link: "/",
                    params: {
                        "notification-type": notificationType
                    }
                };
                $translate([
                    notificationAlertMessage,
                    "MAIN_HEADER.UNREAD"
                ]).then(function(translations) {
                    if (translations) {
                        vm.sendWebNotification(
                            translations[notificationAlertMessage],
                            groupedItem,
                            translations["MAIN_HEADER.UNREAD"]
                        );
                    }
                });
            }
            trackPresentedWebNotifications(newItems);
        }

        function watchUrlForMenuOpen() {
            vm.openNotificationMenu = $stateParams["notification-type"];
            if (vm.openNotificationMenu) {
                if (vm.openNotificationMenu === "content") {
                    vm.contentMenuIsOpen = true;
                } else if (vm.openNotificationMenu === "pending") {
                    vm.pendingMenuIsOpen = true;
                }
            }
        }

        function loadNotifications(latestNotifications) {
            var grouped = MainHeaderHelper.groupNotificationsByMenu(
                latestNotifications
            );

            vm.pendingItems = MainHeaderHelper.loadNotificationItems(
                vm.pendingItems || [],
                grouped[Notification.NOTIFICATION_CLASS.REQUEST]
            );
            vm.pendingBadgeItems = MainHeaderHelper.loadNotificationBadges(
                vm.pendingItems
            );

            vm.newContentItems = MainHeaderHelper.loadNotificationItems(
                vm.newContentItems || [],
                grouped[Notification.NOTIFICATION_CLASS.CONTENT]
            );
            vm.newContentBadgeItems = MainHeaderHelper.loadNotificationBadges(
                vm.newContentItems
            );

            watchUrlForMenuOpen();

            return $q.when(latestNotifications);
        }
    }
})();
