(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("VendorFlairEditController", VendorFlairEditController)
        .controller(
            "VendorFlairEditModalController",
            VendorFlairEditModalController
        );

    function VendorFlairEditController(
        $translate,
        vendorFlairResolve,
        vendorLibraryResolve,
        $uibModal,
        $state,
        Flair,
        SSMTitle
    ) {
        var vm = this;
        vm.flairSave = flairSave;

        activate();

        function activate() {
            $translate("VENDOR.PAGE_TITLE.FLAIR").then(SSMTitle.setTitle);

            $uibModal
                .open({
                    templateUrl:
                        "layout/main/vendor/vendorShow/vendorFlairEdit/vendorFlairEdit.html",
                    controller: "VendorFlairEditModalController",
                    controllerAs: "vm",
                    resolve: {
                        vendorLibraryResolve: function() {
                            return vendorLibraryResolve;
                        },
                        vendorFlairResolve: function() {
                            return vendorFlairResolve;
                        }
                    }
                })
                .result.then(vm.flairSave)
                .finally(function() {
                    $state.go("^");
                });
        }

        function flairSave(edits) {
            var flair_id = vendorFlairResolve.id;
            Flair.patchFlair(flair_id, edits).then(function(result) {
                // end_date and start_date are optional; force them to update to the new values
                angular.extend(vendorFlairResolve, result);
                vendorFlairResolve.end_date = result.end_date;
                vendorFlairResolve.start_date = result.start_date;
            });
        }
    }

    function VendorFlairEditModalController(
        vendorFlairResolve,
        vendorLibraryResolve,
        $scope,
        $uibModalInstance
    ) {
        var vm = this;
        vm.flairData = vendorFlairResolve;
        vm.vendorLibrary = vendorLibraryResolve;
        vm.updateFlair = updateFlair;
        vm.closeModal = closeModal;

        $scope.$on("$stateChangeStart", function() {
            $uibModalInstance.dismiss("cancel");
        });

        function updateFlair(edits) {
            $uibModalInstance.close(edits);
        }

        function closeModal() {
            $uibModalInstance.dismiss("cancel");
        }
    }
})();
