(function() {
    "use strict";
    /**
     * @ngInject
     */

    // Based off of angular-enhance-text
    // https://github.com/Raydiation/angular-enhance-text

    angular
        .module("ssmAngularApp.ssmHashtags", [])
        .filter("ssmHashtags", ssmHashtags);

    function ssmHashtags() {
        return function(hashtags) {
            if (!hashtags) {
                return hashtags;
            }
            return hashtags.toString().replace(/,/g, " ");
        };
    }
})();
