(function() {
    "use strict";

    angular
        .module("ssmAngularApp.profileSettingsForm", [
            "ssmAngularApp.currentUser"
        ])
        .directive("profileSettingsForm", profileSettingsForm)
        .controller("profileSettingsFormCtrl", profileSettingsFormCtrl);

    function profileSettingsForm() {
        return {
            templateUrl:
                "components/profileSettingsForm/profileSettingsForm.html",
            restrict: "E",
            scope: {
                caller: "@",
                disableEmployerEdit: "=?",
                userModel: "=?",
                userFormData: "=?",
                broadcastIdentifier: "@"
            },
            controller: profileSettingsFormCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function profileSettingsFormCtrl(
        $state,
        Tenant,
        CurrentUser
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.disableProfileFields = false;
        vm.disableProfileName = false;

        vm.maxLength = 80;

        // restricts the @ symbol so we can always tell the difference between a name from an email address
        vm.namePattern = new RegExp("^((?!@).)*$"); 

        vm.newButtonShown = vm.caller !== "registration";

        vm.onPhotoUpload = onPhotoUpload;
        vm.updateFullName = updateFullName;
        vm.createButtonOnClick = createButtonOnClick;

        vm.institutionLabel = "Institution";

        activate();

        function activate() {
            Tenant.getTenant().then(function(tenant) {
                vm.institutionLabel =
                    tenant.slug == "mywardenexchange"
                        ? "Institution"
                        : "Organization";
                vm.tenant = {
                    flags: tenant.flags
                };
                vm.ssoSettings = tenant.sso_button;
                determineIfShouldDisableProfileFields();
            });
        }

        

        function determineIfShouldDisableProfileFields() {
            if (CurrentUser.hasNeverUsedSso()) return;
            if (ssoProfileFieldSettingsDoNotExist()) return;

            vm.disableProfileName = vm.ssoSettings.disable_profile_name;
            vm.disableProfileFields = vm.ssoSettings.disable_profile_fields;
        }
       
        vm.states = [
            "AL",
            "AK",
            "AZ",
            "AR",
            "CA",
            "CO",
            "CT",
            "DE",
            "DC",
            "FL",
            "GA",
            "HI",
            "ID",
            "IL",
            "IN",
            "IA",
            "KS",
            "KY",
            "LA",
            "ME",
            "MD",
            "MA",
            "MI",
            "MN",
            "MS",
            "MO",
            "MT",
            "NE",
            "NV",
            "NH",
            "NJ",
            "NM",
            "NY",
            "NC",
            "ND",
            "OH",
            "OK",
            "OR",
            "PA",
            "RI",
            "SC",
            "SD",
            "TN",
            "TX",
            "UT",
            "VT",
            "VA",
            "WA",
            "WV",
            "WI",
            "WY"
        ];

        function updateFullName() {
            var first = vm.userModel.first_name
                ? vm.userModel.first_name.trim()
                : "";
            var last = vm.userModel.last_name
                ? vm.userModel.last_name.trim()
                : "";

            vm.userModel.full_name = first + " " + last;
        }

        function onPhotoUpload() {
            vm.userModel.photo_id = vm.userModel.imageUpload[0].id;
            vm.userModel.photo = vm.userModel.imageUpload[0];
        }

        function createButtonOnClick() {
            var params = angular.toJson({
                caller: vm.caller
            });

            $state.go("main.orgs.create", { params: params });
        }

        function ssoProfileFieldSettingsDoNotExist() {
            if (
                !vm.ssoSettings ||
                ( vm.ssoSettings.disable_profile_fields == null &&
                vm.ssoSettings.disable_profile_name == null )
                ) return true
            else return false
        }
    }
})();
