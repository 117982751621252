(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("ProfileNotificationsCtrl", ProfileEmailCtrl);

    function ProfileEmailCtrl(
        $notification,
        $timeout,
        Analytics,
        CurrentUser,
        NotificationServiceConstants,
        SSMTitle,
        ssmWebNotification
    ) {
        var vm = this;
        vm.webNotifyIsSupported = ssmWebNotification.isSupported;
        vm.webNotifyPermittedType = ssmWebNotification.readPermission;

        vm.disableWebNotifications = ssmWebNotification.disable;
        vm.enableWebNotifications = ssmWebNotification.enable;

        var digestTemplate = {
            digest_frequency: ""
        };
        var DELAY_TIME_HIDING_FLAG = 3000;

        vm.updateNotifications = updateNotifications;
        vm.resetForm = resetForm;
        vm.showSavedFlag = false;

        activate();

        function activate() {
            resetForm(null);
            SSMTitle.setTitle(CurrentUser.profile.full_name);
        }

        function updateNotifications(frequency, form) {
            var digestTemplateCopy = angular.copy(digestTemplate);
            digestTemplateCopy.digest_frequency = frequency;

            Analytics.eventTrack("NotificationsUpdate", {
                category: "Notifications",
                label:
                    "digest_frequency: " +
                    NotificationServiceConstants.DIGEST_FREQUENCY_NAMES[
                        digestTemplateCopy.digest_frequency
                    ]
            });
            Analytics.eventTrack2("notifications_update", {
                category: "Notifications",
                label:
                    "digest_frequency: " +
                    NotificationServiceConstants.DIGEST_FREQUENCY_NAMES[
                        digestTemplateCopy.digest_frequency
                    ]
            });

            return CurrentUser.update(digestTemplateCopy).then(function(data) {
                form.$setPristine();

                vm.showSavedFlag = true;
                $timeout(function() {
                    vm.showSavedFlag = false;
                }, DELAY_TIME_HIDING_FLAG);
            });
        }

        function resetForm(form) {
            vm.digestFrequency = CurrentUser.profile.digest_frequency.toString(); //Angular requires the value to be a string
            vm.nextDigestDate = CurrentUser.profile.next_digest_date;
            if (form && form.$setPristine) {
                form.$setPristine();
            }
        }
    }
})();
