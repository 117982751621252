(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("appa", {
            url: "/auth/login/appa/callback",
            controller: "AppaCtrl",
            controllerAs: "appa"
        });
    });
})();
