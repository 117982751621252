(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmDateValidation", [])
        .directive("ssmDateValidation", ssmDateValidation);

    function ssmDateValidation() {
        return {
            require: "ngModel",
            link: function(scope, elem, attr, ngModel) {
                var inputFormat =
                    attr.ssmDateValidation &&
                    attr.ssmDateValidation !== "ssm-date-validation"
                        ? attr.ssmDateValidation
                        : "YYYY MM DD";
                var outputFormat = "YYYY-MM-DD";

                //For DOM -> model validation
                ngModel.$parsers.unshift(function(value) {
                    if (!value) {
                        ngModel.$setValidity("ssmDate", true);
                        return null; // don't bother parsing an empty string
                    }

                    var m = moment(value, inputFormat); // use moment with our input format to parse the date string
                    var valid = m.isValid();
                    ngModel.$setValidity("ssmDate", valid);
                    var result = m.format(outputFormat); // convert the date to what angular is expecting
                    return valid ? result : value; // use converted version if we understood it; otherwise, just send the original value through
                });

                //For model -> DOM validation
                ngModel.$formatters.unshift(function(value) {
                    ngModel.$setValidity(
                        "ssmDate",
                        value ? moment(value).isValid() : true
                    );
                    return value;
                });
            }
        };
    }
})();
