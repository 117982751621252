(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").controller("SearchCtrl", SearchCtrl);

    function SearchCtrl($translate, SSMTitle, Tenant) {
        var vm = this;
        vm.collaboration_off = true;
        vm.blogs_isHidden = true;
        vm.myLeadership_isHidden = true;
        $translate("TITLE.SEARCH").then(function(title) {
            SSMTitle.setTitle(title);
        });

        Tenant.getTenant().then(function(siteOrganization) {
            vm.collaboration_off = !!siteOrganization.flags.collaboration_off;
            vm.blogs_isHidden = !!siteOrganization.flags.blogs_isHidden;
            vm.myLeadership_isHidden = !!siteOrganization.flags
                .myLeadership_isHidden;
        });
    }
})();
