(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.wallPost").directive("wallPost", wallPost);

    function wallPost() {
        return {
            templateUrl: "components/wallPost/wallPost.html",
            restrict: "E",
            scope: {
                postData: "=",
                posts: "=",
                location: "=",
                onSticky: "&",
                onDelete: "&",
                onApprove: "&"
            },
            replace: true,
            controller: wallPostCtrl,
            controllerAs: "wallPost",
            bindToController: true
        };
    }

    function wallPostCtrl(
        $uibModal,
        $translate,
        Analytics,
        Audience,
        CurrentUser,
        Logger,
        Tenant,
        WallPost,
        SSM_FEATURES
    ) {
        /*jshint validthis:true */
        var vm = this;

        var dimension2 =
            vm.location && vm.location.id ? vm.location.id : "Unknown";
        var dimension4 =
            vm.postData && vm.postData.location && vm.postData.location.id
                ? vm.postData.location.id
                : "Unknown";

        /** Set data helpers and business logic for post. **/
        vm.isGuest = CurrentUser.profile.is_guest;
        vm.editPost = editPost;
        vm.likePost = likePost;
        vm.unLikePost = unLikePost;
        vm.deletePost = deletePost;
        vm.approvePost = approvePost;
        vm.sharePost = sharePost;
        vm.viewLikes = viewLikes;
        vm.canToggleSticky =
            vm.location &&
            vm.location.permissions &&
            vm.location.permissions.canAddSticky &&
            vm.location.permissions.canDeleteSticky &&
            !SSM_FEATURES.COMMUNITY_STICKIES_OFF;

        vm.onToggleStickyHandler = onToggleStickyHandler;

        // if you change this, change it in wallCard.directive.js, too
        vm.canEdit =
            (vm.postData.community &&
                vm.postData.location.permissions.canEditAsCommunityPosts) ||
            ((!vm.postData.community ||
                vm.postData.location.permissions.canPost) && // Post is either not located in a community or the community permits posts
                (vm.postData.is_yours || // Post is yours
                ((!vm.postData.community ||
                    !vm.postData.community.posted_as_community) &&
                    vm.postData.permissions.can_edit_as_delegate) || // Post authored by one of your principals
                    (vm.postData.community &&
                        vm.postData.community.posted_as_community &&
                        vm.postData.location.permissions
                            .canEditAsCommunityPosts))); // Posted as community and you can post as community
        vm.canApprove =
            vm.postData.location.permissions.canEditAsCommunityPosts;
        vm.requiresApproval = vm.postData.requires_approval;

        vm.showContextMenu = vm.canEdit || vm.canToggleSticky;

        activate();

        function activate() {
            vm.currentUser = CurrentUser.profile;
        }
        var emailAddress = _.head(vm.currentUser.emails).email;

        function editPost() {
            Analytics.eventTrack("editPost", {
                category: "wallPostCtrl",
                label:
                    "post_id:" +
                    vm.postData.id +
                    "isEditView: " +
                    !vm.postData.isEditView,
                dimension2: dimension2,
                dimension4: dimension4
            });
            Analytics.eventTrack2('edit_post', {
                category: "wallPostCtrl",
                label: "post_id:" +
                    vm.postData.id +
                    "isEditView: " +
                    !vm.postData.isEditView,
                dimension2: dimension2,
                dimension4: dimension4
            });

            vm.postData.isEditView = !vm.postData.isEditView;
        }

        /** Post Actions **/
        function likePost(post) {
            Analytics.eventTrack("likePost", {
                category: "wallPostCtrl",
                label: "post_id:" + post.id,
                dimension2: dimension2,
                dimension4: dimension4
            });
            Analytics.eventTrack2('like_post', {
                category: "wallPostCtrl",
                label: "post_id:" + post.id,
                dimension2: dimension2,
                dimension4: dimension4
            });
            vm.postData.likes.is_liked_by_you = true;
            vm.postData.likes.total_likes++;

            Tenant.getTenant().then(function(tenant) {
                Audience.updateHashTags(
                    tenant.organization_id,
                    emailAddress,
                    post.hash_tags,
                    post.inline_hash_tags
                );
            });

            WallPost.like(post.id).then(
                function() {
                    //Success
                },
                function(error) {
                    vm.postData.likes.is_liked_by_you = false;
                    vm.postData.likes.total_likes--;

                    if (error.status !== 403) {
                        $translate("WALL.LIKE_ERROR").then(function(
                            translation
                        ) {
                            Logger.error("ERROR: ", translation, error);
                        });
                    }
                }
            );
        }

        function unLikePost(post) {
            Analytics.eventTrack("unLikePost", {
                category: "wallPostCtrl",
                label: "post_id:" + post.id,
                dimension2: dimension2,
                dimension4: dimension4
            });
            Analytics.eventTrack2('unlike_post', {
                category: "wallPostCtrl",
                label: "post_id:" + post.id,
                dimension2: dimension2,
                dimension4: dimension4
            });

            vm.postData.likes.is_liked_by_you = false;
            vm.postData.likes.total_likes--;

            WallPost.unLike(post.id).then(
                function() {
                    //Success
                },
                function(error) {
                    vm.postData.likes.is_liked_by_you = true;
                    vm.postData.likes.total_likes++;
                    $translate("WALL.UNLIKE_ERROR").then(function(translation) {
                        Logger.error("ERROR: ", translation, error);
                    });
                }
            );
        }

        function confirmDeleteModal(confirmText) {
            var promise = $uibModal.open({
                templateUrl: "components/modal/areYouSure/areYouSure.html",
                controller: "AreYouSureCtrl as vm",
                resolve: {
                    textResolve: function() {
                        return $translate(confirmText);
                    }
                }
            });
            return promise;
        }

        function approvePost(post) {
            WallPost.approve(post.id).then(function() {
                vm.posts = _.reject(vm.posts, { id: post.id });
                if (vm.onApprove) {
                    vm.onApprove();
                }
            });
        }

        function deletePost(post) {
            var uibModalInstance = confirmDeleteModal(
                "WALL.DELETE_POST_WARNING"
            );
            uibModalInstance.result.then(function(response) {
                if (response.confirmed) {
                    WallPost.delete(post.id).then(
                        function() {
                            vm.posts = _.reject(vm.posts, { id: post.id });
                        },
                        function(error) {
                            $translate("WALL.DELETE_POST_ERROR").then(function(
                                translation
                            ) {
                                Logger.error("ERROR: ", translation, error);
                            });
                        }
                    );

                    Analytics.eventTrack("deletePost", {
                        category: "wallPostCtrl",
                        label: "post_id:" + post.id,
                        dimension2: dimension2,
                        dimension4: dimension4
                    });
                    Analytics.eventTrack2('delete_post', {
                        category: "wallPostCtrl",
                        label: "post_id:" + post.id,
                        dimension2: dimension2,
                        dimension4: dimension4
                    });

                    if (
                        post.author.id !== vm.currentUser.id &&
                        post.author.author_type === WallPost.LOCATION_TYPE.USER
                    ) {
                        Analytics.eventTrack("deletePostAsDelegate", {
                            category: "Delegation",
                            label:
                                "id:" +
                                post.id +
                                ", author: " +
                                post.author.id +
                                ", author type: " +
                                post.author.author_type,
                            dimension2: dimension2,
                            dimension4: dimension4
                        });
                        Analytics.eventTrack2('delete_post_as_delegate', {
                            category: "Delegation",
                            label: "id:" +
                                post.id +
                                ", author: " +
                                post.author.id +
                                ", author type: " +
                                post.author.author_type,
                            dimension2: dimension2,
                            dimension4: dimension4
                        });
                    }
                    if (vm.onDelete) {
                        vm.onDelete();
                    }
                }
            });
        }

        function sharePost() {
            var postInfo = {
                postData: vm.postData.shared_post
                    ? vm.postData.shared_post
                    : vm.postData,
                postType: "post",
                posts: vm.posts,
                location: vm.location
            };

            $uibModal.open({
                templateUrl: "components/modal/sharePost/sharePost.html",
                controller: "SharePostCtrl as sharePost",
                resolve: {
                    postInfo: function() {
                        return postInfo;
                    }
                }
            });
        }

        function viewLikes() {
            if (vm.postData.likes && vm.postData.likes.total_likes) {
                $uibModal.open({
                    templateUrl: "components/modal/viewLikes/viewLikes.html",
                    controller: "viewLikesCtrl as viewLikes",
                    resolve: {
                        likesResolve: function(WallPost) {
                            return WallPost.allLikes(vm.postData.id);
                        }
                    }
                });
            }
        }

        function onToggleStickyHandler() {
            if (vm.onSticky) {
                vm.onSticky();
            }
        }
    }
})();
