(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmTrackableLink")
        .directive("ssmTrackableLink", ssmTrackableLink);

    function ssmTrackableLink() {
        return {
            templateUrl: "components/ssmTrackableLink/ssmTrackableLink.html",
            restrict: "E",
            replace: true,
            scope: {
                url: "@",
                target: "@",
                postId: "@",
                linkText: "@",
                fileName: "@",
                eventType: "@",
                homeLocationId: "@"
            },
            controller: ssmTrackableLinkCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmTrackableLinkCtrl(Analytics) {
        /*jshint validthis:true */
        var vm = this;
        vm.trackOutboundLink = trackOutboundLink;
        vm.trackFileDownload = trackFileDownload;
        vm.trackEvent = trackEvent;

        function trackEvent(eventType) {
            switch (eventType) {
                case "link":
                    trackOutboundLink(vm.url);
                    break;
                case "download":
                    trackFileDownload(vm.fileName);
                    break;
                default:
                    trackOutboundLink(vm.url);
            }
        }

        function trackOutboundLink(url) {
            if (
                url &&
                !url.toLowerCase().includes(location.host.toLowerCase())
            ) {
                Analytics.eventTrack("ExternalLinkClick", {
                    category: "TrackableLinkCtrl",
                    label: url,
                    dimension2: vm.postId
                });
                Analytics.eventTrack2('external_link_click', {
                    category: "TrackableLinkCtrl",
                    label: url,
                    dimension2: vm.postId,
                });
            }
        }

        function trackFileDownload(file_name) {
            Analytics.eventTrack("FileDownload", {
                category: Analytics.determineAnalyticsCategory(),
                label: file_name,
                dimension4: vm.homeLocationId
            });
            Analytics.eventTrack2('file_download', {
                category: Analytics.determineAnalyticsCategory(),
                label: file_name,
                dimension4: vm.homeLocationId,
            });
        }
    }
})();
