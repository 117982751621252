(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller(
            "ProfileDelegationsController",
            ProfileDelegationsController
        );

    function ProfileDelegationsController(
        $timeout,
        $translate,
        Analytics,
        CurrentUser,
        Delegation,
        delegatesResolve,
        Logger,
        SSMTitle
    ) {
        var vm = this;
        vm.delegates = {
            members: []
        };
        vm.formDirty = false;

        vm.savedDelegates = [];
        vm.showSavedFlag = false;
        vm.showNotYourselfError = false;
        vm.addDelegate = addDelegate;
        vm.removeDelegate = removeDelegate;
        vm.showSavedChanges = showSavedChanges;
        vm.maxDelegates = maxDelegates;

        vm.updateProfile = updateProfile;
        vm.resetForm = resetForm;

        var DELAY_TIME_HIDING_FLAG = 3000;
        var MAX_ALLOWED_DELEGATES = 10;
        var hideSavedFlagPromise;

        activate();

        function activate() {
            var delegates = delegatesResolve;
            _.each(delegates, function(delegate) {
                vm.delegates.members.push({ id: delegate.id, user: delegate });
                vm.savedDelegates.push({ id: delegate.id, user: delegate });
            });
            SSMTitle.setTitle(CurrentUser.profile.full_name);
        }

        function addDelegate(member) {
            vm.showNotYourselfError = false;
            // If there is no member, there is nothing to add.
            if (!member) {
                return;
            }

            // If you try to add yourself, there is nothing to add.
            if (member.id === CurrentUser.profile.id) {
                _.remove(vm.delegates.members, function(delegate) {
                    return delegate.id === CurrentUser.profile.id;
                });

                vm.showNotYourselfError = true;

                return;
            }

            vm.formDirty = true;
        }

        function removeDelegate(member) {
            vm.showNotYourselfError = false;
            // If there is no member, there is nothing to remove.
            if (!member) {
                return;
            }

            vm.formDirty = true;
        }

        function showSavedChanges() {
            vm.showSavedFlag = true;
            $timeout.cancel(hideSavedFlagPromise);
            hideSavedFlagPromise = $timeout(function() {
                vm.showSavedFlag = false;
            }, DELAY_TIME_HIDING_FLAG);
        }

        function maxDelegates() {
            return vm.delegates.members.length >= MAX_ALLOWED_DELEGATES;
        }

        function updateProfile() {
            Delegation.updateDelegates(CurrentUser.profile.id, {
                delegate_ids: _.map(vm.delegates.members, "id")
            })
                .then(function(delegates) {
                    vm.showSavedChanges();
                    Analytics.eventTrack("UpdateDelegates", {
                        category: "Delegation",
                        label:
                            "UpdateDelegates, number of delegates: " +
                            delegates.length
                    });
                    Analytics.eventTrack2("update_delegates", {
                        category: "Delegation",
                        label:
                            "UpdateDelegates, number of delegates: " +
                            delegates.length
                    });
                    vm.delegates.members = [];
                    vm.savedDelegates = [];
                    _.each(delegates, function(delegate) {
                        vm.delegates.members.push({
                            id: delegate.id,
                            user: delegate
                        });
                        vm.savedDelegates.push({
                            id: delegate.id,
                            user: delegate
                        });
                    });
                    vm.formDirty = false;
                })
                .catch(function(error) {
                    $translate(
                        "PROFILE_DELEGATIONS.DELEGATE_UPDATE_FAILED_ERROR"
                    ).then(function(translation) {
                        Logger.error("ERROR: ", translation, error);
                    });
                    return false;
                });
        }

        function resetForm() {
            vm.delegates.members = angular.copy(vm.savedDelegates);
            vm.formDirty = false;
        }
    }
})();
