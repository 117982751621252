(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsFindResultsCtrl", OrgsFindResults);

    function OrgsFindResults(FindOrg, $state, $translate, SSMTitle) {
        /*jshint validthis: true */
        var vm = this;
        $translate("TITLE.FIND_ORGANIZATIONS").then(function(title) {
            SSMTitle.setTitle(title);
        });
        vm.searchResults = FindOrg.search($state.params);
    }
})();
