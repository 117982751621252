(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.fileSize", [])
        .directive("fileSize", fileSize);

    function fileSize() {
        return {
            restrict: "A",
            scope: "=",
            link: function(scope, element, attrs) {
                attrs.$observe("fileSize", function() {
                    element.text(format(attrs.fileSize));
                });
            }
        };

        function format(bytes) {
            var precision = 1;
            var parsedBytes = parseFloat(bytes);
            if (
                parsedBytes < 0 ||
                parsedBytes === 0 ||
                isNaN(parsedBytes) ||
                !isFinite(parsedBytes)
            ) {
                return "-";
            }
            var units = ["bytes", "KB", "MB", "GB"],
                number = Math.floor(Math.log(bytes) / Math.log(1024));
            return (
                (bytes / Math.pow(1024, Math.floor(number))).toFixed(
                    precision
                ) + units[number]
            );
        }
    }
})();
