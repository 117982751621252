(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("StyleGuideCtrl", StyleGuideCtrl);

    function StyleGuideCtrl() {}
})();
