(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .factory("ssmRegistrationRoutesHelper", ssmRegistrationRoutesHelper)
        .config(function($stateProvider) {
            $stateProvider.state("registration", {
                url: "/registration",
                templateUrl: "layout/registration/registration.html",
                controller: "RegistrationCtrl",
                controllerAs: "vm",
                data: {
                    requiresLogin: true,
                    analyticsName: "NewUserRegistration",
                    disableLastAttemptedUrl: true
                },
                resolve: {
                    ensureCurrentUserResolve: function(
                        ssmRegistrationRoutesHelper
                    ) {
                        return ssmRegistrationRoutesHelper.ensureCurrentUser();
                    },
                    communitySuggestionResolve: resolveCommunitySuggestions,
                    invitedCommunityResolve: resolveInvitedCommunities,
                    invitedConnectionResolve: resolveInvitedConnections,
                    suggestedConnectionResolve: resolveSuggestedConnections
                }
            });

            function resolveCommunitySuggestions(Community) {
                return Community.getSuggestions();
            }

            function resolveInvitedCommunities(Community) {
                return Community.getInvitedCommunities();
            }

            function resolveInvitedConnections(Connection) {
                return Connection.getInvited();
            }

            function resolveSuggestedConnections(Connection) {
                return Connection.getSuggestions();
            }
        });

    function ssmRegistrationRoutesHelper(CurrentUser) {
        return {
            ensureCurrentUser: function() {
                return CurrentUser.ensureLoaded();
            }
        };
    }
})();
