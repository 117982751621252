(function() {
    "use strict";

    angular.module("ssmAngularApp.ssmDelegationBlog", [
        "ssmAngularApp.currentUser",
        "pascalprecht.translate",
        "ssmAngularApp.ssmWallPoll",
        "ssmAngularApp.delegation.service"
    ]);
})();
