(function() {
    "use strict";
    /**
     * @ngInject
     */
 
    angular
        .module("ssmAngularApp.bannerAdModifier")
        .controller("BannerAdModifierController", BannerAdModifierController);
 
    function BannerAdModifierController(
        Community,
        $stateParams
    ) {
        /*jshint validthis:true */
        var vm = this;
          
        vm.currentCommunityId;
        vm.currentCommunity;

        vm.imageSource;
        vm.rerouteUrl;

        vm.getCurrentAds = getListOfAvailableAdvertisements;

        vm.onClickingCreateAdvertButton = onClickingCreateAdvertButton;
        vm.thereAreNoAdvertisementsToShow = thereAreNoAdvertisementsToShow;
        vm.thereAreAdsToShow = thereAreAdsToShow;
        vm.getListOfAvailableAdvertisements = getListOfAvailableAdvertisements;
        vm.removeAd = removeAd;
        vm.goBack = goBack;

        vm.currentAds;

        activate();

        function activate() {
            getCurrentCommunityId();
            getCurrentCommunity();
        }

        function getCurrentCommunityId() {
            vm.currentCommunityId = $stateParams.id;
        }

        function getCurrentCommunity() {
            Community.getCommunity(vm.currentCommunityId)
            .then(community => {
                vm.currentCommunity = community;
                getListOfAvailableAdvertisements();
            });
        }

        function onClickingCreateAdvertButton() {
            const withContext = {
                community_id: vm.currentCommunityId,
                image_source: vm.imageSource,
                redirect_url: vm.rerouteUrl
            };
            Community.upsertAdvertisement(withContext)
            .then(editedCommunity => {
                vm.currentAds = editedCommunity.advertisements;
            });
        }

        function thereAreAdsToShow() {
            return !thereAreNoAdvertisementsToShow();
        }

        function thereAreNoAdvertisementsToShow() {
            if (vm.currentCommunity && 
                vm.currentAds
            ) {
                return vm.currentAds.length == 0;
            }
            else {
                return false;
            }
        }

        function getListOfAvailableAdvertisements() {
            if (vm.currentCommunity 
                && vm.currentCommunity.advertisements
            ) {
                vm.currentAds = vm.currentCommunity.advertisements;
                return vm.currentCommunity.advertisements;
            }
            else {
                return [];
            }
        }

        function removeAd(index) {
            const context = {
                communityId: vm.currentCommunityId, 
                indexOfAdvert: index
            };
            Community.removeAdvertisement(context)
            .then(editedCommunity => {
                if (editedCommunity.advertisements) {
                    vm.currentAds = editedCommunity.advertisements;
                }
                else {
                    vm.currentAds = [];
                }
            });
        }

        function goBack() {
            window.history.back();
        }
    }
 })();