(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").controller("LayoutCtrl", LayoutCtrl);

    function LayoutCtrl(Tenant, $location, Logger) {
        var vm = this;
        vm.tenant = {
            slug: "nwc",
            headerLinksClass: "noHeaderLinks"
        };

        var brandOverride = function() {
            return $location.search().brand;
        };

        Tenant.getTenant().then(function(tenant) {
            vm.tenant = {
                description: tenant.description,
                slug: brandOverride() || tenant.slug,
                headerLinksClass:
                    tenant.headerLinks.length > 0
                        ? "headerLinks"
                        : "noHeaderLinks"
            };
        });
    }
})();
