(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmEditDeleteDropDown", [])
        .directive("ssmEditDeleteDropDown", ssmEditDeleteDropDown)
        .controller(
            "ssmEditDeleteDropDownController",
            ssmEditDeleteDropDownController
        );

    function ssmEditDeleteDropDown() {
        return {
            templateUrl:
                "components/ssmEditDeleteDropDown/ssmEditDeleteDropDown.html",
            restrict: "E",
            replace: true,
            scope: {},
            controller: ssmEditDeleteDropDownController,
            controllerAs: "vm",
            bindToController: true,
            transclude: true
        };
    }

    function ssmEditDeleteDropDownController() {
        /*jshint validthis:true */
        var vm = this;
    }
})();
