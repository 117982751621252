(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").constant("ssmMarketplaceConstants", {
        FILTERS: [
            {
                name: "Keyword",
                param: "query"
            },
            {
                name: "State",
                param: "state"
            },
            {
                name: "City",
                param: "city"
            },
            {
                name: "Postal Code",
                param: "postal_code"
            }
        ]
    });
})();
