(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("VendorShowController", VendorShowController);

    function VendorShowController(
        vendorProfileResolve,
        Vendor,
        SSM_VENDOR_ADMIN_FEATURE_ON
    ) {
        var vm = this;
        vm.vendorData = vendorProfileResolve;
        vm.showAdminTab = SSM_VENDOR_ADMIN_FEATURE_ON;
        vm.shouldShowInactiveInstructions = shouldShowInactiveInstructions;
        vm.canEditStatus =
            vm.vendorData &&
            vm.vendorData.permissions &&
            vm.vendorData.permissions.canActivateVendor;

        function shouldShowInactiveInstructions() {
            return (
                vm.vendorData && vm.vendorData.status === Vendor.STATUS.INACTIVE
            );
        }
    }
})();
