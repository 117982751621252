(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmContentResponse")
        .directive("ssmContentResponse", ssmContentResponse)
        .controller(
            "ssmContentResponseController",
            ssmContentResponseController
        );

    function ssmContentResponse() {
        return {
            templateUrl: function(elem, attrs) {
                attrs.contentType = attrs.contentType || "comment";
                return (
                    "components/ssmContentResponse/" +
                    attrs.contentType +
                    ".html"
                );
            },
            restrict: "E",
            replace: true,
            scope: {
                response: "=",
                contentType: "=",
                owner: "=",
                handleRemove: "&"
            },
            controller: ssmContentResponseController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmContentResponseController(CurrentUser, User, WallPost) {
        /*jshint validthis:true */
        var vm = this;
        vm.canDelete =
            vm.response.author &&
            vm.response.author.document_id === CurrentUser.profile.id;
        vm.removeResponse = removeResponse;
        vm.author = {};

        activate();

        function activate() {
            switch (vm.response.author && vm.response.author.document_type) {
                case WallPost.LOCATION_TYPE.USER:
                    vm.isAuthorAdmin = !!_.find(vm.owner.admin_ids, function(
                        admin_id
                    ) {
                        return admin_id === vm.response.author.document_id;
                    });
                    User.getUser(vm.response.author.document_id).then(function(
                        user
                    ) {
                        vm.author = user;
                    });
                    break;
                default:
                    break;
            }
        }

        function removeResponse() {
            if (vm.handleRemove) {
                vm.handleRemove({ response: vm.response });
            }
        }
    }
})();
