(function() {
    "use strict";

    angular.module("ssmAngularApp").config(function($stateProvider) {
        $stateProvider.state("updateEulaAccept", {
            url: "/update-eula",
            controller: "UpdateEulaAcceptCtrl",
            controllerAs: "vm",
            data: {
                requiresLogin: true,
                disableLastAttemptedUrl: true
            },
            resolve: {
                tenantResolve: resolveTenant
            }
        });

        function resolveTenant(Tenant) {
            return Tenant.getTenant();
        }
    });
})();
