(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsEditHostingController", OrgsEditHostingController);

    function OrgsEditHostingController(
        $q,
        $translate,
        Logger,
        Organization,
        orgProfileResolve,
        SSMTitle
    ) {
        var vm = this;
        vm.org = orgProfileResolve;
        vm.initialSiteData = angular.copy(orgProfileResolve.site);
        vm.isSiteOrg = vm.initialSiteData && vm.initialSiteData.name;
        vm.saveForm = saveForm;
        vm.resetForm = resetForm;
        vm.removeHost = removeHost;
        vm.onNewHostKeyDown = onNewHostKeyDown;
        vm.onNewUserProfileSlugKeyDown = onNewUserProfileSlugKeyDown;
        vm.removeUserProfileSlug = removeUserProfileSlug;
        vm.addInternetSiteProperties = addInternetSiteProperties;
        vm.ancestors = [];

        var ENTER_KEY = 13;
        var MAX_NAME_LENGTH = 80;
        var MAX_SLUG_LENGTH = 20;

        vm.maxLengthName = MAX_NAME_LENGTH;
        vm.maxLengthSlug = MAX_SLUG_LENGTH;
        vm.namePattern = new RegExp(/[a-zA-Z0-9\s]/);
        vm.slugPattern = new RegExp(/^[a-z0-9]+$/); //Only lowercase alphaNumeric

        activate();

        function activate() {
            $translate("ORGANIZATION.EDIT.SITE.HOSTING.TITLE").then(
                SSMTitle.setTitle
            );
            updateAncestorMap();
        }

        function saveForm(form) {
            var promises = [];

            if (form.parent_id.$dirty) {
                promises.push(
                    Organization.updateParent(vm.org.id, {
                        parent_id: vm.org.parent_id
                    })
                        .then(function(results) {
                            updateOrg(results, form);
                            vm.flashSavedFlag();
                        })
                        .catch(function(error) {
                            Logger.error(
                                "ERROR: ",
                                "HTTP Server Error: " + error.status
                            );
                        })
                );
            }

            promises.push(
                Organization.update(vm.org.id, {
                    hidden: vm.org.hidden,
                    site: vm.org.site
                })
                    .then(function(results) {
                        updateOrg(results, form);
                        vm.flashSavedFlag();
                    })
                    .catch(function(error) {
                        Logger.error(
                            "ERROR: ",
                            "HTTP Server Error: " + error.status
                        );
                    })
            );

            return $q.all(promises);
        }

        function resetForm(form) {
            Organization.getOrganization(vm.org.id)
                .then(function(results) {
                    updateOrg(results, form);
                })
                .catch(function(error) {
                    Logger.error(
                        "ERROR: ",
                        "HTTP Server Error: " + error.status
                    );
                });
        }

        function removeHost(host_to_remove, form) {
            if (form && form.$setDirty) {
                form.$setDirty();
            }

            host_to_remove = host_to_remove ? host_to_remove.trim() : "";
            vm.org.site.hosts = _.reject(vm.org.site.hosts, function(host) {
                return host === host_to_remove;
            });
        }

        function onNewHostKeyDown(event, host) {
            if (event.keyCode !== ENTER_KEY) {
                return;
            }

            if (!host || !host.trim()) {
                return;
            }

            vm.org.site.hosts.push(host.trim());
            vm.org.site.hosts = _.uniqBy(vm.org.site.hosts);
            vm.newHost = "";
        }

        function removeUserProfileSlug(user_profile_slug, form) {
            if (form && form.$setDirty) {
                form.$setDirty();
            }

            user_profile_slug = user_profile_slug
                ? user_profile_slug.trim()
                : "";
            vm.org.site.user_profile_slugs = _.reject(
                vm.org.site.user_profile_slugs,
                function(slug) {
                    return slug === user_profile_slug;
                }
            );
        }

        function onNewUserProfileSlugKeyDown(event, user_profile_slug) {
            if (event.keyCode !== ENTER_KEY) {
                return;
            }

            var slug = user_profile_slug ? user_profile_slug.trim() : "";
            if (!slug) {
                return;
            }

            vm.org.site.user_profile_slugs.push(slug);
            vm.org.site.user_profile_slugs = _.uniqBy(
                vm.org.site.user_profile_slugs
            );
            vm.newUserProfileSlug = "";
        }

        function updateOrg(data, form) {
            if (!data) {
                return;
            }

            vm.newHost = "";
            vm.newUserProfileSlug = "";

            if (!vm.org) {
                vm.org = {};
            }

            vm.org.id = data.id;
            vm.org.hidden = data.hidden;
            vm.org.site = data.site;
            vm.org.parent_id = data.parent_id;
            vm.org.ancestor_ids = data.ancestor_ids;

            updateAncestorMap();

            if (vm.org.site) {
                var hosts = vm.org.site.hosts || [];

                hosts = _.map(hosts, function(host) {
                    return host.trim();
                });

                vm.org.site.hosts = _.uniqBy(hosts);
            }

            if (form && form.$setPristine) {
                form.$setPristine();
            }
        }

        function updateAncestorMap() {
            vm.ancestors = [];
            for (var i = 0; i < vm.org.ancestor_ids.length; i++) {
                updateAncestorMapHelper(i, vm.org.ancestor_ids[i]);
            }

            function updateAncestorMapHelper(index, id) {
                Organization.getOrganization(id).then(function(ancestor) {
                    vm.ancestors[index] = ancestor;
                });
            }
        }

        function addInternetSiteProperties() {
            if (!vm.org || vm.isSiteOrg) {
                return;
            }

            var firstAddress =
                vm.org.addresses && vm.org.addresses.length
                    ? vm.org.addresses[0]
                    : {};

            var slug = vm.org.name
                .replace(/[\W_]+/g, "")
                .replace(/\s+/g, "")
                .toLowerCase();
            var slugEnd =
                slug.length < MAX_SLUG_LENGTH ? slug.length : MAX_SLUG_LENGTH;
            slug = slug.slice(0, slugEnd);

            var update = {
                site: {
                    name: vm.org.name,
                    title: vm.org.name,
                    slug: slug,
                    description: vm.org.bio,
                    nickname: vm.org.name,
                    hosts: [],
                    flags: {
                        open_content: false,
                        calendar: false,
                        invites: true,
                        find_organization: false,
                        find_someone: false,
                        linked_in: false,
                        standard_auth: true,
                        privacy_policy: true,
                        membership_agreement: true,
                        edit_profile_questions: true,
                        enable_digest: false,
                        prevent_profile_question_sort: false,
                        institution_isRequired: false
                    },
                    user_profile_slugs: ["nwc"],

                    org_super_admin: [],

                    digestFrom: vm.org.name,
                    digestSubject:
                        "See what is waiting for you on NextWaveConnect.com",
                    digest_footer_tagline:
                        "Make Collaboration Meaningful in Healthcare",
                    digest_footer_address1: vm.org.name,
                    digest_footer_address2: firstAddress.address_one || null,

                    images: [
                        {
                            name: "logo_main",
                            url: "logo_nwc.png"
                        }
                    ],
                    colors: [
                        {
                            name: "primary",
                            val: "#04B9DC",
                            text: "#fff"
                        },
                        {
                            name: "secondary",
                            val: "#ec7039",
                            text: "#fff"
                        }
                    ]
                }
            };

            Organization.update(vm.org.id, update)
                .then(function(org) {
                    vm.flashSavedFlag();
                    updateOrg(org);
                    vm.initialSiteData = angular.copy(org.site);
                    vm.isSiteOrg =
                        vm.initialSiteData && vm.initialSiteData.name;
                })
                .catch(function(error) {
                    Logger.error(
                        "ERROR: ",
                        "HTTP Server Error: " + error.status
                    );
                });
        }
    }
})();
