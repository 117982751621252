angular
    .module("ssmAngularApp.startup", ["ssmAngularApp.ssmWebNotification"])
    .config(function(
        ssmAuthProvider,
        $notificationProvider,
        $locationProvider,
        $translateProvider,
        $urlRouterProvider,
        SSM_WARNINGS_MISSING_TRANSLATE,
        SSM_WEB_NOTIFICATIONS
    ) {
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
        $locationProvider.hashPrefix("!");

        ssmAuthProvider.init();

        $translateProvider.useSanitizeValueStrategy("sanitize");

        $translateProvider.useStaticFilesLoader({
            prefix: "languages/locale-",
            suffix: ".json"
        });

        $translateProvider.preferredLanguage("en_US");
        if (SSM_WARNINGS_MISSING_TRANSLATE) {
            $translateProvider.useMissingTranslationHandler(
                "MissingTranslationHandler"
            );
        }

        // For any unmatched url, send to 404
        $urlRouterProvider.otherwise("/404");

        //IE9 hack so that #! will always be #!/.
        $urlRouterProvider.when("", "/");

        $notificationProvider.setOptions(SSM_WEB_NOTIFICATIONS.DEFAULTS);
    })
    .run(function($rootScope, $state, ssmAuth, amMoment, Branding, Logger) {
        Branding.init();

        ssmAuth.hookEvents();

        amMoment.changeLocale("en_US");

        $rootScope.$on("$stateChangeError", function(
            event,
            toState,
            toParams,
            fromState,
            fromParams
        ) {
            Logger.errorWithoutAlert("State change error", {
                event: event,
                toState: toState,
                fromState: fromState
            });

            if (toState.name !== "404") {
                $state.go("404");
            }
        });

        $rootScope.$on("$locationChangeSuccess", function() {
            window.scrollTo(0, 0);
        });

        // add redirect funcitonality; see https://github.com/angular-ui/ui-router/issues/1584#issuecomment-75137373
        $rootScope.$on("$stateChangeStart", function(evt, to, params) {
            if (to.redirectTo) {
                if (to.data && to.data.deprecated) {
                    Logger.warningWithoutAlert("Deprecated state used", {
                        to: to.name,
                        redirect: to.redirectTo
                    });
                }

                evt.preventDefault();
                $state.go(to.redirectTo, params);
            }
        });

        // Bind $state to $rootScope so we can access it in UI (CSS ng-if)
        $rootScope.$state = $state;
    });
