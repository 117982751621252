/*jshint maxparams: 14 */
(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsCreateController", OrgsCreateController);

    function OrgsCreateController(
        $q,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $translate,
        $uibModal,
        Analytics,
        CurrentUser,
        Logger,
        Organization,
        SSMTitle,
        SSMUtilities
    ) {
        var vm = this;
        vm.org = $stateParams.params
            ? angular.fromJson($stateParams.params)
            : {};
        vm.saveForm = saveForm;
        vm.cancelCreate = cancelCreate;
        vm.showFadeout = false;
        vm.modelData = {};
        vm.BROADCAST_IDENTIFIER = "OrgEditController";

        var REGISTRATION_CALLER = "registration";
        var PROFILE_CALLER = "profile";

        activate();

        function activate() {
            $translate("ORGANIZATION.CREATE.TITLE").then(SSMTitle.setTitle);
        }

        function saveForm(form) {
            var params = {
                name: vm.modelData.name,
                bio: vm.modelData.bio,
                sector:
                    vm.modelData.sector !== "None" ? vm.modelData.sector : "",
                photo_id: vm.modelData.photo_id,
                hash_tags: vm.modelData.hash_tags,
                addresses: vm.modelData.addresses
            };

            Analytics.eventTrack("createOrganization", {
                category: "organizationEdit",
                label:
                    params.name +
                    " address: " +
                    params.addresses +
                    ", photo_id: " +
                    params.photo_id
            });
            Analytics.eventTrack2("create_organization", {
                category: "organizationEdit",
                label:
                    params.name +
                    " address: " +
                    params.addresses +
                    ", photo_id: " +
                    params.photo_id
            });

            Organization.create(params)
                .then(function(results) {
                    updateOrg(results);

                    // Slight delay and fadeout so navigation is not jarring
                    vm.showFadeout = true;
                    $timeout(function() {
                        navigateBack(true);
                    }, 200);
                })
                .catch(function(error) {
                    if (error.status === 409) {
                        $translate("ORGANIZATION.CREATE.ERROR.DUPLICATE").then(
                            function(translation) {
                                Logger.error("Error: ", translation);
                            }
                        );
                    } else {
                        $translate("ORGANIZATION.CREATE.ERROR.GENERAL").then(
                            function(translation) {
                                Logger.error("Error: ", translation);
                            }
                        );
                    }
                });
        }

        function cancelCreate(form) {
            if (!vm.org.caller) {
                updateOrg({}, form);
                return;
            }

            var modalInstance = areYouSureModal(
                "ORGANIZATION.CREATE.CANCEL_WARNING"
            );
            modalInstance.result.then(function(response) {
                if (!response.confirmed) {
                    return;
                }

                Analytics.eventTrack("cancelCreateOrganization", {
                    category: "organizationEdit",
                    label: vm.modelData ? vm.modelData.name : "undefined"
                });
                Analytics.eventTrack2("cancel_create_organization", {
                    category: "organizationEdit",
                    label: vm.modelData ? vm.modelData.name : "undefined"
                });

                navigateBack(false);
            });
        }

        function updateOrg(data, form) {
            /*jshint maxcomplexity:9 */
            if (!data) {
                return;
            }

            if (
                !vm.modelData ||
                !vm.modelData.addresses ||
                !vm.modelData.addresses.length
            ) {
                vm.modelData = {
                    addresses: [{}]
                };
            }

            vm.modelData.id = data.id;
            vm.modelData.name = data.name;
            vm.modelData.bio = data.bio;
            vm.modelData.sector = data.sector || "None";
            vm.modelData.photo_id = data.photo_id;
            vm.modelData.photo = data.photo;
            vm.modelData.uploadedImage = [];
            vm.modelData.hash_tags = data.hash_tags || [];
            vm.modelData.addresses = data.addresses || [];
            $scope.$broadcast(
                SSMUtilities.SSM_TAG_SELECT_PROGRAMMATIC_CHANGE_EVENT,
                vm.BROADCAST_IDENTIFIER
            );

            if (form && form.$setPristine) {
                form.$setPristine();
            }
        }

        function areYouSureModal(confirmText) {
            return $uibModal.open({
                templateUrl: "components/modal/areYouSure/areYouSure.html",
                controller: "AreYouSureCtrl as vm",
                resolve: {
                    textResolve: function() {
                        return $translate(confirmText);
                    }
                }
            });
        }

        function navigateBack(wasSubmitted) {
            switch (vm.org.caller) {
                case REGISTRATION_CALLER:
                    $state.go("registration", {}, { reload: wasSubmitted });
                    break;
                case PROFILE_CALLER:
                    $state.go(
                        "main.profile.settings",
                        { organization_id: vm.modelData.id },
                        { reload: wasSubmitted }
                    );
                    break;
                default:
                    $state.go("main.organizations_show", {
                        id: vm.modelData.id
                    });
                    break;
            }
        }
    }
})();
