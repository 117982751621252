(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider
            .state("main.home", {
                url: "/",
                templateUrl: "layout/main/home/home.html",
                controller: "HomeCtrl",
                controllerAs: "home",
                data: {
                    analyticsName: "Home"
                },
                resolve: {
                    tenantResolve: function(Tenant) {
                        return Tenant.getTenant();
                    },
                    marketplacesLandingResolve: function(
                        tenantResolve,
                        $state
                    ) {
                        if (tenantResolve.flags.marketplace_landing) {
                            $state.go("main.marketplace.results");
                        }
                    }
                }
            })
            .state("main.feed", {
                url: "/feed",
                templateUrl: "layout/main/home/home.html",
                controller: "HomeCtrl",
                controllerAs: "home",
                data: {
                    analyticsName: "Feed"
                }
            });
    });
})();
