(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("main.blogs.index", {
            url: "/user/{id}/blogs",
            templateUrl: "layout/main/blog/blogIndex/blogIndex.html",
            controller: "BlogIndexCtrl",
            controllerAs: "blogs",
            resolve: {
                authorProfileResolve: function(
                    $stateParams,
                    User,
                    $q,
                    $translate
                ) {
                    if ($stateParams.id !== "all") {
                        return User.getUser($stateParams.id);
                    }
                    return $translate("BLOG.ALL_AUTHORS").then(function(
                        translation
                    ) {
                        return { full_name: translation, id: "all" };
                    });
                },

                tenantResolve: function(Tenant) {
                    return Tenant.getTenant();
                }
            }
        });
    });
})();
