(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("VendorLibraryController", VendorLibraryController);

    function VendorLibraryController(
        $translate,
        CurrentUser,
        vendorProfileResolve,
        vendorLibraryResolve,
        Analytics,
        Vendor,
        SSMTitle
    ) {
        var vm = this;
        vm.showFileUpload = true;
        vm.libraryData = vendorLibraryResolve;
        vm.vendorData = vendorProfileResolve;
        vm.deleters = CurrentUser.profile.is_vendor_manager
            ? [CurrentUser.profile.id]
            : [];

        vm.filesToUpload = [];
        vm.ENABLE = true;

        vm.addToLibrary = addToLibrary;
        vm.nextPage = nextPage;
        vm.removeFile = removeFile;

        // InfiniteScroll
        vm.nextPage = nextPage;

        var POST_LIMIT = 20;

        activate();

        function activate() {
            $translate("VENDOR.PAGE_TITLE.LIBRARY").then(SSMTitle.setTitle);
        }

        function addToLibrary() {
            if (vm.filesToUpload.length === 0) {
                return;
            }
            var file = vm.filesToUpload[0];
            Analytics.eventTrack("addToLibrary", {
                category: "vendorLibrary",
                label: "file_id:" + file.id
            });
            Analytics.eventTrack2("add_to_library_vendor", {
                category: "vendorLibrary",
                label: "file_id:" + file.id
            });

            Vendor.addVendorLibraryFile(vm.vendorData.id, file.id).then(
                function(results) {
                    file = vm.filesToUpload.shift();
                    vm.libraryData.files.unshift(file);
                }
            );
        }

        function nextPage(lastPost) {
            var query = { limit: POST_LIMIT };
            if (lastPost) {
                query.before_date = lastPost.create_date;
            }

            if (vm.vendorData) {
                return Vendor.getVendorLibrary(vm.vendorData.id, query);
            }
        }

        function removeFile(file) {
            return Vendor.removeVendorLibraryFile(vm.vendorData.id, file.id);
        }
    }
})();
