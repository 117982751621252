(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .factory("ssmVerifyEmailRoutesHelper", ssmVerifyEmailRoutesHelper)
        .config(function($stateProvider) {
            $stateProvider.state("verifyEmail", {
                url: "/verify-email?{token, final_redirect_uri}",
                templateUrl: "layout/verifyEmail/verifyEmail.html",
                controller: "VerifyEmailController",
                controllerAs: "vm",
                resolve: {
                    tenantResolve: function(Tenant) {
                        return Tenant.getTenant();
                    },
                    verifyResolve: function(
                        $stateParams,
                        ssmVerifyEmailRoutesHelper,
                        tenantResolve
                    ) {
                        return ssmVerifyEmailRoutesHelper.verifyResolve(
                            $stateParams,
                            tenantResolve
                        );
                    }
                }
            });
        });

    function ssmVerifyEmailRoutesHelper(
        Analytics,
        CurrentUser,
        ssmAuth,
        ssmAuthHelper
    ) {
        return {
            verifyResolve: verifyResolve
        };

        function verifyResolve($stateParams, tenantResolve) {
            var redirect = $stateParams.final_redirect_uri
                ? decodeURIComponent($stateParams.final_redirect_uri)
                : null;
            if (redirect === "/validate" || redirect === "/verify-email") {
                redirect = null;
            }

            var result = {};
            if (redirect) {
                result.redirect = redirect;
            }

            return ssmAuth
                .verifyEmail($stateParams.token)
                .then(function() {
                    result.success = true;
                    if (!ssmAuthHelper.isSignedIn) {
                        return result;
                    }

                    Analytics.eventTrack("EmailVerified", {
                        category: "RegistrationCtrl",
                        label: "user_id:" + CurrentUser.profile.id
                    });
                    Analytics.eventTrack2("email_verified", {
                        category: "RegistrationCtrl",
                        label: "user_id:" + CurrentUser.profile.id
                    });
                    return CurrentUser.refresh()
                        .then(function() {
                            if (result.redirect) {
                                location.assign(result.redirect || "/");
                            } else if (
                                tenantResolve.flags.marketplace_landing
                            ) {
                                location.assign("/marketplace");
                            } else {
                                location.assign("/");
                            }
                        })
                        .catch(function() {
                            return result;
                        });
                })
                .catch(function() {
                    result.success = false;
                    return result;
                });
        }
    }
})();
