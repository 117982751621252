(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmContentCreate")
        .directive("ssmContentCreate", ssmContentCreate)
        .directive("ssmContentCreateEmbed", function() {
            return {
                template:
                    '<a class="embedly-card" href="{{vm.url}}" data-card-key="c3bd4e6ecabd49cc95297c42b2a2ea37" data-card-controls="0" data-card-recommend="0">',
                restrict: "E",
                replace: true,
                scope: {
                    url: "="
                },
                controller: function() {},
                controllerAs: "vm",
                bindToController: true
            };
        })
        .controller("ssmContentCreateController", ssmContentCreateController);

    function ssmContentCreate() {
        return {
            templateUrl: "components/ssmContentCreate/ssmContentCreate.html",
            restrict: "E",
            replace: true,
            scope: {
                data: "=",
                feedId: "@",
                contentType: "@",
                submitCallback: "=",
                disallowFiles: "="
            },
            controller: ssmContentCreateController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmContentCreateController(
        Analytics,
        $filter,
        $timeout,
        Content,
        Logger,
        SSMInlineContentHelper,
        SSMTitle
    ) {
        /*jshint validthis:true */
        var vm = this;
        vm.createContent = createContent;
        vm.resetContent = resetContent;
        vm.removeEmbed = removeEmbed;
        vm.findEmbedded = findEmbedded;
        vm.userRemoveEmbed = userRemoveEmbed;
        vm.onRemoveFile = onRemoveFile;
        activate();

        function activate() {
            vm.placeholder =
                vm.contentType === "question" ? "Question" : "Title";
            vm.placeholderTextarea =
                vm.contentType === "question" ? "Details" : "Text";
            vm.panelTitle =
                vm.contentType === "question"
                    ? "Post a Question"
                    : "Post an Article";
            resetContent();
        }

        function resetContent() {
            vm.content = {
                content_type: vm.contentType,
                feed_id: vm.feedId
            };
        }

        function createContent() {
            vm.content.embed = vm.findEmbedded();

            vm.content.file_ids = _.map(vm.files, function(file) {
                return file.id;
            });

            Analytics.eventTrack("MarketplacePost", {
                category: "MarketplaceCtrl",
                label: "Feed ID: " + vm.content.feed_id
            });
            Analytics.eventTrack2('marketplace_post', {
                category: "MarketplaceCtrl",
                label: "Feed ID: " + vm.content.feed_id
            });

            Content.create(vm.content)
                .then(function(content) {
                    vm.data.unshift(content);

                    vm.content.title = "";
                    if (vm.submitCallback) {
                        vm.submitCallback();
                    }
                })
                .catch(function(error) {
                    Logger.error(error);
                });
        }

        function onRemoveFile(fileIndex) {
            var files = _.clone(vm.files);
            files.splice(fileIndex, 1);
            saveFileChanges(files);
        }

        function saveFileChanges(files) {
            var params = {};
            params.file_ids = _.map(files, function(file) {
                return file.id || file._id;
            });

            vm.files = files;
        }

        function removeEmbed() {
            delete vm.embeddedUrl;
        }

        function userRemoveEmbed() {
            vm.deletedEmbed = vm.embeddedUrl;
            vm.removeEmbed();
        }

        function findEmbedded(shouldWait) {
            var embed = SSMInlineContentHelper.findFirstUrl(vm.content.text);
            var titleEmbed = SSMInlineContentHelper.findFirstUrl(
                vm.content.title
            );
            if (titleEmbed) {
                embed = titleEmbed;
            }

            if (
                embed &&
                embed !== vm.embeddedUrl &&
                embed !== vm.deletedEmbed
            ) {
                vm.removeEmbed();
                if (shouldWait) {
                    $timeout(function() {
                        vm.embeddedUrl = embed;
                    }, 1500);
                } else {
                    vm.embeddedUrl = embed;
                }
            }

            return vm.embeddedUrl;
        }
    }
})();
