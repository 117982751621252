(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("VendorFlairCreateController", VendorFlairCreateController)
        .controller(
            "VendorFlairCreateModalController",
            VendorFlairCreateModalController
        );

    function VendorFlairCreateController(
        $uibModal,
        $state,
        $translate,
        vendorProfileResolve,
        vendorFlairListResolve,
        vendorLibraryResolve,
        Flair,
        SSMTitle
    ) {
        var vm = this;
        vm.flairSave = flairSave;

        activate();

        function activate() {
            $translate("VENDOR.PAGE_TITLE.FLAIR").then(SSMTitle.setTitle);

            $uibModal
                .open({
                    templateUrl:
                        "layout/main/vendor/vendorShow/vendorFlairCreate/vendorFlairCreate.html",
                    controller: "VendorFlairCreateModalController",
                    controllerAs: "vm",
                    resolve: {
                        vendorLibraryResolve: function() {
                            return vendorLibraryResolve;
                        }
                    }
                })
                .result.then(vm.flairSave)
                .finally(function() {
                    $state.go("^");
                });
        }

        function flairSave(edits) {
            Flair.addVendorFlair(vendorProfileResolve.id, edits).then(function(
                result
            ) {
                vendorFlairListResolve.push(result);
            });
        }
    }

    function VendorFlairCreateModalController(
        $uibModalInstance,
        vendorLibraryResolve
    ) {
        var vm = this;
        vm.flairData = {};
        vm.vendorLibrary = vendorLibraryResolve;
        vm.updateFlair = updateFlair;
        vm.closeModal = closeModal;

        function updateFlair(edits) {
            $uibModalInstance.close(edits);
        }

        function closeModal() {
            $uibModalInstance.dismiss("cancel");
        }
    }
})();
