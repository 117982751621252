(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("ProfileCtrl", ProfileCtrl);

    function ProfileCtrl(
        Tenant,
        CurrentUser
    ) {
        var vm = this;

        vm.shouldShowAllTabs = true;

        activate();

        function activate() {
            getTenant(); 
            determineIfShouldShowAllTabs();
        }

        function getTenant() {
            Tenant.getTenant()
            .then(function(tenant) {
                vm.tenant = tenant;
            });
        }

        function determineIfShouldShowAllTabs() {
            if (CurrentUser.hasNeverUsedSso()) return;
            if (CurrentUser.didNotSsoInFromAhima()) return;

            vm.shouldShowAllTabs = false;
        }
    }
})();
