(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider
            .state("main.viewInvites", {
                url: "/viewInvites",
                redirectTo: "main.viewInvites.viewPending",
                templateUrl: "layout/main/invite/inviteList/inviteList.html",
                controller: "InviteListController",
                controllerAs: "vm"
            })
            .state("main.viewInvites.viewPending", {
                url: "/viewPending",
                templateUrl:
                    "layout/main/invite/invitePending/invitePending.html",
                controller: "InvitePendingController",
                controllerAs: "vm",
                resolve: {
                    pendingPagerResolve: resolvePendingPager
                }
            })
            .state("main.viewInvites.viewAccepted", {
                url: "/viewAccepted",
                templateUrl:
                    "layout/main/invite/inviteAccepted/inviteAccepted.html",
                controller: "InviteAcceptedController",
                controllerAs: "vm",
                resolve: {
                    acceptedPagerResolve: resolveAcceptedPager
                }
            });

        function resolvePendingPager(ExternalInvites) {
            return ExternalInvites.pendingPaged();
        }

        function resolveAcceptedPager(ExternalInvites) {
            return ExternalInvites.acceptedPaged();
        }
    });
})();
