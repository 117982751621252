(function() {
    "use strict";

    angular
        .module("ssmAngularApp.wallPostCard")
        .directive("wallPostCard", wallPostCard);

    function wallPostCard() {
        return {
            templateUrl: "components/wallPostCard/wallPostCard.html",
            restrict: "E",
            replace: true,
            scope: {
                data: "=",
                imageSmall: "@",
                location: "="
            },
            controller: wallPostCardCtrl,
            controllerAs: "wallPostCard",
            bindToController: true
        };
    }

    function wallPostCardCtrl(
        $q,
        $translate,
        $element,
        Analytics,
        CurrentUser,
        Connection,
        Logger,
        WallPost,
        SSMInlineContentHelper
    ) {
        /*jshint validthis:true */
        var vm = this;
        var postTemplate = {
            message: ""
        };
        var pollTemplate = {
            question: "",
            choices: [
                {
                    text: ""
                },
                {
                    text: ""
                }
            ]
        };
        //var inputElement = $element.find("editor");
        var isTextFormat = false;
        vm.textFormatLabel = "t";
        vm.textFormatTag = "";
        var dimension2 =
            vm.location && vm.location.id ? vm.location.id : "Unknown";
        var dimension4 =
            vm.data && vm.data.location && vm.data.location.id
                ? vm.data.location.id
                : "Unknown";

        vm.currentUser = null;
        vm.updatePost = updatePost;
        vm.cancelUpdate = cancelUpdate;
        vm.getTargetSref = getTargetSref;
        vm.showLocation = showLocation;
        vm.collection =
            vm.data.author.author_type === WallPost.POST_AS_TYPE.COMMUNITY
                ? "communities"
                : "users";
        vm.copyPostForEdit = copyPostForEdit;
        vm.removeEmbed = removeEmbed;
        vm.textToggle = textToggle;

        vm.connectionPager = Connection.getAllPaged();

        // if you change this, change it in wallPost.directive.js, too
        vm.canEdit =
            (!vm.data.community || vm.data.location.permissions.canPost) && // Post is either not located in a community or the community permits posts
            (vm.data.is_yours || // Post is yours
            ((!vm.data.community || !vm.data.community.posted_as_community) &&
                vm.data.permissions &&
                vm.data.permissions.can_edit_as_delegate) || // Post authored by one of your principals
                (vm.data.community &&
                    vm.data.community.posted_as_community &&
                    vm.data.location.permissions.canEditAsCommunityPosts)); // Posted as community and you can post as community

        activate();

        function activate() {
            vm.currentUser = CurrentUser.profile;
        }

        function copyPostForEdit() {
            vm.editedPost = angular.copy(vm.data);
            vm.editedPost.message = SSMInlineContentHelper.escapeHtml(
                vm.editedPost.message
            );
            vm.editedPost.message = SSMInlineContentHelper.hydrateMentionsForWall(
                vm.editedPost.message
            );
            vm.editedPost.message = SSMInlineContentHelper.textFormat(
                vm.editedPost.message
            );
            vm.editedPost.hash_tags = vm.editedPost.hash_tags
                .toString()
                .replace(/,/g, "&nbsp;");
        }

        function removeEmbed() {
            if (vm.editedPost) {
                vm.editedPost.embed = null;
            }
            if (vm.data) {
                vm.data.embed = null;
            }

            var updatedPost = {
                message: vm.data.message,
                embed: null
            };

            WallPost.update(vm.data.id, updatedPost).catch(function(error) {
                $translate("WALL.UPDATE_POST_ERROR").then(function(
                    translation
                ) {
                    Logger.error("ERROR: ", translation, error);
                });
            });
        }

        function updatePost() {
            var promises = [];

            var dirty = false;

            if (
                vm.editPostForm.editMessageForm &&
                vm.editPostForm.editMessageForm.$dirty
            ) {
                var updatedPost = angular.copy(postTemplate);

                updatedPost.message = SSMInlineContentHelper.sanitizeUserInput(
                    vm.editedPost.message
                );

                updatedPost.hash_tags = vm.editedPost.hash_tags.split(
                    /&nbsp;|\s/
                );
                updatedPost.hash_tags = _.uniq(updatedPost.hash_tags);
                _.remove(updatedPost.hash_tags, _.isEmpty);

                if (!updatedPost.embed) {
                    updatedPost.embed = SSMInlineContentHelper.findFirstUrl(
                        vm.editedPost.message
                    );
                    vm.data.embed = updatedPost.embed;
                }

                promises.push(
                    WallPost.update(vm.data.id, updatedPost).catch(function(
                        error
                    ) {
                        $translate("WALL.UPDATE_POST_ERROR").then(function(
                            translation
                        ) {
                            Logger.error("ERROR: ", translation, error);
                        });
                    })
                );
            }

            if (
                vm.editPostForm.editPollForm &&
                vm.editPostForm.editPollForm.$dirty
            ) {
                var updatedPoll = angular.copy(pollTemplate);
                updatedPoll.question = vm.editedPost.poll.question;
                updatedPoll.choices = _.map(
                    vm.editedPost.poll.choices,
                    function(choice) {
                        return {
                            text: choice.text
                        };
                    }
                );

                promises.push(
                    WallPost.updatePoll(vm.data.id, updatedPoll).catch(function(
                        error
                    ) {
                        $translate("WALL.UPDATE_POST_ERROR").then(function(
                            translation
                        ) {
                            Logger.error("ERROR: ", translation, error);
                        });
                    })
                );
            }

            if (promises.length > 0 && !isTextFormat) {
                $q.all(promises).finally(function() {
                    Analytics.eventTrack("updatePost", {
                        category: "wallPostCtrl",
                        label: "post_id:" + vm.data.id,
                        dimension2: dimension2,
                        dimension4: dimension4
                    });
                    Analytics.eventTrack2('update_post', {
                        category: "wallPostCtrl",
                        label: "post_id:" + vm.data.id,
                        dimension2: dimension2,
                        dimension4: dimension4
                    });

                    // I have to get the post, because we are doing multiple updates
                    WallPost.find(vm.data.id).then(function(hydratedPost) {
                        angular.merge(vm.data, hydratedPost); // need to perserve stickied flag
                        vm.data.hash_tags = hydratedPost.hash_tags;
                        vm.data.isEditView = false;

                        if (
                            hydratedPost.author.id !== vm.currentUser.id &&
                            hydratedPost.author.author_type ===
                                WallPost.LOCATION_TYPE.USER
                        ) {
                            Analytics.eventTrack("updatePostAsDelegate", {
                                category: "Delegation",
                                label:
                                    "id:" +
                                    hydratedPost.id +
                                    ", author: " +
                                    hydratedPost.author.id +
                                    ", author type: " +
                                    hydratedPost.author.author_type,
                                dimension2: dimension2,
                                dimension4: dimension4
                            });
                            Analytics.eventTrack2('update_post_as_delegate', {
                                category: "Delegation",
                                label: "id:" +
                                    hydratedPost.id +
                                    ", author: " +
                                    hydratedPost.author.id +
                                    ", author type: " +
                                    hydratedPost.author.author_type,
                                dimension2: dimension2,
                                dimension4: dimension4
                            });
                        }
                    });
                });
            } else if (isTextFormat) {
                isTextFormat = false;
            } else {
                vm.data.isEditView = false;
            }
        }

        function cancelUpdate() {
            Analytics.eventTrack("cancelUpdate", {
                category: "wallPostCtrl",
                label: "post_id:" + vm.data.id,
                dimension2: dimension2,
                dimension4: dimension4
            });
            Analytics.eventTrack2('cancel_update', {
                category: "wallPostCtrl",
                label: "post_id:" + vm.data.id,
                dimension2: dimension2,
                dimension4: dimension4
            });
            vm.data.isEditView = false;
        }

        function getTargetSref(target) {
            var target_type = target.author_type || target.type;
            var params = {
                id: target.id
            };
            switch (target_type) {
                case WallPost.LOCATION_TYPE.USER:
                    return "main.users_show(" + JSON.stringify(params) + ")";
                case WallPost.LOCATION_TYPE.COMMUNITY:
                    params.url_name = vm.data.community.url_name;
                    return (
                        "main.communities_show(" + JSON.stringify(params) + ")"
                    );
            }
        }

        function showLocation(post) {
            if (!vm.location) {
                return _.isObject(post.location);
            }

            switch (post.location.type) {
                case WallPost.LOCATION_TYPE.COMMUNITY:
                    if (
                        post.author.author_type ===
                        WallPost.LOCATION_TYPE.COMMUNITY
                    ) {
                        return false;
                    }
                    return vm.location.id !== post.location.id;
                default:
                    return post.author.id !== post.location.id;
            }
        }

        function textToggle(input, label) {
            var openingTag = "<" + input + ">";
            var closingTag = "</" + input + ">";
            isTextFormat = true;
            var editMessage = vm.editedPost.message;
            if (
                editMessage.length > closingTag.length &&
                editMessage.substring(editMessage.length - closingTag.length) ==
                    closingTag
            ) {
                vm.editedPost.message = editMessage.concat(" ");
                vm.textFormatLabel = "t";
            } else {
                vm.editedPost.message = vm.editedPost.message.concat(
                    openingTag + " " + closingTag
                );
                vm.textFormatLabel = label;
            }
        }
    }
})();
