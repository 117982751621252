(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.notification.service", [
            "ssmAngularApp.api",
            "ssmAngularApp.config",
            "ssmAngularApp.analytics",
            "ssmAngularApp.socket.service"
        ])
        .factory("Notification", NotificationService)
        .factory("NotificationServiceHelper", NotificationServiceHelper)
        .factory("NotificationServiceConstants", NotificationServiceConstants);

    function NotificationService(
        NotificationServiceConstants,
        NotificationServiceHelper
    ) {
        return {
            NOTIFICATION_SOCKET_EVENTS:
                NotificationServiceConstants.NOTIFICATION_SOCKET_EVENTS,

            NOTIFICATION_CLASS: NotificationServiceConstants.NOTIFICATION_CLASS,

            NOTIFICATION_TYPE: NotificationServiceConstants.NOTIFICATION_TYPE,

            clearNotifications: clearNotifications,

            getNotification: getNotification,

            getNotifications: getNotifications,

            onNotificationVisted: onNotificationVisted,

            onNotificationsReviewed: onNotificationsReviewed,

            reviewNotification: reviewNotification
        };

        function clearNotifications(user_id, notification) {
            if (!user_id || !notification || !notification.notification_id) {
                return;
            }

            return NotificationServiceHelper.ignoreNotification(
                user_id,
                notification.notification_id
            );
        }

        function getNotification(user_id, notification_id) {
            return NotificationServiceHelper.getNotification(
                user_id,
                notification_id
            ).then(NotificationServiceHelper.convertNotification);
        }

        function getNotifications(user_id, options) {
            return NotificationServiceHelper.getAllNotifications(
                user_id,
                options
            ).then(NotificationServiceHelper.convertNotifications);
        }

        function onNotificationVisted(user_id, notification) {
            if (!user_id || !notification || !notification.notification_id) {
                return;
            }

            return NotificationServiceHelper.visitNotification(
                user_id,
                notification.notification_id
            );
        }

        function onNotificationsReviewed(user_id, notifications) {
            if (!notifications || !notifications.length) {
                return;
            }

            var reviewed = _.filter(notifications, function(notification) {
                return (
                    notification.hidden !== true && notification.notification_id
                );
            });

            var notification_ids = _.chain(reviewed)
                .reject("group_id")
                .map("notification_id")
                .compact()
                .value();
            var group_ids = _.chain(reviewed)
                .map("group_id")
                .compact()
                .value();

            return NotificationServiceHelper.hideAllNotifications(
                user_id,
                notification_ids,
                group_ids
            );
        }

        function reviewNotification(user_id, notification) {
            if (!notification || !notification.notification_id) {
                return;
            }
            var reviewed;

            if (notification.hidden !== true && notification.notification_id) {
                reviewed = notification;
            } else return;

            notification.hidden = true;
            notification.unvisited = false;

            var notification_id = [reviewed.notification_id];
            var group_id = _.chain(reviewed)
                .map("group_id")
                .compact()
                .value();

            return NotificationServiceHelper.hideAllNotifications(
                user_id,
                notification_id,
                group_id
            );
        }
    }

    function NotificationServiceConstants() {
        var NOTIFICATION_CLASS = {
            CONTENT: "content",
            REQUEST: "request",
            OTHER: "other"
        };

        var NOTIFICATION_TYPE = {
            CONNECTION_REQUEST: 0,
            CONNECTION_REQUEST_ACCEPTED: 1,
            WALL_POST_COMMENT: 100,
            WALL_COMMENT_LIKE: 101,
            WALL_POST_LIKE: 102,
            WALL_POST: 103,
            WALL_POST_MENTION: 104,
            WALL_COMMENT_MENTION: 105,
            FOLLOW_INVITE: 200,
            THREAD_ACTIVITY: 300,
            BLOG_COMMENT: 400,
            BLOG_COMMENT_LIKE: 401,
            BLOG_LIKE: 402,
            BLOG_POST: 403,
            BLOG_THREAD_ACTIVITY: 404,
            BLOG_COMMENT_MENTION: 405,
            COMMUNITY_POST: 500,
            MARKETPLACE_CAMPAIGN_POST: 600,
            MARKETPLACE_CAMPAIGN_CHILD_POST: 700
        };

        var CONTENT_NOTIFICATION_TYPES = [
            NOTIFICATION_TYPE.WALL_COMMENT_LIKE,
            NOTIFICATION_TYPE.WALL_COMMENT_MENTION,
            NOTIFICATION_TYPE.WALL_POST,
            NOTIFICATION_TYPE.WALL_POST_COMMENT,
            NOTIFICATION_TYPE.WALL_POST_LIKE,
            NOTIFICATION_TYPE.WALL_POST_MENTION,
            NOTIFICATION_TYPE.BLOG_COMMENT,
            NOTIFICATION_TYPE.BLOG_COMMENT_LIKE,
            NOTIFICATION_TYPE.BLOG_COMMENT_MENTION,
            NOTIFICATION_TYPE.BLOG_LIKE,
            NOTIFICATION_TYPE.BLOG_POST,
            NOTIFICATION_TYPE.BLOG_THREAD_ACTIVITY,
            NOTIFICATION_TYPE.THREAD_ACTIVITY,
            NOTIFICATION_TYPE.COMMUNITY_POST,
            NOTIFICATION_TYPE.MARKETPLACE_CAMPAIGN_POST,
            NOTIFICATION_TYPE.MARKETPLACE_CAMPAIGN_CHILD_POST
        ];

        var REQUEST_NOTIFICATION_TYPES = [
            NOTIFICATION_TYPE.CONNECTION_REQUEST,
            NOTIFICATION_TYPE.FOLLOW_INVITE
        ];

        var ALL_NOTIFICATION_TYPES = _.union(
            CONTENT_NOTIFICATION_TYPES,
            REQUEST_NOTIFICATION_TYPES
        );

        var NOTIFICATION_SOCKET_EVENTS = {
            EVENT_NOTIFICATION_CREATED: "notification_created",
            EVENT_NOTIFICATION_DELETED: "notification_deleted",

            EVENT_NOTIFICATION_REVIEWED: "notification_reviewed",
            EVENT_NOTIFICATION_IGNORED: "notification_ignored",
            EVENT_NOTIFICATION_VISITED: "notification_visited"
        };

        // These are actually for digest frequencies
        var DIGEST_FREQUENCY_NAMES = {
            0: "Never",
            2: "Daily",
            3: "Weekly",
            4: "Monthly"
        };

        var NOTIFICATION_CONTENT_CONFIG = {
            500: {
                icon: "ssm-icon-group",
                header: "NOTIFICATIONS.COMMUNITY_POST_HEADER"
            },
            600: {
                icon: "ssm-icon-group",
                header: "NOTIFICATIONS.MARKETPLACE_POST_HEADER"
            },
            700: {
                icon: "ssm-icon-comment",
                header: "NOTIFICATIONS.MARKETPLACE_COLLABORATION_HEADER"
            }
        };

        return {
            DIGEST_FREQUENCY_NAMES: DIGEST_FREQUENCY_NAMES,
            NOTIFICATION_CLASS: NOTIFICATION_CLASS,
            NOTIFICATION_CLASS_GROUPS: {
                ALL: ALL_NOTIFICATION_TYPES,
                CONTENT: CONTENT_NOTIFICATION_TYPES,
                REQUEST: REQUEST_NOTIFICATION_TYPES
            },
            NOTIFICATION_CONTENT_CONFIG: NOTIFICATION_CONTENT_CONFIG,
            NOTIFICATION_SOCKET_EVENTS: NOTIFICATION_SOCKET_EVENTS,
            NOTIFICATION_TYPE: NOTIFICATION_TYPE
        };
    }

    function NotificationServiceHelper(
        Api,
        $q,
        Logger,
        NotificationServiceConstants
    ) {
        var userApi = Api.all("user");

        var helper = {
            convertNotification: convertNotification,
            convertNotifications: convertNotifications,

            convertBlogComment: convertBlogComment,
            convertBlogCommentLike: convertBlogCommentLike,
            convertBlogLike: convertBlogLike,
            convertBlogThreadActivity: convertBlogThreadActivity,
            convertFollowInvite: convertFollowInvite,
            convertNewComment: convertNewComment,
            convertNewCommentLike: convertNewCommentLike,
            convertNewLike: convertNewLike,
            convertPendingConnection: convertPendingConnection,
            convertSubscribedNewComment: convertSubscribedNewComment,
            convertWallPost: convertWallPost,
            convertWallCommentMention: convertWallCommentMention,

            getNotificationClass: getNotificationClass,
            getNotification: getNotification,
            getAllNotifications: getAllNotifications,

            hideAllNotifications: hideAllNotifications,
            ignoreNotification: ignoreNotification,
            visitNotification: visitNotification
        };
        return helper;

        function convertNotification(notification) {
            /*jshint maxcomplexity: false*/
            if (!notification) {
                return null;
            }

            if (notification.plain) {
                notification = notification.plain();
            }

            notification.class = helper.getNotificationClass(notification.type);

            // If group_id or notification_id exist, use it. Otherwise just send
            // back the whole object for comparison. (This can happen if the
            // notification was deleted.)
            notification.badge_id =
                notification.group_id ||
                notification.notification_id ||
                notification;

            switch (notification.type) {
                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .FOLLOW_INVITE:
                    return convertFollowInvite(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .CONNECTION_REQUEST:
                    return convertPendingConnection(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .THREAD_ACTIVITY:
                    return convertSubscribedNewComment(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .BLOG_COMMENT:
                    return convertBlogComment(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .BLOG_COMMENT_LIKE:
                    return convertBlogCommentLike(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE.BLOG_LIKE:
                    return convertBlogLike(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .BLOG_THREAD_ACTIVITY:
                    return convertBlogThreadActivity(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .BLOG_COMMENT_MENTION:
                    return convertBlogCommentMention(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE.WALL_POST:
                    return convertWallPost(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .WALL_POST_COMMENT:
                    return convertNewComment(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .WALL_POST_MENTION:
                    return convertWallPostMention(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .WALL_COMMENT_MENTION:
                    return convertWallCommentMention(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .WALL_COMMENT_LIKE:
                    return convertNewCommentLike(notification);

                case NotificationServiceConstants.NOTIFICATION_TYPE
                    .WALL_POST_LIKE:
                    return convertNewLike(notification);

                default:
                    var config =
                        NotificationServiceConstants
                            .NOTIFICATION_CONTENT_CONFIG[notification.type];
                    if (!config) {
                        Logger.errorWithoutAlert(
                            "Cannot process notification type",
                            { notification: notification }
                        );
                        return;
                    }

                    notification.icon = config.icon;
                    notification.header = config.header;
                    return notification;
            }

            return null;
        }

        function convertNotifications(notifications) {
            if (!notifications) {
                return $q.reject(
                    new Error("Cannot convert invalid notifications object")
                );
            }

            if (notifications.plain) {
                notifications = notifications.plain();
            }

            var mapped = _.chain(notifications)
                .compact()
                .map(helper.convertNotification)
                .compact()
                .value();
            return $q.when(mapped);
        }

        function convertFollowInvite(notification) {
            delete notification.date;
            notification.icon = "ssm-icon-group";
            notification.header = "NOTIFICATIONS.FOLLOW_INVITE_HEADER";
            return notification;
        }

        function convertPendingConnection(notification) {
            notification.icon = "ssm-icon-placeholder-user";
            notification.header = "NOTIFICATIONS.CONNECTION_PENDING_HEADER";
            return notification;
        }

        function convertSubscribedNewComment(comment) {
            comment.icon = "ssm-icon-comment";
            comment.header = "NOTIFICATIONS.SUBSCRIBED_POST_COMMENT_HEADER";
            return comment;
        }

        function convertBlogComment(comment) {
            comment.icon = "ssm-icon-comment";
            comment.header = "NOTIFICATIONS.BLOG_COMMENT_HEADER";
            return comment;
        }

        function convertBlogCommentLike(like) {
            like.icon = "ssm-icon-thumbs-o-up";
            like.header = "NOTIFICATIONS.BLOG_COMMENT_LIKE_HEADER";
            return like;
        }

        function convertBlogLike(like) {
            like.icon = "ssm-icon-thumbs-o-up";
            like.header = "NOTIFICATIONS.BLOG_LIKE_HEADER";
            return like;
        }

        function convertBlogThreadActivity(comment) {
            comment.icon = "ssm-icon-comment";
            comment.header = "NOTIFICATIONS.SUBSCRIBED_POST_COMMENT_HEADER";
            return comment;
        }

        function convertBlogCommentMention(comment) {
            comment.icon = "ssm-icon-comment";
            comment.header = "NOTIFICATIONS.BLOG_COMMENT_MENTION_HEADER";
            return comment;
        }

        function convertWallPostMention(mention) {
            mention.icon = "ssm-icon-comment";
            mention.header = "NOTIFICATIONS.WALL_POST_MENTION_HEADER";
            return mention;
        }

        function convertWallCommentMention(mention) {
            mention.icon = "ssm-icon-comment";
            mention.header = "NOTIFICATIONS.COMMENT_MENTION_HEADER";
            return mention;
        }

        function convertNewComment(comment) {
            comment.icon = "ssm-icon-comment";
            comment.header = "NOTIFICATIONS.COMMENT_HEADER";
            return comment;
        }

        function convertNewCommentLike(like) {
            like.icon = "ssm-icon-thumbs-o-up";
            like.header = "NOTIFICATIONS.COMMENT_LIKE_HEADER";
            return like;
        }

        function convertNewLike(like) {
            like.icon = "ssm-icon-thumbs-o-up";
            like.header = "NOTIFICATIONS.LIKE_HEADER";
            return like;
        }

        function convertWallPost(post) {
            post.icon = "ssm-icon-user";
            post.header = "NOTIFICATIONS.WALL_POST_HEADER";
            return post;
        }

        function getNotificationClass(notificationType) {
            if (
                _.includes(
                    NotificationServiceConstants.NOTIFICATION_CLASS_GROUPS
                        .REQUEST,
                    notificationType
                )
            ) {
                return NotificationServiceConstants.NOTIFICATION_CLASS.REQUEST;
            }

            if (
                _.includes(
                    NotificationServiceConstants.NOTIFICATION_CLASS_GROUPS
                        .CONTENT,
                    notificationType
                )
            ) {
                return NotificationServiceConstants.NOTIFICATION_CLASS.CONTENT;
            }

            return NotificationServiceConstants.NOTIFICATION_CLASS.OTHER;
        }

        function getNotification(user_id, notification_id, options) {
            options = options || { limit: 20 };
            return userApi
                .one(user_id)
                .all("notifications")
                .one(notification_id)
                .get(options);
        }

        function getAllNotifications(user_id, options) {
            options = options || { limit: 20 };
            return userApi
                .one(user_id)
                .all("notifications")
                .getList(options);
        }

        function hideAllNotifications(user_id, notification_ids, group_ids) {
            if (!notification_ids.length && !group_ids.length) {
                return;
            }

            var options = { hidden: true, unvisited: false };
            var query = {};
            if (notification_ids && notification_ids.length) {
                query.notification_ids = notification_ids;
            }
            if (group_ids && group_ids.length) {
                query.group_ids = group_ids;
            }

            return userApi.one(user_id, "notifications").patch(options, query);
        }

        function visitNotification(user_id, notification_id) {
            var options = { unvisited: false };
            return userApi
                .one(user_id)
                .all("notifications")
                .one(notification_id)
                .patch(options);
        }

        function ignoreNotification(user_id, notification_id) {
            var options = { ignored: true };
            return userApi
                .one(user_id)
                .all("notifications")
                .one(notification_id)
                .patch(options);
        }
    }
})();
