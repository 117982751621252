(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.insertBlogImageModal")
        .controller("InsertBlogImageCtrl", InsertBlogImageCtrl);

    function InsertBlogImageCtrl(
        $scope,
        insertBlogImageData,
        $uibModalInstance
    ) {
        var vm = this;
        vm.blog = insertBlogImageData.blog;
        vm.insertImage = insertImage;
        vm.closeModal = closeModal;
        vm.canInsert = false;

        activate();

        function activate() {
            $scope.$watch(
                function() {
                    return vm.files;
                },
                function(newValue, oldValue) {
                    vm.canInsert = newValue && newValue.length === 1;
                }
            );

            $scope.$on("$locationChangeStart", function() {
                $uibModalInstance.close();
            });
        }

        function insertImage() {
            insertBlogImageData.insertImage(vm.files[0].urls.download);
            $uibModalInstance.close();
        }

        function closeModal() {
            $uibModalInstance.dismiss("cancel");
        }
    }
})();
