(function() {
    "use strict";

    angular
        .module("ssmAngularApp.inviteCardBrief")
        .directive("inviteCardBrief", inviteCardBrief);

    function inviteCardBrief() {
        return {
            templateUrl: "components/inviteCardBrief/inviteCardBrief.html",
            restrict: "E",
            replace: true,
            scope: {
                subject: "=",
                border: "@"
            },
            controller: inviteCardBriefCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function inviteCardBriefCtrl() {
        /*jshint validthis:true */
        var vm = this;
    }
})();
