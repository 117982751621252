(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmCountryProvinceSelect", [
            "pascalprecht.translate"
        ])
        .directive("ssmCountryProvinceSelect", ssmCountryProvinceSelect)
        .controller(
            "ssmCountryProvinceSelectController",
            ssmCountryProvinceSelectController
        );

    function ssmCountryProvinceSelect() {
        return {
            templateUrl:
                "components/ssmCountryProvinceSelect/ssmCountryProvinceSelect.html",
            restrict: "E",
            replace: true,
            scope: {
                formData: "=",
                modelData: "=",
                maxLength: "@"
            },
            controller: ssmCountryProvinceSelectController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmCountryProvinceSelectController($q, $translate) {
        /*jshint validthis:true */
        var vm = this;

        vm.states = {
            suggestions: [],
            searchCallback: statesSearchCallback,
            resultsCallback: statesResultsCallback
        };

        vm.countries = {
            suggestions: [],
            searchCallback: countriesSearchCallback,
            resultsCallback: countriesResultsCallback
        };

        activate();

        function activate() {
            $translate("ORGANIZATION.EDIT.STATE_PLACEHOLDER").then(function(
                translation
            ) {
                vm.statesInputPlaceholder = translation;
            });

            $translate("ORGANIZATION.EDIT.COUNTRY_PLACEHOLDER").then(function(
                translation
            ) {
                vm.countriesInputPlaceholder = translation;
            });
        }

        function statesSearchCallback() {
            var lowerCaseCountry = vm.modelData.country
                ? vm.modelData.country.toLowerCase()
                : "none";
            var stateArray = vm.stateList[lowerCaseCountry] || [];
            return defaultSearchCallback(vm.modelData.state, stateArray);
        }

        function countriesSearchCallback() {
            return defaultSearchCallback(vm.modelData.country, vm.countryList);
        }

        function defaultSearchCallback(inputText, searchArray) {
            if (!inputText) {
                return $q.when([]);
            }

            var lowerCaseText = inputText.toLowerCase();
            var results = _.filter(searchArray, function(item) {
                return (
                    item &&
                    item.name &&
                    item.name.toLowerCase().indexOf(lowerCaseText) >= 0
                );
            });

            return $q.when(results);
        }

        function statesResultsCallback(search) {
            vm.states.suggestions = defaultResultsCallback(
                search,
                vm.modelData.state
            );
        }

        function countriesResultsCallback(search) {
            vm.countries.suggestions = defaultResultsCallback(
                search,
                vm.modelData.country
            );
        }

        function defaultResultsCallback(search, inputText) {
            if (!search.length || !inputText) {
                return [];
            }

            var nameArray = _.uniqBy(_.compact(_.map(search, "name"))).sort(
                function(a, b) {
                    /*jshint maxcomplexity:14 */
                    if (!a || typeof a !== "string") {
                        return -1;
                    }

                    if (!b || typeof b !== "string") {
                        return 1;
                    }

                    var startsWithA = a
                        .toLowerCase()
                        .startsWith(inputText.toLowerCase());
                    var startsWithB = b
                        .toLowerCase()
                        .startsWith(inputText.toLowerCase());
                    if (startsWithA && startsWithB) {
                        return a.toLowerCase() <= b.toLowerCase() ? -1 : 1;
                    }

                    if (startsWithA) {
                        return -1;
                    }

                    if (startsWithB) {
                        return 1;
                    }

                    return a.toLowerCase() <= b.toLowerCase() ? -1 : 1;
                }
            );

            return _.map(nameArray, function(item) {
                return { id: item };
            });
        }

        // Country and state data
        vm.countryList = [
            {
                name: "Afghanistan"
            },
            {
                name: "Aland Islands"
            },
            {
                name: "Albania"
            },
            {
                name: "Algeria"
            },
            {
                name: "American Samoa"
            },
            {
                name: "Andorra"
            },
            {
                name: "Angola"
            },
            {
                name: "Anguilla"
            },
            {
                name: "Antarctica"
            },
            {
                name: "Antigua And Barbuda"
            },
            {
                name: "Argentina"
            },
            {
                name: "Armenia"
            },
            {
                name: "Aruba"
            },
            {
                name: "Australia"
            },
            {
                name: "Austria"
            },
            {
                name: "Azerbaijan"
            },
            {
                name: "Bahamas"
            },
            {
                name: "Bahrain"
            },
            {
                name: "Bangladesh"
            },
            {
                name: "Barbados"
            },
            {
                name: "Belarus"
            },
            {
                name: "Belgium"
            },
            {
                name: "Belize"
            },
            {
                name: "Benin"
            },
            {
                name: "Bermuda"
            },
            {
                name: "Bhutan"
            },
            {
                name: "Bolivia, Plurinational State of"
            },
            {
                name: "Bonaire, Sint Eustatius and Saba"
            },
            {
                name: "Bosnia and Herzegovina"
            },
            {
                name: "Botswana"
            },
            {
                name: "Bouvet Island"
            },
            {
                name: "Brazil"
            },
            {
                name: "British Indian Ocean Territory"
            },
            {
                name: "Brunei Darussalam"
            },
            {
                name: "Bulgaria"
            },
            {
                name: "Burkina Faso"
            },
            {
                name: "Burundi"
            },
            {
                name: "Cambodia"
            },
            {
                name: "Cameroon"
            },
            {
                name: "Canada"
            },
            {
                name: "Cape Verde"
            },
            {
                name: "Cayman Islands"
            },
            {
                name: "Central African Republic"
            },
            {
                name: "Chad"
            },
            {
                name: "Chile"
            },
            {
                name: "China"
            },
            {
                name: "Christmas Island"
            },
            {
                name: "Cocos (Keeling) Islands"
            },
            {
                name: "Colombia"
            },
            {
                name: "Comoros"
            },
            {
                name: "Congo"
            },
            {
                name: "Cook Islands"
            },
            {
                name: "Costa Rica"
            },
            {
                name: "Cote d'Ivoire"
            },
            {
                name: "Croatia"
            },
            {
                name: "Cuba"
            },
            {
                name: "Cyprus"
            },
            {
                name: "Czech Republic"
            },
            {
                name: "Denmark"
            },
            {
                name: "Djibouti"
            },
            {
                name: "Dominica"
            },
            {
                name: "Dominican Republic"
            },
            {
                name: "Ecuador"
            },
            {
                name: "Egypt"
            },
            {
                name: "El Salvador"
            },
            {
                name: "Equatorial Guinea"
            },
            {
                name: "Eritrea"
            },
            {
                name: "Estonia"
            },
            {
                name: "Ethiopia"
            },
            {
                name: "Falkland Islands (Malvinas)"
            },
            {
                name: "Faroe Islands"
            },
            {
                name: "Fiji"
            },
            {
                name: "Finland"
            },
            {
                name: "France"
            },
            {
                name: "French Guiana"
            },
            {
                name: "French Polynesia"
            },
            {
                name: "French Southern Territories"
            },
            {
                name: "Gabon"
            },
            {
                name: "Gambia"
            },
            {
                name: "Georgia"
            },
            {
                name: "Germany"
            },
            {
                name: "Ghana"
            },
            {
                name: "Gibraltar"
            },
            {
                name: "Greece"
            },
            {
                name: "Greenland"
            },
            {
                name: "Grenada"
            },
            {
                name: "Guadeloupe"
            },
            {
                name: "Guam"
            },
            {
                name: "Guatemala"
            },
            {
                name: "Guernsey"
            },
            {
                name: "Guinea"
            },
            {
                name: "Guinea-Bissau"
            },
            {
                name: "Guyana"
            },
            {
                name: "Haiti"
            },
            {
                name: "Heard Island and McDonald Islands"
            },
            {
                name: "Holy See (Vatican City State)"
            },
            {
                name: "Honduras"
            },
            {
                name: "Hong Kong"
            },
            {
                name: "Hungary"
            },
            {
                name: "Iceland"
            },
            {
                name: "India"
            },
            {
                name: "Indonesia"
            },
            {
                name: "Iran"
            },
            {
                name: "Iraq"
            },
            {
                name: "Ireland"
            },
            {
                name: "Isle of Man"
            },
            {
                name: "Israel"
            },
            {
                name: "Italy"
            },
            {
                name: "Jamaica"
            },
            {
                name: "Japan"
            },
            {
                name: "Jersey"
            },
            {
                name: "Jordan"
            },
            {
                name: "Kazakhstan"
            },
            {
                name: "Kenya"
            },
            {
                name: "Kiribati"
            },
            {
                name: "Korea, Democratic People's Republic of"
            },
            {
                name: "Korea, Republic of"
            },
            {
                name: "Kuwait"
            },
            {
                name: "Kyrgyzstan"
            },
            {
                name: "Lao People's Democratic Republic"
            },
            {
                name: "Latvia"
            },
            {
                name: "Lebanon"
            },
            {
                name: "Lesotho"
            },
            {
                name: "Liberia"
            },
            {
                name: "Libya"
            },
            {
                name: "Liechtenstein"
            },
            {
                name: "Lithuania"
            },
            {
                name: "Luxembourg"
            },
            {
                name: "Macao"
            },
            {
                name: "Macedonia"
            },
            {
                name: "Madagascar"
            },
            {
                name: "Malawi"
            },
            {
                name: "Malaysia"
            },
            {
                name: "Maldives"
            },
            {
                name: "Mali"
            },
            {
                name: "Malta"
            },
            {
                name: "Marshall Islands"
            },
            {
                name: "Martinique"
            },
            {
                name: "Mauritania"
            },
            {
                name: "Mauritius"
            },
            {
                name: "Mayotte"
            },
            {
                name: "Mexico"
            },
            {
                name: "Micronesia, Federated States of"
            },
            {
                name: "Moldova, Republic of"
            },
            {
                name: "Monaco"
            },
            {
                name: "Mongolia"
            },
            {
                name: "Montenegro"
            },
            {
                name: "Montserrat"
            },
            {
                name: "Morocco"
            },
            {
                name: "Mozambique"
            },
            {
                name: "Myanmar"
            },
            {
                name: "Namibia"
            },
            {
                name: "Nauru"
            },
            {
                name: "Nepal"
            },
            {
                name: "Netherlands"
            },
            {
                name: "New Caledonia"
            },
            {
                name: "New Zealand"
            },
            {
                name: "Nicaragua"
            },
            {
                name: "Niger"
            },
            {
                name: "Nigeria"
            },
            {
                name: "Niue"
            },
            {
                name: "Norfolk Island"
            },
            {
                name: "Northern Mariana Islands"
            },
            {
                name: "Norway"
            },
            {
                name: "Oman"
            },
            {
                name: "Pakistan"
            },
            {
                name: "Palau"
            },
            {
                name: "Palestinian Territory"
            },
            {
                name: "Panama"
            },
            {
                name: "Papua New Guinea"
            },
            {
                name: "Paraguay"
            },
            {
                name: "Peru"
            },
            {
                name: "Philippines"
            },
            {
                name: "Pitcairn"
            },
            {
                name: "Poland"
            },
            {
                name: "Portugal"
            },
            {
                name: "Puerto Rico"
            },
            {
                name: "Qatar"
            },
            {
                name: "Reunion"
            },
            {
                name: "Romania"
            },
            {
                name: "Russian Federation"
            },
            {
                name: "Rwanda"
            },
            {
                name: "Saint Barthelemy"
            },
            {
                name: "Saint Helena, Ascension and Tristan da Cunha"
            },
            {
                name: "Saint Kitts and Nevis"
            },
            {
                name: "Saint Lucia"
            },
            {
                name: "Saint Martin"
            },
            {
                name: "Saint Pierre and Miquelon"
            },
            {
                name: "Saint Vincent and the Grenadines"
            },
            {
                name: "Samoa"
            },
            {
                name: "San Marino"
            },
            {
                name: "Sao Tome and Principe"
            },
            {
                name: "Saudi Arabia"
            },
            {
                name: "Senegal"
            },
            {
                name: "Serbia"
            },
            {
                name: "Seychelles"
            },
            {
                name: "Sierra Leone"
            },
            {
                name: "Singapore"
            },
            {
                name: "Sint Maarten"
            },
            {
                name: "Slovakia"
            },
            {
                name: "Slovenia"
            },
            {
                name: "Solomon Islands"
            },
            {
                name: "Somalia"
            },
            {
                name: "South Africa"
            },
            {
                name: "South Georgia and the South Sandwich Islands"
            },
            {
                name: "South Sudan"
            },
            {
                name: "Spain"
            },
            {
                name: "Sri Lanka"
            },
            {
                name: "Sudan"
            },
            {
                name: "Suriname"
            },
            {
                name: "Svalbard and Jan Mayen"
            },
            {
                name: "Swaziland"
            },
            {
                name: "Sweden"
            },
            {
                name: "Switzerland"
            },
            {
                name: "Syrian Arab Republic"
            },
            {
                name: "Taiwan"
            },
            {
                name: "Tajikistan"
            },
            {
                name: "Tanzania, United Republic of"
            },
            {
                name: "Thailand"
            },
            {
                name: "Timor-Leste"
            },
            {
                name: "Togo"
            },
            {
                name: "Tokelau"
            },
            {
                name: "Tonga"
            },
            {
                name: "Trinidad and Tobago"
            },
            {
                name: "Tunisia"
            },
            {
                name: "Turkey"
            },
            {
                name: "Turkmenistan"
            },
            {
                name: "Turks and Caicos Islands"
            },
            {
                name: "Tuvalu"
            },
            {
                name: "Uganda"
            },
            {
                name: "Ukraine"
            },
            {
                name: "United Arab Emirates"
            },
            {
                name: "United Kingdom"
            },
            {
                name: "United States"
            },
            {
                name: "United States Minor Outlying Islands"
            },
            {
                name: "Uruguay"
            },
            {
                name: "Uzbekistan"
            },
            {
                name: "Vanuatu"
            },
            {
                name: "Venezuela"
            },
            {
                name: "Viet Nam"
            },
            {
                name: "Virgin Islands, British"
            },
            {
                name: "Virgin Islands, U.S."
            },
            {
                name: "Wallis and Futuna"
            },
            {
                name: "Western Sahara"
            },
            {
                name: "Yemen"
            },
            {
                name: "Zambia"
            },
            {
                name: "Zimbabwe"
            }
        ];

        vm.stateList = {
            canada: [
                {
                    name: "Alberta",
                    abbreviation: "AB"
                },
                {
                    name: "British Columbia",
                    abbreviation: "BC"
                },
                {
                    name: "Manitoba",
                    abbreviation: "MB"
                },
                {
                    name: "New Brunswick",
                    abbreviation: "NB"
                },
                {
                    name: "Newfoundland or Labrador",
                    abbreviation: "NL"
                },
                {
                    name: "Nova Scotia",
                    abbreviation: "NS"
                },
                {
                    name: "Ontario",
                    abbreviation: "ON"
                },
                {
                    name: "Prince Edward Island",
                    abbreviation: "PE"
                },
                {
                    name: "Quebec",
                    abbreviation: "QC"
                },
                {
                    name: "Saskatchewan",
                    abbreviation: "SK"
                },
                {
                    name: "Northwest Territories",
                    abbreviation: "NT"
                },
                {
                    name: "Nunavut",
                    abbreviation: "NU"
                },
                {
                    name: "Yukon",
                    abbreviation: "YT"
                }
            ],
            "united states": [
                {
                    name: "Alabama",
                    abbreviation: "AL"
                },
                {
                    name: "Alaska",
                    abbreviation: "AK"
                },
                {
                    name: "Arizona",
                    abbreviation: "AZ"
                },
                {
                    name: "Arkansas",
                    abbreviation: "AR"
                },
                {
                    name: "California",
                    abbreviation: "CA"
                },
                {
                    name: "Colorado",
                    abbreviation: "CO"
                },
                {
                    name: "Connecticut",
                    abbreviation: "CT"
                },
                {
                    name: "Delaware",
                    abbreviation: "DE"
                },
                {
                    name: "District Of Columbia",
                    abbreviation: "DC"
                },
                {
                    name: "Florida",
                    abbreviation: "FL"
                },
                {
                    name: "Georgia",
                    abbreviation: "GA"
                },
                {
                    name: "Hawaii",
                    abbreviation: "HI"
                },
                {
                    name: "Idaho",
                    abbreviation: "ID"
                },
                {
                    name: "Illinois",
                    abbreviation: "IL"
                },
                {
                    name: "Indiana",
                    abbreviation: "IN"
                },
                {
                    name: "Iowa",
                    abbreviation: "IA"
                },
                {
                    name: "Kansas",
                    abbreviation: "KS"
                },
                {
                    name: "Kentucky",
                    abbreviation: "KY"
                },
                {
                    name: "Louisiana",
                    abbreviation: "LA"
                },
                {
                    name: "Maine",
                    abbreviation: "ME"
                },
                {
                    name: "Maryland",
                    abbreviation: "MD"
                },
                {
                    name: "Massachusetts",
                    abbreviation: "MA"
                },
                {
                    name: "Michigan",
                    abbreviation: "MI"
                },
                {
                    name: "Minnesota",
                    abbreviation: "MN"
                },
                {
                    name: "Mississippi",
                    abbreviation: "MS"
                },
                {
                    name: "Missouri",
                    abbreviation: "MO"
                },
                {
                    name: "Montana",
                    abbreviation: "MT"
                },
                {
                    name: "Nebraska",
                    abbreviation: "NE"
                },
                {
                    name: "Nevada",
                    abbreviation: "NV"
                },
                {
                    name: "New Hampshire",
                    abbreviation: "NH"
                },
                {
                    name: "New Jersey",
                    abbreviation: "NJ"
                },
                {
                    name: "New Mexico",
                    abbreviation: "NM"
                },
                {
                    name: "New York",
                    abbreviation: "NY"
                },
                {
                    name: "North Carolina",
                    abbreviation: "NC"
                },
                {
                    name: "North Dakota",
                    abbreviation: "ND"
                },
                {
                    name: "Ohio",
                    abbreviation: "OH"
                },
                {
                    name: "Oklahoma",
                    abbreviation: "OK"
                },
                {
                    name: "Oregon",
                    abbreviation: "OR"
                },
                {
                    name: "Pennsylvania",
                    abbreviation: "PA"
                },
                {
                    name: "Rhode Island",
                    abbreviation: "RI"
                },
                {
                    name: "South Carolina",
                    abbreviation: "SC"
                },
                {
                    name: "South Dakota",
                    abbreviation: "SD"
                },
                {
                    name: "Tennessee",
                    abbreviation: "TN"
                },
                {
                    name: "Texas",
                    abbreviation: "TX"
                },
                {
                    name: "Utah",
                    abbreviation: "UT"
                },
                {
                    name: "Vermont",
                    abbreviation: "VT"
                },
                {
                    name: "Virginia",
                    abbreviation: "VA"
                },
                {
                    name: "Washington",
                    abbreviation: "WA"
                },
                {
                    name: "West Virginia",
                    abbreviation: "WV"
                },
                {
                    name: "Wisconsin",
                    abbreviation: "WI"
                },
                {
                    name: "Wyoming",
                    abbreviation: "WY"
                }
            ],
            none: []
        };
    }
})();
