(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.viewLikesModal")
        .controller("viewLikesCtrl", viewLikesCtrl);

    function viewLikesCtrl($scope, likesResolve, $uibModalInstance) {
        /*jshint validthis: true */
        var vm = this;

        vm.likes = likesResolve;

        vm.closeModal = function() {
            $uibModalInstance.dismiss("cancel");
        };

        $scope.$on("$locationChangeStart", function() {
            $uibModalInstance.close();
        });
    }
})();
