(function() {
    "use strict";

    angular
        .module("ssmAngularApp.outOfNetworkBadge")
        .directive("outOfNetworkBadge", outOfNetworkBadge);

    function outOfNetworkBadge() {
        return {
            templateUrl: "components/outOfNetworkBadge/outOfNetworkBadge.html",
            restrict: "EA",
            replace: true,
            scope: {
                subject: "="
            },
            controller: outOfNetworkBadgeCtrl,
            controllerAs: "badge",
            bindToController: true
        };
    }

    function outOfNetworkBadgeCtrl(CurrentUser, Tenant) {
        /*jshint validthis:true */
        var vm = this;
        vm.isOutOfNetwork = false;
        var nwcId = Tenant.getOrganizationIdBySlug("nwc");

        var user = CurrentUser.profile;
        var userIsInNonNwcOrg =
            user &&
            user.organization_ids &&
            user.organization_ids.length &&
            _.some(user.organization_ids, function(orgId) {
                return orgId !== nwcId;
            });

        if (userIsInNonNwcOrg && vm.subject.location) {
            vm.isOutOfNetwork =
                !vm.subject.location.organization_ids ||
                !vm.subject.location.organization_ids.length ||
                (vm.subject.location.organization_ids.length === 1 &&
                    vm.subject.location.organization_ids[0] === nwcId);
        }
    }
})();
