(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmAutocompleteInput", [])
        .directive("ssmAutocompleteInput", ssmAutocompleteInput)
        .controller("ssmAutocompleteInputCtrl", ssmAutocompleteInputCtrl);

    function ssmAutocompleteInput() {
        return {
            templateUrl:
                "components/ssmAutocompleteInput/ssmAutocompleteInput.html",
            restrict: "E",
            replace: true,
            scope: {
                identifier: "@",
                inputName: "@",
                inputText: "=ngModel",
                inputPlaceholder: "=",
                maxLength: "=",
                suggestions: "=",
                searchCallback: "=",
                resultsCallback: "=",
                catchCallback: "=",
                onType: "=",
                onSelect: "=",
                onBlur: "=",
                onToggle: "=",
                createButtonOptions: "=",
                focusTrigger: "=",
                deactivate: "=",
                showDetails: "@",
                setTextOnSelect: "@",
                isRequired: "@",
                isDisabled: "&",
                onChangeInput: "="
            },
            controller: ssmAutocompleteInputCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmAutocompleteInputCtrl(
        $scope,
        $timeout,
        $translate,
        SSMUtilities
    ) {
        /*jshint validthis:true */
        var vm = this;
        vm.showAutocomplete = false;
        vm.showCreateButton = false;
        vm.bestSuggestion = null;

        vm.getSuggestionText = getSuggestionText;
        vm.dropDownToggled = dropDownToggled;
        vm.onKeyDownInput = onKeyDownHandler;
        vm.onBlurInput = onBlurHandler;
        vm.onSelectSuggestion = onSelectHandler;
        vm.onMouseEnterSuggestion = onMouseEnterHandler;
        vm.onMouseLeaveSuggestion = onMouseLeaveHandler;
        vm.createButtonClicked = createButtonClicked;

        // Expose the change handler to the parent
        // Required: Add this argument to the Pug call to be able to trigger the text input change handler
        // ==> on-change-input="vm.onChangeInput"
        // ==> Then calling vm.onChangeInput() from parent will trigger the change handler
        if (!vm.onChangeInput) {
            vm.onChangeInput = onChangeHandler;
        }

        var SHOW_CREATE_BUTTON_DELAY = 1000;
        var AUTOCOMPLETE_DELAY = 300;
        var ENTER_KEY = 13;

        var createButtonPromise;

        var resultsMethod = vm.resultsCallback || defaultResults;
        var catchMethod = vm.catchCallback || defaultCatch;

        activate();

        function activate() {
            if (vm.createButtonOptions) {
                vm.shouldShowCreateButton =
                    vm.createButtonOptions.shouldShowCreateButton;
                vm.emitOnCreateButtonToggle =
                    vm.createButtonOptions.emitOnCreateButtonToggle;
                vm.createButtonOnClick =
                    vm.createButtonOptions.createButtonOnClick;

                if (vm.createButtonOptions.createButtonLabel) {
                    $translate(vm.createButtonOptions.createButtonLabel).then(
                        function(translation) {
                            vm.createButtonLabel = translation;
                        }
                    );
                }
            }

            $scope.$watch(
                function() {
                    return vm.suggestions;
                },
                function(newValue, oldValue) {
                    if (oldValue === newValue) {
                        return;
                    }

                    if (!newValue || !newValue.length) {
                        vm.bestSuggestion = null;
                        vm.showAutocomplete = false;
                        return;
                    }

                    var hintText = getSuggestionText(newValue[0]);
                    if (
                        vm.inputText &&
                        hintText
                            .toLowerCase()
                            .startsWith(vm.inputText.toLowerCase())
                    ) {
                        if (
                            vm.inputText.toLowerCase() ===
                            hintText.toLowerCase()
                        ) {
                            vm.showCreateButton = false;
                        }
                        vm.bestSuggestion = newValue[0];

                        // Because casing could be different, need to surgically splice strings together
                        var splicedText = vm.inputText.concat(
                            hintText.substring(vm.inputText.length)
                        );
                        vm.inputHint = splicedText;
                    } else {
                        vm.bestSuggestion = null;
                        vm.inputHint = vm.inputText;

                        $timeout.cancel(createButtonPromise);
                        createButtonPromise = $timeout(function() {
                            vm.showCreateButton = showCreateButtonHelper();
                        }, SHOW_CREATE_BUTTON_DELAY);
                    }

                    vm.showAutocomplete = !vm.deactivate;

                    if (vm.onType) {
                        vm.onType(newValue, vm.bestSuggestion);
                    }
                }
            );

            $scope.$on(
                SSMUtilities.SSM_AUTOCOMPLETE_PROGRAMMATIC_INPUT_CHANGE_EVENT,
                function(event, identifier, onlyClearHint) {
                    if (vm.identifier && vm.identifier === identifier) {
                        $timeout(function() {
                            if (onlyClearHint) {
                                vm.inputHint = "";
                                vm.showCreateButton = false;
                            } else {
                                vm.onChangeInput();
                            }
                        });
                    }
                }
            );

            if (vm.emitOnCreateButtonToggle) {
                $scope.$watch(
                    function() {
                        return vm.showCreateButton;
                    },
                    function(newValue, oldValue) {
                        if (oldValue === newValue) {
                            return;
                        }

                        $scope.$emit(
                            SSMUtilities.SSM_AUTOCOMPLETE_CREATE_BUTTON_CHANGED_EVENT,
                            newValue
                        );
                    }
                );
            }
        }

        function getSuggestionText(item) {
            if (!item) {
                return null;
            }

            if (typeof item === "string") {
                return item;
            }

            if (item.user) {
                return item.user.full_name;
            }

            if (item.organization) {
                return item.organization.name;
            }

            if (item.id) {
                return item.id;
            }
        }

        function dropDownToggled(open) {
            if (vm.onToggled) {
                vm.onToggled(open);
            }
        }

        function onChangeHandler() {
            vm.inputHint = vm.inputText;

            if (!vm.inputText) {
                vm.bestSuggestion = null;
                vm.showCreateButton = false;
                vm.showAutocomplete = false;
                return;
            }

            $timeout.cancel(createButtonPromise);
            createButtonPromise = $timeout(function() {
                vm.showCreateButton = showCreateButtonHelper();
            }, SHOW_CREATE_BUTTON_DELAY);

            if (vm.searchCallback) {
                $timeout(function() {
                    return vm
                        .searchCallback(vm.inputText)
                        .then(resultsMethod)
                        .catch(catchMethod);
                }, AUTOCOMPLETE_DELAY);
            }
        }

        function onKeyDownHandler(event) {
            if (event.keyCode !== ENTER_KEY) {
                return;
            }
            onSelectHandler(vm.bestSuggestion, event);
        }

        function onSelectHandler(suggestion, event) {
            vm.showAutocomplete = false;

            if (suggestion) {
                vm.inputHint = "";
                vm.inputText = vm.setTextOnSelect
                    ? getSuggestionText(suggestion)
                    : "";
                vm.showCreateButton = false;
            }

            if (vm.onSelect) {
                vm.onSelect(suggestion, event.target);
            }
            if (!suggestion) {
                vm.inputHint = vm.inputText;
            }
        }

        function onBlurHandler(event) {
            if (vm.onBlur && !vm.showAutocomplete) {
                vm.onBlur(vm.bestSuggestion, event.target);
            }
        }

        function onMouseEnterHandler(event, index) {}

        function onMouseLeaveHandler(event, index) {}

        function defaultResults(search) {
            if (search.search_id && search.search_id !== vm.inputText) {
                return;
            }

            if (!search.results || !search.results.length || !vm.inputText) {
                vm.suggestions = [];
                return;
            }

            vm.suggestions = _.uniqBy(
                _.compact(
                    _.map(search.results, function(item) {
                        var itemText = getSuggestionText(item);
                        if (!itemText) {
                            return null;
                        }

                        if (item.user) {
                            return { user: item.user, id: item.user.id };
                        }

                        if (item.organization) {
                            return {
                                organization: item.organization,
                                id: item.organization.id
                            };
                        }

                        return null;
                    })
                ),
                function(item) {
                    // ensure the suggestions are unique with respect to their IDs
                    return item.id;
                }
            ).sort(function(a, b) {
                /*jshint maxcomplexity:14 */
                var nameA;
                var nameB;

                if (a.user) {
                    nameA = a.user.full_name;
                } else if (a.organization) {
                    nameA = a.organization.name;
                }

                if (b.user) {
                    nameB = b.user.full_name;
                } else if (b.organization) {
                    nameB = b.organization.name;
                }

                if (!nameA || typeof nameA !== "string") {
                    return -1;
                }

                if (!nameB || typeof nameB !== "string") {
                    return 1;
                }

                var startsWithA = nameA
                    .toLowerCase()
                    .startsWith(vm.inputText.toLowerCase());
                var startsWithB = nameB
                    .toLowerCase()
                    .startsWith(vm.inputText.toLowerCase());
                if (startsWithA && startsWithB) {
                    return nameA.toLowerCase() <= nameB.toLowerCase() ? -1 : 1;
                }

                if (startsWithA) {
                    return -1;
                }

                if (startsWithB) {
                    return 1;
                }

                return nameA.toLowerCase() <= nameB.toLowerCase() ? -1 : 1;
            });
        }

        function defaultCatch(error) {
            vm.suggestions = [];
        }

        function showCreateButtonHelper() {
            if (!vm.shouldShowCreateButton) {
                return false;
            }

            if (!vm.inputText) {
                return false;
            }

            if (
                vm.bestSuggestion &&
                getSuggestionText(vm.bestSuggestion) === vm.inputText
            ) {
                return false;
            }

            // Call the parent shouldShowCreateButton
            return vm.shouldShowCreateButton();
        }

        function createButtonClicked(event) {
            if (vm.createButtonOnClick) {
                vm.createButtonOnClick(event);
            }
        }
    }
})();
