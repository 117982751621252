(function() {
    "use strict";

    angular
        .module("ssmAngularApp.communityInformationForm")
        .directive("communityInformationForm", communityInformationForm);

    function communityInformationForm() {
        return {
            templateUrl:
                "components/communityInformationForm/communityInformationForm.html",
            restrict: "E",
            scope: {
                informationData: "=",
                informationForm: "=",
                communityPhoto: "="
            },
            controller: communityInformationFormCtrl,
            controllerAs: "communityInformation",
            bindToController: true
        };
    }

    function communityInformationFormCtrl(ssmFiles, CurrentUser) {
        /*jshint validthis:true */
        var vm = this;

        vm.onPhotoUpload = onPhotoUpload;
        vm.clearCandidate = clearCandidate;
        vm.uploadedPhoto = [];
        vm.isCurrentUserAdmin = false;

        // NOTE: The values and variable names also exist in CommunityManager.ts on the server side.
        vm.maxAllowedName = 80;
        vm.maxAllowedDescription = 2500;
        vm.namePattern = new RegExp(/[a-zA-Z0-9\s]/);

        activate();

        function activate() {
            if (vm.communityPhoto.length > 0) {
                vm.uploadedPhoto = vm.communityPhoto;
            }
            vm.isCurrentUserAdmin = CurrentUser.profile.is_admin;
        }

        function onPhotoUpload() {
            vm.informationData.photo_id = vm.uploadedPhoto[0].id;
        }

        function clearCandidate() {
            if (vm.uploadedPhoto.length <= 0) {
                return;
            }

            vm.informationData.photo_id = "";
            vm.uploadedPhoto = [];
        }
    }
})();
