(function() {
    "use strict";

    angular.module("ssmAngularApp", [
        "ssmAngularApp.core",
        "ssmAngularApp.startup",
        "ssmAngularApp.templates",
        "ssmAngularApp.layout",
        "ssmAngularApp.components",
        "ssmAngularApp.services",
        "ssmAngularApp.directives",
        "ssmAngularApp.filters"
    ]);
})();
