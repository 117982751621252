(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("InviteListController", InviteListController);

    function InviteListController() {
        var vm = this;

        activate();

        function activate() {}
    }
})();
