(function() {
    "use strict";

    angular.module("ssmAngularApp.analytics", [
        "angulartics",
        "angulartics.google.analytics",
        "ssmAngularApp.ssmLocalStorage",
        "ssmAngularApp.currentUser"
    ]);
})();
