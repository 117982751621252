(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmInlineContentHelper")
        .factory("SSMInlineContentHelper", SSMInlineContentHelper);

    function SSMInlineContentHelper() {
        function hydrateMentionsForWall(text) {
            var regex = /@user\|(.*?)\|([0-9a-fA-F]{24})/g;

            var matches = [];
            var match;
            while ((match = regex.exec(text)) !== null) {
                matches.push({
                    replace: match[0],
                    id: match[2],
                    label: match[1]
                });
            }

            _.forEach(matches, function(mention) {
                var html =
                    '<a class="text-primary mention" href="/users/' +
                    mention.id +
                    '">@' +
                    mention.label +
                    "</a>";
                text = text.replace(mention.replace.trim(), html);
            });

            return text;
        }

        function hydrateHashTagsForWall(text, validInlineTags) {
            var regex = /(^|\W)(#[a-z\d][\w-]*)/gi;

            var matches = [];
            var match;
            while ((match = regex.exec(text)) !== null) {
                if (validInlineTags && _.includes(validInlineTags, match[2])) {
                    matches.push({
                        replace: match[0],
                        label: match[2],
                        search: match[2].substring(1)
                    });
                }
            }

            _.forEach(matches, function(hashtag) {
                var html =
                    '<a class="text-primary hashtag" href="/search/_all?q=%23' +
                    hashtag.search +
                    '">' +
                    hashtag.label +
                    "</a>";
                text = text.replace(
                    new RegExp(hashtag.replace.trim(), "g"),
                    html
                );
            });

            return text;
        }

        return {
            createMentionInlineContent: function(id, label) {
                return (
                    '<a class="text-primary mention" href="/users/' +
                    id +
                    '">@' +
                    label +
                    "</a>"
                );
            },
            createHashTagInlineContent: function(label) {
                // If we try to add styling here we get all sorts of ment.io versus ContentEditable versus browser issues. See #SSM-3386.
                return label ? label.trim() : label;
            },
            hydrateInlineContentForWall: function(text, validInlineTags) {
                var hydrated = hydrateMentionsForWall(text);
                return hydrateHashTagsForWall(hydrated, validInlineTags);
            },
            findFirstUrl: function(text) {
                var re = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:;,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:;,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:;,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
                var result = re.exec(text);
                return result ? result[0] : null;
            },
            hydrateMentionsForWall: hydrateMentionsForWall,
            textFormat: function(message) {
                var options;
                message = message
                    .replace(/\[b\]/gi, "<b>")
                    .replace(/\[\/b\]/gi, "</b>");
                message = message
                    .replace(/\[i\]/gi, "<i>")
                    .replace(/\[\/i\]/gi, "</i>");
                message = message
                    .replace(/\[u\]/gi, "<u>")
                    .replace(/\[\/u\]/gi, "</u>");
                message = message
                    .replace(/\[h3\]/gi, "<h3>")
                    .replace(/\[\/h3\]/gi, "</h3>");
                message = message
                    .replace(/\[li\]/gi, "<li>")
                    .replace(/\[\/li\]/gi, "</li>");

                return message;
            },

            sanitizeUserInput: function(input) {
                // Remove editing cruft and put the message in a span so we can treat it as HTML
                var message = input.replace(/&nbsp;/g, " ");
                // Preserve line breaks -- Chrome and Safari use <div><br></div> and <div></div>
                message = message.replace(/<div><br><\/div>/gi, "\n");
                message = message
                    .replace(/<div>/gi, "\n")
                    .replace(/<\/div>/gi, "");
                // Firefox uses <br></br>
                message = message
                    .replace(/<br>/gi, "\n")
                    .replace(/<\/br>/gi, "");
                // IE uses <p></p>
                message = message.replace(/<p>/gi, "").replace(/<\/p>/gi, "\n");

                message = message
                    .replace(/<b>/gi, "[b]")
                    .replace(/<\/b>/gi, "[/b]");
                message = message
                    .replace(/<i>/gi, "[i]")
                    .replace(/<\/i>/gi, "[/i]");
                message = message
                    .replace(/<u>/gi, "[u]")
                    .replace(/<\/u>/gi, "[/u]");
                message = message
                    .replace(/<h3>/gi, "[h3]")
                    .replace(/<\/h3>/gi, "[/h3]");
                message = message
                    .replace(/<li>/gi, "[li]")
                    .replace(/<\/li>/gi, "[/li]");

                message = "<span>" + message + "</span>";

                // Find any elements that are of class 'mention' (which we injected)
                var element = angular.element(message);
                var elementResult = element[0].getElementsByClassName(
                    "mention"
                );

                // For each element, determine what the outgoing (plaintext) tokenization of the mention should look like
                _.forEachRight(elementResult, function(result) {
                    var href = result.getAttribute("href");
                    var components = href.split("/");

                    // Wrap the jQuery item in an angular element
                    var wrappedResult = angular.element(result);

                    var label = wrappedResult.text();
                    label = label.replace("@", "");

                    // Note: Due to some quirkiness with the mentio lib, it's
                    // possible that the mention element is broken into two
                    // pieces. We coalesce them here, if needed. Long term,
                    // the library should be fixed or replaced.
                    var userId = _.last(components);
                    if (userId === "undefined") {
                        var sibling = result.previousElementSibling;
                        if (sibling) {
                            var siblingHref = sibling.getAttribute("href");
                            if (siblingHref) {
                                var siblingComponents = siblingHref.split("/");
                                userId = _.last(siblingComponents);
                            }
                        }
                    }

                    // If we still end up "undefined", flag it
                    if (userId === "undefined") {
                        userId = "";
                    }

                    if (_.isEmpty(label) || _.isEmpty(userId)) {
                        wrappedResult.text("");
                    } else {
                        wrappedResult.text(
                            "@" + "user" + "|" + label + "|" + userId
                        );
                    }
                });

                return element.text();
            },
            escapeHtml: function(str) {
                var div = document.createElement("div");
                div.appendChild(document.createTextNode(str));
                return div.innerHTML;
            }
        };
    }
})();
