(function() {
    "use strict";

    angular
        .module("ssmAngularApp.marketplaceContact")
        .controller(
            "marketplaceContactController",
            marketplaceContactController
        );

    function marketplaceContactController(
        Analytics,
        $translate,
        $uibModalInstance,
        AlertService,
        CurrentUser,
        SSM_MARKETPLACE,
        listing,
        partner,
        ssmMarketplace
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.currentUser = CurrentUser.profile;
        vm.partner = partner;
        vm.listing = listing;
        vm.PHONE_LINE_TYPES = SSM_MARKETPLACE.PHONE_LINE_TYPES;
        vm.closeModal = closeModal;
        vm.submit = submit;
        vm.translations = [];
        vm.getTranslation = getTranslation;

        var DEFAULT_CONV0_TEXT = "MARKETPLACE_CONTACT.CONVO_TYPES.GENERAL";
        var MSG_SENT = "MARKETPLACE_CONTACT.ALERTS.MSG_SENT";

        activate();

        function activate() {
            var HEADER_TEXT = "MARKETPLACE_CONTACT.HEADER_TITLE";
            var _translationStrings = [
                HEADER_TEXT,
                DEFAULT_CONV0_TEXT,
                MSG_SENT
            ];
            vm.partner.contacts = vm.partner.contacts || [];
            vm.listing.phone_numbers = vm.listing.phone_numbers || [];
            _.forEach(vm.partner.contacts, function(contact) {
                if (
                    !contact.custom_conversation ||
                    contact.custom_conversation == false
                ) {
                    _translationStrings.push(
                        makeTranslationKey(contact.conversation_type)
                    );
                } else {
                    _translationStrings.push(contact.conversation_type);
                }
            });

            $translate(_translationStrings).then(function(translations) {
                vm.titleText = translations[HEADER_TEXT];
                vm.translations = translations;
            });

            vm.conversation_type = "general";
            if (vm.partner.contacts.length > 0) {
                vm.conversation_type =
                    vm.partner.contacts[0].conversation_type ||
                    vm.conversation_type;
            }
        }

        function makeTranslationKey(key) {
            return "MARKETPLACE_CONTACT.CONVO_TYPES." + _.toUpper(key);
        }

        function getTranslation(key) {
            var translated = vm.translations[makeTranslationKey(key)];
            if (translated) {
                return translated;
            } else {
                return key;
            }
        }

        function closeModal() {
            $uibModalInstance.close();
        }

        function submit(form, event) {
            if (form.$valid) {
                vm.stopSubmit = true;
                var message = {
                    marketplace_campaign_id: listing.id,
                    message: vm.message,
                    conversation_type: vm.conversation_type || "default"
                };

                Analytics.eventTrack("MarketplaceContact", {
                    category: "MarketplaceCtrl",
                    label: "Listing ID: " + vm.message.marketplace_campaign_id
                });
                Analytics.eventTrack2('marketplace_contact', {
                    category: "MarketplaceCtrl",
                    label: "Listing ID: " + vm.message.marketplace_campaign_id
                });

                ssmMarketplace
                    .createMarketplaceMessage(message)
                    .then(function() {
                        AlertService.add({
                            msg: vm.translations[MSG_SENT],
                            type: AlertService.TYPES.SUCCESS
                        });
                        vm.closeModal();
                    })
                    .finally(function() {
                        vm.stopSubmit = false;
                    });
            }
        }
    }
})();
