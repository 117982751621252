(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmFileModel")
        .directive("ssmFileModel", ssmFileModel);

    function ssmFileModel(SSMUtilities) {
        return {
            restrict: "A",
            scope: {
                ssmFileModel: "="
            },
            link: function(scope, element) {
                if (window.isIE9) {
                    window.FileAPI.event.on(element[0], "change", function(
                        evt
                    ) {
                        var file = window.FileAPI.getFiles(evt);
                        updateFileModel(file[0]);
                    });
                } else {
                    element.on("change", function(evt) {
                        var fileList = evt.target && evt.target.files;
                        updateFileModel(fileList[0]);
                    });
                }

                function updateFileModel(file) {
                    SSMUtilities.fileToBase64(file).then(function(base64) {
                        scope.ssmFileModel = {
                            size: file.size,
                            file_type: file.type,
                            name: file.name,
                            base64: base64
                        };
                    });
                }
            }
        };
    }
})();
