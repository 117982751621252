(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmNotFound")
        .directive("ssmNotFound", ssmNotFound)
        .controller("ssmNotFoundController", ssmNotFoundController);

    function ssmNotFound() {
        return {
            templateUrl: "components/ssmNotFound/ssmNotFound.html",
            restrict: "E",
            replace: true,
            scope: {
                heading: "@?",
                description: "@?",
                destinationLabel: "@?",
                destinationState: "@?"
            },
            controller: ssmNotFoundController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmNotFoundController(
        Tenant,
        Cookie
        ) {
        /*jshint validthis: true */
        var vm = this;

        vm.followLink = followLink;

        vm.isAppa;
        vm.tenant;
        vm.redirectUrl;

        activate();

        _.defaults(vm, {
            heading: "404.TITLE",
            description: "404.DESCRIPTION",
            destinationLabel: "404.HOME_BUTTON",
            destinationState: "main.home"
        });

        function activate() {
            getTenant();
        }

        function getTenant() {
            Tenant.getTenant().then(tenant => {
                if (tenant.slug == "appavirtual") {
                    vm.isAppa = true;
                    vm.tenant = tenant;
                    createCookieToRememberToComeBackToThisPage();
                    appendHookToRedirectUrl();
                }
            });
        }

        function createCookieToRememberToComeBackToThisPage() {
            Cookie.create("lastPageVisited", window.location.href, 1);
        }

        function appendHookToRedirectUrl() {
            var staticSuccessRedirectUrl = vm.tenant.sso_button.button_url;
            vm.redirectUrl = staticSuccessRedirectUrl + "%26samePage%3dtrue";
        }

        function followLink() {
            window.open("https://www.appa-net.org/eweb/DynamicPage.aspx?webcode=Membership-Individuals&site=APPA-3");
        } 

    }


})();
