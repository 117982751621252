(function() {
    "use strict";

    angular.module("ssmAngularApp.ssmMarketplace.service").constant(
        "SSM_MARKETPLACE",
        (function() {
            var constant = {};

            constant.STATUS = {
                ACTIVE: "active",
                INACTIVE: "inactive"
            };

            constant.TEMPLATES = {
                INLINE: "inline",
                FULLPAGE: "page"
            };

            constant.NEW_LISTING = {
                id: undefined,
                status: "active",
                marketplace_id: undefined,
                marketplace_partner_id: undefined,
                marketplace_level_id: "basic",
                name: undefined,
                short_description: undefined,
                description: undefined,
                addresses: [],
                phone_numbers: [],
                emails: [],
                category_ids: [],
                file_ids: [],
                website: undefined,
                contact: undefined,
                hash_tags: []
            };

            constant.NEW_VENDOR = {
                id: undefined,
                name: undefined,
                organization_id: undefined,
                short_description: undefined,
                description: undefined,
                addresses: [],
                phone_numbers: [],
                emails: [],
                categories: [],
                website: undefined,
                contacts: [],
                hash_tags: []
            };

            constant.PHONE_LINE_TYPES = {
                Phone: "phone",
                Fax: "fax"
            };

            return constant;
        })()
    );
})();
