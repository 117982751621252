(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("main.calendar", {
            url: "/calendar",
            templateUrl: "layout/main/calendar/calendar.html",
            controller: "CalendarCtrl",
            controllerAs: "calendar",
            data: {
                allowTenant: "calendar"
            },
            resolve: {
                eventsResolve: eventsResolve
            }
        });
    });

    function eventsResolve(Api, Tenant) {
        var eventsApi = Api.all("events");
        return Tenant.getTenant().then(function(site_org) {
            return eventsApi.getList({
                organization_id: site_org.organization_id
            });
        });
    }
})();
