(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").controller("ApaCtrl", ApaCtrl);

    function ApaCtrl(
        $translate,
        $state,
        ssmAuth,
        Logger,
        $location
    ) {
        var query = $location.search();

        var userToken = {
            ct: query["ct"]
        };

        ssmAuth
            .apaSsoLogin(userToken)
            .catch(onLoginFailed);

        function onLoginFailed(error) {
            $translate("LOGIN.ERROR.AVECTRA_LOGIN").then(function(translation) {
                Logger.error("ERROR: ", translation, error);
                $state.go("main.home", {}, { location: "replace" });
            });
        }
    }
})();
