(function() {
    "use strict";
    /*jshint maxparams: 12 */
    angular
        .module("ssmAngularApp.blogEditor")
        .directive("blogEditor", blogEditor);

    function blogEditor() {
        return {
            templateUrl: "components/blogEditor/blogEditor.html",
            restrict: "E",
            replace: true,
            scope: {
                blog: "="
            },
            controller: blogEditorCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    // This is a Quill extension
    function SSMTools(quill, options) {
        var vm = this;
        vm.quill = quill;
        vm.options = options;
        vm.range = null;

        vm.onImage = function() {
            vm.range = vm.quill.getSelection();
            vm.options.showImageModal(vm);
        };

        vm.onUndo = function() {
            vm.quill.undo();
        };

        vm.onRedo = function() {
            vm.quill.redo();
        };

        vm.onRemoveFormatting = function() {
            vm.quill.beginUndoTransaction();
            vm.quill.focus();
            var range = switchToPlainText();
            removeStylesAndTags();
            vm.quill.setSelection(range.start, range.end);
            vm.quill.endUndoTransaction();
        };

        function switchToPlainText() {
            var range = vm.quill.getSelection();
            var text = vm.quill.getText(range.start, range.end);
            vm.quill.deleteText(range.start, range.end);
            vm.quill.insertText(range.start, text);
            var newRange = {
                start: range.start,
                end: range.start + text.length
            };
            vm.quill.setSelection(newRange.start, newRange.end);

            return newRange;
        }

        function removeStylesAndTags() {
            var sel = rangy.getSelection();
            var range = sel.getRangeAt(0);
            var nodes = range.getNodes();
            nodes.forEach(function(node) {
                removeLineFormats(node);
            });
        }

        function removeLineFormats(node) {
            if (node.nodeType === Node.ELEMENT_NODE) {
                var classes = node.getAttribute("class") || "";

                if (classes.indexOf("ql-editor") >= 0) {
                    // Reached the editor container.  Stop looking.
                    return;
                }

                node.removeAttribute("style");

                // Line/paragraph type tags, we replace with a div.
                var tag = node.nodeName.toLowerCase();
                if (
                    tag === "h1" ||
                    tag === "h2" ||
                    tag === "h3" ||
                    tag === "blockquote"
                ) {
                    node = replaceWithDiv(node);
                }
            }

            // Look in the parent.
            return removeLineFormats(node.parentNode);
        }

        function replaceWithDiv(node) {
            var parent = node.parentNode;
            var document = node.ownerDocument;
            var newNode = document.createElement("div");
            parent.insertBefore(newNode, node);
            var len = node.childNodes.length;
            while (node.childNodes.length) {
                var child = node.childNodes.item(0);
                node.removeChild(child);
                newNode.appendChild(child);
            }
            parent.removeChild(node);

            return newNode;
        }

        vm.insertImage = function(url) {
            var index = vm.range ? vm.range.end : 0;
            vm.quill.insertEmbed(index, "image", url, "user");
            vm.quill.setSelection(index + 1, index + 1);
        };

        quill.onModuleLoad("toolbar", function(toolbar) {
            toolbar.initFormat("image", _.bind(vm.onImage, vm));
            toolbar.initFormat("undo", _.bind(vm.onUndo, vm));
            toolbar.initFormat("redo", _.bind(vm.onRedo, vm));
            toolbar.initFormat(
                "remove-formatting",
                _.bind(vm.onRemoveFormatting, vm)
            );
        });
    }

    function blogEditorCtrl(
        $uibModal,
        $rootScope,
        $scope,
        $timeout,
        $translate,
        Analytics,
        Blog,
        CurrentUser,
        Logger,
        ssmFileUpload,
        scroll
    ) {
        /*jshint validthis:true */
        var vm = this;
        var DELAY_TIME_HIDING_FLAG = 3000;
        var DELAY_WITH_SAVE = 1000;

        vm.dirty = false;
        vm.showSaved = false;
        vm.textColor = null;
        vm.highlightColor = null;
        // Used to only log edit analytics once per edit session.
        vm.loggedAnalytics = false;
        vm.saveWithDelay = saveWithDelay;
        vm.saveTitleWithDelay = saveTitleWithDelay;
        vm.undoNotAvailable = true;
        vm.redoNotAvailable = true;

        var editor;
        var savePromise;
        var hideSavedFlagPromise;
        var savedText;
        var saveFailedText;
        var resettingEditorContents;
        var defaultBlogTitle;

        activate();

        function activate() {
            scroll.bind();
            $scope.$on("scroll", function(event, data) {
                vm.toolbarFixed = data.y >= 210;
            });

            $scope.$watch(
                function() {
                    return vm.blog;
                },
                function(newValue, oldValue) {
                    if (oldValue) {
                        save(oldValue);
                    }
                    resetEditorContents(newValue);
                }
            );

            $scope.$watch(
                function() {
                    return vm.blog.status;
                },
                function(newValue, oldValue) {
                    if (oldValue && newValue && oldValue !== newValue) {
                        saveWithDelay(vm.blog);
                    }
                }
            );

            $scope.$watch(
                function() {
                    return vm.blog.hash_tags;
                },
                function(newValue, oldValue) {
                    if (oldValue && newValue && oldValue !== newValue) {
                        saveWithDelay(vm.blog);
                    }
                }
            );

            $scope.$on("colorpicker-selected", function(evt, data) {
                if (data.value === vm.textColor) {
                    $timeout(function() {
                        setColor("color", vm.textColor);
                    });
                } else if (data.value === vm.highlightColor) {
                    $timeout(function() {
                        setColor("background", vm.highlightColor);
                    });
                }
            });

            $translate("FORMS.SAVED_FORM").then(function(translation) {
                savedText = translation;
            });

            $translate("FORMS.SAVE_FORM_FAILED").then(function(translation) {
                saveFailedText = translation;
            });

            Quill.registerModule("ssmTools", SSMTools);

            editor = new Quill(".blog-edit-editor", {
                modules: {
                    toolbar: {
                        container: ".blog-edit-toolbar"
                    },
                    "link-tooltip": true,
                    ssmTools: {
                        showImageModal: showImageModal
                    }
                },
                theme: "snow"
            });

            // Fixes SSM-1905 - Formatting blog post brings you to top of page
            // There's something weird about force-setting the focus to the editor root,
            // which is something Quill periodically does on many edit operations.
            // Disabling focus doesn't appear to damage any user functionality or behavior
            // but just in case, we're limiting the scope of this fix to Safari, and only Safari
            // NOTE:  On a Mac, Chrome identifies itself as both Safari and Chrome, thus the two-part check.
            var is_chrome = navigator.userAgent.indexOf("Chrome") > -1;
            var is_safari = navigator.userAgent.indexOf("Safari") > -1;
            if (is_safari && !is_chrome) {
                var ql_editor = angular.element(".ql-editor")[0];
                ql_editor.focus = function() {};
            }

            // Register a handler for the paste event, so we can special-case image pastes.
            var node = angular.element(".blog-edit-editor")[0];
            node.addEventListener("paste", handlePaste);
            node.addEventListener("click", handleClick);

            resetEditorContents(vm.blog);

            editor.on("text-change", function() {
                if (resettingEditorContents) {
                    return;
                }

                // Check the undo/redo status after the text change event is complete.
                // Ensures that we get an accurate answer from Quill.
                $timeout(function() {
                    vm.undoNotAvailable = !editor.undoAvailable();
                    vm.redoNotAvailable = !editor.redoAvailable();
                });

                saveWithDelay(vm.blog);
            });

            editor.on("selection-change", function(range) {
                $timeout(function() {
                    updateColors(range);
                });
            });

            $translate("BLOG.PLACEHOLDER_TEXT").then(function(translation) {
                editor.setPlaceholderText(translation);
            });

            $translate("BLOG.TITLE_PLACEHOLDER_TEXT").then(function(
                translation
            ) {
                vm.titlePlaceholder = translation;
            });

            $translate("BLOG.DEFAULT_BLOG_TITLE").then(function(translation) {
                defaultBlogTitle = translation;
            });

            // We have to register for the location change start event with a tiny delay
            // Otherwise, angular can fire the event, even though technically, the event should
            // have already fired before getting here.
            $timeout(function() {
                var onRouteChangeOff = $rootScope.$on(
                    "$locationChangeStart",
                    function() {
                        save(vm.blog);
                        onRouteChangeOff();
                    }
                );
            }, 0);

            editor.focus();
        }

        function resetEditorContents(blog) {
            try {
                if (blog && blog.body) {
                    var body = JSON.parse(blog.body);
                    resettingEditorContents = true;
                    editor.setText("");
                    editor.setContents({
                        ops: body.quill_ops
                    });
                    resettingEditorContents = false;

                    editor.setSelection(0, 0);

                    vm.dirty = false;
                    vm.showSaved = false;

                    // Quill considers the "setContents" above to be an undoable event.
                    // So, we clear the undo history to make the initial document contents
                    // the beginning of the undo stack, rather than an empty document.
                    editor.clearUndoHistory();
                }
            } catch (error) {
                $translate("BLOG.EDIT_ERROR").then(function(translation) {
                    Logger.error("Error: ", translation);
                });
            }
        }

        function getActiveColors(contents, attribute, defaultColor) {
            return _(contents.ops)
                .map(function(op) {
                    var color = op.attributes ? op.attributes[attribute] : null;
                    color = color || defaultColor;
                    return color.replace(/ /g, "");
                })
                .uniq()
                .value();
        }

        function updateColors(range) {
            if (!range) {
                return;
            }

            if (range.start === range.end) {
                if (range.start === 0) {
                    range.end += 1;
                } else {
                    range.start -= 1;
                }
            }
            var contents = editor.getContents(range.start, range.end);

            var defaultColor = "rgb(0,0,0)";
            var defaultBackground = "rgb(255,255,255)";

            var colors = getActiveColors(contents, "color", defaultColor);
            var backgrounds = getActiveColors(
                contents,
                "background",
                defaultBackground
            );

            vm.textColor = colors.length === 1 ? colors[0] : defaultColor;
            vm.highlightColor =
                backgrounds.length === 1 ? backgrounds[0] : defaultBackground;
        }

        function setColor(name, color) {
            editor.focus();
            var range = editor.getSelection();

            var format = {};
            format[name] = color;
            editor.formatText(range.start, range.end, format);
        }

        function saveTitleWithDelay(blog, forceSave) {
            var title = blog.title.trim();
            if (!!title || forceSave) {
                return saveWithDelay(blog);
            }
        }

        function saveWithDelay(blog) {
            $timeout(function() {
                vm.dirty = true;
                vm.showSaved = false;
                $timeout.cancel(savePromise);
                savePromise = $timeout(function() {
                    save(blog);
                }, DELAY_WITH_SAVE);
            }, 0);
        }

        function save(blog) {
            $timeout.cancel(savePromise);

            if (!vm.dirty) {
                return;
            }

            vm.dirty = false;
            var quill_data = editor.getContents();
            blog.body = JSON.stringify({
                quill_ops: quill_data.ops
            });

            blog.plain_text = editor.getText();

            // Set the blog title to *something*, if the user has deleted it.
            var title = blog.title.trim();
            if (!title) {
                $translate("BLOG.BLANK_TITLE_ERROR").then(function(
                    translation
                ) {
                    Logger.error("Error: ", translation);
                });
                title = defaultBlogTitle;
                blog.title = title;
            }

            var blog_data = {
                body: blog.body,
                plain_text: blog.plain_text,
                title: title,
                status: blog.status,
                hash_tags: blog.hash_tags
            };

            Blog.update(blog.id, blog_data)
                .then(function(updatedBlog) {
                    // Since blogs are auto-save, this update might be called many times during the same edit session.
                    // Only log the analytics event once.  This will reset and log a new event on any navigation or state change.
                    if (!vm.loggedAnalytics) {
                        Analytics.eventTrack("editBlog", {
                            category: "Blogs",
                            label:
                                "id:" +
                                updatedBlog.id +
                                ", author: " +
                                updatedBlog.author.id
                        });
                        Analytics.eventTrack2('edit_blog', {
                            category: "Blogs",
                            label: "id:" +
                                updatedBlog.id +
                                ", author: " +
                                updatedBlog.author.id,
                        });

                        if (updatedBlog.author.id !== CurrentUser.profile.id) {
                            Analytics.eventTrack("editBlogAsDelegate", {
                                category: "Delegation",
                                label:
                                    "id:" +
                                    updatedBlog.id +
                                    ", author: " +
                                    updatedBlog.author.id
                            });
                            Analytics.eventTrack2('edit_blog_as_delegate', {
                                category: "Delegation",
                                label: "id:" +
                                    updatedBlog.id +
                                    ", author: " +
                                    updatedBlog.author.id,
                            });
                        }
                        vm.loggedAnalytics = true;
                    }
                    vm.showSaved = true;
                    $timeout.cancel(hideSavedFlagPromise);
                    hideSavedFlagPromise = $timeout(function() {
                        vm.showSaved = false;
                    }, DELAY_TIME_HIDING_FLAG);
                    vm.savedText = savedText;
                })
                .catch(function() {
                    vm.showSaved = false;
                    vm.savedText = saveFailedText;
                });
        }

        function showImageModal(imageTool) {
            var insertBlogImageData = {
                blog: vm.blog,
                insertImage: function(imageUrl) {
                    imageTool.insertImage(imageUrl);
                }
            };

            $uibModal.open({
                templateUrl:
                    "components/modal/insertBlogImage/insertBlogImage.html",
                controller: "InsertBlogImageCtrl as vm",
                resolve: {
                    insertBlogImageData: function() {
                        return insertBlogImageData;
                    }
                }
            });
        }

        function handleClick(clickEvent) {
            if (
                !clickEvent ||
                !clickEvent.target ||
                !clickEvent.target.tagName ||
                clickEvent.target.tagName.toUpperCase() !== "IMG"
            ) {
                $scope.$broadcast(Blog.BLOG_EDITOR_CLICKED_EVENT, null, editor);
                return;
            }

            var target = clickEvent.target;
            $scope.$broadcast(Blog.BLOG_EDITOR_CLICKED_EVENT, target, editor);
        }

        function handlePaste(e) {
            _.some(e.clipboardData.items, function(item) {
                if (/text\/(plain|rtf|html)/.test(item.type)) {
                    if(navigator.userAgent.indexOf("Firefox") > -1) {
                        var qlEditor = document.getElementById('ql-editor-1');
                        $timeout(function() {
                            if (qlEditor && qlEditor.lastChild) {
                                qlEditor.removeChild(qlEditor.lastChild);
                            }
                        }, 10);
                    }
                    return true
                }
                if (item.type.indexOf("image") !== -1) {
                    // We're handling this -- make sure nothing else does.
                    e.preventDefault();
                    var imageFile = item.getAsFile();

                    uploadFile(imageFile, function(uploadedFile) {
                        var imageTool = editor.getModule("ssmTools");
                        imageTool.insertImage(uploadedFile.urls.download);
                    });
                    return true
                }
                return false
            });
        }

        function uploadFile(fileToUpload, callback) {
            var fileInfo = {
                size: fileToUpload.size,
                file_type: fileToUpload.type,
                role: "BLOG",
                name: "Pasted Image"
            };

            ssmFileUpload
                .upload(fileToUpload, fileInfo)
                .then(function(uploadedFile) {
                    callback(uploadedFile);
                });
        }
    }
})();
