(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmCategoryNav")
        .directive("ssmCategoryNav", ssmCategoryNav)
        .controller("ssmCategoryNavController", ssmCategoryNavController);

    function ssmCategoryNav() {
        return {
            templateUrl: "components/ssmCategoryNav/ssmCategoryNav.html",
            restrict: "E",
            replace: true,
            scope: {
                marketplace: "="
            },
            controller: ssmCategoryNavController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmCategoryNavController(
        $scope,
        $state,
        Analytics,
        ssmMarketplace,
        Tenant
    ) {
        /*jshint validthis:true */
        var vm = this;
        vm.sortDescendingPreference;
        vm.categoryPanes = [];
        vm.marketplaceRoute = "main.marketplace.results";
        vm.paneLink = "";
        vm.categoryLink = "";
        vm.animateCategory = animateCategory;
        vm.animatePaneBack = animatePaneBack;
        vm.animatePaneBackToStart = animatePaneBackToStart;
        vm.animatePaneForward = animatePaneForward;
        vm.arePanesMissing = arePanesMissing;
        vm.createCategoryPane = createCategoryPane;
        vm.getCategoryHeaderTitle = getCategoryHeaderTitle;
        vm.getCategoryIdToDisplay = getCategoryIdToDisplay;
        vm.getDisplayedCategoryList = getDisplayedCategoryList;
        vm.getPanelIndex = getPanelIndex;
        vm.initialCreatePanes = initialCreatePanes;
        vm.onCategoryClick = onCategoryClick;
        vm.hideCategoryCounts =
            $state.params.query ||
            $state.params.city ||
            $state.params.state ||
            $state.params.postal_code;
        vm.menus = [];

        activate();

        function activate() {
            determineCategorySorting();
            initMenus();
            
        }

        function initMenus() {
            ssmMarketplace
                .getMarketplace(vm.marketplace.id)
                .then(function(marketplace) {
                    if (marketplace.organization_id == "5e6149be7ede1b6e0efc1ef7") {
                        vm.marketplaceRoute = "main.marketplace_npz.results";
                    }
                    vm.paneLink = vm.marketplaceRoute + "( {category: pane.parentPanelId} )";
                    vm.categoryLink = vm.marketplaceRoute + "( {category: category.id} )";

                    var tempArray = [];
                    tempArray = marketplace.menus;
                    vm.menus = tempArray;

                    ssmMarketplace
                        .getMarketplaceCategories(vm.marketplace.id)
                        .then(function(categories) {
                            vm.categories = categories;
                            vm.initialCreatePanes();

                            $scope.$on("$stateChangeSuccess", function() {
                                // TODO: recompute category counts instead of hiding them
                                vm.hideCategoryCounts =
                                    $state.params.query ||
                                    $state.params.city ||
                                    $state.params.state ||
                                    $state.params.postal_code;
                                vm.animateCategory($state.params.category);
                            });
                        });
                });
        }

        function animateCategory(categoryId) {
            if (!categoryId) {
                vm.animatePaneBackToStart();
                return;
            }

            var foundIndex = vm.getPanelIndex(categoryId);

            if (foundIndex > -1) {
                while (vm.categoryPanes.length > foundIndex + 1) {
                    vm.animatePaneBack();
                }
                return;
            }
            if (vm.arePanesMissing(categoryId)) {
                vm.initialCreatePanes();
                return;
            }

            return vm.animatePaneForward(categoryId);
        }

        function animatePaneBack() {
            if (vm.categoryPanes.length > 1) {
                vm.categoryPanes.pop();
            }
        }

        function animatePaneBackToStart() {
            while (vm.categoryPanes.length > 1) {
                vm.animatePaneBack();
            }
        }

        function animatePaneForward(categoryId) {
            if (vm.categories.categoryMap[categoryId]) {
                var newPane = vm.createCategoryPane(categoryId);
                vm.categoryPanes.push(newPane);
            }
        }

        function arePanesMissing(currentId) {
            var parentId = _.get(
                vm.marketplace,
                "categoryIdMap." + [currentId] + ".parent_id"
            );
            if (!parentId) {
                return false;
            }

            if (_.last(vm.categoryPanes).id === parentId) {
                return false;
            }
            return true;
        }

        function determineCategorySorting() {
            Tenant.getTenant().then(function(tenant) {
                var sort_preference = tenant.marketplace_preferences.categories.sort_preference;
                if (sort_preference == "desc") {
                    vm.sortDescendingPreference = true;
                }
                else {
                    vm.sortDescendingPreference = false;
                }
            });
        }

        function createCategoryPane(id) {
            var pane = {};

            pane.hasBack = !!id;
            pane.id = id;
            pane.parentPanelId = _.get(
                vm.marketplace,
                "categoryIdMap." + [id] + ".parent_id"
            );
            pane.headerTitle = vm.getCategoryHeaderTitle(id);
            pane.displayedCategoryList = vm.getDisplayedCategoryList(id);
            pane.menus = [
                {
                    title: vm.marketplace.name,
                    displayedCategoryList: pane.displayedCategoryList
                }
            ];


            return pane;
        }

        function createCategoryBasePane(menus) {
            var pane = {};

            pane.hasBack = false;
            pane.id = undefined;
            pane.parentPanelId = _.get(
                vm.marketplace,
                "categoryIdMap." + ".parent_id"
            );
            pane.headerTitle = vm.getCategoryHeaderTitle();
            var allDisplayedCategories = vm.getDisplayedCategoryList();
            if (menus == null || menus.length <= 0) {
                var menuCollections = [];
                allDisplayedCategories.forEach(function(category) {
                    menuCollections.push(category.id);
                });

                menus = [
                    {
                        title: vm.marketplace.name,
                        collections: menuCollections,
                        displayedCategoryList: allDisplayedCategories
                    }
                ];
            }
            pane.menus = menus;

            pane.menus.forEach(function(menu) {
                menu.displayedCategoryList = allDisplayedCategories.filter(
                    function(category) {
                        return menu.collections.includes(category.id);
                    }
                );
            });

            return pane;
        }

        function getCategoryHeaderTitle(categoryId) {
            if (!categoryId) {
                return "Categories";
            }

            return vm.categories.categoryIdMap[categoryId].name;
        }

        function getCategoryIdToDisplay() {
            var selectedUrlCategory =
                vm.categories.categoryIdMap[$state.params.category];

            if (!selectedUrlCategory) {
                return undefined;
            }

            return selectedUrlCategory.parent_id || selectedUrlCategory.id;
        }

        function getDisplayedCategoryList(categoryId) {
            return vm.categories.categoryMap[categoryId];
        }

        function getPanelIndex(categoryId) {
            return _.findIndex(vm.categoryPanes, function(pane) {
                if (pane.id === categoryId) {
                    return pane;
                }
            });
        }

        function initialCreatePanes() {
            vm.categoryPanes = [];
            vm.categoryParentId = vm.getCategoryIdToDisplay();
            var categoryId = vm.categoryParentId;
            var newPane = {};

            while (categoryId) {
                newPane = createCategoryPane(categoryId);
                vm.categoryPanes.unshift(newPane);
                categoryId = vm.categories.categoryIdMap[categoryId].parent_id;
            }

            //Base category panel
            if (categoryId == null && vm.categoryPanes.length == 0) {
                newPane = createCategoryBasePane(vm.menus);
                vm.categoryPanes.unshift(newPane);
            }
        }

        function onCategoryClick(category) {
            Analytics.eventTrack("categoryClick", {
                category: "marketplace",
                label: "category: " + category.id,
                dimension2: vm.marketplace.id,
                dimension4: vm.marketplace.id
            });
            Analytics.eventTrack2('category_click', {
                category: "marketplace",
                label: "category: " + category.id,
                dimension2: vm.marketplace.id,
                dimension4: vm.marketplace.id
            });
        }
    }
})();
