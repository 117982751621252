(function() {
    "use strict";

    angular.module("ssmAngularApp.createWallPost", [
        "ssmAngularApp.wallPost",
        "ssmAngularApp.user.service",
        "ssmAngularApp.community",
        "pascalprecht.translate",
        "ssmAngularApp.analytics",
        "ssmAngularApp.logger",
        "ssmAngularApp.ssmWallPoll",
        "ssmAngularApp.fileUpload",
        "ssmAngularApp.photoGallery",
        "ssmAngularApp.fileGallery",
        "ssmAngularApp.ssmDelegationPost"
    ]);
})();
