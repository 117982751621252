(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.layout").controller("HomeCtrl", HomeCtrl);

    function HomeCtrl(
        $sce,
        $translate,
        WallPost,
        CurrentUser,
        Analytics,
        Logger,
        Flair,
        Tenant,
        SSMTitle,
        Audience
    ) {
        /*jshint validthis: true */
        var POST_LIMIT = 13;
        var vm = this;
        vm.disableInfiniteScrollEvents = false;

        vm.nextPage = nextPage;
        vm.posts = [];
        vm.flair = {};
        vm.processPosts = processPosts;
        vm.showAppaWidgets = false;
        vm.appaJobs = "";
        vm.keepAliveLink = null;
        vm.isTenantBlocked = "";

        if (CurrentUser.profile.appa_settings) {
            var appaCustomerType =
                CurrentUser.profile.appa_settings.customer_type;
        }

        activate();

        function activate() {
            $translate("TITLE.HOME").then(function(title) {
                SSMTitle.setTitle(title);
            });

            vm.currentUser = CurrentUser.profile;

            vm.location = {
                id: vm.currentUser.id,
                type: WallPost.LOCATION_TYPE.USER
            };
            vm.location.permissions = {
                canPost: true,
                canComment: true
            };

            setUpTenantValues();
            vm.nextPage();
            getFlairData();
        }

        function nextPage() {
            var lastPost = _.last(vm.posts);
            var query = {
                limit: POST_LIMIT,
                sort_by_activity: false,
                format: "enhanced"
            };
            if (lastPost) {
                query.before_date = lastPost.activity_date;
            }

            vm.disableInfiniteScrollEvents = true;
            WallPost.all(query)
                .then(function(posts) {
                    vm.processPosts(posts);
                })
                .catch(function(error) {
                    // Error getting posts, don't add any more and leave infinite scroll disabled
                    $translate("WALL.GET_POSTS_ERROR").then(function(
                        translation
                    ) {
                        Logger.error("ERROR: ", translation, error);
                    });
                });
        }

        function processPosts(posts) {
            if (!posts || posts.length === 0) {
                // no more posts, don't add any more and leave infinite scroll disabled
                return;
            }

            _.each(posts, function(post) {
                // User will have permission to comment on a user's post on their wall if they are connected
                // This is far less than calculating on the server and we're only using this for UI.  The server will
                // still validate the connection when the user submits the comment.
                // If we can see a user's posts on our feed we're connected
                if (post.location.type === WallPost.LOCATION_TYPE.USER) {
                    post.location.permissions.canComment =
                        vm.location.permissions.canComment;
                }
            });

            vm.posts.push.apply(vm.posts, posts);
            //sortProcessedPostsByDateDesc(vm.posts);
            vm.disableInfiniteScrollEvents = false;
        }
        /*
        function sortProcessedPostsByDateDesc(posts) {
            posts.sort((a, b) => {
              const dateA = new Date(a.create_date);
              const dateB = new Date(b.create_date);
              return dateB - dateA; 
            });
        }
        */
        function getFlairData() {
            var platinumQuery = Flair.createFlairQuery(
                2,
                Flair.SIZE.MEDIUM_RECTANGLE,
                Flair.LEVEL.PLATINUM
            );
            var goldQuery = Flair.createFlairQuery(
                3,
                Flair.SIZE.RECTANGLE,
                Flair.LEVEL.GOLD
            );
            var houseQuery = Flair.createFlairQuery(
                1,
                Flair.SIZE.RECTANGLE,
                Flair.LEVEL.HOUSE
            );

            Flair.getFlair("HOME", [platinumQuery, goldQuery, houseQuery])
                .then(processFlair)
                .then(function(flair) {
                    vm.flair = flair;
                });
        }

        function processFlair(allFlair) {
            var platinumFlair = allFlair[0];
            var goldFlair = allFlair[1];
            var houseFlair = allFlair[2];

            var displayedFlair = {};
            if (platinumFlair && platinumFlair.length > 0) {
                displayedFlair.platinum = {
                    flair: platinumFlair
                };
            }
            if (goldFlair && goldFlair.length > 0) {
                displayedFlair.gold = {
                    flair: goldFlair
                };
            }
            if (houseFlair && houseFlair.length > 0) {
                displayedFlair.house = {
                    flair: houseFlair
                };
            }

            Analytics.flairDisplayEvents(allFlair, "HomeCtrl");

            return displayedFlair;
        }

        function logAudienceMember(organizationId) {
            const profile = CurrentUser.profile;
            const member = {
                organization_id: organizationId
            };
            if (!profile.is_guest) {
                Object.assign(member, {
                    first_name: profile.first_name,
                    last_name: profile.last_name,
                    email_address: profile.emails[0].email
                });
            }
            Audience.upsertWithIpAddress(member);
        }

        function showWidgetsIfSiteAppa(currentOrgId, customerType) {
            const APPA_ORGANIZATION_ID = "5f283a7f1e74102c0e54252c";

            if (currentOrgId == APPA_ORGANIZATION_ID)
            {
                return (
                    customerType == "Individual" ||
                    customerType == "Lifetime" ||
                    customerType == "Student"
                );
            }
            else return false;
        }

        function setKeepAliveIfSiteCHIME(currentOrgId) {
            const CHIME_ORGANIZATION_ID = "54efe0f32e1f88382143dca8";
            const KEEP_ALIVE_URL = "https://chime-sandbox.users.membersuite.com/KeepAlive.aspx";
            if (currentOrgId == CHIME_ORGANIZATION_ID) {
                return trustExternalUrl(KEEP_ALIVE_URL);
            }
            else return null;
        }

        function trustExternalUrl(url) {
            return $sce.trustAsResourceUrl(url);
        }

        function setUpTenantValues() {
            Tenant.getTenant().then(function(tenant) {
                vm.announcement_banner_img = tenant.announcement_banner_img;
                vm.announcement_banner_url = tenant.announcement_banner_url;

                vm.announcement_banner_img_2 = tenant.announcement_banner_img_2;
                vm.announcement_banner_url_2 = tenant.announcement_banner_url_2;

                vm.announcement_banner_img_3 = tenant.announcement_banner_img_3;
                vm.announcement_banner_url_3 = tenant.announcement_banner_url_3;
                //if its ahima then it will add the ID to be tracked
                if(getAhimaTenantHost(tenant)){
                    vm.announcement_banner_id = "AhimaAnnouncementTrack1";
                    vm.announcement_banner_id_2 = "AhimaAnnouncementTrack2";
                    vm.announcement_banner_id_3 = "AhimaAnnouncementTrack3";
                }

                vm.allowEvents = tenant.flags.calendar;

                vm.showAppaWidgets = showWidgetsIfSiteAppa(tenant.organization_id, appaCustomerType);
                vm.keepAliveLink = setKeepAliveIfSiteCHIME(tenant.organization_id);
                vm.isTenantBlocked = tenant.tenant_blocked_error;

                logAudienceMember(tenant.organization_id);
            });
        }
        //function to get ahima tenant and return true to put the desired Id for to announcements 
        function getAhimaTenantHost(Tenant){
            if(Object.keys(Tenant).includes("hosts")){
                const ahimaHosts = (host) => host === "ahima.nextwaveconnect.com" || host === "access.ahima.org" || host === "ahima.staging.nextwaveconnect.com" || host === "ahima.ssm-devbox.local";
                return Tenant.hosts.some(ahimaHosts);
            }
        }       
    }
})();
