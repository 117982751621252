(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.missingTranslationHandler")
        .factory("MissingTranslationHandler", MissingTranslationHandler);

    function MissingTranslationHandler(Logger) {
        return function(translationId) {
            Logger.warningWithoutAlert("Missing translation", {
                translationId: translationId
            });
        };
    }
})();
