(function() {
   "use strict";
   /**
    * @ngInject
    */

   angular
      .module("ssmAngularApp.ssoButton")
      .directive("ssoButton", SsoButton);

   function SsoButton() {
      return {
         templateUrl: "components/ssoButton/ssoButton.html",
         restrict: "E",
         scope: {
               buttonRedirectUrl: "@",
               organizationName: "@",
               organizationLogo: "@",
               ssoPromptText: "@"
         },
         replace: true,
         controller: SsoButtonController,
         controllerAs: "vm",
         bindToController: true
      };
   }

   function SsoButtonController() {

      /*jshint validthis: true */
      var vm = this;

      vm.signInViaSso = attemptSignIn;

      activate();

      function activate() {
         clearDeviceStorage();
      }

      function clearDeviceStorage() {
         localStorage.clear();
         sessionStorage.clear();
      }

      function attemptSignIn() {
         window.location = `${vm.buttonRedirectUrl}`;
      }

   }
})();
