(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmDisableBackspace", [])
        .directive("ssmDisableBackspace", ssmDisableBackspace);

    // Prevent backspace from exiting during wizard edits prior to finishing, default behavior in many browsers is to navigate "back".
    // Disable backspace from doing anything except on text and textarea fields.
    // This disables the backspace on the whole document as long as the element the directive is applied too has a clientHeight (is in the current client view)
    function ssmDisableBackspace($document) {
        return {
            restrict: "A",
            link: ssmDisableBackspaceLink
        };

        function ssmDisableBackspaceLink(scope, element, attr) {
            var handler = function(event) {
                // If the element has no clientHeight that means it is not in the view, and we should no longer apply this directive.
                if (!element[0].clientHeight) {
                    return $document.unbind("keydown keypress", handler);
                }

                if (
                    event.keyCode === 8 &&
                    !(
                        event.target.type === "text" ||
                        event.target.type === "textarea"
                    )
                ) {
                    event.preventDefault();
                }
            };
            // Disable the backspace for the whole document, not just the element.
            $document.bind("keydown keypress", handler);
        }
    }
})();
