(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmAuth.service")
        .factory("ssmAuthHelper", ssmAuthHelper);

    function ssmAuthHelper(Cookie, ssmLocalStorage, $timeout, $location) {
        return {
            getUserId: getUserId,
            saveToken: saveToken,
            logout: logout,

            get token() {
                return getToken();
            },

            get secondsUntilTokenExpiration() {
                return getSecondsUntilTokenExpiration();
            },

            get isSignedIn() {
                return getSecondsUntilTokenExpiration() > 0;
            }
        };

        function getUserId() {
            return ssmLocalStorage.get(ssmLocalStorage.key.SSM_USER_ID);
        }

        function saveToken(token) {
            ssmLocalStorage.set(ssmLocalStorage.key.TOKEN, token);

            var decoded_token = jwt_decode(token);
            if (decoded_token && decoded_token.ssm_user_id) {
                ssmLocalStorage.set(
                    ssmLocalStorage.key.SSM_USER_ID,
                    decoded_token.ssm_user_id
                );
            }
        }

        function logout(reload) {
            const ahimaOrgId = "5faefb8da8af10474ce6ddc8";
            var currentTenant = ssmLocalStorage.get(
                ssmLocalStorage.key.CURRENT_TENANT
            );
            ssmLocalStorage.clear();
            if (currentTenant.id == ahimaOrgId) {
                logoutOfB2C();
            }
            else if (reload) {
                $timeout(function() {
                    $location.path("/login");
                }, 1);
            }
        }

        function logoutOfB2C() {
            var CONFIG = initializeConfig();
            const _tenant = "https://" + sessionStorage.getItem("tenant") + ".b2clogin.com/" + sessionStorage.getItem("tenant") + ".onmicrosoft.com/";

            const msalConfig = {
                auth: {
                    clientId: CONFIG.client_id, 
                    authority: _tenant + "B2C_1A_signup_signin_migratejitv2", //This is your tenant info and signIn/signUp user policy
                    validateAuthority: false,
                    redirectUri: CONFIG.azure_allowed_url, //defaults to application start page
                    postLogoutRedirectUri: CONFIG.azure_post_logout_url
                },
                cache: {
                    cacheLocation: "localStorage",
                    storeAuthStateInCookie: true
                }
            };
            var myMSALObj = new Msal.UserAgentApplication(msalConfig);

            let idToken = Cookie.read("msal." + CONFIG.client_id + ".idtoken");
            if (
                (idToken == null || idToken == "") &&
                myMSALObj.redirectResponse != null
            ) {
                idToken = myMSALObj.redirectResponse.idToken.rawIdToken;
            }

            getLogoutToken(myMSALObj)
            .then(function () {
                myMSALObj.logout(idToken);
                myMSALObj.clearCache();
            })
            .catch(function () {
                myMSALObj.logout(idToken);
                myMSALObj.clearCache();
            });    
        }

        function getLogoutToken(myMSALObj) {
            var appConfig = {
                b2cScopes: ["https://" + sessionStorage.getItem("tenant") + ".onmicrosoft.com/api/read", "https://" + sessionStorage.getItem("tenant") + ".onmicrosoft.com/api/write"],
                webApi: "https://" + sessionStorage.getItem("tenant") + ".onmicrosoft.com/api/"
             };
            const tokenRequest = {
                scopes: appConfig.b2cScopes
             };
            return myMSALObj.acquireTokenSilent(tokenRequest).catch(function (error) {
               // ClientAuthError: User login is required.
               console.log("aquire token popup");
               // fallback to interaction when silent call fails
               return myMSALObj.acquireTokenPopup(tokenRequest).then(function (tokenResponse) {
               }).catch(function (error) {
                  console.log("Failed acquireTokenPopup acquisition");
               });
            });
         }

        function initializeConfig() {
            var currentHost = $location.host();
            if (currentHost == "ahima.ssm-devbox.local") {
               sessionStorage.setItem("tenant", "ahimaTest");
               return{
                client_id : "fffaee6c-051a-4aad-b4f4-b4268a65be80",
                azure_allowed_url : "https://ahima.ssm-devbox.local:8443",
                azure_post_logout_url : "https://ahima.ssm-devbox.local:8443/login?from-logout"
               };
            }
   
            else if (currentHost == "ahima.staging.nextwaveconnect.com") {
               sessionStorage.setItem("tenant", "ahimaTest");
               return{
                client_id : "fffaee6c-051a-4aad-b4f4-b4268a65be80",
                azure_allowed_url : "https://ahima.staging.nextwaveconnect.com",
                azure_post_logout_url : "https://ahima.staging.nextwaveconnect.com/login?from-logout"
               }
            }
      
            else if (currentHost == "ahima.nextwaveconnect.com"
               || currentHost == "access.ahima.org"
            ) {
               sessionStorage.setItem("tenant", "ahimaProdB2C");
               return{
               client_id : "263a9d40-fa9a-418e-ab7c-c1d7f2003ba9",
               azure_allowed_url : "https://access.ahima.org",
               azure_post_logout_url : "https://access.ahima.org/login?from-logout"
               }
            }
         }

        function getToken() {
            return ssmLocalStorage.get(ssmLocalStorage.key.TOKEN);
        }

        function getSecondsUntilTokenExpiration() {
            var token = getToken();
            if (!token) {
                return -1;
            }
            var decoded_token = jwt_decode(token);

            var expiration = decoded_token.exp * 1000;
            var now = Date.now();
            var difference = expiration - now;
            return difference;
        }
    }
})();
