(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("VendorCreateController", VendorCreateController);

    function VendorCreateController(Vendor, $state) {
        var vm = this;
        vm.vendorData = {
            status: Vendor.STATUS.INACTIVE
        };
        vm.cancel = cancel;
        vm.create = create;

        function create(edits) {
            Vendor.addVendor(edits).then(function(newVendor) {
                $state.go("main.vendor.show", { vendor_id: newVendor.id });
            });
        }

        function cancel() {
            $state.go("main.vendor.index");
        }
    }
})();
