(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsEditMembersController", OrgsEditMembersController);

    function OrgsEditMembersController(
        $translate,
        Logger,
        Organization,
        orgProfileResolve,
        SSMTitle
    ) {
        var vm = this;
        vm.org = orgProfileResolve;
        vm.add_user_ids = [];
        vm.remove_user_ids = [];
        vm.formDirty = false;
        vm.members = {
            members: []
        };

        vm.saveForm = saveForm;
        vm.resetForm = resetForm;
        vm.addMember = addMember;
        vm.removeMember = removeMember;

        activate();

        function activate() {
            $translate("ORGANIZATION.EDIT.SITE.MEMBERS.TITLE").then(
                SSMTitle.setTitle
            );

            Organization.getMembers(vm.org.id).then(function(results) {
                vm.members.members = _.map(results || [], function(user) {
                    return { user: user, id: user.id };
                });
            });
        }

        function saveForm() {
            vm.add_user_ids = _(vm.add_user_ids)
                .compact()
                .uniq()
                .value();
            vm.remove_user_ids = _(vm.remove_user_ids)
                .compact()
                .uniq()
                .value();
            Organization.updateMembers(vm.org.id, {
                add_user_ids: vm.add_user_ids,
                remove_user_ids: vm.remove_user_ids
            })
                .then(function(results) {
                    vm.formDirty = false;
                    vm.flashSavedFlag();
                    vm.add_user_ids = [];
                    vm.remove_user_ids = [];
                })
                .catch(function(error) {
                    switch (error.status) {
                        case 403: //forbidden
                            $translate(
                                "ORGANIZATION.EDIT.ERROR.FORBIDDEN"
                            ).then(function(translation) {
                                Logger.error("Error: ", translation);
                            });
                            break;
                        default:
                            $translate("ORGANIZATION.EDIT.ERROR.DEFAULT").then(
                                function(translation) {
                                    Logger.error("Error: ", translation);
                                }
                            );
                            break;
                    }
                });
        }

        function resetForm() {
            vm.formDirty = false;
            vm.add_user_ids = [];
            vm.remove_user_ids = [];

            Organization.getMembers(vm.org.id)
                .then(function(results) {
                    var members = _.map(results || [], function(user) {
                        return { user: user, id: user.id };
                    });

                    vm.members = { members: members };
                })
                .catch(function(error) {
                    $translate("ORGANIZATION.EDIT.ERROR.DEFAULT").then(function(
                        translation
                    ) {
                        Logger.error("Error: ", translation);
                    });
                });
        }

        function addMember(user) {
            if (!user) {
                return;
            }

            vm.formDirty = true;
            vm.add_user_ids.push(user.id);

            var index = _.findIndex(vm.remove_user_ids, function(id) {
                return id === user.id;
            });
            while (index >= 0) {
                vm.remove_user_ids.splice(index, 1);
                index = _.findIndex(vm.remove_user_ids, function(id) {
                    return id === user.id;
                });
            }
        }

        function removeMember(user) {
            if (!user) {
                return;
            }

            vm.formDirty = true;
            vm.remove_user_ids.push(user.id);

            var index = _.findIndex(vm.add_user_ids, function(id) {
                return id === user.id;
            });
            while (index >= 0) {
                vm.add_user_ids.splice(index, 1);
                index = _.findIndex(vm.add_user_ids, function(id) {
                    return id === user.id;
                });
            }
        }
    }
})();
