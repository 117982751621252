(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.delegation.service", ["ssmAngularApp.api"])
        .factory("Delegation", Delegation);

    function Delegation(Api) {
        var userApi = Api.all("user");

        return {
            getDelegates: getDelegates,
            addDelegate: addDelegate,
            removeDelegate: removeDelegate,
            removePrincipals: removePrincipals,
            getPrincipals: getPrincipals,
            getPrincipalsForCommunity: getPrincipalsForCommunity,
            getPrincipalsForBlog: getPrincipalsForBlog,
            updateDelegates: updateDelegates
        };

        function getDelegates(profile_id) {
            return userApi
                .one(profile_id)
                .all("delegates")
                .getList();
        }

        function addDelegate(profile_id, delegate_id) {
            return userApi
                .one(profile_id)
                .all("delegates")
                .one(delegate_id)
                .put();
        }

        function removeDelegate(profile_id, delegate_id) {
            return userApi
                .one(profile_id)
                .all("delegates")
                .one(delegate_id)
                .remove();
        }

        function updateDelegates(profile_id, delegate_ids) {
            return userApi.one(profile_id, "delegates").patch(delegate_ids);
        }

        function removePrincipals(profile_id) {
            return userApi
                .one(profile_id)
                .all("principals")
                .remove();
        }

        function getPrincipals(profile_id) {
            return userApi
                .one(profile_id)
                .all("principals")
                .getList();
        }

        function getPrincipalsForCommunity(
            profile_id,
            community_id,
            searchObject
        ) {
            return userApi
                .one(profile_id)
                .all("communities")
                .one(community_id)
                .one("principals")
                .get(searchObject);
        }

        function getPrincipalsForBlog(profile_id, searchObject) {
            return userApi
                .one(profile_id)
                .one("blogPrincipals")
                .get(searchObject);
        }
    }
})();
