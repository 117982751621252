(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmVendorFlairForm", ["ssmAngularApp.flair"])
        .directive("ssmVendorFlairForm", ssmVendorFlairForm)
        .controller(
            "ssmVendorFlairFormController",
            ssmVendorFlairFormController
        )
        .factory("ssmVendorFlairFormHelper", ssmVendorFlairFormHelper);

    function ssmVendorFlairForm() {
        return {
            templateUrl:
                "components/ssmVendorFlairForm/ssmVendorFlairForm.html",
            restrict: "E",
            scope: {
                autoSave: "@",
                onCancel: "&",
                onUpdate: "&",
                flairData: "=",
                libraryData: "="
            },
            controller: ssmVendorFlairFormController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmVendorFlairFormController(
        $scope,
        ssmVendorFlairFormHelper,
        Flair
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.canEditLevel = true;

        vm.autoUpdate = autoUpdate;
        vm.buttonClickCancel = buttonClickCancel;
        vm.buttonClickSubmit = buttonClickSubmit;

        vm.flairEdit = ssmVendorFlairFormHelper.copyFlair(vm.flairData);

        vm.levelStrings = Flair.LEVEL_STRINGS;
        vm.priorityStrings = Flair.PRIORITY_STRINGS;
        vm.sizeStrings = Flair.SIZE_STRINGS;
        vm.statusStrings = Flair.STATUS_STRINGS;
        vm.topicStrings = Flair.TOPIC;

        vm.canEditStatus = false;
        vm.showSaveButton = !vm.autoSave;

        // show date format if the browser doesn't support the date input
        vm.showDateFormat =
            !Modernizr || !Modernizr.inputtypes || !Modernizr.inputtypes.date;

        vm.validUrlRegex = /^(\w+:\/\/|communities\/\w+)/;

        function autoUpdate(flairCreateForm) {
            // if we have a form, make sure it is valid before sending the update
            if (
                vm.onUpdate &&
                vm.autoSave &&
                (!flairCreateForm || flairCreateForm.$valid)
            ) {
                return vm.onUpdate({ edits: vm.flairEdit });
            }
        }

        function buttonClickCancel(flairCreateForm) {
            if (flairCreateForm) {
                flairCreateForm.$setPristine();
                flairCreateForm.$setUntouched();
            }

            vm.flairEdit = ssmVendorFlairFormHelper.copyFlair(vm.flairData);
            return vm.onCancel();
        }

        function buttonClickSubmit(flairCreateForm) {
            $scope.$broadcast("show-errors-check-validity"); // show errors will not validate on submit automatically

            if (vm.onUpdate && flairCreateForm && flairCreateForm.$valid) {
                return vm.onUpdate({ edits: vm.flairEdit });
            }
        }
    }

    function ssmVendorFlairFormHelper() {
        return {
            copyFlair: copyFlair
        };

        // don't use angular copy because we don't want all properties
        function copyFlair(original) {
            if (!original) {
                return null;
            }

            return {
                destination_url: original.destination_url,
                image_url: original.image_url,
                file_id: original.file_id,
                level: original.level,
                priority: original.priority,
                size: original.size,
                status: original.status,
                topic: original.topic,
                start_date: original.start_date
                    ? new Date(original.start_date)
                    : null,
                end_date: original.end_date ? new Date(original.end_date) : null
            };
        }
    }
})();
