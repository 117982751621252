(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.layout").directive("ssoSignup", sso_signup);

    function sso_signup() {
        return {
            templateUrl: "layout/sso-signup/sso-signup.html",
            restrict: "E",
            controller: SsoSignupCtrl,
            replace: true,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function SsoSignupCtrl(
        CurrentUser, 
        Tenant
    ) {
        var vm = this;

        vm.memberType;
        vm.button_object;

        activate();

        function activate() {
            setButtonUrl();  
            determineMembershipOfCurrentUser();
        }

        function setButtonUrl() {
            Tenant.getTenant().then(function(tenant) {
                vm.button_object = tenant.sso_button;
            });
        }

        // TODO: this exists solely for the apa sso, there's two types of guest accounts that interact with the site with different user id's, needs refactoring
        function determineMembershipOfCurrentUser() {
            var memberType = "Member";
            if (CurrentUser.profile.id == "5fca5fc58b9f7c1703844f1d") {
                memberType = "Guest";
            }
            vm.memberType = memberType;
        }

    }
})();

