(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.vendorpermissions.service", [
            "ssmAngularApp.currentUser"
        ])
        .factory("VendorPermissions", VendorPermissions);

    function VendorPermissions(CurrentUser, Vendor, $q) {
        return {
            canActivateVendor: canActivateVendor,
            canAddVendor: canAddVendor,
            canAddVendorAdmin: canAddVendorAdmin,
            canDeleteVendor: canDeleteVendor,
            canDeleteVendorAdmin: canDeleteVendorAdmin,
            canEditVendor: canEditVendor

            // canViewVendor is missing because there is no real front-end scenario that could check that
        };

        function canActivateVendor(vendor_id) {
            // if we don't have a vendor_id, fall back to checking is_vendor_manager until we get a better pattern in place
            if (!vendor_id) {
                return $q.when(CurrentUser.profile.is_vendor_manager);
            }

            return Vendor.getVendor(vendor_id).then(function(vendor) {
                return vendor && vendor.permissions
                    ? vendor.permissions.canActivateVendor
                    : false;
            });
        }

        function canAddVendor() {
            return $q.when(CurrentUser.profile.is_vendor_manager);
        }

        function canAddVendorAdmin(vendor_id) {
            return Vendor.getVendor(vendor_id).then(function(vendor) {
                return vendor && vendor.permissions
                    ? vendor.permissions.canAddVendorAdmin
                    : false;
            });
        }

        function canDeleteVendor() {
            return $q.when(CurrentUser.profile.is_vendor_manager);
        }

        function canDeleteVendorAdmin(vendor_id) {
            return Vendor.getVendor(vendor_id).then(function(vendor) {
                return vendor && vendor.permissions
                    ? vendor.permissions.canDeleteVendorAdmin
                    : false;
            });
        }

        function canEditVendor(vendor_id) {
            return Vendor.getVendor(vendor_id).then(function(vendor) {
                return vendor && vendor.permissions
                    ? vendor.permissions.canEditVendor
                    : false;
            });
        }
    }
})();
