(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsEditAdminsController", OrgsEditAdminsController);

    function OrgsEditAdminsController(
        $translate,
        orgProfileResolve,
        orgAdminsResolve,
        Logger,
        Organization,
        SSMTitle
    ) {
        var vm = this;
        vm.org = orgProfileResolve;
        vm.formDirty = false;

        vm.saveForm = saveForm;
        vm.resetForm = resetForm;
        vm.updateAdmin = updateAdmin;

        activate();

        function activate() {
            $translate("ORGANIZATION.EDIT.ADMINS.TITLE").then(
                SSMTitle.setTitle
            );

            var members = _.map(orgAdminsResolve || [], function(user) {
                return { user: user, id: user.id };
            });
            vm.admins = {
                members: members
            };
        }

        function saveForm() {
            var user_ids = _.map(vm.admins.members, "id");
            Organization.updateAdmins(vm.org.id, { user_ids: user_ids })
                .then(function(results) {
                    vm.formDirty = false;
                    vm.flashSavedFlag();
                    if (
                        results &&
                        results.length !== vm.admins.members.length
                    ) {
                        $translate("ORGANIZATION.EDIT.ERROR.BAD_ADMINS").then(
                            function(translation) {
                                Logger.error("Error: ", translation);
                            }
                        );

                        var members = _.map(results || [], function(user) {
                            return { user: user, id: user.id };
                        });

                        vm.admins = { members: members };
                    }
                })
                .catch(function(error) {
                    switch (error.status) {
                        case 403: //forbidden
                            $translate(
                                "ORGANIZATION.EDIT.ERROR.FORBIDDEN"
                            ).then(function(translation) {
                                Logger.error("Error: ", translation);
                            });
                            break;
                        default:
                            $translate("ORGANIZATION.EDIT.ERROR.DEFAULT").then(
                                function(translation) {
                                    Logger.error("Error: ", translation);
                                }
                            );
                            break;
                    }
                });
        }

        function resetForm() {
            vm.formDirty = false;

            Organization.getAdmins(vm.org.id)
                .then(function(results) {
                    var members = _.map(results || [], function(user) {
                        return { user: user, id: user.id };
                    });

                    vm.admins = { members: members };
                })
                .catch(function(error) {
                    $translate("ORGANIZATION.EDIT.ERROR.DEFAULT").then(function(
                        translation
                    ) {
                        Logger.error("Error: ", translation);
                    });
                });
        }

        function updateAdmin(user) {
            if (!user) {
                return;
            }

            vm.formDirty = true;
        }
    }
})();
