(function() {
    "use strict";

    angular.module("ssmAngularApp.modal", [
        "ssmAngularApp.createResponse",
        "ssmAngularApp.createContent",
        "ssmAngularApp.sharePostModal",
        "ssmAngularApp.guestRegistration",
        "ssmAngularApp.insertBlogImageModal",
        "ssmAngularApp.areYouSureModal",
        "ssmAngularApp.viewLikesModal",
        "ssmAngularApp.marketplaceContact",
        "ssmAngularApp.textBoxModal",
        "ssmAngularApp.editFileModal"
    ]);
})();
