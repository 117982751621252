(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("chimeSso", {
            url: "/chime-sso",
            controller: "ChimeSsoCtrl",
            controllerAs: "vm"
        });
    });
})();
