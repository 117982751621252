(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmContentEditable", ["ngSanitize"])
        .directive("contenteditable", ssmContentEditable);

    /*
    By default, contenteditable divs not not propagate changes to ngmodels. This
    directive makes sure they do, when an ngmodel exists on one.
     */
    function ssmContentEditable($sce, $timeout) {
        return {
            restrict: "A",
            require: "?ngModel",
            link: function(scope, element, attrs, ngModel) {
                function read() {
                    var html = element.html();
                    if (attrs.stripBr && html === "<br>") {
                        html = "";
                    }
                    ngModel.$setViewValue(html);
                }

                if (!ngModel) {
                    return;
                }

                ngModel.$render = function() {
                    if (ngModel.$viewValue !== element.html()) {
                        element.html(
                            $sce.getTrustedHtml(ngModel.$viewValue || "")
                        );
                    }
                };

                element.on("blur keyup change", function() {
                    $timeout(function() {
                        scope.$apply(read);
                    });
                });

                $timeout(function() {
                    read();
                });
            }
        };
    }
})();
