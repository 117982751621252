(function () {
    "use strict";

    angular
        .module("ssmAngularApp.communityPrivate")
        .controller("CommunityPrivateCtrl", CommunityPrivateCtrl);

    function CommunityPrivateCtrl(
        communityProfileResolveNoAuth,
        Community,
        CommunityMembership,
        CurrentUser,
        $state,
        $translate,
        Logger
    ) {
        var vm = this;
        vm.communityInfo = communityProfileResolveNoAuth;
        vm.sendRequest = sendRequest;
        vm.isRequestSent = isRequestSent;
        vm.currentRequestSent = false;
        vm.isLoading = true;
        vm.communitiesPending = [];
        vm.communitiesFollowed = [];
        vm.description = vm.communityInfo.private_description_override
            ? vm.communityInfo.private_description_override
            : vm.communityInfo.description;
        vm.buttonText = "";

        activate();

        function activate() {
            if (vm.communityInfo.private_button_text)
                vm.buttonText = vm.communityInfo.private_button_text;
            else
                $translate("COMMUNITY_PRIVATE.REQUEST").then(function (
                    translation
                ) {
                    vm.buttonText = translation;
                });
            CommunityMembership.getPendingFollows()
                .then(
                    function (data) {
                        _.forEach(data, function (communitySet) {
                            vm.communitiesPending = vm.communitiesPending.concat(
                                communitySet.communities
                            );
                        });
                    },
                    function (error) {
                        Logger.errorWithoutAlert(
                            "Error getting communities.",
                            error
                        );
                    }
                )
                .then(
                    CommunityMembership.getAll().then(
                        function (data) {
                            _.forEach(data, function (communitySet) {
                                vm.communitiesFollowed = vm.communitiesFollowed.concat(
                                    communitySet.communities
                                );
                            });
                            vm.isLoading = false;
                        },
                        function (error) {
                            Logger.errorWithoutAlert(
                                "Error getting communities.",
                                error
                            );
                        }
                    )
                );
        }

        function isRequestSent() {
            if (vm.currentRequestSent || vm.isLoading) {
                return true;
            } else {
                vm.allCommunityMemberships = _.concat(
                    vm.communitiesPending,
                    vm.communitiesFollowed
                );

                return _.find(vm.allCommunityMemberships, function (community) {
                    return community.id === vm.communityInfo.id;
                });
            }
        }

        function sendRequest() {
            if (vm.communityInfo.private_link_override) {
                window.open(vm.communityInfo.private_link_override);
                return true;
            }
            var hasPendingRequest = _.find(vm.communitiesPending, function (
                community
            ) {
                return community.id === vm.communityInfo.id;
            });

            if (vm.communitiesPending.length == 0 || !hasPendingRequest) {
                CommunityMembership.createMembership(
                    vm.communityInfo.id,
                    false,
                    false,
                    true
                )
                    .then(() => {
                        // Creates pending follows
                        Community.requestCommunityAccess(
                            vm.communityInfo.id,
                            CurrentUser.profile.id
                        );
                        vm.currentRequestSent = true;
                    })
                    .then(onEmailSuccess)
                    .catch(onEmailFailed);
            }
        }

        function onEmailSuccess(success) {
            $translate("COMMUNITY_PRIVATE.RQUEST_SUCCESS").then(function (
                translation
            ) {
                Logger.success(null, translation, null, 5000);
                $state.go("main.communities_index");
            });
        }

        function onEmailFailed(error) {
            vm.errorText = "";
            $translate("COMMUNITY_PRIVATE.REQUEST_FAILURE").then(function (
                translation
            ) {
                Logger.error(null, translation, error, 5000);
            });
        }
    }
})();
