(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmWebNotification", [])
        .factory("ssmWebNotification", ssmWebNotification);

    function ssmWebNotification(
        $notification,
        $window,
        SSM_WEB_NOTIFICATIONS,
        ssmLocalStorage
    ) {
        var isSupported;
        var actualPermission;

        init();

        function init() {
            isSupported = !!$window.Notification;
            askPermission();
        }

        function askPermission() {
            return $notification.requestPermission().then(function(permission) {
                readPermission();
            });
        }

        function create(title, options, clickCallback, showCallback) {
            var userBlocked = ssmLocalStorage.get(
                SSM_WEB_NOTIFICATIONS.LOCAL_STORAGE_KEY
            );
            if (userBlocked || !options.body) {
                return {};
            }

            window.navigator.vibrate(200, 200);

            var notificationInstance = new $window.Notification(title, options);

            notificationInstance.onclick = function() {
                notificationInstance.close();
                if (clickCallback) {
                    clickCallback();
                }
            };

            notificationInstance.onshow = function() {
                if (showCallback) {
                    showCallback();
                }
            };

            return notificationInstance;
        }

        function disable() {
            ssmLocalStorage.set(SSM_WEB_NOTIFICATIONS.LOCAL_STORAGE_KEY, true);
            readPermission();
        }

        function enable() {
            ssmLocalStorage.remove(SSM_WEB_NOTIFICATIONS.LOCAL_STORAGE_KEY);
            askPermission();
        }

        function readPermission() {
            var userBlocked = ssmLocalStorage.get(
                SSM_WEB_NOTIFICATIONS.LOCAL_STORAGE_KEY
            );
            if (userBlocked) {
                actualPermission = SSM_WEB_NOTIFICATIONS.LOCAL_STORAGE_KEY;
                return actualPermission;
            } else {
                actualPermission = !isSupported
                    ? undefined
                    : $window.Notification.permission;
                return actualPermission;
            }
        }

        return {
            askPermission: askPermission,
            create: create,
            disable: disable,
            enable: enable,
            isSupported: isSupported,
            readPermission: readPermission
        };
    }
})();
