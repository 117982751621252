(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.tutorial.service")
        .factory("Tutorial", Tutorial);
    /*
        This Tutorial is meant to explain to a new user some key information about the site:
        (1) Where and how to edit user profile information
        (2) How posting works and that the goal of the site is for users to post in communities, NOT their main wall

        TODO: The UI should be re-designed to make (1) and (2) more clear and intuitive, removing the need for a Tutorial
    */
    function Tutorial(CurrentUser, ngIntroService, Tenant, $state, $timeout) {
        var userTutorialStatus = CurrentUser.profile.tutorial_status;
        var baseOptions = {
            overlayOpacity: 0.7,
            showStepNumbers: false,
            exitOnOverlayClick: false
        };

        function getTutorialStatus(statusString) {
            switch (statusString) {
                case "NOT_STARTED":
                    return 1;
                case "STEP_EIGHT":
                    return 2;
                case "STEP_TEN":
                    return 3;
                case "COMPLETE":
                    return 4;
                default:
                    return 1;
            }
        }

        function tutorialEarlySteps(tutorialStatus) {
            return (
                !tutorialStatus ||
                tutorialStatus == getTutorialStatus("NOT_STARTED")
            );
        }

        function setupTutorialStep(customOptions) {
            var tutorialOptions = Object.assign(baseOptions, customOptions);
            // Clear out existing tutorial windows (if any)
            ngIntroService.clear();
            ngIntroService.setOptions(tutorialOptions);

            // TODO: Get better method for waiting for DOM elements to load besides $timeout
            $timeout(() => ngIntroService.start(), 2500);
            skipTutorialOption();
        }

        function skipTutorialOption() {
            ngIntroService.onComplete(function() {
                var update = {
                    tutorial_status: 4
                };
                CurrentUser.profile.tutorial_status = getTutorialStatus(
                    "COMPLETE"
                );
                userTutorialStatus = 4;
                CurrentUser.update(update);
            });
        }

        return {
            openStep1: function() {
                if (tutorialEarlySteps(userTutorialStatus)) {
                    var styleString =
                        '"display: block;margin-left: auto;margin-right: auto;"';
                    var introString =
                        "Welcome to the smartCONNECT platform.<br/>" +
                        "To get started, click on your PROFILE icon on the far-right side of the screen and select MY PROFILE." +
                        "<img style=" +
                        styleString +
                        'src="../../assets/images/Step1TutorialImage.png" width="75%" height="75%">';
                    var customOptions = {
                        doneLabel: "Skip Tutorial",
                        showBullets: false,
                        steps: [
                            {
                                element: "#tutorialStep1",
                                intro: introString
                            }
                        ]
                    };
                    setupTutorialStep(customOptions);
                }
            },

            openStep2: function() {
                if (tutorialEarlySteps(userTutorialStatus)) {
                    var introString =
                        "Click the EDIT button to update your profile information.<br/>" +
                        "This information is visible to other users on the platform when they navigate to your profile page.";
                    var customOptions = {
                        doneLabel: "Skip Tutorial",
                        showBullets: false,
                        steps: [
                            {
                                element: "#tutorialStep2",
                                intro: introString
                            }
                        ]
                    };
                    setupTutorialStep(customOptions);
                }
            },

            openStep3to7: function() {
                if (tutorialEarlySteps(userTutorialStatus)) {
                    var introStringStep5 =
                        "Adding a profile picture to your account is an easy way to add a personal touch to your content. " +
                        "We encourage you to use a photo of yourself so other platform participants know who you are.<br/>" +
                        "Click here to add your PROFILE PICTURE.";
                    var introStringStep7 =
                        "Now that your profile information is correct, its time to start connecting and collaborating.<br/>" +
                        "Click the button below to navigate back to your home wall.<br/>" +
                        "You can always click the logo on the upper-left hand corner as well." +
                        '<img src="../../assets/images/Step7TutorialImage.png" width="100%" height="100%">';

                    var customOptions = {
                        doneLabel: "To Homepage!",
                        scrollToElement: true,
                        skipLabel: "Skip",
                        steps: [
                            {
                                element: "#tutorialStep3",
                                intro: "Make changes here to your First Name.",
                                position: "top"
                            },
                            {
                                element: "#tutorialStep4",
                                intro: "Make changes here to your Last Name.",
                                position: "top"
                            },
                            {
                                element: "#tutorialStep5",
                                intro: introStringStep5,
                                position: "top"
                            },
                            {
                                element: "#tutorialStep6",
                                intro:
                                    "Once you have finished updating your profile, be sure to SAVE CHANGES.",
                                position: "top"
                            },
                            {
                                element: "#tutorialStep7",
                                intro:
                                    "You can get a preview of what your profile looks like here, and the email that is tied to your account."
                            },
                            {
                                element: "#tutorialStep7",
                                intro: introStringStep7
                            }
                        ]
                    };
                    ngIntroService.clear();
                    var tutorialOptions = Object.assign(
                        baseOptions,
                        customOptions
                    );
                    ngIntroService.setOptions(tutorialOptions);
                    $timeout(() => ngIntroService.start(), 2500);
                    ngIntroService.onComplete(function() {
                        var update = {
                            tutorial_status: 2
                        };
                        CurrentUser.profile.tutorial_status = getTutorialStatus(
                            "STEP_EIGHT"
                        );
                        userTutorialStatus = 2;
                        CurrentUser.update(update);
                        $state.go("main.home", {}, { reload: true });
                    });
                }
            },

            openStep8to9: function() {
                if (userTutorialStatus == getTutorialStatus("STEP_EIGHT")) {
                    var introStringStep8 =
                        "Its time to start engaging on the platform. " +
                        "Start a post here from the main share box to share a post with all your personal connections.<br/>" +
                        "Want to reach a bigger audience?";
                    var introStringStep9 =
                        "If your post is geared towards a larger audience on the platform, " +
                        "be sure to make that post in a COMMUNITY so that all the community followers see it.<br/>" +
                        "Access the COMMUNITY DIRECTORY application to browse the communities that are available to you. ";
                    var customOptions = {
                        doneLabel: "Skip Tutorial",
                        steps: [
                            {
                                element: "#tutorialStep8",
                                position: "right",
                                intro: introStringStep8
                            },
                            {
                                element: "#tutorialStep9",
                                position: "right",
                                intro: introStringStep9
                            }
                        ]
                    };
                    ngIntroService.clear();
                    var tutorialOptions = Object.assign(
                        baseOptions,
                        customOptions
                    );
                    ngIntroService.setOptions(tutorialOptions);
                    $timeout(() => ngIntroService.start(), 2500);
                    var update = {
                        tutorial_status: 3
                    };
                    CurrentUser.profile.tutorial_status = getTutorialStatus(
                        "STEP_TEN"
                    );
                    userTutorialStatus = 3;
                    CurrentUser.update(update);
                }
            },

            openStep10: function() {
                if (userTutorialStatus == getTutorialStatus("STEP_TEN")) {
                    var introString =
                        "As your browse the COMMUNITY DIRECTORY, be sure to FOLLOW all communities with topics that are relevant to you.<br/>" +
                        "Opt into a community by selecting the FOLLOW button. " +
                        '<img src="../../assets/images/Step10TutorialImage.png" width="100%" height="100%">';
                    var customOptions = {
                        doneLabel: "Got it!",
                        showBullets: false,
                        steps: [
                            {
                                element: "#tutorialStep10",
                                intro: introString
                            }
                        ]
                    };
                    var tutorialOptions = Object.assign(
                        baseOptions,
                        customOptions
                    );
                    // Clear out existing tutorial windows (if any)
                    ngIntroService.clear();
                    ngIntroService.setOptions(tutorialOptions);

                    // TODO: Get better method for waiting for DOM elements to load besides $timeout
                    $timeout(() => ngIntroService.start(), 2500);
                }
            },

            openStep11to13: function() {
                if (userTutorialStatus == getTutorialStatus("STEP_TEN")) {
                    var introStringStep11 =
                        "When you select to FOLLOW a community, the status will change to FOLLOWING so you know what communities you are a part of. " +
                        "Posts made in communities you are following will be automatically populated on your personal wall and are visible to all community members. ";
                    var introStringStep12 =
                        "Looking for a digital resource related to this community? " +
                        "Each community has a dedicated FILE LIBRARY to make finding a wide range of documents shared with this select group of users simple and convenient. ";
                    var introStringStep13 =
                        "Ensure that your post is seen by a larger audience by posting to a COMMUNITY WALL. " +
                        "Posts made in communities you are following will be automatically populated on your personal wall and are visible to all community members. ";
                    var customOptions = {
                        skipLabel: "Skip",
                        disableInteraction: true,
                        steps: [
                            {
                                element: "#tutorialStep11",
                                intro: introStringStep11
                            },
                            {
                                element: "#tutorialStep12",
                                intro: introStringStep12
                            },
                            {
                                element: "#tutorialStep13",
                                position: "right",
                                intro: introStringStep13
                            }
                        ]
                    };
                    setupTutorialStep(customOptions);
                }
            },

            exit: function() {
                ngIntroService.exit();
            }
        };
    }
})();
