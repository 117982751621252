(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.ssmFolders.service")
        .factory("ssmFolders", ssmFolders);

    function ssmFolders(Api) {
        var foldersApi = Api.all("folders");

        var service = {
            create: create,
            update: update
        };

        return service;

        function create(folderToCreate) {
            return foldersApi.post(folderToCreate).then(function(response) {
                return response.plain();
            });
        }

        function update(folderId, folderToUpdate) {
            return foldersApi
                .one(folderId)
                .patch(folderToUpdate)
                .then(function(response) {
                    return response.plain();
                });
        }
    }
})();
