(function() {
    "use strict";

    angular
        .module("ssmAngularApp.communityJoin")
        .directive("communityJoin", communityJoin);

    function communityJoin() {
        return {
            templateUrl: "components/communityJoin/communityJoin.html",
            restrict: "E",
            replace: true,
            scope: {
                communityData: "=",
                gearBox: "@"
            },
            controller: communityJoinCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function communityJoinCtrl(
        $state,
        $translate,
        Analytics,
        Community,
        CommunityMembership,
        CurrentUser,
        Logger
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.showPending = $state.params.pending === "true";
        vm.endMembership = endMembership;
        vm.createMembership = createMembership;
        vm.showInFeed = showInFeed;
        vm.getNotifications = getNotifications;
        vm.isMember =
            vm.communityData.currentUserFollowStatus ===
            CommunityMembership.MEMBERSHIP_TYPE.MEMBER;
        vm.canJoin = vm.communityData.has_access;
        const isGuest = CurrentUser.profile.is_guest;
        vm.showControls =
            vm.communityData.status === Community.STATUS.ACTIVE ||
            vm.communityData.status === Community.STATUS.ARCHIVED;
        vm.showControls = vm.showControls && !isGuest;
        vm.showGearBox = !!vm.gearBox;
        vm.canEdit = false;

        activate();

        function activate() {
            vm.btnWidth = vm.showGearBox ? "132px" : "100%";

            vm.currentUserIsAdmin = !!_.find(vm.communityData.admins, function(
                admin
            ) {
                return admin === CurrentUser.profile.id;
            });
            vm.canEdit =
                vm.currentUserIsAdmin &&
                vm.communityData.status === Community.STATUS.ACTIVE;
        }

        function endMembership() {
            Analytics.eventTrack("unfollowCommunity", {
                category: Analytics.determineAnalyticsCategory(),
                label: "id:" + vm.communityData.id,
                dimension4: vm.communityData.id
            });
            Analytics.eventTrack2('unfollow_community', {
                category: Analytics.determineAnalyticsCategory(),
                label: "id:" + vm.communityData.id,
                dimension4: vm.communityData.id,
            });

            CommunityMembership.endMembership(vm.communityData.id)
                .then(function(result) {
                    if (result && result.status !== undefined) {
                        vm.communityData.currentUserFollowStatus =
                            result.status;
                    } else {
                        delete vm.communityData.currentUserFollowStatus;
                    }
                    vm.isMember =
                        vm.communityData.currentUserFollowStatus ===
                        CommunityMembership.MEMBERSHIP_TYPE.MEMBER;
                })
                .catch(function(error) {
                    Logger.errorWithoutAlert(
                        "Error trying to end community membership.",
                        error
                    );
                });
        }

        function createMembership() {
            Analytics.eventTrack("followCommunity", {
                category: Analytics.determineAnalyticsCategory(),
                label: "id:" + vm.communityData.id,
                dimension4: vm.communityData.id
            });
            Analytics.eventTrack2('follow_community', {
                category: Analytics.determineAnalyticsCategory(),
                label: "id:" + vm.communityData.id,
                dimension4: vm.communityData.id
            });

            updateFollows(null, null);
        }

        function showInFeed(hide_in_feed) {
            updateFollows(hide_in_feed, null);
        }

        function getNotifications(disable_notifications) {
            updateFollows(null, disable_notifications);
        }

        function updateFollows(hide_in_feed, disable_notifications) {
            vm.undoCurrentUserHideInFeed =
                vm.communityData.currentUserHideInFeed;
            vm.undoCurrentUserDisableNotifications =
                vm.communityData.currentUserDisableNotifications;
            vm.communityData.currentUserDisableNotifications =
                disable_notifications !== null
                    ? disable_notifications
                    : vm.communityData.currentUserDisableNotifications;
            vm.communityData.currentUserHideInFeed =
                hide_in_feed !== null
                    ? hide_in_feed
                    : vm.communityData.currentUserHideInFeed;
            CommunityMembership.createMembership(
                vm.communityData.id,
                hide_in_feed,
                disable_notifications
            )
                .then(function(result) {
                    if (result && result.status !== undefined) {
                        vm.communityData.currentUserFollowStatus =
                            result.status;
                        vm.communityData.currentUserHideInFeed =
                            result.hide_in_feed;
                        vm.communityData.currentUserDisableNotifications =
                            result.disable_notifications;
                        vm.isMember =
                            vm.communityData.currentUserFollowStatus ===
                            CommunityMembership.MEMBERSHIP_TYPE.MEMBER;
                    }
                })
                .catch(function(error) {
                    vm.communityData.currentUserHideInFeed =
                        vm.undoCurrentUserHideInFeed;
                    vm.communityData.currentUserDisableNotifications =
                        vm.undoCurrentUserDisableNotifications;
                    $translate("PROFILE.ERROR.DEFAULT").then(function(
                        translation
                    ) {
                        Logger.error("Error: ", translation);
                    });
                });
        }
    }
})();
