(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ip.service", [
            "ssmAngularApp.ssmLocalStorage",
            "ssmAngularApp.logger"
        ])
        .service("ipService", ipService);

    function ipService($http, $q, ssmLocalStorage, Logger) {
        const IP_ADDRESS = ssmLocalStorage.key.IP_ADDRESS;
        const GUEST_TOKEN = ssmLocalStorage.key.GUEST_TOKEN;
        const IPINFO_API = "https://ipinfo.io/json";

        return {
            getIpAddress: function() {
                const payload = {};
                payload.ip = ssmLocalStorage.get(IP_ADDRESS);
                payload.guest_token = ssmLocalStorage.get(GUEST_TOKEN);
                if (!payload.ip || !payload.guest_token) {
                    return fetchIpAddress();
                }
                return $q.resolve(payload);
            }
        };

        function fetchIpAddress() {
            return $http
                .get(IPINFO_API)
                .then(response => storeIpAddress(response.data.ip))
                .catch(() => {
                    Logger.warningWithoutAlert("Failed to retrieve ip info");
                    return storeIpAddress();
                });
        }

        function storeIpAddress(ipAddress) {
            const payload = {};
            payload.ip = ipAddress;
            if (uuid.v1) {
                payload.guest_token = uuid.v1();
            }
            ssmLocalStorage.set(IP_ADDRESS, ipAddress);
            ssmLocalStorage.set(GUEST_TOKEN, payload.guest_token);
            return payload;
        }
    }
})();
