(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("InviteAcceptedController", InviteAcceptedController);

    function InviteAcceptedController(acceptedPagerResolve) {
        var vm = this;
        vm.acceptedPager = acceptedPagerResolve;

        activate();

        function activate() {}
    }
})();
