(function() {
    "use strict";

    angular
        .module("ssmAngularApp.editButton", [])
        .directive("editButton", editButton);

    function editButton() {
        return {
            templateUrl: "components/editButton/editButton.html",
            restrict: "E",
            replace: true
        };
    }
})();
