(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.content").factory("ContentApi", ContentApi);

    function ContentApi(Api) {
        var contentApi = Api.all("articles");
        var feedApi = Api.all("feed");
        return {
            all: function(options) {
                return contentApi.getList(options).then(function(response) {
                    return response.plain();
                });
            },
            findByPopularity: function(options) {
                return contentApi
                    .one("popular")
                    .get(options)
                    .then(function(response) {
                        return response.plain();
                    });
            },
            findById: function(content_id, options) {
                return contentApi
                    .one(content_id)
                    .get(options)
                    .then(function(response) {
                        return response.plain();
                    });
            },
            create: function(content) {
                return contentApi.post(content).then(function(response) {
                    return response.plain();
                });
            },
            update: function(content_id, updatedContent) {
                return contentApi
                    .one(content_id)
                    .patch(updatedContent)
                    .then(function(response) {
                        return response.plain();
                    });
            },
            remove: function(content_id) {
                return contentApi
                    .one(content_id)
                    .remove()
                    .then(function(response) {
                        return response.plain();
                    });
            },
            createChildContent: function(content_id, answer) {
                return contentApi
                    .one(content_id)
                    .all("children")
                    .post(answer)
                    .then(function(response) {
                        return response.plain();
                    });
            },
            voteForContent: function(content_id, vote) {
                return contentApi
                    .one(content_id)
                    .all("votes")
                    .post(vote)
                    .then(function(response) {
                        return response.plain();
                    });
            },
            removeVoteForContent: function(content_id) {
                return contentApi
                    .one(content_id)
                    .all("votes")
                    .remove()
                    .then(function(response) {
                        return response.plain();
                    });
            },
            createFeed: function(params) {
                return feedApi.post(params);
            }
        };
    }
})();
