(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmCommunitySettingsForm")
        .directive("ssmCommunitySettingsForm", ssmCommunitySettingsForm)
        .controller(
            "ssmCommunitySettingsFormController",
            ssmCommunitySettingsFormController
        )
        .factory(
            "ssmCommunitySettingsFormHelper",
            ssmCommunitySettingsFormHelper
        );

    function ssmCommunitySettingsForm() {
        return {
            templateUrl:
                "components/ssmCommunitySettingsForm/ssmCommunitySettingsForm.html",
            restrict: "E",
            scope: {
                communityForm: "="
            },
            controller: ssmCommunitySettingsFormController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmCommunitySettingsFormController(
        $scope,
        $timeout,
        $state,
        $translate,
        Community,
        CurrentUser,
        Analytics,
        WizardHandler,
        Logger,
        Tenant,
        ssmCommunitySettingsFormHelper
    ) {
        /*jshint validthis:true */
        var vm = this;
        vm.currentUser = CurrentUser.profile;
        var communityProfileTemplate = {
            admins: [],
            is_private: false,
            show_in_directory: true,
            show_members_to_members: true,
            has_file_library: true,
            link_slack: false,
            posting_permissions: 0,
            hash_tags: []
        };

        vm.administrators = {
            memberType: "admin",
            members: []
        };
        vm.followers = {
            memberType: "follower",
            members: [],
            followerData: {
                added_followers: [],
                removed_followers: []
            }
        };
        vm.editedProfile = angular.copy(communityProfileTemplate);
        vm.wizardCancel = wizardCancel;
        vm.wizardNext = wizardNext;
        vm.finishedWizard = finishedWizard;
        vm.isEditState = $state.current.name === "main.communities_edit";
        vm.isCreateState = $state.current.name === "main.communities_create";
        vm.photo = [];
        // Disable the finish button after someone clicks while waiting for the server response, so it can't be clicked multiple times.
        vm.waitingFinishResponse = false;

        activate();

        function activate() {
            if (vm.isCreateState) {
                // @todo SSM-1085 allows chime users to choose if they want to create a Chime or NWC community.
                // At V2 launch someone who signed in through Chime always creates a Chime community.
                Tenant.getTenant().then(function(tenant) {
                    if (tenant.organization_id) {
                        vm.editedProfile.organization_id =
                            tenant.organization_id;
                    }
                });
            }

            if (vm.isEditState) {
                if (!(vm.communityForm && vm.communityForm.id)) {
                    $translate("COMMUNITY_CREATION.EDIT_ERROR").then(function(
                        translation
                    ) {
                        Logger.error("ERROR: ", translation);
                    });
                    $state.go("main.home");
                    return;
                }
                vm.editedProfile = ssmCommunitySettingsFormHelper.getEditableCommunityProperties(
                    vm.communityForm
                );
                if (vm.communityForm.photo) {
                    vm.photo[0] = vm.communityForm.photo;
                }

                ssmCommunitySettingsFormHelper
                    .getAdmins(vm.communityForm.id)
                    .then(function(admins) {
                        vm.administrators.members = admins;
                    });
            }
        }

        // If editing a community return to that community's page on cancel, otherwise return to your wall.
        function wizardCancel() {
            return vm.isEditState
                ? $state.go("main.communities_show", {
                      id: vm.communityForm.id,
                      url_name: vm.communityForm.url_name
                  })
                : $state.go("main.home");
        }

        function wizardNext(form) {
            $scope.$broadcast("show-errors-check-validity");
            // Some controls we use start in secondary pages, and wait for the next "apply" to update themselves. This
            // gives them a push when the next page appears.
            $timeout(function() {
                $scope.$apply();
            });
            if (form.$valid) {
                WizardHandler.wizard("communitySettings").next();
            }
        }

        function finishedWizard() {
            if (vm.administrators.members.length < 1) {
                return;
            }
            vm.waitingFinishResponse = true;

            vm.editedProfile.admins = [];
            // Only the user ids get copied into the community properties not the users names.
            vm.editedProfile.admins = _.map(vm.administrators.members, function(
                member
            ) {
                return member.id;
            });

            if (vm.isEditState) {
                updateCommunity();
            }

            if (vm.isCreateState) {
                createCommunity();
            }
        }

        function updateCommunity() {
            Community.updateCommunityAccessList(
                vm.communityForm.id,
                vm.followers.followerData
            )
                .then(function() {
                    Community.update(vm.communityForm.id, vm.editedProfile)
                        .then(function(result) {
                            Analytics.eventTrack("finishedWizard", {
                                category: "CommunityEdit",
                                label: "community_id:" + result.id
                            });
                            Analytics.eventTrack2('finished_wizard_edit', {
                                category: "CommunityEdit",
                                label: "community_id:" + result.id
                            });
                            $state.go("main.communities_show", {
                                id: result.id,
                                url_name: result.url_name
                            });
                        })
                        .catch(function(error) {
                            vm.waitingFinishResponse = false;
                            $translate("COMMUNITY_CREATION.EDIT_ERROR").then(
                                function(translation) {
                                    Logger.error("ERROR: ", translation, error);
                                }
                            );
                        });
                })
                .catch(function(error) {
                    vm.waitingFinishResponse = false;
                    $translate("COMMUNITY_CREATION.FOLLOWER_ERROR").then(
                        function(translation) {
                            Logger.error("ERROR: ", translation, error);
                        }
                    );
                });
        }

        function createCommunity() {
            Community.create(vm.editedProfile)
                .then(function(result) {
                    Analytics.eventTrack("finishedWizard", {
                        category: "CommunityCreation",
                        label: "community_id:" + result.id
                    });
                    Analytics.eventTrack2('finished_wizard_create', {
                        category: "CommunityCreation",
                        label: "community_id:" + result.id
                    });
                    Community.updateCommunityAccessList(
                        result.id,
                        vm.followers.followerData
                    )
                        .catch(function(error) {
                            $translate(
                                "COMMUNITY_CREATION.FOLLOWER_ERROR"
                            ).then(function(translation) {
                                Logger.error("ERROR: ", translation, error);
                            });
                        })
                        .finally(function() {
                            $state.go("main.communities_show", {
                                id: result.id,
                                url_name: result.url_name
                            });
                        });
                })
                .catch(function(error) {
                    vm.waitingFinishResponse = false;
                    $translate("COMMUNITY_CREATION.CREATE_ERROR").then(function(
                        translation
                    ) {
                        Logger.error("ERROR: ", translation, error);
                    });
                });
        }
    }

    /* @ngInject */
    function ssmCommunitySettingsFormHelper($q, Community, Logger) {
        return {
            getEditableCommunityProperties: getEditableCommunityProperties,
            getAdmins: getAdmins
        };

        function getEditableCommunityProperties(unEditedCommunity) {
            var editedProfile = {};

            if (!unEditedCommunity) {
                return editedProfile;
            }

            // create a copy profile object into the form that contains only the editable fields (i.e. no id or create_date properties)
            editedProfile.name = unEditedCommunity.name;
            editedProfile.description = unEditedCommunity.description;
            editedProfile.photo_id = unEditedCommunity.photo_id;
            editedProfile.show_in_directory =
                unEditedCommunity.show_in_directory;
            editedProfile.show_members_to_members =
                unEditedCommunity.show_members_to_members;
            editedProfile.posts_require_approval =
                unEditedCommunity.posts_require_approval;
            editedProfile.is_private = unEditedCommunity.is_private;
            editedProfile.has_file_library =
                unEditedCommunity.has_file_library !== undefined
                    ? unEditedCommunity.has_file_library
                    : true;
            editedProfile.link_slack = !!unEditedCommunity.slack_id;
            editedProfile.posting_permissions =
                unEditedCommunity.posting_permissions;
            editedProfile.organization_id = unEditedCommunity.organization_id;
            editedProfile.hash_tags = unEditedCommunity.hash_tags;
            editedProfile.subheader_name = unEditedCommunity.subheader_name;

            return editedProfile;
        }

        function getAdmins(communityId) {
            var getAdminPromise = $q.defer();

            var communityAdmins = [];
            Community.getCommunityAdmins(communityId)
                .then(function(admins) {
                    _.each(admins, function(admin) {
                        var member = { id: admin.id, user: admin };
                        communityAdmins.push(member);
                    });
                    getAdminPromise.resolve(communityAdmins);
                })
                .catch(function(error) {
                    Logger.error("ERROR: ", error);
                    getAdminPromise.reject();
                });
            return getAdminPromise.promise;
        }
    }
})();
