(function() {
    "use strict";

    angular
        .module("ssmAngularApp.layout")
        .controller("PasswordResetCtrl", PasswordResetCtrl);

    function PasswordResetCtrl(
        ssmAuth,
        Logger,
        $state,
        $stateParams,
        $translate,
        Tenant,
        SSMTitle
    ) {
        var vm = this;
        vm.forgotPassword = forgotPassword;
        vm.logoUrl = null;
        vm.haveToken = false;
        vm.resetButtonText = "";

        activate();

        function activate() {
            Tenant.getTenant().then(function(tenant) {
                vm.logoUrl = tenant.logo_url;
            });

            $translate("TITLE.RESET_PASSWORD").then(function(title) {
                SSMTitle.setTitle(title);
            });

            vm.haveToken = !!$stateParams.token;

            if (vm.haveToken) {
                $translate("LOGIN.FORGOT_PASSWORD_RESET_ACTION").then(function(
                    translation
                ) {
                    vm.resetButtonText = translation;
                });
            } else {
                $translate("LOGIN.SEND_PASSWORD_RESET_EMAIL_ACTION").then(
                    function(translation) {
                        vm.resetButtonText = translation;
                    }
                );
            }
        }

        function forgotPassword(form) {
            if (!form.$valid) {
                return $translate("LOGIN.ERROR.NOT_VALID_FORM").then(function(
                    translation
                ) {
                    vm.errorText = translation;
                });
            }

            if (vm.haveToken) {
                resetPassword();
            } else {
                sendResetPasswordEmail();
            }
        }

        function resetPassword() {
            if (vm.user.password !== vm.user.retypePassword) {
                $translate("LOGIN.ERROR.PASSWORDS_MATCH").then(function(
                    translation
                ) {
                    vm.errorText = translation;
                });
                return;
            }

            vm.errorText = "";
            vm.isLoading = true;
            ssmAuth
                .resetPassword(
                    vm.user.email,
                    vm.user.password,
                    $stateParams.token
                )
                .then(onResetPasswordSuccess)
                .catch(onResetPasswordFailed);
        }

        function sendResetPasswordEmail() {
            vm.errorText = "";
            vm.isLoading = true;
            ssmAuth
                .sendResetPasswordEmail(vm.user.email)
                .then(onEmailSuccess)
                .catch(onEmailFailed);
        }

        function onResetPasswordSuccess() {
            $state.go("login");
            $translate("LOGIN.PASSWORD_CHANGED").then(function(translation) {
                Logger.success(null, translation, null);
            });
        }

        function onResetPasswordFailed(error) {
            vm.errorText = "";
            if (error.status === 404) {
                $translate("LOGIN.ERROR.INCORRECT_EMAIL_ADDRESS").then(function(
                    translation
                ) {
                    vm.errorText = translation;
                });
            } else if (error.status === 419) {
                $translate("LOGIN.ERROR.FORGOT_PASSWORD_TOKEN_TIMEOUT").then(
                    function(translation) {
                        Logger.error(null, translation, error);
                    }
                );
            } else {
                $translate("LOGIN.ERROR.FORGOT_PASSWORD_RESET").then(function(
                    translation
                ) {
                    Logger.error(null, translation, error);
                });
            }
            vm.isLoading = false;
        }

        function onEmailSuccess(success) {
            $state.go("login");
            $translate("LOGIN.FORGOT_PASSWORD_SENT_EMAIL").then(function(
                translation
            ) {
                Logger.success(null, translation, null);
            });
        }

        function onEmailFailed(error) {
            vm.errorText = "";
            $translate("LOGIN.ERROR.FORGOT_PASSWORD_RESET").then(function(
                translation
            ) {
                Logger.error(null, translation, error);
            });
            vm.isLoading = false;
        }
    }
})();
