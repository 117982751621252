(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmResetPassword")
        .directive("ssmResetPassword", ssmResetPassword);

    function ssmResetPassword() {
        return {
            templateUrl: "components/ssmResetPassword/ssmResetPassword.html",
            restrict: "E",
            replace: true,
            scope: {},
            controller: ssmResetPasswordCtrl,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmResetPasswordCtrl(
        ssmAuth,
        Logger,
        $timeout,
        $translate,
        CurrentUser
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.user = {};
        vm.showSavedFlag = false;

        vm.changePassword = changePassword;
        vm.resetForm = resetForm;
        vm.currentUser = CurrentUser.profile;

        // TODO: put this in a constant store or service
        var DELAY_TIME_HIDING_FLAG = 3000;

        activate();

        function activate() {
            if (
                CurrentUser.profile.emails &&
                CurrentUser.profile.emails.length
            ) {
                vm.user.email = CurrentUser.profile.emails[0].email;
            }
        }

        function changePassword(form) {
            if (!form.$valid) {
                return;
            }

            if (vm.user.password !== vm.user.retypePassword) {
                $translate("LOGIN.ERROR.PASSWORDS_MATCH").then(function(
                    translation
                ) {
                    vm.errorText = translation;
                });
                return;
            }

            ssmAuth
                .changePassword(vm.user.oldPassword, vm.user.password)
                .then(function(result) {
                    onChangePasswordSuccess(form);
                })
                .catch(onChangePasswordFailed);
        }

        function onChangePasswordSuccess(form) {
            vm.user = {};
            vm.errorText = "";
            form.$setPristine();
            vm.showSavedFlag = true;
            $timeout(function() {
                vm.showSavedFlag = false;
            }, DELAY_TIME_HIDING_FLAG);
            vm.currentUser.has_password = true;
            CurrentUser.profile.has_password = true;
            CurrentUser.refresh();
        }

        function onChangePasswordFailed(error) {
            vm.errorText = "";
            if (error.status === 400) {
                $translate("LOGIN.ERROR.OLD_PASSWORD_INCORRECT").then(function(
                    translation
                ) {
                    Logger.error(null, translation);
                });
            } else {
                $translate("LOGIN.ERROR.FORGOT_PASSWORD_RESET").then(function(
                    translation
                ) {
                    Logger.error(null, translation);
                });
            }
        }

        function resetForm(form) {
            vm.user.oldPassword = "";
            vm.user.password = "";
            vm.user.retypePassword = "";
            form.$setPristine();
        }
    }
})();
