(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmUserVendorManager")
        .controller(
            "ssmUserVendorManagerController",
            ssmUserVendorManagerController
        )
        .directive("ssmUserVendorManager", ssmUserVendorManager);

    function ssmUserVendorManager() {
        return {
            templateUrl:
                "components/ssmUserVendorManager/ssmUserVendorManager.html",
            restrict: "E",
            replace: true,
            scope: {
                userData: "=",
                oneBtn: "@"
            },
            controller: ssmUserVendorManagerController,
            controllerAs: "userVendorManager",
            bindToController: true
        };
    }

    function ssmUserVendorManagerController(Vendor, Logger, CurrentUser) {
        /*jshint validthis:true */
        var vm = this;

        vm.canPromote = vm.userData && !vm.userData.is_vendor_manager;
        vm.canDemote = vm.userData && !!vm.userData.is_vendor_manager;

        vm.currentUser = null;

        vm.promote = promote;
        vm.demote = demote;

        activate();

        function activate() {
            vm.currentUser = CurrentUser.profile;
        }

        function promote() {
            if (!vm.userData) {
                Logger.error("Could not promote user.");
                return;
            }

            Vendor.promote(vm.userData.id)
                .then(function() {
                    vm.canPromote = false;
                    vm.canDemote = true;

                    if (vm.userData.id === vm.currentUser.id) {
                        CurrentUser.refresh();
                    }
                })
                .catch(function(error) {
                    Logger.errorWithoutAlert(
                        "Error promoting user to vendor manager.",
                        error
                    );
                });
        }

        function demote() {
            if (!vm.userData) {
                Logger.error("Could not demote user.");
                return;
            }

            Vendor.demote(vm.userData.id)
                .then(function() {
                    vm.canPromote = true;
                    vm.canDemote = false;

                    if (vm.userData.id === vm.currentUser.id) {
                        CurrentUser.refresh();
                    }
                })
                .catch(function(error) {
                    Logger.errorWithoutAlert(
                        "Error demoting user from vendor manager.",
                        error
                    );
                });
        }
    }
})();
