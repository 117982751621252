(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("CommunityIndexCtrl", CommunityIndexCtrl);

    function CommunityIndexCtrl(
        CurrentUser,
        Community,
        CommunityMembership,
        Tenant,
        Tutorial,
        Logger,
        $location,
        $state,
        $translate,
        SSMTitle
    ) {
        var vm = this;
        vm.stateName = $state.current.name;
        vm.pageTitle = "";
        vm.isGuest = CurrentUser.profile.is_guest;
        vm.isAdmin = CurrentUser.profile.is_admin;
        vm.communitySets = [];
        vm.nextPage = nextPage;
        vm.disableInfiniteScrollEvents = true;
        vm.isFollowingState = false;
        vm.hideCreateCommunityButtonForUsers = false;
        var followedCommunitiesError = "Error getting community membership.";
        var allCommunitiesError = "Error getting all communities";
        var skip = 0;
        var COMMUNITY_LIMIT = 20;
        var totalCommunitiesCount = 0;
        // If the currently signed in host not NWC any communities belonging to that organization are sorted to the top.
        var host = $location.host();

        activate();

        function activate() {
            displayCommunities();

            Tenant.getTenant().then(function(tenant) {
                vm.hideCreateCommunityButtonForUsers =
                    tenant.flags.hideCreateCommunityButtonForUsers;
                shouldShowCreateCommunityButton();
                openTutorial(tenant.flags.enable_tutorial_mode);
            });
        }

        function shouldShowCreateCommunityButton()
        {
            if(vm.isAdmin)
            {
                vm.hideCreateCommunityButtonForUsers = true;
                return vm.hideCreateCommunityButtonForUsers;
            }
            else if(!vm.isGuest && !vm.hideCreateCommunityButtonForUsers)
            {
                return vm.hideCreateCommunityButtonForUsers;
            }
        }

        function openTutorial(enable_tutorial_mode) {
            if (enable_tutorial_mode) {
                Tutorial.openStep10();
            }
        }

        function nextPage() {
            vm.disableInfiniteScrollEvents = true;
            if (vm.communitySets.length) {
                // We get the total number of communities, then 'skip' past them to query for new ones
                _.each(vm.communitySets, function(communitySet) {
                    totalCommunitiesCount += communitySet.communities.length;
                });
                skip = totalCommunitiesCount;

                var query = { host: host, skip: skip, limit: COMMUNITY_LIMIT };
                if (vm.stateName === "main.communities_following") {
                    getCommunities(query, CommunityMembership.getAll, followedCommunitiesError);
                } else {
                    getCommunities(query, Community.all, allCommunitiesError);
                }
            }
        }

        function displayCommunities() {
            var query = { host: host, skip: 0, limit: COMMUNITY_LIMIT };

            if (vm.stateName === "main.communities_following") {
                vm.isFollowingState = true;

                setupCommunitiesPage("COMMUNITY.COMMUNITY_MEMBERSHIP_TITLE");
                getCommunities(query, CommunityMembership.getAll, followedCommunitiesError);
            } else {
                setupCommunitiesPage("COMMUNITY.ALL_COMMUNITIES");
                getCommunities(query, Community.all, allCommunitiesError);
            }
        }

        function setupCommunitiesPage(communityTypeTitle) {
            $translate(communityTypeTitle).then(function(
                translation
            ) {
                SSMTitle.setTitle(translation);
                vm.pageTitle = translation;
            });
        }

        function getCommunities(query, getCommunitiesFunction, errorMessage) {
            return getCommunitiesFunction(query)
                .then(function(communitiesGroupedByOrg) {
                    // The getCommunitiesFunction returns arrays of communities, grouped by organization
                    if (communitiesGroupedByOrg && communitiesGroupedByOrg.length) {
                        createCommunitySubheaderSets(communitiesGroupedByOrg);
                        groupCommunitiesIntoSubheaders(vm.communitySets);
                        vm.disableInfiniteScrollEvents = false;
                    } else {
                        vm.disableInfiniteScrollEvents = true;
                    }
                })
                .catch(function(error) {
                    vm.disableInfiniteScrollEvents = true;
                    Logger.errorWithoutAlert(
                        errorMessage,
                        error
                    );
                });
        }

        function createCommunitySubheaderSets(communitiesGroupedByOrg) {
            // Makes an array of all the subheaders
            _.each(communitiesGroupedByOrg, function(communitiesInOrg) {
                var index = _.findIndex(vm.communitySets, function(
                    communitySet
                ) {
                    return (
                        communitySet.name === communitiesInOrg.name
                    );
                });
                if (index >= 0) {
                    vm.communitySets[index].communities =
                    vm.communitySets[index].communities.concat(
                        communitiesInOrg.communities
                    );
                } else {
                    vm.communitySets.push(communitiesInOrg);
                }
            });
            changeOrder(vm.communitySets[0].communities, "*Featured Communities (NEW)", 1)
        }

        function changeOrder(array, subheaderName, desirePosition){
            const indexSubheader = array.findIndex(elem => elem.subheader_name === subheaderName)
            if (indexSubheader !== -1){
                const objSubheader = array[indexSubheader]
                array.splice(desirePosition,0,objSubheader)
                array.splice(indexSubheader+1,1)
            }
            return array
        }

        function groupCommunitiesIntoSubheaders(communitySets) {
            _.each(communitySets, function(communitySet) {
                var subheaderSet = makeSubheaderSet(
                    communitySet.communities
                );
                if (subheaderSet.length > 0) {
                    var nonSubheaderSet = _.filter(
                        communitySet.communities,
                        function(community) {
                            return !community.subheader_name;
                        }
                    );
                    communitySet.subheaderSet = subheaderSet;
                    communitySet.nonSubheaderSet = nonSubheaderSet;
                }
            });
        }

        function makeSubheaderSet(communities) {
            var subheaderCommunities = _.filter(communities, function(
                community
            ) {
                return community.subheader_name;
            });
            if (subheaderCommunities) {
                var groupedSubheaders = _.groupBy(
                    subheaderCommunities,
                    "subheader_name"
                );
                return _.map(groupedSubheaders, function(value, key) {
                    return {
                        title: key,
                        communities: value
                    };
                });
            } else return null;
        }
    }
})();
