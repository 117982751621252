(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("CommunitiesEditCtrl", CommunitiesEditCtrl);

    function CommunitiesEditCtrl(
        $state,
        $translate,
        Community,
        communityProfileResolve,
        CurrentUser,
        SSMTitle
    ) {
        var vm = this;
        vm.showForm = true;
        if (communityProfileResolve) {
            var currentUserIsAdmin = !!_.find(
                communityProfileResolve.admins,
                function(admin) {
                    return admin === CurrentUser.profile.id;
                }
            );
            vm.showForm =
                currentUserIsAdmin &&
                communityProfileResolve.status === Community.STATUS.ACTIVE;
        }
        if ($state.current.name === "main.communities_edit") {
            $translate("COMMUNITY.EDIT").then(function(translation) {
                SSMTitle.setTitle(translation);
            });
            vm.originalProfile = communityProfileResolve;
        } else {
            $translate("COMMUNITY.CREATE_COMMUNITY").then(function(
                translation
            ) {
                SSMTitle.setTitle(translation);
            });
            vm.originalProfile = {};
        }
    }
})();
