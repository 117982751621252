(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("BlogMineCtrl", BlogMineCtrl);

    function BlogMineCtrl(
        $state,
        $translate,
        Analytics,
        Blog,
        CurrentUser,
        Logger,
        SSM_DELEGATION_FEATURE_ON,
        Tenant,
        User
    ) {
        var vm = this;
        vm.blogs = [];
        vm.authorNameToIdMap = [];
        vm.disableInfiniteScrollEvents = false;
        vm.canPostAsDelegate = false;
        vm.SSM_DELEGATION_FEATURE_ON = SSM_DELEGATION_FEATURE_ON;
        vm.nextPage = nextPage;
        vm.createNew = createNew;
        vm.onDelegationSelectionChanged = onDelegationSelectionChanged;
        vm.onCancel = onCancel;
        vm.authorId = CurrentUser.profile.id;
        vm.currentUser = CurrentUser.profile;
        vm.showPostAsDelegate = false;
        vm.selectedBlogAuthor = CurrentUser.profile;

        var defaultBlogTitle;
        var organization_id;

        // TODO: SSM-688 Standardize limits
        var POST_LIMIT = 20;

        activate();

        function activate() {
            $translate("BLOG.EDIT").then(function(editing_translation) {
                $translate("BLOG.EDIT_PAGE").then(function(
                    edit_page_translation
                ) {
                    vm.editingText = editing_translation;
                    vm.editPageText = edit_page_translation;
                    vm.pageTitle = vm.editingText + vm.editPageText;
                });
            });

            $translate("BLOG.DEFAULT_BLOG_TITLE").then(function(translation) {
                defaultBlogTitle = translation;
            });

            Blog.getBlogPermissions().then(function(postPermissions) {
                vm.canPostAsDelegate =
                    postPermissions.canPostAsDelegate &&
                    SSM_DELEGATION_FEATURE_ON;
            });

            Tenant.getTenant().then(function(tenant) {
                organization_id = tenant.organization_id;
            });
        }

        function nextPage() {
            vm.disableInfiniteScrollEvents = true;

            var query = { limit: POST_LIMIT, get_all: true };
            var lastPost = _.last(vm.blogs);
            if (lastPost) {
                query.before_date = lastPost.create_date;
            }

            User.getBlogs(vm.authorId, query)
                .then(function(data) {
                    processBlogs(data);
                })
                .catch(function(error) {
                    // Error getting blogs, don't add any more and leave infinite scroll disabled
                    $translate("BLOG.GET_BLOGS_ERROR").then(function(
                        translation
                    ) {
                        Logger.error("Error: ", translation);
                    });
                });
        }

        function processBlogs(blogs) {
            if (!blogs || blogs.length === 0) {
                // no more blogs, don't add any more and leave infinite scroll disabled
                return;
            }
            vm.blogs.push.apply(vm.blogs, blogs);
            vm.disableInfiniteScrollEvents = false;
        }

        function createNew() {
            var blog = {
                title: defaultBlogTitle,
                author: { document_id: vm.authorId },
                status: Blog.BLOG_STATUS.EDITING,
                organization_id: organization_id
            };
            Blog.create(blog)
                .then(function(createdBlog) {
                    Analytics.eventTrack("createBlog", {
                        category: "Blogs",
                        label:
                            "id:" +
                            createdBlog.id +
                            ", author: " +
                            createdBlog.author.id
                    });
                    Analytics.eventTrack2('create_blog', {
                        category: "Blogs",
                        label: "id:" +
                            createdBlog.id +
                            ", author: " +
                            createdBlog.author.id
                    });

                    if (createdBlog.author.id !== CurrentUser.profile.id) {
                        Analytics.eventTrack("createBlogAsDelegate", {
                            category: "Delegation",
                            label:
                                "id:" +
                                createdBlog.id +
                                ", author: " +
                                createdBlog.author.id
                        });
                        Analytics.eventTrack2('create_blog_as_delegate', {
                            category: "Delegation",
                            label: "id:" +
                                createdBlog.id +
                                ", author: " +
                                createdBlog.author.id
                        });
                    }
                    $state.go("main.blogs.edit", { id: createdBlog.id });
                })

                .catch(function(error) {
                    $translate("BLOG.CREATE_ERROR").then(function(translation) {
                        Logger.error("Error: ", translation);
                    });
                });
        }

        function onDelegationSelectionChanged(selectedBlogAuthor) {
            if (selectedBlogAuthor) {
                vm.authorId = selectedBlogAuthor.id;
                vm.selectedBlogAuthor = selectedBlogAuthor;
            } else {
                vm.authorId = CurrentUser.profile.id;
                vm.selectedBlogAuthor = CurrentUser.profile;
            }
            vm.showPostAsDelegate = false;
            vm.blogs = [];
            vm.nextPage();
        }

        function onCancel() {
            vm.showPostAsDelegate = false;
        }
    }
})();
