(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.community", ["ssmAngularApp.api"])
        .factory("Community", Community);

    function Community(Api) {
        var communityApi = Api.all("communities");
        var fileApi = Api.all("files");
        var folderApi = Api.all("folders");
        var posts = Api.all("posts");
        return {
            FOLLOW_STATUS: {
                FOLLOWING: 0,
                INVITED: 1
            },
            POSTING_PERMISSIONS: {
                ALL: 0,
                PARTICIPATION_ONLY: 1,
                ADMINS_ONLY: 2
            },
            STATUS: {
                ACTIVE: 1,
                INACTIVE: 2,
                SUSPENDED: 3,
                ARCHIVED: 4
            },

            all: function(query) {
                //query : {skip:number, limit:number}
                return communityApi.getList(query);
            },

            report: function(query) {
                var format = query.format || "application/json";
                var params = {
                    skip: query.skip,
                    limit: query.limit,
                    date_format: query.date_format
                };

                return communityApi
                    .one("report")
                    .get(params, { Accept: format });
            },

            allPremium: function(query) {
                //query : {skip:number, limit:number}
                return communityApi.customGETLIST("premium", query);
            },

            create: function(community) {
                return communityApi.post(community);
            },

            getCommunity: function(id, useCache = false) {
                var options = {
                    cache: useCache
                };
                return communityApi.withHttpConfig(options).get(id);
            },

            getCommunityNoAuth: function(id) {
                return communityApi.one(id, "NoAuth").get();
            },

            getCommunityAdmins: function(id) {
                return communityApi
                    .one(id)
                    .all("admins")
                    .getList();
            },

            getCommunityAdminProperties: function(id) {
                return communityApi.one(id, "adminProperties").get();
            },

            getCommunityAces: function(id) {
                return communityApi
                    .one(id)
                    .all("aces")
                    .getList();
            },

            getCommunityAccessList: function(id, query) {
                //query : {skip:number, limit:number}
                return communityApi
                    .one(id)
                    .all("members")
                    .getList(query);
            },

            getPendingCommunityFollowers: function(id, query) {
                return communityApi
                    .one(id)
                    .all("pending-followers")
                    .getList(query);
            },

            getCommunityFollowers: function(id, query) {
                //query : {skip:number, limit:number}
                return communityApi
                    .one(id)
                    .all("followers")
                    .getList(query);
            },

            getLibrary: function(communityId, query) {
                return communityApi.one(communityId, "library").get(query);
            },

            getInvitableCommunities: function(query) {
                return communityApi.all("invitable").getList(query);
            },

            getInvitedCommunities: function() {
                return communityApi.all("invited").getList();
            },

            getCommunityPostPermissions: function(id) {
                return communityApi.one(id, "postpermissions").get();
            },

            getPost: function(id) {
                return posts.one(id).get({ format: "enhanced" });
            },

            addFile: function(communityId, fileId) {
                return communityApi
                    .one(communityId)
                    .all("library")
                    .one(fileId)
                    .post();
            },

            removeFile: function(communityId, fileId) {
                // NOTE: this only removes from the library but does not delete the file from the DB
                return communityApi
                    .one(communityId, "library")
                    .one(fileId)
                    .remove();
            },

            // Start Folders
            // next 3 functions are used
            createFolder: function(folderToCreate) {
                return folderApi.post(folderToCreate);
            },

            addFolderToLibrary: function(communityId, folderId) {
                return communityApi
                    .one(communityId)
                    .all("library")
                    .all("folders")
                    .one(folderId)
                    .post();
            },

            removeFolder: function(communityId, folderId) {
                // NOTE: this only removes from the library but does not delete the folder from the DB
                return communityApi
                    .one(communityId, "library")
                    .all("folders")
                    .one(folderId)
                    .remove();
            },

            // currently Unused, could be used for renaming
            updateFolder: function(folderId, folderToUpdate) {
                return folderApi
                    .one(folderId)
                    .patch(folderToUpdate)
                    .then(function(response) {
                        return response.plain();
                    });
            },

            // Probably deprecated
            populateFolders: function(communityId, folderId) {
                if (folderId === "root") {
                    return communityApi
                        .one(communityId)
                        .all("library")
                        .all("folders")
                        .one(folderId)
                        .get();
                } else {
                    return communityApi
                        .one(communityId)
                        .all("library")
                        .all("folders")
                        .one(folderId)
                        .get();
                }
            },
            // end folders

            post: function(post) {
                return post;
            },

            update: function(id, updatedCommunity) {
                return communityApi.one(id).patch(updatedCommunity);
            },

            updateCommunityAccessList: function(id, accessListUpdates) {
                return communityApi
                    .one(id)
                    .all("members")
                    .post(accessListUpdates);
            },

            delete: function(post, postId) {
                return posts.one(postId).remove();
            },

            like: function(postId) {
                return posts
                    .one(postId)
                    .all("likes")
                    .post();
            },

            getPosts: function(id, query) {
                //query : { limit:number, before_date:Date }
                return communityApi
                    .one(id)
                    .all("posts")
                    .getList(query);
            },

            createPost: function(id, post) {
                return communityApi
                    .one(id)
                    .all("posts")
                    .post(post, { format: "enhanced" });
            },

            addPostSticky: function(communityId, postId) {
                return communityApi
                    .one(communityId)
                    .all("stickied")
                    .one(postId)
                    .put();
            },

            deletePostSticky: function(communityId, postId) {
                return communityApi
                    .one(communityId)
                    .all("stickied")
                    .one(postId)
                    .remove();
            },

            getSuggestions: function() {
                return communityApi.get("suggested");
            },

            putCommunityAce: function(id, user_id) {
                return communityApi
                    .one(id)
                    .all("aces")
                    .one(user_id)
                    .put();
            },

            deleteCommunityAce: function(id, user_id) {
                return communityApi
                    .one(id)
                    .all("aces")
                    .one(user_id)
                    .remove();
            },

            putAdmin: function(id, user_id) {
                return communityApi
                    .one(id)
                    .all("admins")
                    .one(user_id)
                    .put();
            },

            deleteAdmin: function(id, user_id) {
                return communityApi
                    .one(id)
                    .all("admins")
                    .one(user_id)
                    .remove();
            },

            requestCommunityAccess: function(id, user_id) {
                return communityApi
                    .one(id)
                    .one("request_access")
                    .one(user_id)
                    .post();
            },

            patchCommunityProperties: function(id, data) {
                return communityApi
                    .one(id)
                    .all("admin_properties")
                    .patch(data);
            },

            updateFile: function(id, data) {
                return fileApi
                    .one(id)
                    .patch(data)
                    .then(function(response) {
                        return response.plain();
                    });
            },

            updateNoAuth: function(fileId, updatedFile) {
                return fileApi
                    .all("noauth")
                    .one(fileId)
                    .patch(updatedFile)
                    .then(function(response) {
                        return response.plain();
                    });
            },

            upsertAdvertisement: function(newAdWithContext) {
                return communityApi
                    .all("upsertAdvertisement")
                    .post(newAdWithContext).then(response => {
                        return response;
                    });
            },

            removeAdvertisement: function(context) {
                return communityApi
                    .one(context.communityId)
                    .one("removeAd", context.indexOfAdvert)
                    .remove().then(response => {
                        return response;
                    });
            }
        };
    }
})();
