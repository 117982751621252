(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmVendorProfileForm", [
            "ssmAngularApp.vendor.service",
            "ssmAngularApp.vendorpermissions.service"
        ])
        .directive("ssmVendorProfileForm", ssmVendorProfileForm)
        .controller(
            "ssmVendorProfileFormController",
            ssmVendorProfileFormController
        )
        .factory("ssmVendorProfileFormHelper", ssmVendorProfileFormHelper);

    function ssmVendorProfileForm() {
        return {
            templateUrl:
                "components/ssmVendorProfileForm/ssmVendorProfileForm.html",
            restrict: "E",
            scope: {
                autoSave: "@",
                onCancel: "&",
                onUpdate: "&",
                vendorData: "="
            },
            controller: ssmVendorProfileFormController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmVendorProfileFormController(
        $scope,
        ssmVendorProfileFormHelper,
        VendorPermissions
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.autoUpdate = autoUpdate;
        vm.buttonClickCancel = buttonClickCancel;
        vm.buttonClickSubmit = buttonClickSubmit;

        vm.vendorEdit = ssmVendorProfileFormHelper.copyVendor(vm.vendorData);

        vm.canEditStatus = false;
        vm.showSaveButton = !vm.autoSave;

        activate();

        function activate() {
            VendorPermissions.canActivateVendor(
                vm.vendorData ? vm.vendorData.id : null
            ).then(function(allowed) {
                vm.canEditStatus = allowed;
            });
        }

        function autoUpdate(vendorCreateForm) {
            // if we have a form, make sure it is valid before sending the update
            if (
                vm.onUpdate &&
                vm.autoSave &&
                (!vendorCreateForm || vendorCreateForm.$valid)
            ) {
                return vm.onUpdate({ edits: vm.vendorEdit });
            }
        }

        function buttonClickCancel(vendorCreateForm) {
            if (vendorCreateForm) {
                vendorCreateForm.$setPristine();
                vendorCreateForm.$setUntouched();
            }

            vm.vendorEdit = ssmVendorProfileFormHelper.copyVendor(
                vm.vendorData
            );
            return vm.onCancel();
        }

        function buttonClickSubmit(vendorCreateForm) {
            $scope.$broadcast("show-errors-check-validity");
            if (vm.onUpdate && vendorCreateForm && vendorCreateForm.$valid) {
                return vm.onUpdate({ edits: vm.vendorEdit });
            }
        }
    }

    function ssmVendorProfileFormHelper() {
        return {
            copyVendor: copyVendor
        };

        // don't use angular copy because we don't want all properties
        function copyVendor(original) {
            if (!original) {
                return null;
            }

            return {
                address: original.address,
                description: original.description,
                name: original.name,
                phone: original.phone,
                status: original.status.toString()
            };
        }
    }
})();
