(function() {
    "use strict";

    angular
        .module("ssmAngularApp.ssmUtilities")
        .factory("SSMUtilities", SSMUtilities);

    function SSMUtilities($q) {
        return {
            // TODO: move this constant to someplace permanent
            SSM_AUTOCOMPLETE_CREATE_BUTTON_CHANGED_EVENT:
                "ssmAutocompleteCreateButtonChangedEvent",
            SSM_AUTOCOMPLETE_PROGRAMMATIC_INPUT_CHANGE_EVENT:
                "ssmAutocompleteProgrammaticInputChangeEvent",
            SSM_TAG_SELECT_PROGRAMMATIC_CHANGE_EVENT:
                "SsmTagSelectProgrammaticChangeEvent",
            SSM_AFFILIATION_PROGRAMMATIC_CHANGE_EVENT:
                "SsmAffiliationProgrammaticChangeEvent",

            fileToBase64: function(file) {
                var readerPromise = $q.defer();

                if (window.isIE9) {
                    window.FileAPI.readAsDataURL(file, function(data) {
                        readerPromise.resolve(data.result);
                    });
                } else {
                    var reader = new FileReader();
                    reader.onload = function(evt) {
                        readerPromise.resolve(evt.target.result);
                    };
                    reader.readAsDataURL(file);
                }
                return readerPromise.promise;
            },

            base64ToByteArray: function(base64) {
                var substring = base64.split(",")[1];
                var byteCharacters = atob(substring);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                return new Uint8Array(byteNumbers);
            }
        };
    }
})();
