(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.findOrg", []).factory("FindOrg", FindOrg);

    function FindOrg(Search) {
        var vm = {
            data: {},
            initFields: initFields,
            search: search
        };

        var search_terms;

        Search.org_search_terms().then(function(results) {
            search_terms = results.plain();
            initFields();
        });

        function initFields() {
            vm.data.name = {};
            vm.data.city = getOptions(search_terms.city);
            vm.data.state = getOptions(search_terms.state);
            vm.data.products_and_services = getOptions(
                search_terms.products_and_services
            );
            vm.data.bio = {};

            function getOptions(options) {
                options = _.map(options, function(option) {
                    return {
                        text: option
                    };
                });

                return {
                    options: options
                };
            }
        }

        function buildSearchData() {
            function getString(field) {
                if (!field.text) {
                    return undefined;
                }

                var text = field.text.trim();
                if (!text.length) {
                    return undefined;
                }

                return text;
            }

            function getOptions(field) {
                var options = _(field.options)
                    .filter(function(option) {
                        return option.selected;
                    })
                    .map(function(option) {
                        return option.text;
                    })
                    .value();

                if (!options.length) {
                    return undefined;
                }

                options = _.map(options, function(option) {
                    return '"' + option + '"';
                });

                return options.join(" ");
            }

            var data = {
                name: getString(vm.data.name),
                city: getOptions(vm.data.city),
                state: getOptions(vm.data.state),
                products_and_services: getOptions(
                    vm.data.products_and_services
                ),
                bio: getString(vm.data.bio)
            };

            return data;
        }

        function search(params) {
            // Cook up a search data object.
            var search_data = buildSearchData();

            // Fire off an async call to get the results
            return Search.orgs(search_data, params);
        }

        return vm;
    }
})();
