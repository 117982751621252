(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.areYouSureModal")
        .controller("AreYouSureCtrl", AreYouSureCtrl);

    function AreYouSureCtrl(
        textResolve,
        $uibModalInstance,
        $scope,
        $translate
    ) {
        var vm = this;
        vm.messageText = textResolve;
        vm.confirm = confirm;
        vm.reject = reject;

        activate();

        function activate() {
            $translate("ARE_YOU_SURE.TITLE").then(function(translation) {
                vm.titleText = translation;
            });
        }

        function confirm() {
            $uibModalInstance.close({ confirmed: true });
        }

        function reject() {
            $uibModalInstance.close({ confirmed: false });
        }

        $scope.$on("$locationChangeStart", function() {
            $uibModalInstance.close({ confirmed: false });
        });
    }
})();
