(function() {
    "use strict";

    angular.module("ssmAngularApp.communityPrivate", [
        "ssmAngularApp.layout",
        "ssmAngularApp.logger",
        "ssmAngularApp.community",
        "ssmAngularApp.currentUser",
        "ssmAngularApp.CommunityMembership.service"
    ]);
})();
