(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("imis", {
            url: "/auth/login/rise/callback",
            controller: "ImisCtrl",
            controllerAs: "imis"
        });
    });
})();
