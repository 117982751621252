(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("ProfileQuestionsCtrl", ProfileQuestionsCtrl);

    function ProfileQuestionsCtrl(
        $state,
        $timeout,
        questionResolve,
        CurrentUser,
        User,
        SSMTitle,
        tenantResolve
    ) {
        var vm = this;
        vm.showSavedFlag = false;

        vm.formSubmit = formSubmit;
        vm.resetForm = resetForm;
        vm.multiCheckboxToggle = multiCheckboxToggle;
        vm.multiCheckboxInit = multiCheckboxInit;
        vm.textFieldInit = textFieldInit;
        vm.singleSelectInit = singleSelectInit;
        vm.formAnswers = {};
        vm.readOnly = false;

        var DELAY_TIME_HIDING_FLAG = 3000;

        activate();

        function activate() {
            if (tenantResolve.flags.prevent_profile_question_sort) {
                vm.questions = _.flatten(questionResolve);
            } else {
                vm.questions = _.sortBy(_.flatten(questionResolve), "label");
            }
            vm.readOnly = !tenantResolve.flags.edit_profile_questions;

            SSMTitle.setTitle(CurrentUser.profile.full_name);
        }

        function formSubmit(answers, form) {
            User.saveQuestions(CurrentUser.profile.id, answers);
            form.$setPristine();

            vm.showSavedFlag = true;
            $timeout(function() {
                vm.showSavedFlag = false;
            }, DELAY_TIME_HIDING_FLAG);
        }

        function singleSelectInit(question) {
            _.forEach(question.field_options, function(option) {
                if (option.selected) {
                    if (!vm.formAnswers[question.id]) {
                        vm.formAnswers[question.id] = {};
                    }
                    vm.formAnswers[question.id].option_ids = option;
                }
            });
        }

        function textFieldInit(questionId, value) {
            if (!vm.formAnswers[questionId]) {
                vm.formAnswers[questionId] = {};
            }
            vm.formAnswers[questionId].text = value;
        }

        function multiCheckboxInit(questionId, option) {
            if (option.selected) {
                if (!vm.formAnswers[questionId]) {
                    vm.formAnswers[questionId] = {};
                }
                if (!vm.formAnswers[questionId].option_ids) {
                    vm.formAnswers[questionId].option_ids = [];
                }
                vm.formAnswers[questionId].option_ids.push(option.id);
            }
        }

        function multiCheckboxToggle(questionId, option) {
            if (!vm.formAnswers[questionId]) {
                vm.formAnswers[questionId] = {};
            }
            if (!vm.formAnswers[questionId].option_ids) {
                vm.formAnswers[questionId].option_ids = [];
            }
            if (option.selected) {
                vm.formAnswers[questionId].option_ids.push(option.id);
            } else {
                _.pull(vm.formAnswers[questionId].option_ids, option.id);
            }
        }

        function resetForm(form) {
            // TODO : make this cleaner
            $state.go("main.profile.questions", {}, { reload: true });
        }
    }
})();
