(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp").controller("FeedbackCtrl", FeedbackCtrl);

    function FeedbackCtrl(
        $translate,
        Api,
        $state,
        Analytics,
        Logger,
        CurrentUser,
        Feedback,
        ssmAuth,
        Tenant,
        SSMTitle
    ) {
        var vm = this;

        vm.submit = submit;
        vm.logoUrl = null;
        vm.submitted = false;
        vm.isFormValid = isFormValid;
        vm.isSignedIn = ssmAuth.isSignedIn;
        vm.data = {
            userAgent: navigator.userAgent
        };

        activate();

        function activate() {
            vm.isSignedIn = ssmAuth.isSignedIn;

            $translate("TITLE.REPORT_A_PROBLEM").then(function(title) {
                SSMTitle.setTitle(title);
            });

            if (ssmAuth.isSignedIn) {
                var user = CurrentUser.profile;
                vm.data.name = user.first_name + " " + user.last_name;
                if (user.emails && user.emails.length > 0) {
                    // TODO: chrisb: Should this look for the user's *primary* email address?
                    vm.data.email = user.emails[0].email;
                }
            }

            $translate("FEEDBACK.SENDING_REQUEST").then(function(translation) {
                vm.loadingMessage = translation;
            });

            Tenant.getTenant().then(function(tenant) {
                vm.logoUrl = tenant.logo_url;
                vm.data.tenant_slug = tenant.slug;
            });
        }

        function submit(form) {
            if (form.$valid) {
                vm.isLoading = true;
                // Extract just the necessary data from the uploaded files array.
                if (vm.files && ssmAuth.isSignedIn) {
                    vm.data.files = vm.files.map(function(file) {
                        return {
                            url: file.urls.download,
                            file_type: file.file_type,
                            id: file.id,
                            name: file.name,
                            size: file.size
                        };
                    });
                }

                Analytics.eventTrack("submitFeedback", {
                    catagory: "feedback",
                    label: "email:" + vm.data.email
                });
                Analytics.eventTrack2('submit_feedback', {
                    category: "feedback",
                    label: "email:" + vm.data.email
                });

                Feedback.create(vm.data)
                    .then(function() {
                        $translate("FEEDBACK.SUCCESSFULLY_CREATED_TICKET").then(
                            function(translation) {
                                Logger.success("", translation, vm.data);
                            }
                        );
                        $state.go("main.home");
                    })
                    .catch(function(error) {
                        $translate("FEEDBACK.ERROR.CREATE_TICKET_FAILED").then(
                            function(translation) {
                                Logger.error("", translation, error);
                            }
                        );
                        vm.isLoading = false;
                    });
            }
        }

        function isFormValid(form, name) {
            return (
                (vm.submitted && form[name].$invalid) ||
                (form[name].$invalid && form[name].$dirty)
            );
        }
    }
})();
