(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.ssmOrganizationEdit", [])
        .directive("ssmOrganizationEdit", ssmOrganizationEdit)
        .controller(
            "ssmOrganizationEditController",
            ssmOrganizationEditController
        );

    function ssmOrganizationEdit() {
        return {
            templateUrl:
                "components/ssmOrganizationEdit/ssmOrganizationEdit.html",
            restrict: "E",
            replace: true,
            scope: {
                modelData: "=",
                formName: "=",
                initializeCountryToUsa: "@",
                broadcastIdentifier: "@"
            },
            controller: ssmOrganizationEditController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmOrganizationEditController(
        $uibModal,
        $translate,
        Analytics,
        CurrentUser,
        Logger,
        Organization,
        SSM_FILES,
        ssmFileUpload
    ) {
        /*jshint validthis:true */
        var vm = this;
        vm.onImageUpload = onImageUpload;
        vm.sectorItemClick = sectorItemClick;
        vm.sectorTypes = [];

        // TODO add config definition for max lengths https://ssmteam.atlassian.net/browse/SSM-2993
        vm.maxLength = 80;
        vm.maxLengthBio = 500;
        vm.alphaNumeric = new RegExp(/[a-zA-Z0-9\s]/);

        activate();

        function activate() {
            $translate("ORGANIZATION.EDIT.SECTOR_PLACEHOLDER")
                .then(function(translation) {
                    vm.sectorPlaceholder = translation;
                })
                .finally(function() {
                    if (vm.initializeCountryToUsa) {
                        vm.modelData.addresses[0].country =
                            vm.modelData.addresses[0].country ||
                            "United States";
                    }
                });

            Organization.getSectors().then(function(sectorTypes) {
                vm.sectorTypes = sectorTypes;
            });
        }

        function onImageUpload() {
            Analytics.eventTrack("uploadOrgImage", {
                category: "organizationEdit",
                label:
                    vm.modelData.uploadedImage[0].id +
                    " file_type: " +
                    vm.modelData.uploadedImage[0].file_type +
                    " size: " +
                    vm.modelData.uploadedImage[0].size
            });
            Analytics.eventTrack2('upload_organization_image', {
                category: "organizationEdit",
                label: vm.modelData.uploadedImage[0].id +
                    " file_type: " +
                    vm.modelData.uploadedImage[0].file_type +
                    " size: " +
                    vm.modelData.uploadedImage[0].size,
            });

            vm.modelData.photo_id = vm.modelData.uploadedImage[0].id;
            vm.modelData.photo = vm.modelData.uploadedImage[0]; // Updates the preview
        }

        function sectorItemClick(item, form) {
            if (vm.modelData.sector !== item) {
                vm.modelData.sector = item;
                if (form && form.$setDirty) {
                    form.$setDirty();
                }
            }
        }
    }
})();
