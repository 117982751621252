(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("NotificationController", NotificationController);

    function NotificationController() {}
})();
