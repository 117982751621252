(function() {
    "use strict";

    angular.module("ssmAngularApp.layout").config(function($stateProvider) {
        $stateProvider.state("apaSso", {
            url: "/apa-sso/login/redirect",
            controller: "ApaCtrl",
            controllerAs: "apaSso"
        });
    });
})();
