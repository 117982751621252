(function() {
    "use strict";
    /**
     * @ngInject
     */
    // This file stores functions used by mainHeader. Currently it's miscellaneous.
    // TODO: Continue separating out functions into this file, and then organization them into multiple helper files potentially
    angular
        .module("ssmAngularApp.mainHeader.helper", ["ssmAngularApp.mainHeader"])
        .factory("MainHeaderHelper", MainHeaderHelper);

    function MainHeaderHelper($interval, Analytics) {
        return {
            groupNotificationsByMenu: function(notifications) {
                return _.groupBy(notifications, "class");
            },

            // The order of each step here is finnicky because of grouped notifications.
            // * Reject ignored needs to happen before uniqBy in case one was hidden and one was not
            // * Sorting forces uniqBy to take the most recent info
            // * We save notification_id instead of group id to make add/remove easier
            loadNotificationBadges: function(
                latestNotifications,
                notificationBadgeIgnore
            ) {
                return _.chain(latestNotifications)
                    .reject("hidden")
                    .reject(function(notification) {
                        return _.includes(
                            notificationBadgeIgnore,
                            notification.notification_id
                        );
                    })
                    .sortBy("date")
                    .reverse()
                    .uniqBy("badge_id")
                    .map("notification_id")
                    .compact()
                    .value();
            },

            loadNotificationItems: function(oldItems, newItems) {
                return _.chain(oldItems.concat(newItems))
                    .compact()
                    .sortBy("date") // keep the newest version
                    .reverse()
                    .uniqBy("badge_id")
                    .value();
            }
        };
    }
})();
