(function() {
    "use strict";

    angular
        .module("ssmAngularApp.marketplace.services")
        .service("ssmMarketplacePermissions", ssmMarketplacePermissions);

    function ssmMarketplacePermissions(ActiveAngular) {
        return new ActiveAngular("marketplace_permissions/:id");
    }
})();
