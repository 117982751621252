(function() {
    "use strict";

    angular.module("ssmAngularApp.ssmFiles.service").constant(
        "SSM_FILES",
        (function() {
            var constant = {};

            constant.STATUS = {
                UPLOADED: 2
            };

            var FILE_TYPES = {
                ARCHIVE: [
                    // ARCHIVE
                    /*zip*/
                    "application/zip"
                ],
                AUDIO: [
                    // AUDIO
                    /*mp3*/
                    "audio/mpeg",
                    /*mp3*/
                    "audio/mp3",
                    /*mp4*/
                    "audio/mp4"
                ],
                DOCUMENT: [
                    // DOCUMENTS
                    /*psd*/
                    "image/vnd.adobe.photoshop",
                    /*pdf*/
                    "application/pdf",
                    /*xml*/
                    "application/xml",
                    /*txt*/
                    "text/plain"
                ],
                IMAGE: [
                    // IMAGES
                    /*bmp*/
                    "image/bmp",
                    /*jpg*/
                    "image/jpg",
                    /*jpg*/
                    "image/jpeg",
                    /*gif*/
                    "image/gif",
                    /*png*/
                    "image/png"
                ],
                OFFICE: [
                    // OFFICE
                    /*mpp*/
                    "application/vnd.ms-project",
                    /*xlsx*/
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    /*docx*/
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    /*pptx*/
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    /*vsd*/
                    "application/vnd.visio",
                    /*ppt*/
                    "application/vnd.ms-powerpoint",
                    /*doc*/
                    "application/msword",
                    /*xls*/
                    "application/vnd.ms-excel"
                ],
                VIDEO: [
                    // VIDEO
                    /*mp4*/
                    "video/mp4",
                    /*avi*/
                    "video/x-msvideo"
                ],
                CSV: [
                    /*csv*/
                    "text/csv"
                ],
                ZIP: [
                    /*csv*/
                    "application/zip"
                ]
            };

            constant.TYPES = {
                ARCHIVE: FILE_TYPES.ARCHIVE.join(","),
                AUDIO: FILE_TYPES.AUDIO.join(","),
                DOCUMENT: FILE_TYPES.DOCUMENT.join(","),
                IMAGE: FILE_TYPES.IMAGE.join(","),
                OFFICE: FILE_TYPES.OFFICE.join(","),
                VIDEO: FILE_TYPES.VIDEO.join(","),
                CSV: FILE_TYPES.CSV.join(","),
                ZIP: FILE_TYPES.ZIP.join(",")
            };

            constant.TYPES.ALL = _(FILE_TYPES)
                .omit("CSV")
                .omit("ZIP")
                .values()
                .concat()
                .value()
                .join(",");

            constant.TYPES.ALL_EXCEPT_MEDIA = _(FILE_TYPES)
                .omit("AUDIO")
                .omit("IMAGE")
                .omit("VIDEO")
                .values()
                .concat()
                .value()
                .join(",");

            // This is to make it easier to set the role using directive attributes
            // It is converted to the proper enum value on the backend
            constant.ROLE = {
                POST: { accept: constant.TYPES.ALL }, //1
                FEEDBACK: { accept: constant.TYPES.ALL }, //2
                USER_PROFILE: { accept: constant.TYPES.IMAGE, isImage: true }, //3
                COMMUNITY_PROFILE: {
                    accept: constant.TYPES.IMAGE,
                    isImage: true
                }, //4
                COMMUNITY_LIBRARY: { accept: constant.TYPES.ALL }, //5
                ORGANIZATION_PROFILE: {
                    accept: constant.TYPES.IMAGE,
                    isImage: true
                }, //6
                BLOG: { accept: constant.TYPES.IMAGE, isImage: true }, //7
                PDF: { accept: constant.TYPES.DOCUMENT }, //8
                VENDOR_FLAIR: { accept: constant.TYPES.IMAGE, isImage: true }, //9
                CSV_USER_IMPORT: { accept: constant.TYPES.CSV }, //10
                MARKETPLACE_LOGO: {
                    accept: constant.TYPES.IMAGE,
                    isImage: true
                }, //11
                MARKETPLACE_CAMPAIGN_LOGO: {
                    accept: constant.TYPES.IMAGE,
                    isImage: true
                }, //12
                MARKETPLACE_PARTNER_LOGO: {
                    accept: constant.TYPES.IMAGE,
                    isImage: true
                }, //13
                MARKETPLACE_IMPORT_ZIP_FILE: { accept: constant.TYPES.ZIP }, //14
                MARKETPLACE_CAMPAIGN_FILE: { accept: constant.TYPES.ALL }, //15
                CSV_AUDIENCE_IMPORT: { accept: constant.TYPES.CSV }, //16
                MARKETPLACE_HEADER: {
                    accept: constant.TYPES.IMAGE,
                    isImage: true
                } //17
            };

            return constant;
        })()
    );
})();
