(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.ssmCommunityLibraryFile")
        .directive("ssmCommunityLibraryFile", ssmCommunityLibraryFile);

    function ssmCommunityLibraryFile() {
        return {
            templateUrl:
                "components/ssmCommunityLibraryFile/ssmCommunityLibraryFile.html",
            restrict: "E",
            scope: {
                fileData: "=",
                moveDirs: "=?",
                folderData: "=?",
                currentDirectory: "=?",
                currentDirectoryName: "=?",
                communityData: "=",
                sideBySide: "@",
                removeButton: "@",
                enableInfiniteScroll: "@"
            },
            replace: true,
            controller: ssmCommunityLibraryFileController,
            controllerAs: "vm",
            bindToController: true
        };
    }

    function ssmCommunityLibraryFileController(Analytics, Community) {
        /*jshint validthis: true */
        var vm = this;
        vm.removeFile = removeFile;
        vm.removeFolder = removeFolder;

        // InfiniteScroll
        vm.nextPage = nextPage;

        var POST_LIMIT = 1000;

        activate();

        function activate() {
            vm.fileData = vm.fileData || [];
            vm.folderData = vm.folderData || [];
            vm.moveDirs = vm.moveDirs || [];
            vm.currentDirectory = vm.currentDirectory || "root";
            vm.currentDirectoryName =
                vm.currentDirectoryName || "Community Files";
        }

        function nextPage(lastPost) {
            var query = { limit: POST_LIMIT };
            if (lastPost) {
                query.before_date = lastPost.create_date;
            }

            // THIS is where the library is loaded. Community.getLibray() is a service call that loads all data from the library (files, folders)
            if (vm.communityData) {
                var results = Community.getLibrary(vm.communityData.id, query);
                return results;
            }
        }

        // Connected to libraryFile.directive.js via on-remove-file variable from ssmCommunityLibraryFile.pug file
        function removeFile(file) {
            Analytics.eventTrack("removeFile", {
                category: "libraryFileCtrl",
                label: "file_id:" + file.id
            });
            Analytics.eventTrack2('remove_file', {
                category: "libraryFileCtrl",
                label: "file_id:" + file.id
            });
            return Community.removeFile(vm.communityData.id, file.id);
        }

        // Connected to libraryFile.directive.js via on-remove-folder variable from ssmCommunityLibraryFile.pug file
        function removeFolder(folder) {
            Analytics.eventTrack("removeFolder", {
                category: "libraryFileCtrl",
                label: "folder_id:" + folder.id
            });
            Analytics.eventTrack2('remove_folder', {
                category: "libraryFileCtrl",
                label: "folder_id:" + folder.id
            });
            return Community.removeFolder(vm.communityData.id, folder.id);
        }
    }
})();
