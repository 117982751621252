(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("ExternalInvitesCtrl", ExternalInvitesCtrl);

    function ExternalInvitesCtrl(
        AlertService,
        Analytics,
        CurrentUser,
        ExternalInvites,
        Logger,
        Tenant,
        $state,
        SSMTitle,
        $translate,
        WizardHandler
    ) {
        var vm = this;

        vm.finishedWizard = finishedWizard;
        vm.wizardNext = wizardNext;
        vm.emails = [];
        vm.communities = [];
        vm.invites = [];
        vm.existingUsers = [];
        vm.message = null;
        vm.submitting = false;
        let organizationId;

        activate();

        function activate() {
            $translate("TITLE.EXTERNAL_INVITES").then(function(title) {
                SSMTitle.setTitle(title);
            });
        }

        function finishedWizard() {
            $state.go("main.home");
        }

        function wizardNext(submit) {
            if (!vm.emails.length) {
                return $translate(
                    "EXTERNAL_INVITES_WIZARD.NO_EMAILS_ADDED_ERROR"
                ).then(function(translation) {
                    AlertService.add({
                        msg: translation,
                        type: "warning",
                        duration: 1,
                        animation: "fadeInRight"
                    });
                });
            }

            if (!submit) {
                WizardHandler.wizard("ExternalInvitesWizard").next();
                return;
            }

            Tenant.getTenant().then(function(tenant) {
                var payload = {
                    emails: vm.emails,
                    communityIds: vm.communities.map(function(community) {
                        return community.id;
                    }),
                    message: vm.message,
                    organizationId: tenant.organization_id
                };

                ExternalInvites.createInvites(payload)
                    .then(function(results) {
                        vm.submitting = false;
                        vm.invites = _.filter(results.invites, function(
                            invite
                        ) {
                            return invite.type === "external";
                        });

                        Analytics.eventTrack("sendEmailInvitations", {
                            category: "externalInvitesCtrl",
                            label: "user_id: " + CurrentUser.profile.id,
                            dimension2: "new invites" + vm.invites.length,
                            dimension4:
                                "existing invites" +
                                results.existingUsers.length
                        });
                        Analytics.eventTrack2("send_email_invitations", {
                            category: "externalInvitesCtrl",
                            label: "user_id: " + CurrentUser.profile.id,
                            dimension2: "new invites" + vm.invites.length,
                            dimension4:
                                "existing invites" +
                                results.existingUsers.length
                        });
                        vm.existingUsers = results.existingUsers;
                    })
                    .catch(function(response) {
                        if (
                            response.config &&
                            response.config.data &&
                            response.config.data.emails
                        ) {
                            response.config.data.emails =
                                response.config.data.emails.length +
                                " emails suppressed";
                        }

                        switch (response.status) {
                            case 403:
                                $translate(
                                    "EXTERNAL_INVITES_WIZARD.INVITE_PERMISSION_ERROR"
                                ).then(function(translation) {
                                    Logger.warning(
                                        "Error Creating Invites: ",
                                        translation,
                                        response
                                    );
                                });
                                break;
                            case 500:
                                $translate("ERROR.INTERNAL_SERVER_ERROR").then(
                                    function(translation) {
                                        Logger.warning(
                                            "Error Creating Invites: ",
                                            translation,
                                            response
                                        );
                                    }
                                );
                                break;
                            default:
                                $translate("ERROR.INTERNAL_SERVER_ERROR").then(
                                    function(translation) {
                                        Logger.error(
                                            "Error Creating Invites: ",
                                            translation,
                                            response
                                        );
                                    }
                                );
                                break;
                        }
                    });
            });

            vm.submitting = true;
            WizardHandler.wizard("ExternalInvitesWizard").next();
        }
    }
})();
