(function() {
    "use strict";

    angular
        .module("ssmAngularApp.recentlyViewedCommunities")
        .directive("recentlyViewedCommunities", RecentlyViewedCommunities);

    function RecentlyViewedCommunities() {
        return {
            templateUrl: "components/recentlyViewedCommunities/recentlyViewedCommunities.html",
            restrict: "E",
            replace: true,
            controller: RecentlyViewedCommunitiesCtrl,
            controllerAs: "communityHistory",
            bindToController: true
        };
    }

    function RecentlyViewedCommunitiesCtrl(
        CurrentUser
    ) {
        /*jshint validthis:true */
        var vm = this;

        vm.communityHistory = [];

        activate();

        function activate() {
            getRecentlyVisitedCommunities();
        }

        function getRecentlyVisitedCommunities() {
            if (currentUserHasNoCommunityHistory()) return;
            vm.communityHistory = CurrentUser.profile.recently_visited_communities;
        }

        function currentUserHasNoCommunityHistory() {
            return CurrentUser.profile.recently_visited_communities == null
                || CurrentUser.profile.recently_visited_communities.length == 0;
        }
    }
})();
