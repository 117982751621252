(function() {
    "use strict";

    angular
        .module("ssmAngularApp.feedback.service", ["ssmAngularApp.api"])
        .service("Feedback", Feedback);

    function Feedback(Api) {
        var feedback = Api.all("feedback");

        return {
            create: function(data) {
                return feedback.customPOST(data, "tickets");
            }
        };
    }
})();
