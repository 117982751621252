(function() {
    "use strict";

    angular
        .module("ssmAngularApp.blogCardBrief")
        .directive("blogCardBrief", blogCardBrief);

    function blogCardBrief() {
        return {
            templateUrl: "components/blogCardBrief/blogCardBrief.html",
            restrict: "E",
            replace: true,
            scope: {
                subject: "=",
                user: "=",
                shared: "@",
                editView: "@"
            },
            controller: blogCardBriefCtrl,
            controllerAs: "blog",
            bindToController: true
        };
    }

    function blogCardBriefCtrl($scope, $state, $translate, Blog) {
        /*jshint validthis:true */
        var vm = this;
        vm.clipBody = clipBody;
        vm.navigateToEdit = navigateToEdit;
        vm.deleteBlog = deleteBlog;
        vm.canEdit = false;
        vm.blogStatusClass = blogStatusClass;

        activate();

        function activate() {
            vm.maxCharCount = vm.shared ? 100 : 300;
            vm.canEdit = vm.user && vm.subject.author && vm.subject.canEdit;

            $scope.$watch(
                function() {
                    return vm.subject;
                },
                function() {
                    if (vm.subject) {
                        vm.subject.clippedBody = vm.clipBody(
                            vm.subject.plain_text
                        );
                        getBlogStatusText();
                    }
                }
            );
        }

        function getBlogStatusText() {
            switch (vm.subject.status) {
                case Blog.BLOG_STATUS.EDITING:
                    $translate("BLOG.STATUS.UNPUBLISHED").then(function(
                        translation
                    ) {
                        vm.blogStatusText = translation;
                    });
                    break;
                case Blog.BLOG_STATUS.PUBLISHED:
                    $translate("BLOG.STATUS.PUBLISHED").then(function(
                        translation
                    ) {
                        vm.blogStatusText = translation;
                    });
                    break;
                case Blog.BLOG_STATUS.DELETED:
                    $translate("BLOG.STATUS.DELETED").then(function(
                        translation
                    ) {
                        vm.blogStatusText = translation;
                    });
                    break;
                default:
                    vm.blogStatusText = "";
                    break;
            }
        }

        function clipBody(body) {
            if (!body) {
                return body;
            }

            var index = body.substring(0, vm.maxCharCount).lastIndexOf(" ");
            if (index < 0) {
                return body.substring(0, vm.maxCharCount);
            }

            return body.substring(0, index) + " ...";
        }

        function navigateToEdit() {
            $state.go("main.blogs.edit", { id: vm.subject.id });
        }

        function deleteBlog() {
            Blog.helper
                .deleteBlog(vm.subject.id, vm.subject.author.id)
                .then(function(result) {
                    vm.subject.status = Blog.BLOG_STATUS.DELETED;
                    getBlogStatusText();
                });
        }

        function blogStatusClass() {
            if (vm.subject.status === Blog.BLOG_STATUS.EDITING) {
                return "label-warning";
            } else if (vm.subject.status === Blog.BLOG_STATUS.PUBLISHED) {
                return "label-success";
            }

            return "label-danger";
        }
    }
})();
