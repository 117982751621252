(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.linktracker.service", ["ssmAngularApp.api"])
        .factory("LinkTracker", LinkTracker);

    function LinkTracker(Api) {
        return {
            report: function(query) {
                var format = query.format || "application/json";
                var params = {
                    skip: query.skip,
                    limit: query.limit,
                    end_date: query.end_date,
                    start_date: query.start_date
                };

                if (query.platforms) {
                    params["platforms"] = query.platforms;
                }

                return Api.one("link", "report").get(params, {
                    Accept: format
                });
            }
        };
    }
})();
