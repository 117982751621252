"use strict";

angular.module("ssmAngularApp").config(function($stateProvider) {
    $stateProvider.state("main.search.results", {
        url: "/{filter}?{q}{?showscore}{?continuationtoken}{?start?}{?size}",
        templateUrl: "layout/main/search/searchResults/searchResults.html",
        controller: "SearchresultsCtrl",
        controllerAs: "searchResults"
    });
});
