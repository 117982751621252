(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.layout")
        .controller(
            "MarketplaceResultsController",
            MarketplaceResultsController
        );

    function MarketplaceResultsController(
        $anchorScroll,
        $location,
        $state,
        $translate,
        Appa,
        CurrentUser,
        ssmMarketplace,
        ssmMarketplaceConstants,
        marketplaceResolve,
        SSMTitle,
        Tenant
    ) {
        var vm = this;
        var PAGER_THROTTLE = 500;

        vm.sortPreference;
        vm.locationHash = $location.hash();
        vm.marketplace = marketplaceResolve;
        vm.categoryId = $state.params.category;
        vm.isBackgroundDefault = true;
        vm.marketplaceListingRoute = "main.marketplace_listing({id: vm.listing.id})";

        vm.resultsPager = { 
            listings: [],
            disabled: false 
        };

        vm.fetchingPage = false;

        vm.filterList = ssmMarketplaceConstants.FILTERS;

        vm.getCategorySubtree = getCategorySubtree;
        vm.getNextPage = _.throttle(getNextPage, PAGER_THROTTLE);

        activate();

        function activate() {
            setMarketplaceTitle();

            Tenant.getTenant().then(function(tenant) {
                getCategoriesAndListings();
                determineBackgroundImageBasedOffTenant(tenant);
                
                // TODO: Figure out better way of handling alternate route names for marketplace
                if (tenant.organization_id == "5e6149be7ede1b6e0efc1ef7") {
                    vm.marketplaceListingRoute = "main.marketplace_npz_listing({id: vm.listing.id})";
                }

                vm.isApprovedUser = Appa.isApprovedUser(CurrentUser.profile, vm.marketplace.id);
            });
        }

        function setMarketplaceTitle() {
            $translate("MARKETPLACE_RESULTS.MAIN_PAGE_TITLE")
            .then(function(translation) {
                SSMTitle.setTitle(translation + " | " + vm.marketplace.name);
            });
        }

        function getCategoriesAndListings() {
            ssmMarketplace.getMarketplaceCategories(vm.marketplace.id)
            .then(function(categories) {
                vm.categories = categories;

                var categorySubtree = vm.getCategorySubtree(vm.categoryId);
                var search = {
                    category_ids: categorySubtree,
                    sort: "name",
                    sort_order: vm.marketplace.listings_sort,
                    skip: 0
                };

                _.forEach(vm.filterList, function(filter) {
                    search[filter.param] = $state.params[filter.param];
                });

                ssmMarketplace
                    .getMarketplaceListingsPaged(vm.marketplace.id, search)
                    .then(function(pager) {
                        vm.resultsPager = pager
                        if (vm.locationHash) {
                            $anchorScroll.yOffset = 100;
                            $anchorScroll();
                        }
                    });
            });
        }

        function getCategorySubtree(categoryId) {
            var allCategoryIds = [categoryId];
            for (var i = 0; i < allCategoryIds.length; i++) {
                var childCategoryIds = _.map(
                    vm.categories.categoryMap[allCategoryIds[i]],
                    "id"
                );
                allCategoryIds.push.apply(allCategoryIds, childCategoryIds);
            }
            return allCategoryIds;
        }

        function determineBackgroundImageBasedOffTenant(tenant) {
            if (tenant.marketplace_header && tenant.marketplace_header.external_url) {
                vm.backgroundUrl = tenant.marketplace_header.external_url;
                vm.isBackgroundDefault = false;
            } else {
                vm.backgroundUrl = "/assets/images/bg-marketplace.jpeg";
            }
        }

        function getNextPage() {
            if (vm.resultsPager.disabled) {
                return;
            }
            vm.fetchingPage = true;
            vm.resultsPager.next().finally(function() {
                vm.fetchingPage = false;
            });
        }
    }
})();
