(function() {
    "use strict";

    angular
        .module("ssmAngularApp.orgCardBrief")
        .directive("orgCardBrief", orgCardBrief);

    function orgCardBrief() {
        return {
            templateUrl: "components/orgCardBrief/orgCardBrief.html",
            restrict: "E",
            replace: true,
            scope: {
                subject: "=",
                border: "@",
                oneBtn: "@"
            },
            controller: orgCardBriefCtrl,
            controllerAs: "org",
            bindToController: true
        };
    }

    function orgCardBriefCtrl($translate) {
        /*jshint validthis:true */
        var vm = this;

        vm.getCityAndState = getCityAndState;
        vm.hasCityOrState = hasCityOrState;
        vm.getMemberCountText = getMemberCountText;

        var members_text;

        activate();

        function activate() {
            $translate("ORGANIZATION.MEMBERS").then(function(translation) {
                members_text = translation;
            });
        }

        function getMemberCountText() {
            if (!vm.subject.member_count) {
                return "";
            }

            return vm.subject.member_count + " " + members_text;
        }

        function hasCityOrState() {
            if (!vm.subject.addresses || !vm.subject.addresses.length) {
                return false;
            }

            var address = vm.subject.addresses[0];

            return address && (address.city || address.state);
        }

        function getCityAndState() {
            if (!hasCityOrState()) {
                return "";
            }

            var address = vm.subject.addresses[0];

            var pieces = [
                address.city ? address.city.trim() : undefined,
                address.state ? address.state.trim() : undefined
            ];

            return _.compact(pieces).join(", ");
        }
    }
})();
