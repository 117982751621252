(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.ssmFileUpload.service")
        .factory("ssmFileUpload", ssmFileUpload)
        .factory("ssmFileUploadHelper", ssmFileUploadHelper);

    function ssmFileUpload(ssmFileUploadHelper) {
        return {
            upload: upload,
            fileTypeToIcon: fileTypeToIcon
        };

        function upload(html5File, ssmFileInfo, onProgress) {
            var createPromise = ssmFileUploadHelper.createFile(ssmFileInfo);
            var uploadPromise = ssmFileUploadHelper.uploadFile(
                createPromise,
                html5File,
                onProgress
            );
            return ssmFileUploadHelper.updateFile(uploadPromise);
        }

        function fileTypeToIcon(file_type) {
            /*jshint maxcomplexity: false*/
            switch (file_type) {
                case "application/pdf":
                    return "file-pdf";

                case "application/zip":
                    return "file-zip";

                case "application/vnd.ms-excel":
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                    return "file-excel";

                case "video/mp4":
                case "video/x-msvideo":
                    return "file-movie";

                case "application/vnd.ms-powerpoint":
                case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                case fileTypeToIcon:
                    return "file-powerpoint";

                case "audio/mpeg":
                case "audio/mp4":
                    return "file-sound";

                case "application/msword":
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                    return "file-word";

                case "image/bmp":
                case "image/jpeg":
                case "image/gif":
                case "image/png":
                    return "file-photo";

                default:
                    return "file";
            }
        }
    }

    function ssmFileUploadHelper(
        $q,
        $translate,
        Analytics,
        Logger,
        ssmAuth,
        SSM_FILES,
        ssmFiles,
        Upload
    ) {
        var service = {
            createFile: createFile,
            updateFile: updateFile,
            uploadFile: uploadFile,
            uploadToAWS: uploadToAWS
        };

        return service;

        function createFile(fileInfo) {
            var create = ssmAuth.isSignedIn
                ? ssmFiles.create
                : ssmFiles.createNoAuth;

            return create(fileInfo)
                .then(function(file) {
                    Analytics.eventTrack("upload.started", {
                        category: "fileUpload",
                        label: "file_id: " + file.id
                    });
                    Analytics.eventTrack2("upload_started", {
                        category: "fileUpload",
                        label: "file_id: " + file.id
                    });

                    return file;
                })
                .catch(function(err) {
                    $translate("FILE.CREATE_ERROR").then(function(translation) {
                        Logger.error("ERROR: ", translation, err);
                    });
                    return $q.reject(err);
                });
        }

        function updateFile(uploadPromise) {
            var file;

            return uploadPromise
                .then(function(_file_) {
                    file = _file_;

                    var update = ssmAuth.isSignedIn
                        ? ssmFiles.update
                        : ssmFiles.updateNoAuth;

                    return update(file.id, {
                        status: SSM_FILES.STATUS.UPLOADED
                    });
                })
                .catch(function(err) {
                    if (file) {
                        $translate("FILE.UPDATE_ERROR").then(function(
                            translation
                        ) {
                            Logger.error("ERROR: ", translation, err);
                        });
                    }

                    return $q.reject(err);
                });
        }

        function uploadFile(createPromise, html5File, onProgress) {
            var file;

            return createPromise
                .then(function(_file_) {
                    file = _file_;
                    return service.uploadToAWS(html5File, file, onProgress);
                })
                .then(function() {
                    Analytics.eventTrack("upload.succeded", {
                        category: "fileUpload",
                        label: "file_id: " + file.id
                    });
                    Analytics.eventTrack2("upload_succeded", {
                        category: "fileUpload",
                        label: "file_id: " + file.id
                    });

                    return file;
                })
                .catch(function(err) {
                    if (file) {
                        Analytics.eventTrack("upload.failed", {
                            category: "fileUpload",
                            label: "file_id: " + file.id
                        });
                        Analytics.eventTrack2("upload_failed", {
                            category: "fileUpload",
                            label: "file_id: " + file.id
                        });


                        $translate("FILE.UPLOAD_ERROR").then(function(
                            translation
                        ) {
                            Logger.error("ERROR: ", translation, err);
                        });
                    }

                    return $q.reject(err);
                });
        }

        function uploadToAWS(html5File, file, onProgress) {
            return Upload.http({
                url: file.urls.upload,
                method: "PUT",
                data: html5File,
                headers: {
                    "Content-Type":
                        file.file_type !== ""
                            ? file.file_type
                            : "application/octet-stream"
                },
                transformRequest: function(data, headersGetter) {
                    // Remove the Authorization header - file uploads do not require auth
                    var headers = headersGetter();
                    delete headers.Authorization;
                    return data;
                }
            }).then(
                function(result) {
                    return result;
                },
                function(error) {
                    $q.reject(error);
                },
                onProgress
            );
        }
    }
})();
