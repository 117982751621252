(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.layout")
        .controller("NotificationIndexController", NotificationIndexController);

    function NotificationIndexController(
        CurrentUser,
        Logger,
        Notification,
        SSMTitle,
        $translate,
        $q,
        $stateParams
    ) {
        var vm = this;

        vm.loading = true;
        vm.notifications = [];
        vm.onNotificationIgnored = onNotificationIgnored;
        vm.onNotificationReviewed = onNotificationReviewed;
        vm.onNotificationVisited = onNotificationVisited;

        vm.pageTitle = "NOTIFICATION.INDEX.HEADER";

        activate();

        function activate() {
            SSMTitle.setTitle($translate(vm.pageTitle));
            refreshNotificationList();
        }

        function onNotificationIgnored(notification) {
            Notification.clearNotifications(
                $stateParams.user_id,
                notification
            ).then(refreshNotificationList);
        }

        function onNotificationReviewed(notification) {
            Notification.onNotificationsReviewed($stateParams.user_id, [
                notification
            ]).then(refreshNotificationList);
        }

        function onNotificationVisited(notification) {
            Notification.onNotificationVisted(
                $stateParams.user_id,
                notification
            ).then(refreshNotificationList);
        }

        // Only ignore if the active user is the same as the owner of the notification
        function onNotificationClicked(notification) {
            if (CurrentUser.profile.id === $stateParams.user_id) {
                Notification.onNotificationVisted(
                    $stateParams.user_id,
                    notification
                ).then(refreshNotificationList);
            }
        }

        function refreshNotificationList() {
            return resolveNotificationsList()
                .then(function(result) {
                    vm.notifications = result;
                })
                .finally(function() {
                    vm.loading = false;
                });
        }

        function resolveNotificationsList() {
            var deferred = $q.defer();

            Notification.getNotifications($stateParams.user_id, { limit: 50 })
                .then(deferred.resolve)
                .catch(function(error) {
                    switch (error.status) {
                        case 403:
                        case 404:
                            return deferred.resolve();
                        default:
                            $translate("NOTIFICATION.ERROR.DEFAULT").then(
                                function(translation) {
                                    Logger.error("ERROR: ", translation, error);
                                }
                            );
                            break;
                    }
                    return deferred.reject();
                });

            return deferred.promise;
        }
    }
})();
