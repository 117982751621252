(function() {
    "use strict";

    angular.module("ssmAngularApp.ssmFileUpload.service", [
        "ssmAngularApp.analytics",
        "ssmAngularApp.ssmFiles.service",
        "ssmAngularApp.logger",
        "pascalprecht.translate",
        "ssmAngularApp.ssmAuth.service",
        "ngFileUpload"
    ]);
})();
