(function() {
    "use strict";
    /* @ngInject */

    angular
        .module("ssmAngularApp.layout")
        .controller("OrgsEditBrandingController", OrgsEditBrandingController);

    function OrgsEditBrandingController(
        $translate,
        Logger,
        Organization,
        orgProfileResolve,
        ssmFileUpload,
        SSMTitle
    ) {
        var vm = this;
        vm.org = orgProfileResolve;
        vm.saveForm = saveForm;
        vm.resetForm = resetForm;
        vm.getColorExampleStyles = getColorExampleStyles;
        vm.onImageUpload = onImageUpload;
        vm.selectedFiles = [];
        vm.logoIndex = -1;

        activate();

        function activate() {
            $translate("ORGANIZATION.EDIT.SITE.BRANDING.TITLE").then(
                SSMTitle.setTitle
            );

            if (vm.org.site && vm.org.site.images) {
                vm.logoIndex = _.findIndex(vm.org.site.images, function(image) {
                    return (
                        image.name && image.name.toLowerCase() === "logo_main"
                    );
                });
            }
        }

        function saveForm(form) {
            var params = {
                site: vm.org.site
            };

            return Organization.update(vm.org.id, params)
                .then(function(results) {
                    updateOrg(results, form);
                    vm.flashSavedFlag();
                })
                .catch(function(error) {
                    Logger.error(
                        "ERROR: ",
                        "HTTP Server Error: " + error.status
                    );
                });
        }

        function resetForm(form) {
            Organization.getOrganization(vm.org.id)
                .then(function(results) {
                    updateOrg(results, form);
                })
                .catch(function(error) {
                    Logger.error(
                        "ERROR: ",
                        "HTTP Server Error: " + error.status
                    );
                });
        }

        function getColorExampleStyles(color, txt) {
            return {
                "background-color": color,
                color: txt,
                border: "none"
            };
        }

        function updateOrg(data, form) {
            if (!data) {
                return;
            }

            if (form && form.$setPristine) {
                form.$setPristine();
            }

            if (!vm.org) {
                vm.org = {};
            }

            vm.org.id = data.id;
            vm.org.site = data.site;

            if (vm.org.site) {
                vm.org.site.images = vm.org.site.images || [];
                vm.logoIndex = _.findIndex(vm.org.site.images, function(image) {
                    return (
                        image.name && image.name.toLowerCase() === "logo_main"
                    );
                });
            }
        }

        function onImageUpload() {
            if (vm.logoIndex < 0) {
                vm.org.site.images = vm.org.site.images || [];
                vm.logoIndex =
                    vm.org.site.images.push({
                        name: "logo_main",
                        url: "logo_nwc.png"
                    }) - 1; // Minus one to convert new array length to last item index
            }
            vm.org.site.images[vm.logoIndex].external_url = _.get(
                vm.uploadedImage[0],
                "urls.download"
            );
        }
    }
})();
