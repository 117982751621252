(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular
        .module("ssmAngularApp.connection.service", [
            "ssmAngularApp.api",
            "ssmAngularApp.currentUser"
        ])
        .factory("Connection", Connection);

    function Connection($q, Api) {
        var connectionApi = Api.all("connections");

        var PAGE_LIMIT = 20;

        var getAllPager = {
            items: [], // previously retrieved vendors
            disabled: false, // cannot retrieve more if we are already waiting or there are no more to get
            limit: PAGE_LIMIT,
            next: null
        };

        return {
            CONNECTION_STATUS: {
                REQUESTED: 0,
                APPROVED: 1,
                DECLINED: 2
            },
            getAll: getAll,
            getAllPaged: getAllPaged,
            getPending: getPending,
            createRequest: createRequest,
            deleteConnection: deleteConnection,
            approveRequest: approveRequest,
            declineRequest: declineRequest,
            getSuggestions: getSuggestions,
            getInvited: getInvited
        };

        function getAll(query) {
            return connectionApi.getList(query);
        }

        function getAllPaged() {
            if (!getAllPager.next) {
                // This function returns a promise that resolves to the entire paging object. Every time .next resolves, it
                // resolves to the same object with an updated state.
                getAllPager.next = function() {
                    if (getAllPager.disabled) {
                        return $q.when(getAllPager);
                    }

                    getAllPager.disabled = true;

                    var query = {
                        skip: getAllPager.items.length,
                        limit: getAllPager.limit
                    };
                    return getAll(query).then(function(items) {
                        if (items.plain) {
                            items = items.plain();
                        }

                        // if we got more vendors, add them to our list and enable paging
                        if (items && items.length) {
                            getAllPager.disabled = false;
                            getAllPager.items = _.union(
                                getAllPager.items,
                                items
                            );
                        }

                        return $q.when(getAllPager);
                    });
                };

                getAllPager.next(); // go ahead and start loading the first page
            }

            return getAllPager;
        }

        function getPending() {
            return connectionApi.all("pending").getList();
        }

        function createRequest(user_id) {
            return connectionApi.all("request").post({ user_id: user_id });
        }

        function deleteConnection(user_id) {
            return connectionApi.one(user_id).remove();
        }

        function approveRequest(user_id) {
            return connectionApi
                .one(user_id)
                .all("approve")
                .patch();
        }

        function declineRequest(user_id) {
            return connectionApi
                .one(user_id)
                .all("decline")
                .patch();
        }

        function getSuggestions() {
            return connectionApi.get("suggested");
        }

        function getInvited() {
            return connectionApi.get("invited");
        }
    }
})();
