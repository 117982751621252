(function() {
    "use strict";
    /**
     * @ngInject
     */

    angular.module("ssmAngularApp.content").factory("Content", Content);

    function Content(ContentApi) {
        return {
            all: all,
            findByPopularity: findByPopularity,
            findById: findById,
            create: create,
            update: update,
            remove: remove,
            createChildContent: createChildContent,
            voteForContent: voteForContent,
            removeVoteForContent: removeVoteForContent,
            createFeed: createFeed
        };

        function all(options) {
            return ContentApi.all(options);
        }

        function findByPopularity(options) {
            return ContentApi.findByPopularity(options);
        }

        function findById(content_id, options) {
            return ContentApi.findById(content_id, options);
        }

        function create(content) {
            return ContentApi.create(content);
        }

        function update(content_id, updatedContent) {
            return ContentApi.update(content_id, updatedContent);
        }

        function remove(content_id) {
            return ContentApi.remove(content_id);
        }

        function createChildContent(content_id, content) {
            return ContentApi.createChildContent(content_id, content);
        }

        function voteForContent(content_id, vote) {
            return ContentApi.voteForContent(content_id, vote);
        }

        function removeVoteForContent(content_id) {
            return ContentApi.removeVoteForContent(content_id);
        }

        function createFeed(params) {
            return ContentApi.createFeed(params);
        }
    }
})();
